import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
//services
import { MasterService } from '../../../services/master.service'
import { MessageService } from '../../../services/message.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  constructor(private master: MasterService,private ms: MessageService) { }

  public loading: boolean = false;
  public resetPassForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(150)]),
  })
  get email() { return this.resetPassForm.get('email') }

  ngOnInit() {
  }

  checkEmail(): void
  {
    if(!this.resetPassForm.valid) return;
    this.loading = true;
    let params = this.resetPassForm.getRawValue();
    this.master.sendPost('checkEmailReset', params, res => {
      // check if API conecction success
      if (res) {
        // In case success
        if (res.status == 200) {
          // if exist
          this.resetPassword();
        } else {
          // if not exist
          this.loading = false;
          this.ms.sendMessage("alert", { type: "warning", text: "We couldn't find any account associated with "+params.email });
        }
      }
    })
  }

  resetPassword(): void
  {
    let params = this.resetPassForm.getRawValue();
    this.master.sendPost('recoveryPassword', params, res => {
      // check if API conecction success
          this.loading = false;
      if (res) {
        // In case success
        if (res.status == 200) {
          this.ms.sendMessage("alert", { type: "success", text: "We've emailed you a reset password link." });
        } else {
          this.ms.sendMessage("alert", { type: "warning", text: res.data.error });
        }
      }
    })
  }
}
