import { Component, Input, OnInit,SimpleChanges } from '@angular/core';

@Component({
  selector: 'inactive-after-sales',
  templateUrl: './inactive-after-sales.component.html',
  styleUrls: ['./inactive-after-sales.component.scss']
})
export class InactiveAfterSalesComponent implements OnInit {
// ****************
//  variables
// ***************
@Input() language: string = 'EN'
//
@Input() data=[]
// ****************
//  functions
// ***************

// ****************
//  life cycles
// ***************
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if(changes.data){
      this.data['totalAmount']=this.data['afterSaleLines'].reduce( (total,value)=>total+parseFloat(value.amount), 0 )
    }
  }

}
