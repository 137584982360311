import { Component, Input, OnInit,Output,EventEmitter,SimpleChanges } from '@angular/core';
import { MessageService } from "../../../../../../services/message.service";
import { MasterService } from "../../../../../../services/master.service";

@Component({
  selector: 'history-payments',
  templateUrl: './history-payments.component.html',
  styleUrls: ['./history-payments.component.scss']
})
export class HistoryPaymentsComponent implements OnInit {
// *************
// * variables
// ************
//
@Input() paymentCategories: any = {}
//
@Input() statusCategories: any = {}
//
@Input() paymentMethodList: any = {}
//
@Input() data:Object={}
//
public rowSelected:Object={}
//
@Input() parents:Object[]=[]
// save the language
@Input() language: string =  'EN'
// set all words
@Input() words:Object = {}
//
public refund:any = { refundReason: '', refundAmount: 0, refundMethod: '' }
//
@Output() history$: EventEmitter<Object> = new EventEmitter();
// *************
// * functions
// ************
// todo: do bounced
public doMarkBounced = ():void => {
  let bouncedCharge = (document.getElementById('bounceCharge') as HTMLInputElement).value

  this.master.sendPost("CRUDForPayments", { bounceCharge: parseFloat(bouncedCharge), bouncedPayment: this.rowSelected, activity: 'update' }, (res) => {
    if (res) {
      if (res.status == 200) {
        // * success
        this.history$.emit({message:'reload Payments'});

        (document.getElementById('bounceCharge') as HTMLInputElement).value = ''
        this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
      } else {
        // ! error
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
      }
    } else {
      // in case API no response
      this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
    }
  }
  );
}
// todo: do refund
public doMarkRefund = () => {
  if (this.rowSelected['amountPaid'] >= this.refund.refundAmount) {
    this.master.sendPost("CRUDForPayments", { refundAmount: this.refund.refundAmount, refundMethod: this.refund.refundMethod, refundReason: this.refund.refundReason, refundPayment: this.rowSelected, activity: 'update' }, (res) => {
      if (res) {
        if (res.status == 200) {
          // * success
          this.history$.emit({message:'reload Payments'});
          this.refund = { refundReason: '', refundAmount: 0, refundMethod: '' }
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          // ! error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    }
    );
  } else {
    this.ms.sendMessage("alert", { type: "warning", text: 'The refund amount paid is greater than the amount paid.' });
  }
}
// *************
// * life cycles
// ************
  constructor(private ms: MessageService,private master: MasterService,) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if(changes.data){
      this.data=changes.data.currentValue

    }
  }

}
