import { Component, OnInit, Input, Output, EventEmitter,SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// services
import { MasterService } from '../../../../../services/master.service'
import { MessageService } from '../../../../../services/message.service'
// external libreries
import * as moment from 'moment'
@Component({
  selector: 'identifications',
  templateUrl: './identifications.component.html',
  styleUrls: ['./identifications.component.scss']
})
export class IdentificationsComponent implements OnInit {
  /*
 * variables
 */
  @Output() identifications$: EventEmitter<Object> = new EventEmitter()
  // save the language
  @Input() language: string =  'EN'
  // set all words
  @Input() words = {}
  //  define if loading state
  public loading: boolean = false
  // define the selected identification
  public identificationSelected: Object = { object: {}, index: 0 }
  // ontact object
  @Input() contact: Object = {}
  // permissions
  @Input() permissions: string[] = []
  // permissions number of identifications
  @Input() permissionsNumber: string[] = []
  // define the identification selected
  public selected: number = 0
  // save all identifications
  @Input() identificationList: Object[] = []
  // save new identifications
  public newIdentifications: Object[] = [{
    identificationTypeId: '',
    number: '',
    issuer: '',
    expirationDate: ''
  }]
  // define if is flat version
  @Input() isFlat: boolean = false
  // save the types
  public types: string[] = []
  // define if number is avaible
  public viewNumber: boolean = false
  //  define if some changes not saved
  public savePending: boolean = false
  // Group of inputs
  public information = new FormGroup({})
  // form to update some row
  public informationUpdate = new FormGroup({
    contactId: new FormControl(0, [Validators.required]),
    identificationTypeId: new FormControl('', [Validators.required]),
    number: new FormControl('', [Validators.required, Validators.pattern('[0-9]{1,50}')]),
    issuer: new FormControl('', [Validators.required]),
    expirationDate: new FormControl('', [Validators.required])
  })
  // get information of inputs
  get number() { return this.informationUpdate.get('number') }
  get issuer() { return this.informationUpdate.get('issuer') }
  get expirationDate() { return this.informationUpdate.get('expirationDate') }
  //
  public issuers = [
    {
      "name": "Alabama",
      "abbreviation": "AL"
    },
    {
      "name": "Alaska",
      "abbreviation": "AK"
    },
    {
      "name": "American Samoa",
      "abbreviation": "AS"
    },
    {
      "name": "Arizona",
      "abbreviation": "AZ"
    },
    {
      "name": "Arkansas",
      "abbreviation": "AR"
    },
    {
      "name": "California",
      "abbreviation": "CA"
    },
    {
      "name": "Colorado",
      "abbreviation": "CO"
    },
    {
      "name": "Connecticut",
      "abbreviation": "CT"
    },
    {
      "name": "Delaware",
      "abbreviation": "DE"
    },
    {
      "name": "District Of Columbia",
      "abbreviation": "DC"
    },
    {
      "name": "Federated States Of Micronesia",
      "abbreviation": "FM"
    },
    {
      "name": "Florida",
      "abbreviation": "FL"
    },
    {
      "name": "Georgia",
      "abbreviation": "GA"
    },
    {
      "name": "Guam",
      "abbreviation": "GU"
    },
    {
      "name": "Hawaii",
      "abbreviation": "HI"
    },
    {
      "name": "Idaho",
      "abbreviation": "ID"
    },
    {
      "name": "Illinois",
      "abbreviation": "IL"
    },
    {
      "name": "Indiana",
      "abbreviation": "IN"
    },
    {
      "name": "Iowa",
      "abbreviation": "IA"
    },
    {
      "name": "Kansas",
      "abbreviation": "KS"
    },
    {
      "name": "Kentucky",
      "abbreviation": "KY"
    },
    {
      "name": "Louisiana",
      "abbreviation": "LA"
    },
    {
      "name": "Maine",
      "abbreviation": "ME"
    },
    {
      "name": "Marshall Islands",
      "abbreviation": "MH"
    },
    {
      "name": "Maryland",
      "abbreviation": "MD"
    },
    {
      "name": "Massachusetts",
      "abbreviation": "MA"
    },
    {
      "name": "Michigan",
      "abbreviation": "MI"
    },
    {
      "name": "Minnesota",
      "abbreviation": "MN"
    },
    {
      "name": "Mississippi",
      "abbreviation": "MS"
    },
    {
      "name": "Missouri",
      "abbreviation": "MO"
    },
    {
      "name": "Montana",
      "abbreviation": "MT"
    },
    {
      "name": "Nebraska",
      "abbreviation": "NE"
    },
    {
      "name": "Nevada",
      "abbreviation": "NV"
    },
    {
      "name": "New Hampshire",
      "abbreviation": "NH"
    },
    {
      "name": "New Jersey",
      "abbreviation": "NJ"
    },
    {
      "name": "New Mexico",
      "abbreviation": "NM"
    },
    {
      "name": "New York",
      "abbreviation": "NY"
    },
    {
      "name": "North Carolina",
      "abbreviation": "NC"
    },
    {
      "name": "North Dakota",
      "abbreviation": "ND"
    },
    {
      "name": "Northern Mariana Islands",
      "abbreviation": "MP"
    },
    {
      "name": "Ohio",
      "abbreviation": "OH"
    },
    {
      "name": "Oklahoma",
      "abbreviation": "OK"
    },
    {
      "name": "Oregon",
      "abbreviation": "OR"
    },
    {
      "name": "Palau",
      "abbreviation": "PW"
    },
    {
      "name": "Pennsylvania",
      "abbreviation": "PA"
    },
    {
      "name": "Puerto Rico",
      "abbreviation": "PR"
    },
    {
      "name": "Rhode Island",
      "abbreviation": "RI"
    },
    {
      "name": "South Carolina",
      "abbreviation": "SC"
    },
    {
      "name": "South Dakota",
      "abbreviation": "SD"
    },
    {
      "name": "Tennessee",
      "abbreviation": "TN"
    },
    {
      "name": "Texas",
      "abbreviation": "TX"
    },
    {
      "name": "Utah",
      "abbreviation": "UT"
    },
    {
      "name": "Vermont",
      "abbreviation": "VT"
    },
    {
      "name": "Virgin Islands",
      "abbreviation": "VI"
    },
    {
      "name": "Virginia",
      "abbreviation": "VA"
    },
    {
      "name": "Washington",
      "abbreviation": "WA"
    },
    {
      "name": "West Virginia",
      "abbreviation": "WV"
    },
    {
      "name": "Wisconsin",
      "abbreviation": "WI"
    },
    {
      "name": "Wyoming",
      "abbreviation": "WY"
    }
  ]
  /*
  * functions
  */
  /*
  todo: detect changes
  */
  public changesPending = (): void => {
    if (!this.savePending) {
      this.savePending = true
      this.identifications$.emit({ message: 'changesPendingContacts', section: 'identifications', value: this.savePending })
    }
  }


  /*
  todo: create a new group form
  */
  public addIdentification = (e, index: number, newId: number): void => {
    this.changesPending()
    if (this.information.invalid && this.newIdentifications.length - 1 != index) {
      this.information.removeControl('identificationTypeId_' + (this.newIdentifications.length - 1))
      this.information.removeControl('number_' + (this.newIdentifications.length - 1))
      this.information.removeControl('issuer_' + (this.newIdentifications.length - 1))
      this.information.removeControl('expirationDate_' + (this.newIdentifications.length - 1))
      this.newIdentifications.pop()
    }

    if (this.newIdentifications[index]['expirationDate'] != null && moment(this.newIdentifications[index]['expirationDate']) < moment()) {
      this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['expirationError'] });
      return
    }

    if (index == this.newIdentifications.length - 1 && this.information.valid) {

      this.information.addControl('identificationTypeId_' + newId, new FormControl('', [Validators.required]));
      this.information.addControl('number_' + newId, new FormControl('', [Validators.required, Validators.pattern('[0-9]{1,50}')]));
      this.information.addControl('issuer_' + newId, new FormControl('', [Validators.required]));
      this.information.addControl('expirationDate_' + newId, new FormControl('', [Validators.required]));
      this.newIdentifications.push({ identificationTypeId: '', number: '', issuer: '', expirationDate: '' })
    }
  }
  /*
  todo: reload formules
  */
  public reloadFormules = (): void => {

    for (let index = 0; index < this.newIdentifications.length + 1; index++) {
      this.information.removeControl('identificationTypeId_' + index)
      this.information.removeControl('number_' + index)
      this.information.removeControl('issuer_' + index)
      this.information.removeControl('expirationDate_' + index)
    }

    this.information.addControl('identificationTypeId_0', new FormControl('', [Validators.required]));
    this.information.addControl('number_0', new FormControl('', [Validators.required, Validators.pattern('[0-9]{3,50}')]));
    this.information.addControl('issuer_0', new FormControl('', [Validators.required]));
    this.information.addControl('expirationDate_0', new FormControl('', [Validators.required]));
    //
    this.newIdentifications = []
    this.newIdentifications.push({ identificationTypeId: '', number: '', issuer: '', expirationDate: '' })

  }
  /*
  todo: save changes
  */
  public save = (): void => {
    this.loading = true
    // the informationUpdate form is used for check if last identification group is valid, else delete it
    this.informationUpdate.setValue({ ...this.newIdentifications[this.newIdentifications.length - 1], contactId: this.contact['id'] })
    if (this.informationUpdate.invalid) {
      if (this.newIdentifications.length > 1) {
        this.newIdentifications.pop()
      }
      this.informationUpdate.reset()
    }
    // set others atributes necessary
    this.newIdentifications.forEach(el => {
      el['contactId'] = this.contact['id']
      el['identificationTypeId'] = parseInt(el['identificationTypeId'])
    })

    this.master.sendPost('createContactIndetification', { identifications: this.newIdentifications }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          this.identifications$.emit({})
          // * success
          this.savePending = false
          this.identifications$.emit({ message: 'changesPendingContacts', section: 'identifications', value: this.savePending })
          // recharge user data
          // this.getData()
          this.reloadFormules()
          if (!this.isFlat) {
            this.identifications$.emit({ message: 'reloadContactDetail' });
            // close modal
            (document.getElementById('close-modal-identifications') as HTMLButtonElement).click();
          }
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })


  }
  /*
  todo: clean Fields
  */
  public cleanFields = (): void => {
    this.identificationSelected = { object: {}, index: 0 }
    this.information.reset()

    if(this.information.value['contactId']){

      this.information.get('contactId').setValue(this.contact['id'] ? this.contact['id']:0)
    }
  }
  /*
    ? update
    */
  /*
  todo: update some row
  */
  public update = (): void => {
    if (this.informationUpdate.valid) {
      this.loading = true
      let today = moment().format()
      let expirationDate = moment(this.informationUpdate.value['expirationDate']).format()

      if (expirationDate > today) {

        // * date success
        let objectChanged = { ...this.informationUpdate.value }
        objectChanged['identificationId'] = this.identificationSelected['object']['id']
        // set hour to date
        if (!objectChanged['expirationDate'].includes('00:00:00')) {
          objectChanged['expirationDate'] = objectChanged['expirationDate'] + ' 00:00:00'
        }

        this.master.sendPost('updateContactIdentification', objectChanged, res => {
          // set loading state
          this.loading = false;
          if (res) {
            if (res.status == 200) {
              // * success
              // recharge user data
              // this.getData();
              this.identificationSelected = { object: {}, index: 0 }
              if (!this.isFlat) {
                this.identifications$.emit({ message: 'reloadContactDetail' });
                // close modal
                (document.getElementById('close-modal-update-identifications') as HTMLButtonElement).click();
              }
              // clean the fields
              this.cleanFields()
              // send message
              this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
            } else {
              // ! send message in case error
              this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
            }
          } else {
            // in case API no response
            this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
          }
        })

      } else {
        // ! date error
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['expirationError'] });
        this.loading = false
      }
    }

  }
  /*
  todo: select identification update
  */
  public selectIdentificationUpdate = (identification: Object): void => {
    this.identificationSelected['object'] = identification
    this.informationUpdate.setValue({
      contactId: this.contact['id'],
      identificationTypeId: identification['identificationTypeId'],
      number: identification['number'],
      issuer: identification['issuer'],
      expirationDate: identification['expirationDate'],
    })
  }
  /*
   ? delete
   */
  /*
  todo: select identification
  */
  public selectIdentification = (identification: Object, index: number): void => {
    this.identificationSelected['object'] = identification
    this.identificationSelected['index'] = index
  }
  /*
  todo: delete identification
  */
  public delete = (): void => {
    this.loading = true
    this.master.sendPost('deleteEntity', { polimorphEntity: "identification", polimorphId: this.identificationSelected['object'].id }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.identificationSelected = { object: {}, index: 0 }
          if (!this.isFlat) {
            (document.getElementById('close-delete-modal-identification') as HTMLButtonElement).click();
          }
          this.identificationList.splice(this.identificationSelected['index'], 1)
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  /*
 todo: Quest before of delete
 */
  public questDelete = (identification: Object, index: number) => {
    if (confirm(this.words[this.language]['deleteIdentifications'])) {
      this.selectIdentification(identification, index)
      this.delete()
    }
  }
  /*
  todo: change identification
  */
  public changeIdentification = (value): void => {
    this.selected = value
  }
  /*
 todo: define the identification default
 */
  public selectDefault = (id: number, index: number): void => {

    this.master.sendPost('selectPrimaryEntity', { polimorphId: id, contactId: this.contact['id'], polimorphEntity: "identification" }, res => {
      // set loading state
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          // set default
          this.identificationList.map(el => el['primary'] = '0')

          this.identificationList[index]['primary'] = "1"

          this.selected = id
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  /*
  todo: get types of identifications
  */
  public getTypes = (): void => {
    this.master.sendPost('identificationTypes', {}, res => {
      if (res) {
        if (res.status == 200) {
          this.types = res.data.types
        } else {
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }

  /*
  * Life Cycles
  */
  constructor(private master: MasterService, private ms: MessageService) {

  }

  ngAfterContentInit(): void {
    this.information.addControl('identificationTypeId_0', new FormControl('', [Validators.required]));
    this.information.addControl('number_0', new FormControl('', [Validators.required, Validators.pattern('[0-9]{1,50}')]));
    this.information.addControl('issuer_0', new FormControl('', [Validators.required]));
    this.information.addControl('expirationDate_0', new FormControl('', [Validators.required]));
  }

  ngOnInit() {

    // if (this.contact['identifications'].length > 0) {
    //   let object = this.contact['identifications'].filter(el => el.primary == 1)
    //   if (object.length > 1) {
    //     this.selected = object[0].id
    //   }
    // }
    this.getTypes()
    // this.identificationList = this.contact['identifications']
    this.cleanFields()
    // recarge data

  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if(changes.identificationList && changes.identificationList.currentValue!=undefined){
      if (changes.identificationList.currentValue.length > 0) {
        let object = changes.identificationList.currentValue.filter(el => el.primary == 1)
      if (object.length > 1) {
        this.selected = object[0].id
      }
      }
    }
  }


}
