import { Component, Input, OnInit,EventEmitter,Output,SimpleChanges } from '@angular/core';
import {MessageService} from '../../../../../../services/message.service'
import * as moment from 'moment';
@Component({
  selector: 'expected-payments-table',
  templateUrl: './expected-payments-table.component.html',
  styleUrls: ['./expected-payments-table.component.scss']
})
export class ExpectedPaymentsTableComponent implements OnInit {
  // ************************
  // variables
  // ***********************
  @Input() statusCategories: any = {}
  // save the language
  @Input() language: string = 'EN'
  // set all words
  @Input() words = {}
  //
  public flagOnePayment: boolean = false
  //
  @Input() paymentSelected = {}
  //
  @Input() data:Object={}
  //
  @Input() paymentCategories:Object={}
 //
 public filtersSelected = []
 //
 public filters = ['Initial Down', 'Deferred Down', 'MV Loan', 'After-Sale Charges', 'CPI', 'Late Fee', 'NSF']
//
 @Output() expectedPayments$: EventEmitter<Object> = new EventEmitter();
  // ************************
  // functions
  // ***********************
//
public toggleSelectPayment = (payment:Object, index:number) => {

  if (this.paymentSelected['id'] == payment['id']) {
    // ? same id
    this.paymentSelected = {}
  } else {
    // ? change payment selected
    let category = this.data['hierarchy'].find(el => el['id'] == payment['paymentCategoryId'])
    this.flagOnePayment = category.flag && !this.data['paymentsExpected'].some(el => el['categoryname'] == 'Initial Down' || el['categoryname'] == 'Deferred Down')
    if (this.flagOnePayment) {
      this.data['paymentsExpected'].forEach(el => el['amountAllocated'] = '')
      this.ms.channelComponents$.emit({message:'onePayment', paymentSelected:{...this.paymentSelected,value:payment['amountDue'] - payment['amountPaid']}})
      // this.ms.channelComponents$.emit({message:'onePayment', value:})
    }
    this.paymentSelected = { ...payment, index: index }
  }
  this.expectedPayments$.emit({message:'set payment selected', paymentSelected:this.paymentSelected})

}
// todo: recalculate amount paid
public recalculateAmountPaid=(payment:Object, index:number)=>{
  let fixNumber=(number:string):number=>{return parseFloat((+number).toFixed(2))}
  if( fixNumber(payment['amountAllocated']) > (fixNumber(payment['amountDue']) -  fixNumber(payment['amountPaid']))) {

    payment['amountAllocated']=(fixNumber(payment['amountDue']) -  fixNumber(payment['amountPaid']))
  }
  this.paymentSelected = { ...payment, index: index }
  this.ms.channelComponents$.emit({message:'set payment selected', table:this.data['paymentsExpected']})
  this.expectedPayments$.emit({message:'set payment selected', paymentSelected:this.paymentSelected})
}
// toggle a filter
public toggleFilter = (index) => {

  if (this.filtersSelected.includes(this.filters[index])) {
    this.filtersSelected.splice(this.filtersSelected.indexOf(this.filters[index]), 1)
  } else {
    this.filtersSelected.push(this.filters[index])
  }
  let data = []

  this.filtersSelected.forEach(category => {
    data = data.concat(this.data['paymentsExpectedAll'].filter(el => el['categoryname'] == category))
  })

  this.data['paymentsExpected'] = this.filtersSelected.length > 0 ? data : this.data['paymentsExpectedAll']

  this.data['paymentsExpected']=this.data['paymentsExpected'].sort((a,b)=>a['orderFirstNumber']>b['orderFirstNumber']?1:-1)
}
// todo: reload payments
public reloadPayments=():void=>{

  this.expectedPayments$.emit({message:'reloadPayments'})
}
  // ************************
  // life cycles
  // ***********************
  constructor(private ms:MessageService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.data && changes.data.currentValue != undefined) {

      // this.data['paymentsExpected']= this.data['paymentsExpected'].filter(el=> !(el['categoryname']=='Deferred Down' || el['categoryname']=='Initial Down') )
      this.data['paymentsExpectedAll'] = this.data['paymentsExpected'].slice()

      this.data['firstPayments'] = this.data['paymentsExpected'].some(el=>el['categoryname']=='Deferred Down' || el['categoryname']=='Initial Down')
      for (let index = 0; index < this.data['paymentsExpected'].length; index++) {
        this.data['paymentsExpected'][index]['orderFirstNumber']=index
        this.data['paymentsExpected'][index]['flag'] = this.data['hierarchy'].find(el => el['name'] == this.data['paymentsExpected'][index]['categoryname'])
        this.data['paymentsExpected'][index]['flag'] = this.data['paymentsExpected'][index]['flag']?this.data['paymentsExpected'][index]['flag']['flag']:false
        this.data['paymentsExpected'][index]['amountAllocated'] = ''
        this.data['paymentsExpected'][index]['amountDue'] = parseFloat(this.data['paymentsExpected'][index]['amountDue'])
        this.data['paymentsExpected'][index]['amountDue'] =
          parseFloat(this.data['paymentsExpected'][index]['amountDue']) +
          (this.data['paymentsExpected'][index].bounceCharge ? parseFloat(this.data['paymentsExpected'][index].bounceCharge) : 0)
      }
    }
    if(changes.paymentCategories){

      this.filters= Object.values(changes.paymentCategories.currentValue)

    }
  }

}
