import { Component, Input, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// services
import { MessageService } from '../../../../services/message.service'
import { MasterService } from '../../../../services/master.service'
// external
import * as languageLibrary from '../../../../services/language'
import * as moment from 'moment'

@Component({
  selector: 'inventory-images-list',
  templateUrl: './inventory-images-list.component.html',
  styleUrls: ['./inventory-images-list.component.scss']
})
export class InventoryImagesListComponent implements OnInit {
  /*
    * Variables
    */
  //  calc the right to carousel -> right: calc(224px + 2rem)
  public calcRight: number = 0
  // sumatory Right
  public sumatoryRight: string
  // define the vehicleId
  @Input() vehicleId: string = ''
  // permissions
  @Input() permissions = []
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  // images array
  public images = []
  //  define the image selected
  public imageSelected: Object = {}
  // define expanded
  public expanded: boolean = true
  //  save headers
  public headers: Object[] = [
    { name: 'Image' }, { name: 'Caption' },
    { name: 'Description' }, { name: 'uploadedBy' },
    { name: 'uploadedDate' }, { name: 'modifiedBy' },
    { name: 'modifiedDate' }
  ]
  // loading state
  public loading: boolean = false
  //  save all data
  public data: Object[] = []

  // Group of inputs
  public information = new FormGroup({
    imageId: new FormControl('', [Validators.required]),
    caption: new FormControl('', []),
    description: new FormControl('', [])
  })
  get caption() { return this.information.get('caption') }
  get description() { return this.information.get('description') }
  /*
  * functions
  */
  public sort = (): void => {
    let ids = this.data.map(el => el['id'])
    this.master.sendPost('sortImages', { imagesIdArr: ids }, res => {
      if (res) {
        if (res.status == 200) {
          // * success
          // this.getData()
        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  public drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.data, event.previousIndex, event.currentIndex);
    this.sort()
    this.ms.channelComponents$.emit({message:'reloadImages'})
  }

  public formatBytes = (bytes: number): string => {
    return (bytes / 1024).toFixed(2) + " KB"
  }
  //  cut base64 string
  public subStringB64 = (word: string): string => {
    let index = word.indexOf(',')
    return word.substr(index + 1)
  }
  // get iamege and convert to base64
  public changeImage = (e): void => {
    // let e={...y}
    // y.target.value=''
    let files = []
    let reader = []
    let vm = this

    files = e.target.files


    for (let index = 0; index < files.length; index++) {
      // check if some file is invalid
      if (['image/jpg', 'image/jpeg', 'image/heic', 'image/heif', 'image/png'].includes(files[index].type)) {
        // * format valid
        reader[index] = new FileReader();
        reader[index].readAsDataURL(files[index]);
        reader[index].onload = function (event) {
          vm.images.push({
            'name': files[index].name,
            '1024': '',
            '480': '',
            'img': ''
          })

          vm.resizeImge(reader[index].result, 1024, 768, vm.images.length - 1, vm.images, '1024')
          vm.resizeImge(reader[index].result, 480, 360, vm.images.length - 1, vm.images, '480')
          // reload
          if (index == files.length - 1) {
            let x = (document.getElementById('file-upload-input') as HTMLInputElement);
            x.value = ""
          };
        }

      } else {
        // ! invalid format
        this.ms.sendMessage("alert", { type: "danger", text: 'Invalid image format' });
      }
    }


    // this.images[0].imageOpt = this.resizeImge(e.target.files[0], 100)
  }
  // resize the base64 image
  public resizeImge(image, maxWidth, maxHeight, index, images, imageFormat) {
    let img = new Image();
    img.src = image;
    img.onload = () => {
      let canvas = (document.createElement('canvas') as HTMLCanvasElement)
      // size
      const MAX_WIDTH = maxWidth
      const MAX_HEIGHT = maxHeight
      let width = img.width
      let height = img.height

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width
          width = MAX_WIDTH
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height
          height = MAX_HEIGHT
        }
      }
      // size
      canvas.width = width
      canvas.height = height
      let cx = canvas.getContext('2d')

      cx.drawImage(img, 0, 0, width, height)
      // console.log(canvas.toDataURL());
      images[index][imageFormat] = this.subStringB64(canvas.toDataURL())
      images[index]['img'] = canvas.toDataURL();
    }

  }

  // delete image pre upload
  public delete = (index: number): void => {
    this.images.splice(index, 1)
    this.loading = true
    this.master.sendPost('deleteImagesByKey', {imageKey: index, vehicleId: this.vehicleId}, res =>{
      this.loading = false
      if (res) {
        if (res.status == 200) {
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // ! in case error
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // select a image
  public selectImage = (image: Object, index: number) => {
    this.imageSelected = { ...image, index: index }
    this.information.setValue({
      imageId: image['id'],
      caption: image['caption'],
      description: image['description']
    })
  }
  // select a image
  public changeIndexImage = (next: boolean) => {

    let newIndex
    if (next) {
      // check if index can increase 1
      this.imageSelected['index'] < this.data.length - 1 ? newIndex = this.imageSelected['index'] + 1 : newIndex = 0

    } else {
      // check if index can decrease 1
      this.imageSelected['index'] > 0 ? newIndex = this.imageSelected['index'] - 1 : newIndex = this.data.length - 1

    }

    this.information.reset()
    let image = this.data[newIndex]
    this.imageSelected = { ...image, index: newIndex }

    this.information.setValue({
      imageId: image['id'],
      caption: image['caption'],
      description: image['description'],
    })
  }

  // upload array images
  public upload = (): void => {
    this.loading = true
    let arrImg = this.images.map(el => {
      return {
        '1024': el['1024'],
        '480': el['480']
      }
    })

    this.master.sendPost('uploadImages', { lastSort: this.data.length + 1, moduleType: 'inventory', imagesArr: arrImg, vehicleId: this.vehicleId, inventoryId: this.vehicleId }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.getData()
          this.images = []
          this.ms.sendMessage("alert", { type: "success", text: 'Success' });
          this.ms.channelComponents$.emit({message:'reloadImages'})
        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // upload array images
  public getData = (): void => {
    this.loading = true
    this.master.sendPost('readImagesByInventory', { vehicleId: this.vehicleId }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          // this.data[0]['img']=res.data.uploads[2].fileURL+'480.jpg'
          this.data = res.data.uploads
          this.data.map(el => {
            el['updatedAt'] = moment(el['updatedAt']).format('YYYY-MM-DD')
            el['createdAt'] = moment(el['createdAt']).format('YYYY-MM-DD')
          })
        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // save changes
  public save = (index): void => {
    this.loading = true
    let resForm={...this.information.value}
    this.master.sendPost('updateImagesById', this.information.value, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          // set caption
          this.data[index]['caption'] = resForm['caption']
          // set description
          this.data[index]['description'] = resForm['description']
          this.ms.sendMessage("alert", { type: "success", text: 'Success' });
        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // select a new primary
  public changePrimary = (): void => {

    // let dataCopy=.slice()
    let aux = this.data[this.imageSelected['index']]
    this.data.splice(this.imageSelected['index'], 1)
    this.data.unshift(aux)
    this.imageSelected = { ...this.data[0], index: 0 }
    this.sort()
  }
  /*
   * life cycles
   */
  constructor(private ms: MessageService, public master: MasterService) {
    this.ms.channelComponents$.subscribe(res => {
      if (res.message == 'changeLanguage') {
        this.language = res.value
      }
    })
  }

  ngOnInit() {
    this.getData()
  }

}
