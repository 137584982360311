
import { Component, OnInit, Input,Output,EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MasterService } from '../../../../services/master.service'
import { MessageService } from '../../../../services/message.service'
import { StoreService } from '../../../../services/store.service'
import { Router, ActivatedRoute } from '@angular/router';
import * as languageLibrary from '../../../../services/language'

@Component({
  selector: 'addresses-corporate',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss']
})
export class AddressesCorporateComponent implements OnInit {
  /*
  * variables
  */
  @Output() childEmitter$:EventEmitter<Object>=new EventEmitter()
  // save the language
  @Input()  language: string =  'EN'
  // set all words
  public words = languageLibrary.language
  //  implement corporate object
  @Input() corporate: Object = {}
  // define the selected address
  public addressSelected: Object = { object: {}, index: 0 }
  // define the address selected
  public selected: number = 0
  // save all addresses
  public addressesList: Object[] = []
  // save the types
  public types: Object[] = [{ id: 1, name: 'billing' }, { id: 2, name: 'home' }, { id: 3, name: 'mailing' }, { id: 4, name: 'work' }]
  // save the rent or own
  public rentOwn: string[] = ['Rent', 'Own', 'Other']
  // define the contact type
  public type: string = ''
  //  define if some changes not saved
  public savePending: boolean = false
  // define the contact type
  public id: number = 0
  // define if loading state
  public loading: boolean = false
  // zip code list
  public zipCodeList: Object[] = []
  // set time out
  private timeOutID: any;
  // permissions
  @Input() permissions: Object[] = []
  // addresslokup
  public addressLookup: Object = {
    city: "",
    county: "",
    state: "",
    zipcode: "",
    country: ""
  }
  public expanded: boolean = true
  // Group of inputs
  public information = new FormGroup({
    entityId: new FormControl(this.id, [Validators.required]),
    entity: new FormControl(this.type, [Validators.required]),
    addressTypeId: new FormControl('', [Validators.required]),
    // status: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    address2: new FormControl('', []),
    zipCode: new FormControl('', [Validators.required, Validators.pattern('[0-9]{3,10}')]),
    city: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    county: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required]),
    createCommunication: new FormControl(false, [Validators.required]),
    updateGeneralInfo: new FormControl(true, [Validators.required]),
    createAddress: new FormControl(false, [Validators.required])
  })
  // get information of inputs
  get address() { return this.information.get('address') }
  get address2() { return this.information.get('address2') }
  get zipCode() { return this.information.get('zipCode') }

  /*
  * functions
  */
  // todo: detect changes
  changesPending = () => {
    if (!this.savePending) {
      this.savePending = true
      this.childEmitter$.emit({ message: 'changesPendingCorporate', section: 'Addresses', value: this.savePending })
    }
  }
  // change the addresses-type
  changeType = (e, type): void => {
    this.changesPending()
    this.information.get(type).setValue(e.target.value, {
      onlySelf: true
    })
  }

  // clean Fields
  public cleanFields = (): void => {

    this.zipCodeList = []
    this.information.reset()

    this.information.setValue({
      entityId: this.id,
      entity: this.type,

      addressTypeId: '',
      // status: '',
      address: '',

      address2: '',
      zipCode: '',
      city: '',

      state: '',
      county: '',
      country: '',

      createCommunication: false,
      updateGeneralInfo: false,
      createAddress: true
    })
  }
  /*
  ?: create
  */
  // save
  public save = (afterClose: boolean): void => {

    if (this.information.valid) {
      this.loading = true
      // check the end date value
      // * date success
      let object = { ...this.information.value }
      object['status'] = "Other"
      object['addressTypeId'] = parseInt(this.information.value['addressTypeId'])
      //  save address
      this.master.sendPost('updateEntityContact', object, res => {
        this.loading = false
        if (res) {
          if (res.status == 200) {
            // * success
            // not save pending right now
            this.savePending = false
            this.childEmitter$.emit({ message: 'changesPendingCorporate', section: 'Addresses', value: this.savePending })
            // close modal
            if (afterClose) {
              (document.getElementById('btn-cancel-addresses') as HTMLButtonElement).click();
            }
            // recharge user data

            this.getData()
            this.childEmitter$.emit({ message: 'reloadCorporationDetail' })
            // clean the fields
            this.cleanFields()
            // send message
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          } else {
            // ! send message in case error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });

          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });

        }
      })
    }

  }

  /*
  todo: update
  */
  // save
  public update = (): void => {
    if (this.information.valid) {
      this.loading = true
      // * date success
      let objectChanged = { ...this.information.value }
      this.information.value['status'] = "Other"
      objectChanged['addressTypeId'] = parseInt(this.information.value['addressTypeId'])
      objectChanged['addressId'] = this.addressSelected['object']['id']

      //  save address
      this.master.sendPost('updateContactAddress', objectChanged, res => {
        // set loading state
        this.loading = false

        if (res) {
          if (res.status == 200) {
            // * success
            // recharge user data
            this.getData()
            this.childEmitter$.emit({ message: 'reloadCorporationDetail' });
            // close modal
            (document.getElementById('btn-close-addresses-update') as HTMLButtonElement).click();
            // clean the fields
            this.cleanFields()
            // send message
            if (this.addressSelected['object']['primaryAddress'] == 1) {
              // in case default address
              this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
            } else {
              // in another case
              this.ms.sendMessage("alert", { type: "success", text: res.data.message });

            }
          } else {
            // ! send message in case error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
            this.loading = false
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
          this.loading = false
        }
      })

    }

  }
  // select address update
  public selectAddressUpdate = (address: Object): void => {
    this.addressSelected['object'] = address
    this.information.setValue({
      addressTypeId: address['addressTypeId'],
      address: address['address'],
      address2: address['address2'],
      zipCode: address['zipCode'],
      city: address['city'],
      state: address['state'],
      county: address['county'],
      country: address['country'],
      entityId: this.id,
      entity: this.type,
      createCommunication: false,
      updateGeneralInfo: false,
      createAddress: true
    })
  }
  /*
 ! delete
 */
  // select address
  public selectAddress = (address: Object, index: number): void => {
    this.addressSelected['object'] = address
    this.addressSelected['index'] = index
  }
  // delete address
  public delete = (): void => {
    this.loading = true

    this.master.sendPost('deleteEntity', { polimorphEntity: "address", polimorphId: this.addressSelected['object'].id }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success

          (document.getElementById('close-delete-modal') as HTMLButtonElement).click();
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          // delete from array
          this.addressesList.splice(this.addressSelected['index'], 1)
          this.getData()
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });

        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });

      }
    })
  }
  /*
 * select defaul
 */
  // define the address default
  public selectDefault = (id: number): void => {
    let object = {}
    switch (this.type) {
      case 'corporate':
        object = { polimorphId: id, polimorphEntity: "address", corporateId: this.id }
        break;
      case 'dealership':
        object = { polimorphId: id, polimorphEntity: "address", dealershipId: this.id }
        break;
      default:
        object = { polimorphId: id, polimorphEntity: "address", lotId: this.id }
        break;
    }
    this.master.sendPost('selectPrimaryEntity', object, res => {
      if (res) {
        if (res.status == 200) {
          // * success
          // set loading state
          this.loading = false
          let selected = this.corporate['addresses'].filter(el => el.id == id)
          this.corporate['addresses'].map(el => el.primaryAddress = 0)
          // send message
          selected[0].primaryAddress = 1
          // this.selected = id
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          this.loading = false
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        this.loading = false
      }
    })
  }

  // ? address loockup
  // verify if the corporate is already exist
  public searchZipCode(): void {

    if (this.addressLookup['zipcode'].length < 3) {
      return;
    }

    clearTimeout(this.timeOutID);
    this.timeOutID = setTimeout(() => {
      this.zipCodeList = []
      this.master.sendPost('getLocationByZipcode', { zipcode: this.addressLookup['zipcode'] }, res => {
        if (res) {
          // check if API conecction success
          if (res.status == 200) {
            // * success
            this.zipCodeList = res.data.locations
            // autocomplete
            if (this.addressLookup['zipcode'].length >= 5) {
              this.selectReference(this.zipCodeList[0])
            }
          }
        } else {
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })
    }, 750);

    /**/
  }

  // set the address exist
  public selectReference = (adrress: Object): void => {
    // set address lockup only view
    this.addressLookup['city'] = adrress['city']
    this.addressLookup['state'] = adrress['state']
    this.addressLookup['county'] = adrress['county']
    this.addressLookup['zipcode'] = adrress['zipcode']
    this.addressLookup['country'] = 'United States'
    // set address for formuler
    this.information.value['city'] = adrress['city']
    this.information.value['state'] = adrress['state']
    this.information.value['county'] = adrress['county']
    this.information.value['zipCode'] = adrress['zipcode']
    this.information.value['country'] = 'United States'

    this.zipCodeList = []
  }

  // open the location
  public openLocation = (address: string, county: string, state: string): void => {
    address = address.replace(' ', '+')
    county = county.replace(' ', '+')
    state = state.replace(' ', '+')
    console.info(`https://www.google.com/maps/search/?api=1&query=${address}+${county}+${state}`);

    window.open(`https://www.google.com/maps/search/?api=1&query=${address}+${county}+${state}`, '_blank')
  }
  // find primary address
  public findPrimaryAddress = (): void => {
    if (this.corporate['addresses']) {
      let primary = this.corporate['addresses'].filter(el => el.primaryAddress == 1)
      if (primary.length > 0) {
        this.selected = primary[0].id
      }
    }
  }
  /*
  todo: refresh data
  */
  public getData = (): void => {
    this.master.sendPost('corporateDetails', { entity: this.type, entityId: this.id }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.addressesList = res.data.data['addresses']
          if(this.type=='corporate'){

            this.store.userAccount['corporateAddresses']=res.data.data['addresses']
          }
        } else {
          // ! error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // api no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }

  /*
  * Life cicles
  */
  constructor(private master: MasterService, private ms: MessageService, private route: ActivatedRoute,private store:StoreService) {
    this.type = this.route.snapshot.params['type']
    this.id = parseInt(this.route.snapshot.params['id'])
  }

  ngOnInit() {
    this.addressesList = this.corporate['addresses']
    this.findPrimaryAddress()

  }

}
