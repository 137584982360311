import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// services
import { MasterService } from '../../../../services/master.service'
import { MessageService } from '../../../../services/message.service'
import * as languageLibrary from '../../../../services/language'
@Component({
  selector: 'notes-inventory',
  templateUrl: './notes-inventory.component.html',
  styleUrls: ['./notes-inventory.component.scss']
})
export class NotesInventoryComponent implements OnInit {
  /*
 * variables
 */
  @Input() isEdit:boolean=true
  @Input() isFlat:boolean=false
// save the language
public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
// set all words
public words = languageLibrary.language
  // define note selected
  public noteSelected: number = 0
  // define note selected
  public noteIndex: number = 0
  // define if form window is open
  public windowOpen: boolean = true
  // loading state
  public loading: boolean = false
  // permissions
  @Input() permissions: string[] = []
  // permissions
  @Input() vehicleId: string = ''
  // save the note
  public notes = []
  // Group of inputs
  public information = new FormGroup({
    text: new FormControl('', [Validators.required]),
    alertFlag: new FormControl(false, [])
  })
  // Group of inputs
  public informationUpdate = new FormGroup({
    text: new FormControl('', [Validators.required]),
    noteId: new FormControl('', [Validators.required]),
    alertFlag: new FormControl(false, [Validators.required]),
    entityId: new FormControl(false, [Validators.required]),
    createdAt: new FormControl(false, [Validators.required]),
  }) /*
  * functions
  */


  // get notes
  public getNotes = (): void => {
    this.loading = true
    
    this.master.sendPost('getNotes', { entityId: this.vehicleId, type: 'inventory' }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          if(res.data.alertTransform.length>0){
            (document.getElementById('btn-note-alert')as HTMLButtonElement).click()
          }
          this.notes = res.data.alertTransform.concat(res.data.normalTransform)
        } else {
          // ! send message in case error 
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text:  this.words[this.language][ 'apiNoResponse' ] });
      }
    })
  }
  // add new note
  public save = (): void => {
    if (this.information.value) {
      this.loading = true
      this.master.sendPost('createNote', { ...this.information.value, entityId: this.vehicleId, type: 'inventory' }, res => {

        if (res) {
          if (res.status == 200) {
            // * success
            this.loading = false
            
            this.notes.push({ ...res.data.note })
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language][ 'success' ] });
            this.information.get('text').setValue('')
            this.information.get('alertFlag').setValue(false)
            this.ms.channelComponents$.emit({ message: 'reloadInventoryDetails' })
          } else {
            this.loading = false
            // ! send message in case error 
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          this.loading = false
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language][ 'apiNoResponse' ] });
        }
      })
    }

  }

  public cancel = (): void => {
    this.noteSelected = 0
  }
  /*
  todo: update functions
  */
  // set note form for update
  public setNote = (note: Object, index: number,edit:boolean): void => {
    if(edit){
      this.noteSelected = note['id']
    }
    this.noteIndex = index
    this.informationUpdate.setValue({
      text: note['text'],
      noteId: note['id'],
      alertFlag: note['alertFlag'],
      entityId: this.vehicleId,
      createdAt: note['createdAt']
    })
  }
  // send form for update
  public update = (): void => {
    if (this.informationUpdate.valid) {
      this.loading = true
      this.master.sendPost('updateNote', { ...this.informationUpdate.value, type: 'inventory' }, res => {
        this.loading = false
        if (res) {
          if (res.status == 200) {
            // * success
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language][ 'success' ] });
            this.noteSelected = 0
            this.notes[this.noteIndex] = { ...this.informationUpdate.value }
            this.ms.channelComponents$.emit({ message: 'reloadInventoryDetails' })
            // this.cleanForm()
          } else {
            // ! send message in case error 
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language][ 'apiNoResponse' ] });
        }
      })

    }
  }
  /*
  !: delete functions
  */
  public delete = (): void => {
  
    this.loading = true
    this.master.sendPost('deleteNote', { ...this.informationUpdate.value, type: 'inventory' }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language][ 'success' ] });
          this.notes.splice(this.noteIndex, 1)
          this.ms.channelComponents$.emit({ message: 'reloadInventoryDetails' })
        } else {
          // ! send message in case error 
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });

        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language][ 'apiNoResponse' ] });

      }
    })
  }
  /*
    * lifre cycles
    */
  constructor(private master: MasterService, private ms: MessageService) {
    this.ms.channelComponents$.subscribe(res=>{
      if(res.message=='changeLanguage'){
        this.language = res.value
      }
    })
   }

  ngOnInit() {
    this.getNotes()
  }


}
