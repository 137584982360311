import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { MessageService } from "../../../../../../services/message.service";
import { MasterService } from "../../../../../../services/master.service";

@Component({
  selector: 'promises-expected',
  templateUrl: './promises-expected.component.html',
  styleUrls: ['./promises-expected.component.scss']
})
export class PromisesExpectedComponent implements OnInit {
  // **********
  // variables
  // *********
  // save the language
  @Input() language: string = 'EN'
  // set all words
  @Input() words = {}
  //
  @Input() data: Object = {}
  //
  public loading: boolean = false
  //
  @Output() promises$: EventEmitter<Object> = new EventEmitter();
  //
  @Input() paymentSelected: Object = {}
  // **********
  // functions
  // *********


  // delete promise
  public deletePromise = (id: number, index: number, activity: string) => {
    this.loading = true
    this.master.sendPost("promiseToPayDispatcher", { parameters: { promisedId: id }, activity: activity }, (res) => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          this.promises$.emit({ message: 'reload Payments' });

        } else {
          // ! error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    }
    );
  }
  //
  // **********
  // life cycles
  // *********
  constructor(private ms: MessageService, private master: MasterService,) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if(changes.data){
      this.data['allPromisedToPay']=[]
      for (let index = 0; index < this.data['promisedToPay'].length; index++) {
        this.data['allPromisedToPay']=this.data['allPromisedToPay'].concat(this.data['promisedToPay'][index])
      }

    }

  }

}
