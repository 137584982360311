import { Component, OnInit, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// services
import * as languageLibrary from '../../../../../../services/language'
import { MessageService } from "../../../../../../services/message.service";
import { MasterService } from "../../../../../../services/master.service";
import { StoreService } from "../../../../../../services/store.service";
@Component({
  selector: 'payments-form',
  templateUrl: './payments-form.component.html',
  styleUrls: ['./payments-form.component.scss']
})
export class PaymentsFormComponent implements OnInit {
  // ************************
  // variables
  // ***********************
  // show error in process
  public errorProcess: boolean = false
  // if one payment enable
  public flagOnePayment: boolean = false
  // send listener to father
  @Output() paymentsForm$: EventEmitter<Object> = new EventEmitter();
  //  loading state
  public loading: boolean = false
  // header of sale
  @Input() header: Object = {}
  // define the payment selected
  @Input() paymentSelected: Object = {}
  // general data from father
  @Input() data: Object = {}
  // general data from father
  @Input() saleId: number = 0
  // save the language
  @Input() language: string = 'EN'
  // set all words
  @Input() words: Object = {}
  // new payment form
  public information = new FormGroup({
    datePaid: new FormControl(moment().format('yyyy-MM-DD'), [Validators.required]),
    amountPaid: new FormControl(0, [Validators.required, Validators.pattern('^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$')]),
    paymentMethod: new FormControl('', [Validators.required]),
    paymentType: new FormControl('', []),
    reference: new FormControl(0, []),
    cashPaid: new FormControl(0, []),
    description: new FormControl(0, []),
  })
  // ************************
  // functions
  // ***********************

  // todo:  change Method
  public changeMethod = () => {
    let method = this.data['paymentMethodCatalog'].filter(el => el['id'] == this.information.value['paymentMethod'])
    this.information.get('paymentType').setValue(method[0]['IRSPaymentType'])
  }
  // todo: reset payment form
  public reset = () => {
    this.information.reset()
    this.information.get('datePaid').setValue(moment().format('yyyy-MM-DD'))
    this.information.get('paymentMethod').setValue('')
    this.information.get('amountPaid').setValue('0')
    this.data['paymentsExpectedAll'].forEach(el => el['amountAllocated'] = '')
    // reload payment expected
    // this.process(false,true)

  }
  // todo: process payment
  public process = ( ): void => {
    // check if the amount paid is greater than amount due


      // ! check if the initial down is not payment yet and ypu want to pay another payment
      let newPayments = this.calculatePayments(this.data['paymentsExpectedAll'], { ...this.information.value })

      this.data['paymentsExpectedAll'].forEach(el => el['amountAllocated'] = '')
      // find if exist initial down or some deferred down partially paid
      const initialPartialyPaid=newPayments.some(el=>el['paymentCategoryId']==1 && el['paymentStatus']==2)
      // find if you want to full paid some another payment
      const fullyPaid=newPayments.some(el=>el['paymentCategoryId']!=1 && el['paymentStatus']==3)
      // ypu cant pay some categories if you have initial and deferred downs
      if(initialPartialyPaid && fullyPaid){
        this.ms.sendMessage("alert", { type: "success", text: 'You have to pay the down payment first for you can to pay another payments' });
        return
      }
      if (this.flagOnePayment) {
        this.data['totalTransactions'] = this.data['totalTransactions'] + 1
        this.onePaymentProcess(this.information.value)
      } else {
        this.reset()
        this.loading = true;

        this.master.sendPost("CRUDForPayments", { activity: 'create', payment: newPayments, saleId: this.saleId }, (res) => {
          this.loading = false;

          this.data['paymentsExpectedAll'].forEach(el => el['amountAllocated'] = '')
          if (res) {
            if (res.status == 200) {
              // * success
              this.errorProcess = false
              this.data['paymentsExpected'] = this.data['paymentsExpectedAll']
              this.data['paymentsExpectedAll'].forEach(el => el['amountAllocated'] = '')
              this.paymentsForm$.emit({ message: 'reload Payments' })
              this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
            } else {
              // ! error
              this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
            }
          } else {
            // in case API no response
            this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
          }
        }
        );

      }


  }
  // todo: one payment process
  public onePaymentProcess = (form) => {
    this.loading = true;
    let param = {
      saleId: this.saleId,
      amountPaid: form['amountPaid'],
      postedDate: form['datePaid'],
      paymentCategoryId: this.paymentSelected['paymentCategoryId'],
      referenceNumber: form['reference'],
      description: form['description'],
      transactionBatchId: this.data['totalTransactions'],
      appliedToExpectedPaymentId: this.paymentSelected['id'],
      receivedBy: this.header['buyer'].contactId,
      receivedDate: form['datePaid'],
      lotId: this.data['lotId'],
      paymentMethodId: parseInt(form['paymentMethod']),
      receiptNumber: this.header['receiptNo'],
      paidIn100s: form['cashPaid'],
      paymentStatus: 3,
      previosIndex: this.paymentSelected['id']
    }
    this.master.sendPost("CRUDForPayments", { activity: 'create', payment: [param], saleId: this.saleId }, (res) => {
      this.loading = false;
      if (res) {
        if (res.status == 200) {
          // * success
          this.flagOnePayment = false
          this.errorProcess = false
          this.data['paymentsExpected'] = this.data['paymentsExpectedAll']
          this.paymentsForm$.emit({ message: 'reload Payments' })
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          // ! error
          // this.errorProcess = true
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    }
    );
  }
  // todo: calculate payments
  public calculatePayments = (expectecs: any[], payment: any): any => {
    let newPayments:any = []
    expectecs=expectecs.filter(el=>el['amountAllocated']!='')

    for (let index = 0; index < expectecs.length; index++) {
      this.data['totalTransactions'] = this.data['totalTransactions'] + 1
      newPayments.push({
        saleId: this.saleId,
        amountPaid: this.fixNumber(expectecs[index]['amountAllocated']),
        postedDate: payment['datePaid'],
        paymentCategoryId: expectecs[index]['paymentCategoryId'],
        referenceNumber: payment['reference'],
        description: payment['description'],
        transactionBatchId: this.data['totalTransactions'],
        appliedToExpectedPaymentId: expectecs[index]['id'],
        receivedBy: this.header['buyer'].contactId,
        receivedDate: payment['datePaid'],
        lotId: this.store['lotSelected'],
        paymentMethodId: parseInt(payment['paymentMethod']),
        receiptNumber: this.header['receiptNo'],
        paidIn100s: payment['cashPaid'],
        previosIndex: expectecs[index].id,
        paymentStatus: this.fixNumber(expectecs[index]['amountAllocated']) === this.fixNumber(expectecs[index]['amountDue'] - expectecs[index]['amountPaid']) ? 3 : 2
      })


    }
    return newPayments
  }
  // todo: simulation
  public simulation=(paymentsExpecteds:any[],amountPaid:number)=>{
    paymentsExpecteds.forEach(el => el['amountAllocated'] = '')
    amountPaid=this.fixNumber(amountPaid)
    let totalPayments=paymentsExpecteds.reduce((total,value)=>(this.fixNumber(value['amountDue']) - this.fixNumber(value['amountPaid']))+total,0)

    if(amountPaid>totalPayments){
      amountPaid=totalPayments;
      // this.ms.sendMessage("alert", { type: "warning", text: 'The amount paid is greater than the amount due.' });
      this.information.get('amountPaid').setValue(amountPaid)

    }
    let amountOwed=0
    // let newPayments = []
    for (let index = 0; index < paymentsExpecteds.length; index++) {

      amountOwed=(this.fixNumber(paymentsExpecteds[index]['amountDue']) - this.fixNumber(paymentsExpecteds[index]['amountPaid']))
      amountOwed=parseFloat(amountOwed.toFixed(2))
      if(amountPaid>=amountOwed){

        paymentsExpecteds[index]['amountAllocated']=amountOwed
      }else{

        paymentsExpecteds[index]['amountAllocated']=this.fixNumber(amountPaid)
        return
      }

      amountPaid=amountPaid - amountOwed

    }
  }
  //
  public fixNumber=(amount:number):number=>{
    // return parseFloat((+amount).toFixed(2))
    return parseFloat(amount.toFixed(2))
  }

  // todo: order Categories
  public orderCategories = (categories): any => {
    let res = []
    for (let index = 0; index < categories.length; index++) {
      res = res.concat(this.data['paymentsExpectedAll'].filter(el => el['paymentCategoryId'] == categories[index]['id']))
    }

    return res
  }

  // ************************
  // life cycles
  // ***********************
  constructor(private ms: MessageService,
    private master: MasterService, private store: StoreService) { }

  ngOnInit() {
    this.ms.channelComponents$.subscribe(res=>{
      if(res.message=='onePayment'){
        // this.data['paymentsExpectedAll'].forEach(el => el['amountAllocated'] = '')
        this.information.get('amountPaid').setValue(res.value)
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.paymentSelected && changes.paymentSelected.currentValue) {
      // if (changes.paymentSelected.currentValue['amountAllocated'] == '') {
      //   this.information.get('amountPaid').setValue((changes.paymentSelected.currentValue['amountDue'] - changes.paymentSelected.currentValue['amountPaid']).toFixed(2) || 0)
      // } else {
        // if (changes.paymentSelected.currentValue['categoryname'] == 'Initial Down' || changes.paymentSelected.currentValue['categoryname'] == 'Deferred Down') {

        //   debugger
        //   this.information.get('amountPaid').setValue((this.data['paymentsExpectedAll'].reduce((total, value) => (value['amountAllocated'] && value['amountAllocated'] != '' ? +value['amountAllocated'] : 0) + total, 0).toFixed(2)))
        //   this.process(false, false)
        // } else {

        //   this.information.get('amountPaid').setValue((this.data['paymentsExpectedAll'].reduce((total, value) => (value['amountAllocated'] && value['amountAllocated'] != '' ? +value['amountAllocated'] : 0) + total, 0).toFixed(2)))
        //   this.process(false, false)
        // }
      // }
      this.information.get('amountPaid').setValue(this.data['paymentsExpectedAll'].reduce((total,value)=>(this.fixNumber(value['amountAllocated']) || 0)+total,0))
    }
    if (changes.data) {

      this.reset()
    }
  }

}
