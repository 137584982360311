import { Component, Input, OnInit } from '@angular/core';
import { MasterService } from '../../../../services/master.service'
import { MessageService } from '../../../../services/message.service'
import * as languageLibrary from '../../../../services/language'
@Component({
  selector: 'users-roles',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersRolesComponent implements OnInit {
  /*
  * Variables
  */
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  //  user list
  public users: Object[] = []
  // save all lots
  public lots: Object[] = []
  // define the user selected
  public userSelected: Object = {}
  // define the status loading
  public loading: boolean = false
  // define the lot id
  public lotId: string = "0"
  // count the administrators
  public administrators: number = 0
  // permissions
  @Input() permissions: Object[] = []
  /*
  * functions
  */
  /*
  ? get functions
  */
  //  get all users
  public getUsers = (loading): void => {
    
    this.master.sendPost('getUsersRoles', {}, res => {
      if (res) {
        if (res.status == 200) {
          // * success
          this.users = res.data.users
        } else {
          // ! in case error 
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // get all lots
  public getLots = (): void => {
    this.master.sendPost('getCorporatesEntities', {}, res => {

      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.lots = res.data.corporate
        } else {
          // ! error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // api no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  /*
  ! delete  functions
  /*
  ? delete a lot in the role
  @ lotId: define the lot id to deleted
  @ userid: define the id to user
  @ roleId: define the role id that lot will be deleted
  @ userIndex: define the index of user
  @ relationshipIndex: define the index of relationship
  @ lotIndex: define the index of lot
  */
  public deleteLot = (lotId: number, userId: number, roleId: number, userIndex: number, relationshipIndex: number, lotIndex: number): void => {
    this.master.sendPost('userRoleSwitch', { lotId: lotId, userId: userId, roleId: roleId, type: 'remove' }, res => {
      if (res) {
        if (res.status == 200) {
          // * success
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          // reload lots
          this.reloadlots()
          // remove item from lots array
          this.users[userIndex]['relationship'][relationshipIndex].lots.splice(lotIndex, 1)
        } else {
          // ! error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // api no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }


  public deleteRole = (userId: number, roleId: number, role): void => {

    // check admin exist
    let administrators = 0

    for (let index = 0; index < this.users.length; index++) {
      if (this.users[index]['relationship'].some(el => el.roleName == "Administrator")) {
        administrators = administrators + 1
      }
    }
    if (administrators > 1 || role.roleName != 'Administrator') {

      // delete admin only if exist more admins
      // delete role
      this.master.sendPost('userRoleSwitch', { userId: userId, roleId: roleId, type: 'remove' }, res => {
        if (res) {
          if (res.status == 200) {
            // * success
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
            // reload user
            this.getUsers(false)

          } else {
            // ! error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          // api no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })
    } else {
      // if only exist one admin
      this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['noAdminError'] });
    }
  }
  /*
   ? toggle checkout roles by user
   @ lotId: define the lot id to deleted
   @ userid: define the id to user
   @ roleId: define the role id that lot will be deleted
   @ userIndex: define the index of user
   @ relationshipIndex: define the index of relationship
   @ lotIndex: define the index of lot
   */
  public toggleRole = (userId: number, roleId: number, checked: boolean, i: number, role: Object, event): void => {

    // check admin exist
    let administrators = 0

    for (let index = 0; index < this.users.length; index++) {
      if (this.users[index]['relationship'].some(el => el.roleName == "Administrator")) {
        administrators = administrators + 1
      }
    }
    
    
    if ( administrators > 1  || role['name'] != 'Administrator' || (role['name']=='Administrator' && !this.userSelected['roles'][i].checked) ) {
      // change input
      
      this.master.sendPost('userRoleSwitch', { userId: userId, roleId: roleId, type: checked ? 'remove' : 'assign' }, res => {

        if (res) {
          if (res.status == 200) {
            // * success
            this.userSelected['roles'][i].checked = !this.userSelected['roles'][i].checked
            let message = ""
            checked ? message = "removed" : message = "assigned"
            this.ms.sendMessage("alert", { type: "success", text: `Role ${message} success!` });
            // reload user
            this.getUsers(false)
          } else {
            // ! error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          // api no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })
    } else {
      // if only exist one admin
      event.srcElement.checked = true
      this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['noAdminError'] });
    }

  }
  /*
  todo: update  functions
  */

  /*
  * create  functions
  */

  /*
  ? add lot to rol
  @ lotId: define the lot id to assigned
  @ userid: define the id to user
  @ roleId: define the role id that will be added
  */
  public addLot = (lotId: number, userId: number, roleId: number): void => {
    this.master.sendPost('userRoleSwitch', { lotId: lotId, userId: userId, roleId: roleId, type: 'entity' }, res => {
      if (res) {
        if (res.status == 200) {
          // * success
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          // reload lots
          this.reloadlots()
          // realod lotId
          this.lotId = "0"
          // reload roles
          this.getUsers(false)
          // reload select defaul
          this.userSelected = {}
        } else {
          // ! error
          this.lotId = "0"
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // api no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }

  // set user data
  public reloadlots = (): void => {
    this.master.sendPost('getUserAccount', {}, res => {
      if (res) {
        if (res.status == 200) {
          // send permissions for all components
          this.ms.channelComponents$.emit({ message: 'updateLotList', lots: res['data']['user'].lots })

        } else {
          // send message in case error 
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }

  /*
  * selections  functions
  */
  // select a user
  public selectUser = (userId: number, roleId: number, roles: string[], index: number): void => {
    this.administrators = 0

    for (let index = 0; index < this.users.length; index++) {
      if (this.users[index]['relationship'].some(el => el.roleName == "Administrator")) {
        this.administrators = this.administrators + 1
      }
    }
    // set values
    this.userSelected = { userId: userId, roleId: roleId, roles: roles, index: index }
  }
  /*
  * life cycles
  */
  constructor(private master: MasterService, private ms: MessageService) {
    // reload roles
    this.ms.channelComponents$.subscribe(res => {
      if (res.message == 'reloadRoles') {
        // get users
        this.getUsers(false)
      } else if (res.message == "changeLanguage") {
        this.language = res.value
      }
    })
  }

  ngOnInit() {
    // get users
    this.getUsers(true)
    // get lots   
    this.getLots()
  }

}
