import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from '../../../../services/message.service'
import { MasterService } from '../../../../services/master.service'
import { StoreService } from '../../../../services/store.service'
import * as languageLibrary from '../../../../services/language'
// extarnal
import * as moment from 'moment/moment'
import { Observable } from 'rxjs';
@Component({
  selector: 'app-corporations-detail',
  templateUrl: './corporations-detail.component.html',
  styleUrls: ['./corporations-detail.component.scss']
})
export class CorporationsDetailComponent implements OnInit {
  /*
* Variables
*/
  //  sections pending save
  private sections = [
    { name: 'generalInformation', savePending: false, btn: 'btn-save-generalInformation' },
    { name: 'Addresses', savePending: false, btn: 'btn-save-addresses_new' },
    { name: 'communications', savePending: false, btn: 'btn-save-communications' },
    { name: 'inventorySetup', savePending: false, btn: 'btn-save-inventorySetup' },
    { name: 'buyersGuide', savePending: false, btn: 'btn-save-buyersGuide' },
  ]
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  //  data analize
  public data: Object = {}
  // define the corporate type
  public type: string = ''
  // define the corporate id
  public id: number = 0
  // define if is loading
  public loading: boolean = false
  // permissions
  public permissions: Object[] = []
  // define if some field was updated
  public isGhost: boolean = false
  // error type
  public error: string = ''

  public inventoryByCorp
  /*
 * Functions
 */
  /*
  todo: charge data
  */
  public chargeData = (type: string, id: number, loading): void => {
    this.data = []
    if (loading) {
      this.loading = true
    }
    setTimeout(() => {
      this.master.sendPost('corporateDetails', { entity: type, entityId: id }, res => {
        this.loading = false
        if (res) {
          if (res.status == 200) {
            // * success
            this.data = res.data.data

            this.inventoryByCorp = res.data.inventoryByCorp
            this.data['dealerships'] = res.data.dealerships
            this.data['departments'] = res.data.departments

          } else {
            // ! error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
            if (res.data.error.indexOf('not exist') >= 0) {
              this.error = 'not exist'
              window.location.href = "#/corporations"
            }
          }
        } else {
          // api no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })
    }, 1000);
  }
  // todo: listen to output of navbar
  public listenerNav = (e): void => {

    switch (e['message']) {
      case 'changeLanguage':
        this.language = e['value']
        break;
      case 'setPermissions':
        // permissions on user list
        this.permissions = e.permissions
        // if not have permissions, return to account settings
        if (this.permissions[2]['childrens'][0].permissions.includes('view')) {
          this.chargeData(this.type, this.id, true)
        }
        break;

    }
  }
  // todo: listen childrens
  public listenerChildrens=(e):void=>{
    switch (e['message']) {
      // save pending
      case 'changesPendingCorporate':
        switch (e.section) {
          case 'generalInformation':

            this.sections[0].savePending = e.value
            break;
          case 'Addresses':
            this.sections[1].savePending = e.value
            break;
          case 'communications':
            this.sections[2].savePending = e.value
            break;
          case 'inventorySetup':
            this.sections[3].savePending = e.value
            break;
          case 'buyersGuide':
            this.sections[4].savePending = e.value
            break;
          default:
            break;
        }
      case 'reloadCorporations':
        this.data['updatedAt'] = moment().format('YYYY-MM-DD HH:mm')
        this.data['modifiedBy'] = this.store.userAccount['user'].firstName + ' ' + this.store.userAccount['user'].lastName
        this.data['dealerNumber'] = e.dealerNumber ? e.dealerNumber : this.data['dealerNumber']
        this.data['name'] = e.name ? e.name:this.data['name']


        break;
      case 'reloadCorporationDetail':
        this.data['updatedAt'] = moment().format('YYYY-MM-DD HH:mm')
        this.data['modifiedBy'] = this.store.userAccount['user'].firstName + ' ' + this.store.userAccount['user'].lastName
        break;
      default:
        break;
    }
  }
  /*
* Life cycles
*/
  constructor(private route: ActivatedRoute, private store: StoreService, private ms: MessageService, private master: MasterService) {
    this.type = this.route.snapshot.params['type']
    this.id = parseInt(this.route.snapshot.params['id'])

  }

  ngOnInit() {
    // normal
    if (this.store.userAccount['permissions']) {
      // permissions on user list
      this.permissions = this.store.userAccount['permissions']

      // if not have permissions, return to account settings
      if (this.permissions[2]['childrens'][0].permissions.includes('view')) {
        this.chargeData(this.type, this.id, true)
      }
    }



  }

  canDeactivate(): Observable<boolean> | boolean {
    let caseLeave = 'justView'
    // case invalid information
    // ! save pending
    let buttons = this.sections.filter(el => el['savePending'] == true)

    if (buttons.length > 0) {
      caseLeave = 'savePending'
    }

    if (this.data != undefined && (this.data['name'] == null || this.data['name'] == '')) {
      caseLeave = 'invalidInformation'
    }
    let result;

    switch (caseLeave) {
      case 'invalidInformation':
        result = window.confirm('The information is not valid, if it leaves the view the ' + this.type + ' will be eliminated, do you want to leave?');
        if (result) {
          this.master.sendPost('deleteCorpByEntity', { entity: this.type, entityId: this.id, type: 'Disabled' }, res => {
            this.loading = false;
            if (res) {
              if (res.status != 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
              }
            } else {
              // in case API no response
              this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
            }
          })
          return true
        } else {
          return false
        }

      // todo:change pending
      case 'savePending':
        result = window.confirm('Do you want to save the changes before exiting?');
        if (result) {
          (document.getElementById('btn-cancel-addresses') as HTMLButtonElement).click();
          (document.getElementById('close-modal-communications') as HTMLButtonElement).click();
          buttons.forEach(el => {
            (document.getElementById(el['btn']) as HTMLButtonElement).click()
          })
          return true
        } else {
          return false
        }


      default:
        return true

    }




  }


}
