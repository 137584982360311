import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
// services
import { MasterService } from "../../services/master.service";
import { MessageService } from "../../services/message.service";
import { StoreService } from "../../services/store.service";
import * as languageLibrary from '../../services/language'
import * as moment from 'moment'
import * as XLSX from 'xlsx';
@Component({
  selector: "app-integration",
  templateUrl: "./integration.component.html",
  styleUrls: ["./integration.component.scss"],
})
export class IntegrationComponent implements OnInit {
  /*
* Variables
*/
  public states: any[] = []
  // save all data by type
  public data: Object[] = []
  // permissions
  public permissions: Object[] = []
  // define if is loading
  public loading: boolean = false
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  //
  public indexSelected: number = -1
  //
  public configureSelected = null

  /*
  * functions
  */
  //
  public getIntegrations = (): void => {
    this.master.sendPost('integrationsList', { activity: 'read' }, res => {
      if (res) {
        if (res.status == 200) {
          // * success

          this.data = res.data.integrationListByCorp

          for (let index = 0; index < this.data.length; index++) {
            this.data[index]['download'] = res.data.download
          }

          if (this.store.userAccount['integrationsPermissions'] && this.store.userAccount['integrationsPermissions'].length > 0) {

            this.setAtributes(this.store.userAccount['integrationsPermissions'])
          }
        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // get configuration
  public getConfiguration = (index, endpoint): void => {
    this.indexSelected = index
    this.master.sendPost(endpoint, { activity: 'read', lotId: localStorage.getItem('lot') }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.configureSelected = res.data

          for (let index = 0; index < this.configureSelected.lotsByCorporate.length; index++) {
            if (res.data.dealerConfig.length == 0) {
              this.configureSelected.lotsByCorporate[index]['dealerId'] = ''
            } else {

              this.configureSelected.lotsByCorporate[index]['dealerId'] = res.data.dealerConfig.filter(el => el['lotId'] == this.configureSelected.lotsByCorporate[index]['id'])
              this.configureSelected.lotsByCorporate[index]['dealerId'] = this.configureSelected.lotsByCorporate[index]['dealerId'].length > 0 ? this.configureSelected.lotsByCorporate[index]['dealerId'][0]['dealerId'] : ''
            }
          }

        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // get dealer
  public saveDealer = (endpoint, dealers): void => {
    this.master.sendPost(endpoint, {
      activity: 'update',
      dealerConfig: dealers,
    }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // save Users
  public saveUsers = (endpoint, users): void => {

    this.master.sendPost(endpoint, {
      activity: 'update',
      txdmvParameters: users
    }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // save and close
  public save = (endpoint) => {
    let users = []
    let dealers = []
    for (let index = 0; index < this.configureSelected.lotsByCorporate.length; index++) {
      dealers.push(
        {
          integrationCompanyId: this.data[this.indexSelected]['integrationCompanyId'],
          corpId: this.store.userAccount['user'].corporateId,
          lotId: this.configureSelected.lotsByCorporate[index]['id'],
          dealerId: this.configureSelected.lotsByCorporate[index]['dealerId'],
          username: null,
          password: null
        }
      )
    }
    this.saveDealer(endpoint, dealers)

    for (let index = 0; index < this.configureSelected.usersByCorporate.length; index++) {

      users.push(
        {
          userId: this.configureSelected.usersByCorporate[index]['userId'],
          enabled: this.configureSelected.usersByCorporate[index]['enabled'],
          corpId: this.store.userAccount['user'].corporateId,
          username: this.configureSelected.usersByCorporate[index]['username'],
          password: this.configureSelected.usersByCorporate[index]['password']
        }
      )

    }

    this.saveUsers(endpoint, users)
  }
  //
  public setAtributes = (permissions: any[]): void => {

    for (let integration = 0; integration < this.data.length; integration++) {
      for (let role = 0; role < permissions.length; role++) {
        if (!this.data[integration]['config']) {
          this.data[integration]['config'] = permissions[role]['integrationsPermissions'].some(el => el['configure'] == true)

        }
        if (!this.data[integration]['use']) {
          this.data[integration]['use'] = permissions[role]['integrationsPermissions'].some(el => el['use'] == true)

        }
      }
    }
  }
  //
  public changeIntegrations = (index) => {
    this.indexSelected = index
    if (this.data[index]['enabled'] == true) {

      this.indexSelected = index;
      (document.getElementById('enabled') as HTMLButtonElement).click();
      (document.getElementById('enableCheck') as HTMLInputElement).checked = true;
      return
    }
    this.data[index]['enabled'] = !this.data[index]['enabled']
    this.master.sendPost('integrationsList', {
      activity: 'update',
      integrationConfig: {
        integrationCompanyId: this.data[this.indexSelected]['integrationCompanyId'],
        corpId: this.store.userAccount['user'].corporateId,
        enabled: this.data[this.indexSelected]['enabled'],
        enabledBy: this.store.userAccount['user'].id,
        enabledDate: moment().format('MM-DD-YYYY HH:mm'),
      }
    }, res => {
      if (res) {
        if (res.status == 200) {
          // * success

          if (this.data[index]['enabled'] == true) {
            (document.getElementById('configureTxDMVBTN') as HTMLButtonElement).click();
          }
          this.getIntegrations()
        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  public deleteIntegrations = (index) => {
    this.indexSelected = index
    this.data[index]['enabled'] = !this.data[index]['enabled']
    this.master.sendPost('integrationsList', {
      activity: 'delete',
      integrationCompanyId: this.data[this.indexSelected]['integrationCompanyId'],
      corpId: this.store.userAccount['user'].corporateId,
    }, res => {
      if (res) {
        if (res.status == 200) {
          // * success
          this.getIntegrations()

        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // go to permisions
  public goToPermissions = (): void => {
    if (confirm('Are you sure you want to be redirected to Security page?')) {
      (document.getElementById('configureClose') as HTMLButtonElement).click();
      this.router.navigate(['permissions'])
    }
  }
  // excel
  downloadFile(data: Object[]) {

    const workBook = XLSX.utils.book_new(); // create a new blank book
    const workSheet = XLSX.utils.json_to_sheet(data);

    XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
    XLSX.writeFile(workBook, 'log.xlsx'); // initiate a file download in browser
  }

      // todo: listen to output of navbar
      public listenerNav=(e):void=>{

        switch (e['message']) {
          case 'changeLanguage':
            this.language=e['value']
            break;
          case 'setPermissions':
            // permissions on user list
            this.permissions = e.permissions
            let tx = this.store.userAccount['corporateAddresses'].find(el => el['state'] == 'TX')
            if (tx != null) {
              this.states.push('TxDMV webDEALER & eTag')
            }
            if (this.data.length > 0) {
              this.setAtributes(this.store.userAccount['integrationsPermissions'])
            }
            this.store.userAccount['user']['roles'] = []

            this.store.userAccount['userRoles']['userRoles'].forEach(el => {
              this.store.userAccount['user']['roles'].push(el.role.name)
            })
          break;

          default:
            break;
        }
      }
  /*
  * life cycles
  */
  constructor(
    private master: MasterService,
    private ms: MessageService,
    private store: StoreService,
    private router: Router
  ) {
    // normal
    if (store.userAccount['permissions']) {
      // permissions on user list

      let tx = store.userAccount['corporateAddresses'].find(el => el['state'] == 'TX')
      if (tx != null) {
        this.states.push('TxDMV webDEALER & eTag')
      }
      this.states.push('REPAY')
      store.userAccount['user']['roles'] = []

      store.userAccount['userRoles']['userRoles'].forEach(el => {
        store.userAccount['user']['roles'].push(el.role.name)
      })

      if (this.data.length > 0) {
        this.setAtributes(store.userAccount['integrationsPermissions'])
      }

    }

    // // listener
    // this.ms.channelComponents$.subscribe(res => {
    //   if (res.message == 'setPermissions') {
    //     // permissions on user list

    //     let tx = store.userAccount['corporateAddresses'].find(el => el['state'] == 'TX')
    //     if (tx != null) {
    //       this.states.push('TxDMV webDEALER & eTag')
    //     }
    //     this.states.push('REPAY')

    //     if (this.data.length > 0) {
    //       this.setAtributes(res['integrations'])
    //     }
    //     store.userAccount['user']['roles'] = []

    //     store.userAccount['userRoles']['userRoles'].forEach(el => {
    //       store.userAccount['user']['roles'].push(el.role.name)
    //     })

    //   }
    // })
  }

  ngOnInit() {
    //
    this.getIntegrations()

  }
}
