import { Component, Input, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
// services
import { MessageService } from "../../../../services/message.service";
import { MasterService } from "../../../../services/master.service";
import * as languageLibrary from '../../../../services/language'
//

@Component({
  selector: 'collections-setup',
  templateUrl: './collections-setup.component.html',
  styleUrls: ['./collections-setup.component.scss']
})
export class CollectionsSetupComponent implements OnInit {
  // *******************
  // ** variables ******
  // *******************
  //
  public starting = { edit: false, number: 0 }
  // save the language
  @Input() language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  //
  public categories = [];
  //
  public categoriesNotDraggables = []
  //
  public loading: boolean = false
  public expanded: boolean = true
  // *******************
  // ** functions ******
  // *******************
  public drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );

    }

  }
  //
  //  todo: get sales Setup
  //
  public salesSetup = (activity, data) => {
    this.loading = true

    this.master.sendPost("collectionsSetup", { activity: activity, collectionsConfig: data, startingNumber: this.starting.number }, (res) => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success

          if (activity == 'read') {

            if (res.data.salesSetup.paymentAllocationHierarchy == null || res.data.salesSetup.paymentAllocationHierarchy == '') {
              res.data.paymentCategories.forEach(el => {
                delete el['createdAt']
                delete el['updatedAt']
                el['flag'] = (el.name == 'Initial Down' || el.name == 'Deferred Down') ? true : false
              })
              this.categories = res.data.paymentCategories.filter(el => !(el.name == 'Initial Down' || el.name == 'Deferred Down'))
              this.categoriesNotDraggables = res.data.paymentCategories.filter(el => el.name == 'Initial Down' || el.name == 'Deferred Down')
              this.categoriesNotDraggables.sort((a, b) => a > b ? -1 : 1)

              this.salesSetup('update', JSON.stringify(this.categoriesNotDraggables.concat(this.categories)))
              this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
            } else {
              this.starting = { edit: res.data.editable, number: res.data.salesSetup.paymentReceiptStartingNumber }
              this.categories = JSON.parse(res.data.salesSetup.paymentAllocationHierarchy)
              this.categoriesNotDraggables = this.categories.filter(el => el.name == 'Initial Down' || el.name == 'Deferred Down')
              this.categories = this.categories.filter(el => !(el.name == 'Initial Down' || el.name == 'Deferred Down'))
              this.categoriesNotDraggables.sort((a, b) => a > b ? -1 : 1)
              this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
            }
          } else {
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          }

        } else {
          // ! error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    }
    );
  }
  //
  // todo: save
  //
  public save = () => {

    this.salesSetup('update', JSON.stringify(this.addOrderNo(this.categoriesNotDraggables.concat(this.categories))))
  }
  // todo: add OrderNo
  public addOrderNo=(categories:Object[]):Object[]=>{
    categories.forEach((el,index)=>{
      el['OrderNo']=index+1
    })
    return categories
  }
  // *******************
  // ** life cycles ******
  // *******************
  constructor(
    private ms: MessageService,
    private master: MasterService
  ) {

  }

  ngOnInit() {
    this.salesSetup('read', {})

  }

}
