import { Component, Input, OnInit, EventEmitter, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from '@angular/forms';
// services
import { MasterService } from '../../../../services/master.service'
import { MessageService } from '../../../../services/message.service'
import { Router, ActivatedRoute } from '@angular/router';
import * as languageLibrary from '../../../../services/language'

@Component({
  selector: "sales-setup",
  templateUrl: "./sales-setup.component.html",
  styleUrls: ["./sales-setup.component.scss"],
})
export class SaleSetupComponent implements OnInit {
  // **************
  // variables
  // *************

  // save the language
  @Input() language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  //
  public expandedPackages: boolean = false;
  //
  public expanded: boolean = true;
  //
  public loading: boolean = false
  //
  @Input() corporateId = 0
  //
  public defaultDealerFees: any[] = []
  // **************
  // functions
  // *************
  /*
todo: refresh data
*/
  public getData = (endpoint: string, activity: string, params: any, otherFees: any[]): void => {
    this.loading = true
    this.master.sendPost(endpoint, { params: params, activity: activity }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          if (activity == 'readCorporateDealerFeesConfig') {

            this.defaultDealerFees = res.data.defaultDealerFees.concat(otherFees)
          } else {
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          }
        } else {
          // ! error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // api no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  /*
todo: refresh data
*/
  public fetchDefault = (activity: string): void => {
    // let x=this.defaultDealerFees.some((el: any) => el['customAmount'] < 0.000001 )
    // let y=this.defaultDealerFees.some((el: any) =>  el['customAmount'] > 100)
    // debugger
    if (activity == 'reFetchDealerFees' && this.defaultDealerFees.some((el: any) => (el['customAmount'] < 0.000001 || el['customAmount'] > 100))) {

      this.ms.sendMessage("alert", { type: "danger", text: 'There is a problem with the custom amount, please check and try again' });
      return
    }

    this.loading = true
    this.master.sendPost('corporateSalesSetupDispatcher', { activity: activity, fees: this.defaultDealerFees }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });

          // activity == 'fetchDealerFees' ? [
          //   {
          //     name: 'Dealer Inventory Tax Rate',
          //     defaultValue: 0,
          //     max: 100.00,
          //     customAmount: 0,
          //     note: `Inventory Tax Rate: The county gives the dealer their inventory tax rate.
          //     In the first year, the rate is 0. After the first year, the dealer will receive a letter that contains the inventory tax rate per lot.`
          //   }] : [])
          this.getData(
            'corporateSalesSetupDispatcher',
            'readCorporateDealerFeesConfig',
            { corporateId: this.corporateId },[])
        } else {
          // ! error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // api no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }

  // **************
  // life cycles
  // *************
  constructor(private master: MasterService, private ms: MessageService) {

  }

  ngOnInit() {
    this.getData('corporateSalesSetupDispatcher', 'readCorporateDealerFeesConfig', { corporateId: this.corporateId }, [])
  }
}
