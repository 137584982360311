import { Component, Input, OnInit } from '@angular/core';
import { MasterService } from '../../../../services/master.service'
import { MessageService } from '../../../../services/message.service'
import { StoreService } from '../../../../services/store.service'
import * as languageLibrary from '../../../../services/language'

@Component({
  selector: 'roles-detail',
  templateUrl: './roles-detail.component.html',
  styleUrls: ['./roles-detail.component.scss']
})
export class RolesDetailComponent implements OnInit {
  /*
  * variables 
  */
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  //  get the rol selected
  @Input() role: Object = { name: '', id: '' }
  // permissions
  //  path structure
  // @ name: row name
  // @ permissions: define the permissions on views, sections and fields
  // @ level: define the level of deep
  // @ CRUD: define the actions avaible on views, sections and fields
  @Input() permissionsDetail: Object[] = []
  // define the loading state
  public loading: boolean = false
  // define the permissions
  public permissions: Object[] = []
  /*
  * functions 
  */
  // get permissions
  public getPermissions = (id: string): void => {
    this.loading = true
    this.master.sendPost('getPagesPermissions', { roleId: id }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.permissions = []
          this.permissions = res.data.pages
          // change positions contact
          this.permissions[1]['childrens'] = [
            this.permissions[1]['childrens'][0],
            this.permissions[1]['childrens'][1],
            this.permissions[1]['childrens'][2],
            this.permissions[1]['childrens'][3],
            this.permissions[1]['childrens'][4],
            this.permissions[1]['childrens'][5],
            this.permissions[1]['childrens'][6],
            this.permissions[1]['childrens'][7],
            this.permissions[1]['childrens'][8],
            this.permissions[1]['childrens'][9],
            this.permissions[1]['childrens'][11],
            this.permissions[1]['childrens'][10]
          ]

          // change positions sales
          // this.permissions[7]['childrens']=[
          //   this.permissions[7]['childrens'][0],
          //   this.permissions[7]['childrens'][1],
          //   this.permissions[7]['childrens'][2],
          //   this.permissions[7]['childrens'][3],
          //   this.permissions[7]['childrens'][4],
          // ]

          this.permissions[4]['childrens'][9].permissions.push('view')
          // change positions inventory
          this.permissions[4]['childrens'] = [
            this.permissions[4]['childrens'][0],
            this.permissions[4]['childrens'][1],
            this.permissions[4]['childrens'][2],
            this.permissions[4]['childrens'][3],
            this.permissions[4]['childrens'][4],
            this.permissions[4]['childrens'][6],
            this.permissions[4]['childrens'][9],
            this.permissions[4]['childrens'][7],
            this.permissions[4]['childrens'][10],
            this.permissions[4]['childrens'][8],
          ]

          // order corporates
          this.permissions[2]['childrens'] = [
            // corporates
            this.permissions[2]['childrens'][0],
            this.permissions[2]['childrens'][1],
            this.permissions[2]['childrens'][2],
            this.permissions[2]['childrens'][3],
            this.permissions[2]['childrens'][4],
            this.permissions[2]['childrens'][13],
            this.permissions[2]['childrens'][16],
            // dealerships
            this.permissions[2]['childrens'][5],
            this.permissions[2]['childrens'][6],
            this.permissions[2]['childrens'][7],
            this.permissions[2]['childrens'][8],
            this.permissions[2]['childrens'][14],
            // this.permissions[2]['childrens'][17],
            // lots
            this.permissions[2]['childrens'][9],
            this.permissions[2]['childrens'][10],
            this.permissions[2]['childrens'][11],
            this.permissions[2]['childrens'][12],
            this.permissions[2]['childrens'][15],
            // this.permissions[2]['childrens'][18]
          ]



        } else {
          // ! in case error 
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // toggle permissions
  public togglePermisions = (roleId: string, table: string, children: Object, permission: string, isCheked: boolean): void => {

    if (this.permissionsDetail.includes('edit') || true) {
      // construct the toggle settings
      let params = [{
        name: table,
        childrens: this.manageArray(children, permission)
      }]

      // call end-point
      this.master.sendPost('pagesPermissions', { roleId: roleId, pages: params }, res => {
        if (res) {
          if (res.status == 200) {
            // * success
            let message = ""
            isCheked ? message = "disabled" : message = "enabled"
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });

            this.setUserAccount()
          } else {
            // ! in case error 
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })

    }

  }

  // add or remove premissions
  public manageArray = (children: Object, permission: string): Object[] => {
    if (children['permissions'].includes(permission)) {
      // ! remove permission
      // in case view, the values change to empty
      if (permission == 'view') {
        children['permissions'] = []
      } else {
        let index = children['permissions'].indexOf(permission)
        children['permissions'].splice(index, 1)
      }
    } else {
      // * set permission
      children['permissions'].push(permission)
    }
    return [children]
  }
  // reload permissions
  public setUserAccount = (): void => {
    this.master.sendPost('getUserAccount', {}, res => {
      if (res) {
        if (res.status == 200) {
          // set data to store service
          this.store.userAccount = res
          // set new permissions by role
          this.permissionsDetail = res.data.permissions[3].childrens[2].permissions
        } else {
          // send message in case error 
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  
  /*
  * life cycles 
  */
  constructor(private master: MasterService, private ms: MessageService, private store: StoreService) {


  }

  ngOnInit() {

    this.getPermissions(this.role['id'])
    // listener
    this.ms.channelComponents$.subscribe(res => {
      if (res.message == 'changeRole') {
        this.role = res.role
        this.getPermissions(res.role.id)
      } else if (res.message == "changeLanguage") {
        this.language = res.value
      }
    })

  }

}
