export const language = {
    ES: {
        // header
        support: "Soporte",
        selectALot: "Selecciona un lote",
        corporationId: "ID de Corporación",
        english: "Inglés",
        spanish: "Español",
        Splashtop: "Splashtop",
        supportCenter: "Centro de Soporte",
        welcome: "Bienvenido",
        myProfile: "Mi Perfil",
        logout: "Cerrar sesión",
        search: "Buscar",
        // navbar
        settings: "Configuración",
        contacts: "Contactos",
        inventory: "Inventorio",
        sales: "Ventas",
        collections: "Cobranza",
        users: "Usuarios",
        employees: "Empleados",
        companySetup: "Configuración de Compañia",
        security: "Seguridad",
        leads: "Prospectos",
        buyers: "Compradores",
        vendors: "Proveedores",
        financeCompanies: "Compañías Financieras",
        finance: "Compañías Financieras",
        companies: "Compañias",
        floorPlans: "Lineas de Crédito",
        insurance: "Seguros",
        lienholders: "Acreedores",
        lenders: "Lines de crédito",
        // my profile
        personalInformation: "Información Personal",
        firstName: "Nombre",
        InvalidfirstName: "Nombre Inválido",
        enterFirstName: "Ingresar Nombre",
        lastName: "Apellido",
        enterLastName: "Ingresar Apellido",
        invalidLastName: "Apellido Inválido",
        emailAddress: "Correo Electrónico",
        enterEmailAddress: "Ingresar Correo Electrónico",
        invalidEmailAddress: "Correo Electrónico Inválido",
        phoneNumber: "Teléfono",
        enterPhoneNumber: "Ingresar Teléfono",
        invalidPhoneNumber: "Teléfono Inválido",
        save: "Guardar",
        cancel: "Cancelar",
        profileUpdated: "Perfil Modificado!",
        apiNoResponse: "La API no responde",
        profile: "Perfil",
        accountSettings: "Configuración de Cuenta",
        language: "Language",
        // account settings
        loginVerification: "Login Verification SPANISH",
        enable: "Permitir",
        disable: "Desactivar",
        // users
        viewSecurityPermissions: "Ver Permisos de Seguridad",
        display: "Mostrar",
        all: "Todos",
        actions: "Acciones",
        previous: "Anterior",
        next: "Siguiente",
        continue: "Continuar",
        deactivateUser: "Desactivar Usuario",
        deactivateMessage:
            "La desactivación del usuario no eliminará al usuario, pero no permitirá que el usuario inicie sesión en la corporación. El usuario desactivado seguirá apareciendo en la lista de usuarios.",
        deactivateMessageSecure:
            "¿Está seguro de que desea desactivar los siguientes usuarios?",
        activateUser: "Activar Usuario",
        activateUserSecure:
            "¿Está seguro de que desea activar los siguientes usuarios?",
        updateResendInvitations: "Modificar y Reenviar Invitaciones",
        updateResendInvitationsMessage:
            "Actualice la dirección de correo electrónico del usuario o copie la URL de la invitación haciendo clic en el botón junto a la dirección de correo electrónico del usuario. Una vez que haya terminado con los cambios, haga clic en el botón 'Enviar invitación'.",
        copyInviteURL: "Copiar URL",
        copying: "Copiando",
        updateSendInvitation: "Modificar y Enviar Invitacion(es)",
        close: "Cerrar",
        Disabled: "Deshabilitado",
        Pending: "Pendiente",
        Active: "Activo",
        email: "Correo Electrónico",
        status: "Estado",
        createdAt: "Fecha de Invitación",
        validateAt: "Fecha de Activación",
        addInviteUser: "Agregar e Invitar Usuario(s)",
        inviteUser: "Invitar Usuario(s)",
        inviteUserMessage:
            "Complete los datos del usuario para enviar la invitación. Se mostrará una nueva fila a medida que complete los espacios en blanco",
        // contacts
        add: "Agregar",
        company: "Compañia",
        typeToFilterOut: "Escriba para filtrar",
        viewActivityLog: "Ver Registro de Actividad",
        sendEmail: "Enviar Correo",
        editDetails: "Editar Detalles",
        // contacts
        detail: "Detalle",
        createdBy: "Creado Por",
        dateCreated: "Fecha de Creación",
        dateModified: "Fecha de Modificación",
        modifiedBy: "Modificado Por",
        nonIndividual: "No-Individual",
        title: "Título",
        placeHolderTitle: "Sr., Sra., Srta.",
        invalidTitle: "Título Inválido",
        middleName: "Segundo Nombre",
        invalidMiddleName: "Segundo Nombre Inválido",
        suffix: "Sufijo",
        placeHolderSuffix: "Jr., Sr., II",
        invalidSuffix: "Sufijo Inválido",
        dateOfBirth: "Fecha de Nacimiento",
        invalidCompany: "Compañia Inválida",
        contactFirstName: "Nombre del Contacto",
        contactLastName: "Apellido del Contacto",
        number: "Número",
        invalidTIDNumber: "Número TID Inválido",
        addresses: "Direcciones",
        default: "Predeterminado",
        from: "Desde",
        to: "Hasta",
        toCurrent: "Hasta la fecha Actual",
        addAddress: "Agregar Dirección",
        type: "Tipo",
        rentOwn: "Rentado/Propio",
        address: "Direcciones",
        invalidAddress: "Dirección Inválida",
        zipcode: "Código Postal",
        invalidZipcode: "Código Postal Inválido",
        city: "Ciudad",
        state: "Estado",
        county: "Condado",
        country: "País",
        startDate: "Fecha de inicio",
        invalidStartDate: "Fecha de Inicio Inválida",
        endDate: "Fecha de Fin",
        invalidEndDate: "Fecha de Fin inválida",
        delete: "Eliminar",
        deleteAddress: "¿Estás seguro de que deseas eliminar esta dirección?",
        yes: "Si",
        updateAddress: "Modificar Dirección",
        communications: "Comunicaciones",
        home: "Casa",
        cellphone: "Teléfono",
        fax: "Fax",
        work: "Trabajo",
        facebook: "Facebook",
        website: "Sitio Web",
        addCommunication: "Agregar Método de Comunicacion",
        value: "Valor",
        valuePlaceHolder: "Teléfono, Correo, URL, Sitio Web",
        invalidValue: "Valor no válido",
        deleteCommunication:
            "¿Estás seguro de que deseas eliminar este método de comunicación?",
        updateCommunication: "Modificar método de comunicación",
        identifications: "Identificaciones",
        issuer: "Expedido por",
        expirationDate: "Fecha de expiración",
        addIdentification: "Agregar Identificación",
        invalidIssuer: "Expedidor Inválido",
        invalidExpirationDate: "Fecha de expiración Inválida",
        deleteIdentifications:
            "¿Estás seguro de que deseas eliminar esta identificación?",
        updateIdentification: "Modificar Identificación",
        expiresOn: "Expira el",
        success: "Éxito!",
        successFinance: "Éxito al guardar finanzas",
        successGeneral: "Éxito al guardar información general",
        successAdministrative: "Éxito al guardar administración",
        errorFinance:
            "Ocurrió un error mientras se guardaba la información de finanzas",
        errorGeneral:
            "Ocurrió un error mientras se guardaba la información general",
        expirationError:
            "La fecha de vencimiento no debe ser anterior. Ingrese una identificación actual.",
        errorBirthDate: "La fecha de nacimiento no puede ser una fecha futura.",
        startDateOrEndDateInvalid: "Las fechas no son válidas",
        noPermission: "No tienes permiso para ver el detalle.",
        incomeSources: "Fuentes de ingresos",
        month: "Mes",
        addIncomeSources: "Agregar Nueva Fuente de Ingresos",
        monthlyAmount: "Monto Mensual",
        deleteIncomeSources:
            "¿Estás seguro de que deseas eliminar esta fuente de ingresos?",
        updateIncomeSources: "Modificar Fuente de Ingresos",
        viewNotes: "Ver Notas",
        notesAlerts: "Notas y Alertas",
        deleteNote: "¿Estas seguro de que deseas eliminar esta nota?",
        markAsImportant: "Marcar Como Importante",
        addNote: "Agregar Nota",
        references: "Referencias",
        relationship: "Tipo de Relación",
        relation: "Relación",
        duration: "Duración",
        addNewReference: "Agregar Nueva Referencia",
        deleteReference: "¿Estás seguro de que deseas eliminar esta referencia?",
        updateReference: "Modificar Referencia",
        activityLog: "Registro de Actividad",
        contactType: "Tipo de Contacto",
        contactId: "ID de Contacto",
        logs: "Registros",
        // activity logs
        transactionBatchId: "Id Lote de Transacciones",
        transactionSource: "Origen de la Transacción",
        corporateId: "ID de Corporación",
        auditClass: "Clase de Auditoría",
        userId: "ID Usuario",
        inventoryId: "ID Inventario",
        IPAddress: "Dirección IP",
        amount: "Monto",
        primaryKey: "Llave Primaria",
        tableName: "Nombre de la Tabla",
        fieldName: "Nombre del Campo",
        oldValue: "Valor Anterior",
        newValue: "Nuevo Valor",
        updatedAt: "Fecha de Modificación",
        corporation: "Coorporación",
        Corporation: "Coorporación",
        dealerships: "Concesionarios",
        dealership: "Concesionario",
        Dealership: "Concesionario",
        addDealership: "Agregar Concesionario",
        lots: "Lotes",
        addLots: "Agregar Lotes",
        deactivate: "Desactivar",
        deactivateInfo:
            "Esta ubicación está vinculada a direcciones, comunicaciones y otra información. ¿Quieres desactivarlo? Esto no eliminará la ubicación y su información estará segura.",
        deleteQuest: "¿Estás seguro de que quieres eliminar esto?",
        companyName: "Nombre de la Compañia",
        dba: "Hace negocios como",
        setupDefaults: "Configuración Predeterminada",
        accountNumber: "Número de Cuenta",
        invalid: "Inválido",
        daysPerYear: "Días por Año",
        invalidDays: "Días Inválidos",
        daysAllowed: "Días permitidos",
        principalReductionCriteria: "Criterio de reducción a capital",
        "Flat Rate": "Tarifa plana",
        Percent: "Porcentage",
        monthly: "Mensual",
        weekly: "Semanal",
        biWeekly: "Quincenal",
        semiMonthly: "Semi Mensual",
        principalReductionAmount: "Importe de Reducción a Capital",
        oneTimeFeeCriteria: "Criterio de Cuota Única",
        oneTimeFeeAmount: "Monto de Cuota Única",
        recurrentFeeCriteria: "Criterio de Cuota Recurrente",
        recurrentFeeAmount: "Importe de la Cuota Recurrente",
        invalidName: "Nombre Inválido",
        dbaName: "Nombre DBA",
        einNumber: "Número EIN",
        closeSecure:
            "Tiene cambios sin guardar. Si abandona la página, los cambios se perderán. Estás seguro que quieres salir?",
        billing: "Facturación",
        mailing: "Postal",
        departmentName: "Departamento",
        reportSetup: "Configuración de Reportes",
        reportDefault: "Reporte Predeterminado",
        buyersOrder: "Ordenes de Compra",
        contactPerson: "Contacto",
        warrantybyDealer: "Garantía del distribuidor",
        manufacturerWarrantyStillApplies: "Aún aplica garantía del fabricante",
        otherUsedVehicleWarrantyApplies: "Aplica otra garantía de vehículos usados",
        serviceContract: "Contrato de servicios",
        manufacturerUsedVehicleWarrantyApplies:
            "Aplica garantía de vehículos usados ​​del fabricante",
        printDisclosures: "Imprimir términos legales",
        customerSignatureRequiredonbackpage:
            "Se requiere la firma del cliente en la contraportada",
        fullWarranty: "Garantía completa",
        inventorySetup: "Configuración de Inventario",
        stockSetup: "Configuración de numero de Inventario",
        defaultStockFormat: "Formato predeterminado de inventario",
        last: "Últimos",
        digitsofVIN: "dígitos de VIN",
        incrementBy: "Incrementar por",
        startingAt: "A partir de",
        theLastAssigned: "Último número asignado",
        stock: "Existencias",
        Stage: "Etapa",
        prefix: "Prefijo",
        invalidDaysPerYear: "Días por año inválidos",
        manageUserRoles: "Administración de roles por usuario",
        viewUsers: "Ver usuarios",
        roleName: "Nombre del Rol",
        roles: "Roles",
        copy: "Copiar",
        rename: "Renombrar",
        userRoles: "Roles de usuario",
        rolePermission: "Permisos de roles",
        deleteRole: "¿Estás seguro de que deseas eliminar esta rol?",
        addRemoveRoles: "Agregar/Remover Roles",
        chooseLot: "Seleccionar Lote",
        select: "Seleccionar",
        noAdminError: "Debe haber al menos un administrador",
        view: "Ver",
        edit: "Editar",

        Users: "Usuarios",
        Contacts: "Contactos",
        "Corporation Setup": "Configuración de Corporación",
        Security: "Seguridad",
        Inventory: "Inventario",
        Reports: "Reportes",
        "User List": "Lista de Usuarios",
        "Contact List": "Lista de Contactos",
        "Contact Detail": "Detalle de Contactos",
        "General Information": "Información General",
        Addresses: "Direcciones",
        Communications: "Comunicaciones",
        Identifications: "Identificaciones",
        Number: "Número",
        "Income Sources": "Fuente de Ingresos",
        "Monthly Amount": "Monto Mensual",
        References: "Referencias",
        "Notes & Alerts": "Notas y Alertas",
        "Setup Defaults": "Configuración Predeterminada",
        "Corporate List": "Lista de Corporaciones",
        "Corporate Detail": "Detalle de Corporaciones",
        "Buyers Guide": "Guía de Compradores",
        "Inventory Setup": "Configuración de Inventario",
        "Dealership Detail": "Detalle del Concesionario",
        "Lot Detail": "Detalle de Lote",
        Roles: "Roles",
        "Roles Users": "Roles de Usuario",
        "Roles Permissions": "Permisos de Roles",
        "Inventory List": "Lista de Inventario",
        "Inventory Detail": "Detalle de Inventario",
        "Reconditioning Cost": "Costo de Reacondicionamiento",
        "Reconditioning Types": "Tipos de Reacondicionamiento",
        "Floor Plans": "Lineas de Credito",
        "Select Floor Plan": "Selecciona Linea de Credito",
        Buyers: "Compradores",
        Financials: "Finanzas",
        Administrative: "Administración",
        vehicles: "Vehículos",
        duplicate: "Duplicar",
        "Vehicle Image": "Imagen del vehículo",
        "Stock #": "# de Inventario",
        "VIN #": "# de VIN",
        Model: "Modelo",
        Year: "Año",
        Make: "Fabricante",
        "Trim Level": "Version",
        Body: "Carroceria",
        Engine: "Motor",
        Cylinders: "Cilindros",
        Transmission: "Transmisión",
        Driveline: "Tracción",
        "Exterior Color": "Color Exterior",
        "Interior Color": "Color Interior",
        "Exterior Color (Minor)": "Color Exterior (menor)",
        "Selling Price": "Precio de Venta",
        Type: "Tipo",
        Miles: "Millas",
        Odometer: "Odómetro",
        "Curb Weight": "Peso Vehicular",
        "CarryingWeight": "Peso de carga",
        GVWR: "GVWR",
        Tonnage: "Tonelaje",
        Lot: "Lote",
        lot: "Lote",
        corporate: "Corporación",
        Status: "Estado",
        "Days Old": "Días de Antiguedad",
        totalVehicles: "Vehículos totales",
        deleteVehicle: "¿Estás seguro de que deseas eliminar este Vehículo?",
        deleteSale: "¿Estás seguro de que deseas eliminar esta Venta?",
        customizeColumns: "Personalizar Columnas",
        noFound: "No hay Registros",
        no: "No",
        // inventory
        purchaseCost: "Costo de Compra",
        invalidCost: "Costo Inválido",
        totalReaconditionCost: "Costo total de reacondicionamiento",
        totalFloorPlan: "Cargos totales de lineas de crédito",
        oneTimeFeeDefaults: "Incumplimientos de tarifas únicas",
        princRedDefaults: "Valores predeterminados de reducción de capital",
        recurFeeDefaults: "Incumplimientos de tarifas recurrentes",
        totalCost: "Costo Total",
        sellingPrice: "Precio de venta",
        downPayment: "Enganche",
        bookValue: "Valor en el mercado",
        LotAssignLocation: "Lote / Asignar ubicación",
        licensePlateNumber: "Numero de Placas",
        inventorySource: "Origen de inventario",
        datePurchased: "Fecha de compra",
        datePaid: "Fecha de pago",
        paymentMethod: "Método de pago",
        licenseAnnouncements: "Anuncios de licencia",
        keyNumberAssigned: "Numero de llave asignado",
        titleCondition: "Condición del título",
        titleLocation: "Ubicación del título",
        titleNumber: "Número de Título",
        dateTitleDeliveredtoCustomer: "Fecha de entrega del título al cliente",
        airBagStatus: "Estado de bolsa de aire",
        inspectionExpires: "Inspección expira en",
        inspectionDue: "Inspección vencida",
        inspectionDate: "Fecha de inspección",
        getLastInspectionDate: "Obtener la última fecha de inspección",
        dateOnLot: "Fecha en lote",
        dateTitleReceived: "Fecha de recepción del título",
        last6VIN: "Últimos 6 dígitos del VIN",
        statusHistory: "Historial",
        days: "días",
        primaryImage: "Imagen Principal",
        seeMore: "Ver Más",
        additionalVehicleInformation: "Información Adicional de Vehículo",
        vehicleInformation: "Información del Vehículo",

        "Manufactured In": "Fabricado en",
        "VIN Number In": "Número de VIN en",
        "Production Seq. Number": "Numero consecutivo de producción",

        "Specifications - General": "Especificaciones - General",
        "Transmission - Short": "Transmisión: corta",
        "Fuel Economy-City": "Economía de combustible-Ciudad",
        "Transmission - Long": "Transmisión: larga",
        "Fuel Economy-Hwy": "Economía de combustible en carretera",
        Tank: "Tanque",
        "Specifications - Chassis": "Especificaciones - Chasis",
        "Anti-Brake System": "Sistema de frenos antibloqueo",
        "Rear Suspension": "Suspensión trasera",
        "Rear Brake Type": "Tipo de freno trasero",
        "Rear Spring Type": "Tipo de resorte trasero",
        "Steering Type": "Tipo de dirección",
        "Turning Diameter": "Diámetro de giro",
        "Front Spring Type": "Tipo de resorte delantero",
        Tires: "Llantas",
        "Front Brake Type": "Tipo de freno delantero",
        "Front Suspension": "Suspensión delantera",

        "Front Head Room": "Espacio frontal para la cabeza",
        "Front Leg Room": "Espacio delantero para las piernas",
        "Front Shoulder Room": "Espacio para hombros delantero",
        "Front Hip Room": "Espacio delantero para caderas",
        "Rear Head Room": "Espacio trasero para la cabeza",
        "Rear Leg Room": "Espacio trasero para piernas",
        "Rear Shoulder Room": "Espacio trasero para hombros",
        "Rear Hip Room": "Espacio trasero para caderas",
        "Specifications - Colors": "Especificaciones - Colores",
        "Interior Trim": "Equipamiento interior",
        "Specifications - Cargo Bed Dimensions":
            "Especificaciones - dimensiones de la plataforma de carga",

        Length: "Largo",
        "Width at Wheelwell": "Ancho en el pozo de la rueda",
        "Width at Wall": "Ancho en la pared",
        Depth: "Profundidad",
        "Specifications - Exterior Dimensions & Weight":
            "Especificaciones - Dimensiones y peso exteriores",
        "Curb Weight - Auto. (lbs)": "Peso vehicular: automático. (libras)",
        "Overall Width": "Ancho promedio",
        Wheelbase: "Distancia entre ejes",
        "Track Front": "Frente de pista",
        "Curb Weight - Man.": "Peso vehicular - Man.",
        "Overall Height": "Altura total",
        "Ground Clearance": "Espacio al suelo",
        "Track Rear": "Pista trasera",
        "Overall Length": "Longitud total",
        "Specifications - Capacities": "Especificaciones - Capacidades",
        "Standard Seating": "Asientos estándar",
        "Cargo Volume (Cubic Foot)": "Volumen de carga (pie cúbico)",
        "Standard Payload (lbs)": "Carga útil estándar (libras)",
        "Standard GVWR (lbs)": "GVWR estándar (libras)",
        "Optional Seating": "Asientos opcionales",
        "Standard Towing (lbs)": "Remolque estándar (lbs)",
        "Maximum Payload (lbs)": "Carga útil máxima (libras)",
        "Maximum GVWR (lbs)": "Maximum GVWR (lbs)",
        "Passenger Vol. (Cubic Ft.)": "Pasajero Vol. (Pies cúbicos)",
        "Maximum Towing (lbs)": "Remolque máximo (libras)",
        "Specifications - Warranty": "Especificaciones - Garantía",
        "Basic-duration (Month)": "Duración básica (mes)",
        "Powertrain-Duration": "Duración del tren motriz",
        "Rust-duration (Month)": "Duración del óxido (mes)",
        "Rust-distance (Mile)": "Distancia al óxido (milla)",
        "Basic-distance (Mile)": "Distancia básica (milla)",
        "Powertrain-distance (Mile)": "Distancia del tren motriz (milla)",
        "Specifications - Pricing": "Especificaciones - Precios",
        "MSRP (USD)": "MSRP (USD)",
        "Dealer Invoice (USD)": "Factura de distribuidor (USD)",
        "Destination Charge (USD)": "Cargo de destino (USD)",
        "Equipment - Anti-Theft & Locks": "Equipo - Antirrobo y candados",
        "Child Safety Door Locks": "Cerraduras de seguridad para niños",
        "Locking Pickup Truck Tailgate":
            "Bloqueo de la puerta trasera de la camioneta pickup",
        "Power Door Locks": "Cerraduras de puertas eléctricas",
        "Vehicle Anti-Theft": "Antirrobo de vehículos",
        "Equipment - Braking & Traction": "Equipo - frenado y tracción",
        "Automatic Load-Leveling": "Nivelación de carga automática",
        "Limited Slip Differential": "Diferencial de deslizamiento limitado",
        "Traction Control": "Control de tracción",
        "ABS Brakes": "Frenos ABS",
        "Locking Differential": "Diferencial de bloqueo",
        "Electronic Brake Assistance": "Asistencia electrónica de frenado",
        "Vehicle Stability Control System":
            "Sistema de control de estabilidad del vehículo",
        "Equipment - Safety": "Equipo - Seguridad",
        "Driver Airbag": "Airbag del conductor",
        "Passenger Airbag": "Bolsa de aire del pasajero",
        "2nd Row Side Airbag": "Airbag lateral de la segunda fila",
        "First Aid Kit": "Kit de primeros auxilios",
        "Front Side Airbag": "Airbag lateral delantero",
        "Side Head Curtain Airbag": "Airbag de cortina lateral para la cabeza",
        "Electronic Parking Aid": "Ayuda electrónica para aparcar",
        "Equipment - Interior Features":
            "Equipamiento - Características interiores",
        "Cruise Control": "Control de crucero",
        "Tilt Steering Column": "Columna de dirección inclinada",
        "Steering Wheel Mounted Controls": "Controles montados en el volante",
        "Genuine Wood Trim": "Adornos de madera genuina",
        Tachometer: "Tacómetro",
        "Heated Steering Wheel": "Volante calefactado",
        "Telescopic Steering Col.": "Columna de dirección telescópica",
        "Tire Pressure Monitor": "Monitor de presión de neumáticos",
        "Tilt Steering Wheel": "Mueva el volante",
        "Leather Steering Wheel": "Volante de cuero",
        "Adjustable Foot Pedals": "Pedales de pie ajustables",
        "Trip Computer": "Computadora de viaje",
        "Equipment - Climate Control": "Equipo - Control de clima",
        "Air Conditioning": "Aire acondicionado",
        "Separate Driver / Front Passenger Climate Controls":
            "Controles de clima separados para conductor / pasajero delantero",
        "Equipment - Remote Controls & Release":
            "Equipo - controles remotos y liberación",
        "Keyless Entry": "Entrada sin llave",
        "Remote Ignition": "Encendido Remoto",
        "Equipment - Entertainment, Communication & Navigation":
            "Equipo - entretenimiento, comunicación y navegación",
        "AM / FM Radio": "Radio AM / FM ",
        "CD Changer": "Cambiador de CD",
        "Navigation Aid": "Ayuda a la navegación",
        Subwoofer: "Altavoz de subgraves",
        "Cassette Player": "Reproductor de casetes",
        "DVD Player": "Reproductor de DVD",
        "2nd Row Sound Controls": "Controles de sonido de la segunda fila",
        "Telematic Systems": "Sistemas telemáticos",
        "CD Player": "Reproductor CD",
        "Voice Activated Telephone": "Teléfono activado por voz",
        "Equipment - Seat": "Equipo - Asiento",
        "Front Power Memory Seat": "Asiento delantero eléctrico con memoria",
        "Front Cooled Seat": "Asiento delantero refrigerado",
        "Front Split Bench Seat": "Asiento de banco delantero dividido",
        "2nd Row Folding Seat": "Asiento plegable de la segunda fila",
        "2nd Row Removable Seat": "Asiento removible de la segunda fila",
        "Front Heated Seat": "Asiento delantero calefactado",
        "Leather Seat": "Asiento de cuero",
        "3rd Row Removable Seat": "Asiento extraíble de la tercera fila",
        "2nd Row Heated Seat": "Asiento con calefacción en la segunda fila",
        "Front Power Lumbar Support": "Soporte lumbar eléctrico delantero",
        "Driver Multi-Adjustable Power Seat":
            "Asiento eléctrico ajustable para el conductor",
        "Passenger Multi-Adjustable Power Seat":
            "Asiento eléctrico multiajustable del pasajero",
        "Second Row Multi-Adjustable Power Seat":
            "Asiento eléctrico multiajustable en la segunda fila ",
        "Trunk Anti-Trap Device": "Dispositivo anti-trampa de maletero",
        "Equipment - Storage": "Equipo - Almacenamiento",
        "Cargo Net": "Red de carga",
        "Load Bearing Exterior Rack": "Estante exterior con soporte de carga",
        "Pickup Truck Bed Liner": "Revestimiento de caja de camioneta",
        "Cargo Area Tiedowns": "Amarres del área de carga",
        "Equipment - Roof": "Equipo - Techo",
        "Power Sunroof": "Techo solar eléctrico",
        "Removable Top": "Top removible",
        "Manual Sunroof": "Techo corredizo manual",
        "Equipment - Exterior Lighting": "Equipo - Iluminación exterior",
        "Automatic Headlights": "Faros Automáticos",
        "Fog Lights": "Faros antiniebla",
        "Daytime Running Lights": "Luces de circulación diurna",
        "High Intensity Discharge Headlights":
            "Faros delanteros de descarga de alta intensidad",
        "Pickup Truck Cargo Box Light": "Luz de caja de carga para camioneta",
        "Equipment - Exterior Features": "Equipo - Características exteriores",
        "Running Board": "Tabla de correr",
        "Rear Spoiler": "Alerón trasero",
        "Splash Guards": "Salpicaderas",
        "Power Sliding Side Door": "Puerta lateral corrediza eléctrica",
        "Front Air Dam": "Presa de aire frontal",
        "Skid Plate": "Placa de deslizamiento",
        "Wind Deflect. Convertible": "Desvío del viento. Convertible",
        "Power Trunk Lid": "Tapa del maletero eléctrico",
        "Equipment - Tires & Wheels": "Equipo - neumáticos y ruedas",
        "Full Size Spare Tire": "Neumático de repuesto de tamaño completo",
        "Run Flat Tires": "Ejecutar llantas desinfladas",
        "Steel Wheels": "Ruedas de acero",
        "Chrome Wheels": "Ruedas cromadas",
        "Equipment - Mirrors & Windows & Wipers":
            "Equipo - espejos, ventanas y limpiaparabrisas",
        "Power Windows (Std.)": "Ventanas eléctricas (estándar)",
        "Heated Exterior Mirror": "Espejo exterior calefactado",
        "Deep Tinted Glass": "Vidrio tintado profundo",
        "Rear Window Defogger": "Desempañante de la ventana trasera",
        "Electrochromic Exterior Rearview Mirror":
            "Espejo retrovisor exterior electrocrómico",
        "Electrochromic Interior Rearview Mirror":
            "Espejo retrovisor interior electrocrómico",
        "Interval Wipers": "Limpiaparabrisas a intervalos",
        "Rear Wiper": "Limpiaparabrisas trasero",
        "Rain Sensing Wipers": "Limpiaparabrisas con sensor de lluvia",
        "Sliding Rear Pickup Wndw": "Pastilla trasera deslizante Wndw",
        "Glass Rear Window on Convertible":
            "Ventana trasera de vidrio en convertible",
        "Power Adjustable Exterior Mirror": "Espejo exterior eléctrico ajustable",
        "Equipment - Towings": "Equipo - Remolques",
        "Tow Hitch Receiver": "Receptor de enganche de remolque",
        "Towing Preparation Package": "Paquete de preparación de remolque",
        "Alloy Wheels": "Llantas de aleación",
        imageError: "Error al leer la imagen",
        Parts: "Partes",
        Others: "Otros",
        "View Entry Form": "Ver formulario de inscripción",
        vendor: "Vendedor",
        cost: "Costo",
        date: "Fecha",
        description: "Descripción",
        "Reference Number": "Número de referencia",
        "Reference Payment Type": "Tipo de pago de referencia",
        "Reference Payment Number": "Número de pago de referencia",
        "Check Number": "Verifique el número",
        clear: "Limpiar",
        "Edit Reconditioning Cost": "Editar costo de reacondicionamiento",
        deleteCost:
            "¿Está seguro de que desea eliminar esta entrada de costo de reacondicionamiento?",
        "Type already exist": "El tipo ya existe",
        "The name is the same": "El nombre es el mismo",
        "The name already exist": "El nombre ya existe",
        "Principle Balance": "Balance Principal",

        "Finance Charges": "Cargos financieros",
        "One time Fee": "Tarifa única",
        "Additional Fees": "Cuotas adicionales",
        "Total Balance": "Balance total",
        "# of Days": "Número de días",

        "Date on Floor Plan": "Fecha en la linea de crédito",
        "Initial Amount": "Cantidad inicial",
        "Principal Reduction": "Reducción de principal",
        criteria: "Criterio",
        "Recurrent Fee": "Tarifa recurrente",
        "One Time Fee": "Tarifa única",
        "Days remaining": "Días restantes",
        "Days on Floor Plan": "Días en la linea de crédito",
        "Recurrent Fee Amount": "Importe de la tarifa recurrente",
        "Recurrent Fee Frequency": "Frecuencia de tarifa recurrente",
        "One Time Fee Amount": "Monto de tarifa única",
        "Add Reduction": "Agregar reducción",
        "Reduction Amount": "Cantidad de reducción",
        "Reduction date": "Fechas de rebajas",
        payOff: "Saldar",
        deleteFloorPlan:
            "¿Está seguro de que desea eliminar esta linea de crédito?",
        "FP Name": "FP Nombre",
        "Status Date": "Situación a la fecha",
        "Current Principle": "Principio actual",
        "Cut Off Date": "Fecha de corte",
        Balance: "Balance",
        "email already in use": "El correo ya está en uso",
        noName: "El correo, el nombre o el apellido no pueden estar vacíos",
        invitationsWasSend:
            "El correo electrónico de invitación se envió correctamente.",
        copySuccess: "Copiado con éxito",
        invalidDate: "Fecha inválida",
        inventorySetupRules:
            "El número inicial no puede ser menor que 0 o menor que el último número de stock asignado",
        "Additional Fee": "Cargos adicionales",
        Images: "Imágenes",
        Image: "Imágen",
        Caption: "Subtítulo",
        Description: "Descripción",
        uploadedBy: "Subido Por",
        uploadedDate: "Fecha de Subida",
        modifiedDate: "Fecha de Modificación",
        makePrimaryImage: "Hacer Imagen Principal",
        primary: "Principal",
        uploadImages: "Suelta las imágenes aquí o haz clic para subir",
        gallery: "Galería",
        dropFiles: "Suelta los archivos aquí o haz clic para subir",
        upload: "Subir",
        caption: "Subtítulo",
        uploadDate: "Fecha de carga",
        image: "Imagen",
        setPrimary: "Establecer esta imagen como imagen principal",
        "Upload multiple VIN Numbers": "Subir Multiples Números VIN",
        "Drop files here or click to upload":
            "Suelta los archivos aquí o haz clic para subir",
        Completed: "Completado",
        Decoded: "Decodificado",
        "Recognition Failed": "Reconocimiento fallido",
        "In progress": "En Progreso",
        "VIN Number": "Número VIN",
        "Stock Number": "Número de inventario",
        "Invalid image format": "Formato de imagen no válido",
        "Invalid VIN Number": "Número VIN Inválido",
        "Buyer": "Comprador",
        "Out of State": "Fuera de estado",
        "Sale Date": "Fecha de venta",
        "Phone Number": "Número de teléfono",
        "Email": "Correo Electrónico",
        "Address": "Dirección",
        "Mileage": "Kilometraje",
        "Primary Lienholder": "Acreedor prendario principal",
        "Co-Buyer": "Co-Comprador",
        "Sale Financing": "Financiamiento de venta",
        "Sale Price": "Precio de venta",
        "Inspection Expires": "Fecha de expiración",
        "Has Active Floor Plan": "Tiene linea de crédito activo",
        "Inventory Source": "Fuente de inventario",
        "Salesperson": "Vendedor",
        "Account Owner": "Propietario de cuenta",
        "Title Condition": "Condición del título",
        "Deals": "Ofertas",
        "totalDeals": "Total Ventas",
        "errorNotAdmin": "Notifique al administrador para realizar este cambio.",
        "errorAdmin": "Vaya a Configuración de la empresa Configuración de inventario para seleccionar el formato de número de stock predeterminado.",
        "stockNull": "Para agregar un vehículo al inventario, se debe completar la configuración del formato de número de stock predeterminado en la configuración de la empresa.",
        "Outside": "Fuera de lugar",
        "Outside Finance": "Fuera de lugar",
        "Wholesale": "Venta al por mayor",
        "Cash Deal": "Oferta en efectivo",
        "Cash": "Oferta en efectivo",
        'The selected sale cannot be deleted because it has an associated trade-in': 'La venta seleccionada no se puede eliminar porque tiene un intercambio asociado',
        "Lock": "Bloquear",
        "Unlock": "Desbloquear",
        "Sale": "Venta",
        "Quote": "Cita",
        "Sale Type": "Tipo de venta",
        "Out of State?": "¿Fuera del estado?",
        "Sale Status": "Estado de venta",
        "Sale ID": "ID de Venta",
        "Sold": "Vendido",
        "Bankruptcy": "Bancarrota",
        "Flag": "Bandera",
        "Funded": "Fundado",
        "Insurance": "Seguro",
        "On Repo": "En Repo",
        "Other": "Otro",
        "Contact Name": "Nombre de contacto",
        "Buyer(s) & Cosigner(s)": "Comprador (s) y cosignatario (s)",
        "Buyer Type":"Tipo de comprador",
        "Individual":"Individual",
        "Business":"Negocio",
        "Government":"Gobierno",
        "Trust":"Confianza",
        "Non-Profit":"Sin ánimo de lucro",
        "Agricultural":"Agricultural",
        "ADD NEW CONTACT":"AGREGAR NUEVO CONTACTO",
        "successLienholders":"Acreedores guardados con éxito",
        "successTerms":"Terminos guardados con éxito",
        "successPayments":"Pagos guardados con éxito",
        "Firm":"Firma",
        "Entity":"Entity"
    },
    EN: {
        "Firm":"Firm",
        "Entity":"Entity",
        "successPayments":"Payments was saved successfully",
        "successTerms":"Terms was saved successfully",
        "successLienholders":"lienholders was saved successfully",
        "ADD NEW CONTACT":"ADD NEW CONTACT",
        "Individual":"Individual",
        "Business":"Business",
        "Government":"Government",
        "Trust":"Trust",
        "Non-Profit":"Non-Profit",
        "Agricultural":"Agricultural",
        "Buyer Type":"Buyer Type",
        "Buyer(s) & Cosigner(s)": "Buyer(s) & Cosigner(s)",
        "Contact Name": "Contact Name",
        "Sold": "Sold",
        "Bankruptcy": "Bankruptcy",
        "Flag": "Flag",
        "Funded": "Funded",
        "Insurance": "Insurance",
        "On Repo": "On Repo",
        "Other": "Other",
        "Sale ID": "Sale ID",
        "Sale Status": "Sale Status",
        "Out of State?": "Out of State?",
        "Sale Type": "Sale Type",
        "Quote": "Quote",
        "Sale": "Sale",
        "Lock": "Lock",
        "Unlock": "Unlock",
        'The selected sale cannot be deleted because it has an associated trade-in': 'The selected sale cannot be deleted because it has an associated trade-in',
        "Cash Deal": "Cash Deal",
        "Cash": "Cash Deal",
        "Wholesale": "Wholesale",
        "Outside": "Outside",
        "Outside Finance": "Outside",
        "BHPH": "BHPH",
        "stockNull": "To add a vehicle into the inventory the set up of the Default Stock # Format in the Company Setup needs to be completed",
        "errorAdmin": "Go to the Company Setup > Click on the Corporation > Inventory Setup to select the Default Stock # Format.",
        "errorNotAdmin": "Please notify the administrator to make this change.",
        "totalSales": "Total Sales",
        // "Deals": "Deals",
        "Title Condition": "Title Condition",
        "Account Owner": "Account Owner",
        "Salesperson": "Salesperson",
        "Inventory Source": "Inventory Source",
        "Has Active Floor Plan": "Has Active Floor Plan",
        "Inspection Expires": "Inspection Expires",
        "Sale Price": "Sale Price",
        "Sale Financing": "Sale Financing",
        "Co-Buyer": "Co-Buyer",
        "Primary Lienholder": "Primary Lienholder",
        "Mileage": "Mileage",
        "Address": "Address",
        "Email": "Email",
        "Phone Number": "Phone Number",
        "Sale Date": "Sale Date",
        "Out of State": "Out of State",
        "Buyer": "Buyer",
        "Invalid VIN Number": "Invalid VIN Number",
        "Invalid image format": "Invalid image format",
        "Stock Number": "Stock Number",
        "VIN Number": "VIN Number",
        Completed: "Completed",
        Decoded: "Decoded",
        "Recognition Failed": "Recognition Failed",
        "In progress": "In progress",
        "Drop files here or click to upload": "Drop files here or click to upload",
        "Upload multiple VIN Numbers": "Upload multiple VIN Numbers",
        Dashboard: "Dashboard",
        Basic: "Basic",
        Images: "Images",
        Image: "Image",
        Caption: "Caption",
        Description: "Description",
        uploadedBy: "Uploaded By",
        uploadedDate: "Uploaded Date",
        modifiedDate: "Modified Date",
        primary: "Primary",
        makePrimaryImage: "Make Primary Image",
        uploadImages: "Drop images here or click to upload",
        "Additional Fee": "Additional Fee",
        invalidDate: "Invalid Date",
        "Date on Floor Plan": "Date on Floor Plan",
        "Initial Amount": "Initial Amount",
        setPrimary: "Set this picture as main image",
        image: "Image",
        uploadDate: "Upload Date",
        caption: "Caption",
        description: "Description",
        upload: "Upload",
        dropFiles: "Drop files here or click to upload",
        gallery: "Gallery",
        // header
        support: "Support",
        selectALot: "Select a lot",
        corporationId: "Corporation ID",
        english: "English",
        spanish: "Spanish",
        Splashtop: "Splashtop",
        supportCenter: "Support Center",
        welcome: "Welcome",
        myProfile: "My Profile",
        logout: "Logout",
        search: "Search",
        // navbar
        settings: "Settings",
        contacts: "Contacts",
        inventory: "Inventory",
        sales: "Sales",
        collections: "Collection Center",
        users: "Users",
        employees: "Employees",
        companySetup: "Company Setup",
        security: "Security",
        leads: "Leads",
        buyers: "Buyers",
        vendors: "Vendors",
        financeCompanies: "Finance Companies",
        "finance companies": "Finance Companies",
        finance: "Finance Companies",
        companies: "Companies",
        floorPlans: "Floor Plans",
        insurance: "Insurance",
        "Insurance Companies": "Insurance Companies",
        "insurance companies": "Insurance Companies",
        lienholders: "Lienholders",
        Lienholders: "Lienholders",
        lienholder: "Lienholder",
        Lienholder: "Lienholder",
        lenders: "Floor Plans",
        // my profile
        personalInformation: "Personal Information",
        firstName: "First Name",
        InvalidfirstName: "Invalid First Name",
        enterFirstName: "Enter First Name",
        lastName: "Last Name",
        enterLastName: "Enter Last Name",
        invalidLastName: "Invalid Last Name",
        emailAddress: "Email Address",
        enterEmailAddress: "Enter Email Address",
        invalidEmailAddress: "Invalid Email Address",
        phoneNumber: "Phone Number",
        enterPhoneNumber: "Enter Phone Number",
        invalidPhoneNumber: "Invalid Phone Number",
        save: "Save",
        cancel: "Cancel",
        profileUpdated: "Profile Updated!",
        apiNoResponse: "API no response",
        profile: "Profile",
        accountSettings: "Account Settings",
        language: "Language",
        // account settings
        loginVerification: "Login Verification",
        enable: "Enable",
        disable: "Disable",
        // users
        viewSecurityPermissions: "View Security Permissions",
        display: "Display",
        all: "All",
        actions: "Actions",
        previous: "Previous",
        next: "Next",
        continue: "Continue",
        deactivateUser: "Deactivate User",
        deactivateMessage:
            "Deactivating the user will not delete the user but will not allow the user to sign into corporation. The deactivated user will continue to appear in the user list.",
        deactivateMessageSecure:
            "Are you sure you want to deactivate the following user(s)?",
        activateUser: "Activate User",
        activateUserSecure:
            "Are you sure you want to activate the following user(s)?",
        updateResendInvitations: "Update & Resend Invitations",
        updateResendInvitationsMessage:
            "Update the user's email address or copy the invite URL by clicking on the button next to the user's email address. Once you're done with the changes click on the 'Update and Resend Invitations' button.",
        copyInviteURL: "Copy Invite URL",
        copying: "Copying",
        updateSendInvitation: "Update & Send Invitation(s)",
        close: "Close",
        Disabled: "Disabled",
        Pending: "Pending",
        Active: "Active",
        email: "Email Address",
        status: "Status",
        createdAt: "Created Date",
        invitedAt: "Invited Date",
        validateAt: "Active Date",
        addInviteUser: "Add & Invite User(s)",
        inviteUser: "Invite User(s)",
        inviteUserMessage:
            "Fill in the user's information to send the invitation. A new row will be shown as you fill in the blanks",
        add: "Add",
        company: "Company",
        typeToFilterOut: "Type here to filter out...",
        viewActivityLog: "View Activity Log",
        sendEmail: "Send Email",
        editDetails: "Edit Details",
        // contacts
        detail: "Detail",
        createdBy: "Created By",
        dateCreated: "Created Date",
        dateModified: "Modified Date",
        modifiedBy: "Modified By",
        nonIndividual: "Non-Individual",
        title: "Title",
        placeHolderTitle: "Mr., Mrs., Miss",
        invalidTitle: "Invalid Tittle",
        middleName: "Middle Name",
        invalidMiddleName: "Invalid Middle Name",
        suffix: "Suffix",
        placeHolderSuffix: "Jr., Sr., II",
        invalidSuffix: "Invalid Suffix",
        dateOfBirth: "Date Of Birth",
        invalidCompany: "Invalid Business Name",
        contactFirstName: "Contact First Name",
        contactLastName: "Contact Last Name",
        number: "Number",
        invalidTIDNumber: "Invalid TID Number",
        addresses: "Addresses",
        default: "Default",
        from: "From",
        to: "To",
        toCurrent: "To Current",
        addAddress: "Add Address",
        type: "Type",
        rentOwn: "Rent/Own",
        address: "Address",
        invalidAddress: "Invalid Address",
        zipcode: "Zip Code",
        invalidZipcode: "Invalid Zip Code",
        city: "City",
        state: "State",
        county: "County",
        country: "Country",
        startDate: "Start Date",
        invalidStartDate: "Invalid Start Date",
        endDate: "End Date",
        invalidEndDate: "Invalid End Date",
        delete: "Delete",
        deleteContact: "Are you sure you want to delete this contact?",
        deactivateContact:
            "This contact is related to another record or sales process and is not possible to delete it. Do you want to deactivate instead?",
        deleteAddress: "Are you sure you want to delete this address?",
        yes: "Yes",
        updateAddress: "Update Address",
        communications: "Communications",
        home: "Home",
        cellphone: "Cellphone",
        fax: "Fax",
        work: "Work",
        facebook: "Facebook",
        website: "Website",
        addCommunication: "Add Communication",
        value: "Value",
        valuePlaceHolder: "Phone Number, Email, URL, Username, Website",
        invalidValue: "Invalid Value",
        deleteCommunication: "Are you sure you want to delete this communication?",
        updateCommunication: "Update Communication",
        identifications: "Identifications",
        issuer: "Issuer",
        expirationDate: "Expiration Date",
        addIdentification: "Add Identification",
        invalidIssuer: "Invalid Issuer",
        invalidExpirationDate: "Invalid Expiration Date",
        deleteIdentifications:
            "Are you sure you want to delete this identification?",
        updateIdentification: "Update Identification",
        expiresOn: "Expires On",
        success: "Success!",
        successFinance: "Financial information saved successfully",
        successGeneral: "General information saved successfully",
        successAdministrative: "Administrative information saved successfully",

        errorFinance: "Error while saving the financial information",
        errorGeneral: "Error while saving the general information",

        expirationError:
            "Expiration date must not be in the past. Please enter a current Identification.",
        errorBirthDate: "The birthdate cannot be in the future.",
        startDateOrEndDateInvalid: "The start date or end date is invalid",
        noPermission: "You do not have permissions to view the details.",
        incomeSources: "Income Sources",
        month: "Month",
        addIncomeSources: "Add New Income Sources",
        monthlyAmount: "Monthly Amount",
        deleteIncomeSources: "Are you sure you want to delete this income source?",
        updateIncomeSources: "Update Income Sources",
        viewNotes: "View Notes",
        notesAlerts: "Notes & Alerts",
        deleteNote: "Are you sure you want to delete this note?",
        markAsImportant: "Mark As Important",
        addNote: "Add Note",
        references: "References",
        relationship: "Relationship Type",
        relation: "Relation",
        duration: "Duration",
        addNewReference: "Add Reference",
        deleteReference: "Are you sure you want to delete this reference?",
        updateReference: "Update Reference",
        activityLog: "Activity Log",
        contactType: "Contact Type",
        contactId: "Contact ID",
        logs: "Logs",
        // activity logs
        transactionBatchId: "Transaction Batch Id",
        transactionSource: "transaction Source",
        corporateId: "Corporate Id",
        auditClass: "Audit Class",
        userId: "User Id",
        inventoryId: "Inventory Id",
        IPAddress: "IP Address",
        amount: "Amount",
        primaryKey: "Primary Key",
        tableName: "Table Name",
        fieldName: "Field Name",
        oldValue: "Old Value",
        newValue: "New Value",
        updatedAt: "Updated At",
        // coorporate
        corporation: "Corporation",
        Corporation: "Corporation",
        Corporate: "Corporation",
        corporate: "Corporation",
        dealerships: "Dealerships",
        parentDealership: "Parent Dealership",
        dealership: "Dealership",
        Dealership: "Dealership",
        addDealership: "Add Dealership",
        lots: "Lots",
        addLots: "Add Lots",
        deactivate: "Deactivate",
        deactivateInfo:
            "This location is tied to addresses, communications and other information. Would you like to deactivate? This will not delete the location and your information will be safe.",
        deleteQuest: "Are you sure you want to delete this",
        reactivate: "Reactivate",
        reactivateQuest: "Are you sure you want to reactivate this",
        companyName: "Company Name",
        dba: "Doing-Business-As Name",
        setupDefaults: "Inventory Default Setup",
        accountNumber: "Account Number",
        invalid: "Invalid",
        daysPerYear: "Days Per Year",
        invalidDays: "Invalid Days",
        daysAllowed: "Days Allowed",
        principalReductionCriteria: "Principal Reduction Criteria",
        "Flat Rate": "Flat Rate",
        Percent: "Percent",
        monthly: "Monthly",
        weekly: "Weekly",
        biWeekly: "Bi-Weekly",
        semiMonthly: "Semi-Monthly",
        principalReductionAmount: "Principal Reduction Amount",
        oneTimeFeeCriteria: "One Time Fee Criteria",
        oneTimeFeeAmount: "One Time Fee Amount",
        recurrentFeeAmount: "Recurrent Fee Amount",
        recurrentFeeCriteria: "Recurrent Fee Criteria",
        invalidName: "Invalid Name",
        dbaName: "DBA Name",
        einNumber: "EIN Number",
        closeSecure:
            "You have unsaved changes. If you leave the page, the changes will be lost. Are you sure you want to leave?",
        billing: "Billing",
        mailing: "Mailing",
        departmentName: "Department Name",
        reportSetup: "Report Setup",
        reportDefault: "Report Default",
        buyersOrderReport: "Buyer's Order",
        receiptReport: "Receipt",
        defaultReceiptMessage: "Default Receipt Message",
        contactPerson: "Contact Person",

        warrantybyDealer: "Warranty by Dealer",
        manufacturerWarrantyStillApplies: "Manufacturer's Warranty Still Applies",
        otherUsedVehicleWarrantyApplies: "Other Used Vehicle Warranty Applies",
        serviceContract: "Service Contract",
        manufacturerUsedVehicleWarrantyApplies:
            "Manufacturer´s Used Vehicle Warranty Applies",
        printDisclosures: "Print Disclosures",
        customerSignatureRequiredonbackpage:
            "Customer Signature Required on back page",
        fullWarranty: "Full Warranty",
        inventorySetup: "Inventory Setup",
        stockSetup: "Stock # Setup",
        defaultStockFormat: "Default Stock # Format",
        last: "Last",
        digitsofVIN: "Digits of VIN",
        incrementBy: "Increment By",
        startingAt: "Starting At",
        theLastAssigned: "The last assigned stock number",
        stock: "Stock",
        Stage: "Stage",
        prefix: "Prefix",
        invalidDaysPerYear: "Invalid Days Per Year",
        manageUserRoles: "Manage User(s) Roles",
        viewUsers: "View User(s)",
        roleName: "Role Name",
        roles: "Roles",
        copy: "Copy",
        rename: "Rename",
        userRoles: "User Roles",
        rolePermission: "Roles Permission",
        deleteRole: "Are you sure you want to delete this role?",
        addRemoveRoles: "Add/Remove Roles",
        chooseLot: "Choose Lot",
        select: "Select",
        noAdminError: "There must be at least one admin",
        view: "View",
        edit: "Edit",
        Users: "Users",
        Contacts: "Contacts",
        "Corporation Setup": "Corporation Setup",
        Security: "Security",
        Inventory: "Inventory",
        Reports: "Reports",
        "User List": "User List",
        "Contact List": "Contact List",
        "Contact Detail": "Contact Detail",
        "General Information": "General Information",
        Addresses: "Addresses",
        Communications: "Communications",
        Identifications: "Identifications",
        Number: "Number",
        "Income Sources": "Income Sources",
        "Monthly Amount": "Monthly Amount",
        References: "References",
        "Notes & Alerts": "Notes & Alerts",
        "Setup Defaults": "Setup Defaults",
        "Corporate List": "Corporate List",
        "Corporate Detail": "Corporate Detail",
        "Buyers Guide": "Buyers Guide",
        "Inventory Setup": "Inventory Setup",
        "Dealership Detail": "Dealership Detail",
        "Lot Detail": "Lot Detail",
        Roles: "Roles",
        "Roles Users": "Roles Users",
        "Roles Permissions": "Roles Permissions",
        "Inventory List": "Inventory List",
        "Inventory Detail": "Inventory Detail",
        "Reconditioning Cost": "Reconditioning Cost",
        "Reconditioning Types": "Reconditioning Types",
        "Floor Plans": "Floor Plans",
        "Select Floor Plan": "Select Floor Plan",
        Buyers: "Buyers",
        Financials: "Financials",
        Administrative: "Administrative",
        vehicles: "Vehicles",

        "Vehicle Image": "Vehicle Image",
        "Stock #": "Stock #",
        "VIN #": "VIN #",
        Model: "Model",
        Year: "Year",
        Make: "Make",
        "Trim Level": "Trim Level",
        Body: "Body",
        Engine: "Engine",
        Cylinders: "Cylinders",
        Transmission: "Transmission",
        Driveline: "Driveline",
        "Exterior Color": "Exterior Color",
        "Interior Color": "Interior Color",
        "Exterior Color (Minor)": "Exterior Color (Minor)",
        "Selling Price": "Selling Price",
        Type: "Type",
        Miles: "Mileage",
        Odometer: "Odometer",
        "Curb Weight": "Curb Weight",
        "CarryingWeight": "Carrying Weight",
        GVWR: "GVWR",
        Tonnage: "Tonnage",
        lot: "Lot",
        Lot: "Lot",
        Status: "Status",
        "Days Old": "Days Old",
        totalVehicles: "Total Vehicles",
        deleteVehicle: "Are you sure you want to delete this vehicle?",
        deleteSale: "Are you sure you want to delete this Sale?",
        customizeColumns: "Customize Columns",
        noFound: "No records found",
        purchaseCost: "Purchase Cost",
        invalidCost: "Invalid Cost",
        totalReaconditionCost: "Total Reconditioning Cost",
        totalFloorPlan: "Total Floor Plan Charges",
        totalCost: "Total Cost",
        sellingPrice: "Selling Price",
        downPayment: "Down Payment",
        bookValue: "Book Value",
        LotAssignLocation: "Lot / Assign Location",
        licensePlateNumber: "License Plate Number",
        inventorySource: "Inventory Source",
        datePurchased: "Date Purchased",
        datePaid: "Date Paid",
        paymentMethod: "Payment Method",
        licenseAnnouncements: "License Announcements",
        keyNumberAssigned: "Key Number Assigned",
        titleCondition: "Title Condition",
        titleLocation: "Title Location",
        titleNumber: "Title Number",
        dateTitleDeliveredtoCustomer: "Date Title Delivered to Customer",
        airBagStatus: "Air Bag Status",
        getLastInspectionDate: "Get Last Inspection Date",
        inspectionExpires: "Inspection Expires",
        inspectionDue: "Inspection Due",
        inspectionDate: "Inspection Date",
        dateOnLot: "Date On Lot",
        dateTitleReceived: "Date Title Received",
        last6VIN: "Last 6 of VIN",
        statusHistory: "Status History",
        days: "days ago",
        primaryImage: "Primary Image",
        seeMore: "See More",
        duplicate: "Duplicate",
        additionalVehicleInformation: "Additional Vehicle Information",
        vehicleInformation: "Vehicle Information",

        "Manufactured In": "Manufactured In",
        "VIN Number In": "VIN Number In",
        "Production Seq. Number": "Production Seq. Number",
        "Specifications - General": "Specifications - General",
        "Transmission - Short": "Transmission - Short",
        "Fuel Economy-City": "Fuel Economy-City",
        "Transmission - Long": "Transmission - Long",
        "Fuel Economy-Hwy": "Fuel Economy-Hwy",
        Tank: "Tank",
        "Specifications - Chassis": "Specifications - Chassis",
        "Anti-Brake System": "Anti-Brake System",
        "Rear Suspension": "Rear Suspension",
        "Rear Brake Type": "Rear Brake Type",
        "Rear Spring Type": "Rear Spring Type",
        "Steering Type": "Steering Type",
        "Turning Diameter": "Turning Diameter",
        "Front Spring Type": "Front Spring Type",
        Tires: "Tires",
        "Front Brake Type": "Front Brake Type",
        "Front Suspension": "Front Suspension",
        "Front Head Room": "Front Head Room",
        "Front Leg Room": "Front Leg Room",
        "Front Shoulder Room": "Front Shoulder Room",
        "Front Hip Room": "Front Hip Room",
        "Rear Head Room": "Rear Head Room",
        "Rear Leg Room": "Rear Leg Room",
        "Rear Shoulder Room": "Rear Shoulder Room",
        "Rear Hip Room": "Rear Hip Room",
        "Specifications - Colors": "Specifications - Colors",
        "Interior Trim": "Interior Trim",
        "Specifications - Cargo Bed Dimensions":
            "Specifications - Cargo Bed Dimensions",

        Length: "Length",
        "Width at Wheelwell": "Width at Wheelwell",
        "Width at Wall": "Width at Wall",
        Depth: "Depth",
        "Specifications - Exterior Dimensions & Weight":
            "Specifications - Exterior Dimensions & Weight",
        "Curb Weight - Auto. (lbs)": "Curb Weight - Auto. (lbs)",
        "Overall Width": "Overall Width",
        Wheelbase: "Wheelbase",
        "Track Front": "Track Front",
        "Curb Weight - Man.": "Curb Weight - Man.",
        "Overall Height": "Overall Height",
        "Ground Clearance": "Ground Clearance",
        "Track Rear": "Track Rear",
        "Overall Length": "Overall Length",
        "Specifications - Capacities": "Specifications - Capacities",
        "Standard Seating": "Standard Seating",
        "Cargo Volume (Cubic Foot)": "Cargo Volume (Cubic Foot)",
        "Standard Payload (lbs)": "Standard Payload (lbs)",
        "Standard GVWR (lbs)": "Standard GVWR (lbs)",
        "Optional Seating": "Optional Seating",
        "Standard Towing (lbs)": "Standard Towing (lbs)",
        "Maximum Payload (lbs)": "Maximum Payload (lbs)",
        "Maximum GVWR (lbs)": "Maximum GVWR (lbs)",
        "Passenger Vol. (Cubic Ft.)": "Passenger Vol. (Cubic Ft.)",
        "Maximum Towing (lbs)": "Maximum Towing (lbs)",
        "Specifications - Warranty": "Specifications - Warranty",
        "Basic-duration (Month)": "Basic-duration (Month)",
        "Powertrain-Duration": "Powertrain-Duration",
        "Rust-duration (Month)": "Rust-duration (Month)",
        "Rust-distance (Mile)": "Rust-distance (Mile)",
        "Basic-distance (Mile)": "Basic-distance (Mile)",
        "Powertrain-distance (Mile)": "Powertrain-distance (Mile)",
        "Specifications - Pricing": "Specifications - Pricing",
        "MSRP (USD)": "MSRP (USD)",
        "Dealer Invoice (USD)": "Dealer Invoice (USD)",
        "Destination Charge (USD)": "Destination Charge (USD)",
        "Equipment - Anti-Theft & Locks": "Equipment - Anti-Theft & Locks",
        "Child Safety Door Locks": "Child Safety Door Locks",
        "Locking Pickup Truck Tailgate": "Locking Pickup Truck Tailgate",
        "Power Door Locks": "Power Door Locks",
        "Vehicle Anti-Theft": "Vehicle Anti-Theft",
        "Equipment - Braking & Traction": "Equipment - Braking & Traction",
        "Automatic Load-Leveling": "Automatic Load-Leveling",
        "Limited Slip Differential": "Limited Slip Differential",
        "Traction Control": "Traction Control",
        "ABS Brakes": "ABS Brakes",
        "Locking Differential": "Locking Differential",
        "Electronic Brake Assistance": "Electronic Brake Assistance",
        "Vehicle Stability Control System": "Vehicle Stability Control System",
        "Equipment - Safety": "Equipment - Safety",
        "Driver Airbag": "Driver Airbag",
        "Passenger Airbag": "Passenger Airbag",
        "2nd Row Side Airbag": "2nd Row Side Airbag",
        "First Aid Kit": "First Aid Kit",
        "Front Side Airbag": "Front Side Airbag",
        "Side Head Curtain Airbag": "Side Head Curtain Airbag",
        "Electronic Parking Aid": "Electronic Parking Aid",
        "Equipment - Interior Features": "Equipment - Interior Features",
        "Cruise Control": "Cruise Control",
        "Tilt Steering Column": "Tilt Steering Column",
        "Steering Wheel Mounted Controls": "Steering Wheel Mounted Controls",
        "Genuine Wood Trim": "Genuine Wood Trim",
        Tachometer: "Tachometer",
        "Heated Steering Wheel": "Heated Steering Wheel",
        "Telescopic Steering Col.": "Telescopic Steering Col.",
        "Tire Pressure Monitor": "Tire Pressure Monitor",
        "Tilt Steering Wheel": "Tilt Steering Wheel",
        "Leather Steering Wheel": "Leather Steering Wheel",
        "Adjustable Foot Pedals": "Adjustable Foot Pedals",
        "Trip Computer": "Trip Computer",
        "Equipment - Climate Control": "Equipment - Climate Control",
        "Air Conditioning": "Air Conditioning",
        "Separate Driver / Front Passenger Climate Controls":
            "Separate Driver / Front Passenger Climate Controls",
        "Equipment - Remote Controls & Release":
            "Equipment - Remote Controls & Release",
        "Keyless Entry": "Keyless Entry",
        "Remote Ignition": "Remote Ignition",
        "Equipment - Entertainment, Communication & Navigation":
            "Equipment - Entertainment, Communication & Navigation",
        "AM / FM Radio": "AM / FM Radio",
        "CD Changer": "CD Changer",
        "Navigation Aid": "Navigation Aid",
        Subwoofer: "Subwoofer",
        "Cassette Player": "Cassette Player",
        "DVD Player": "DVD Player",
        "2nd Row Sound Controls": "2nd Row Sound Controls",
        "Telematic Systems": "Telematic Systems",
        "CD Player": "CD Player",
        "Voice Activated Telephone": "Voice Activated Telephone",
        "Equipment - Seat": "Equipment - Seat",
        "Front Power Memory Seat": "Front Power Memory Seat",
        "Front Cooled Seat": "Front Cooled Seat",
        "Front Split Bench Seat": "Front Split Bench Seat",
        "2nd Row Folding Seat": "2nd Row Folding Seat",
        "2nd Row Removable Seat": "2nd Row Removable Seat",
        "Front Heated Seat": "Front Heated Seat",
        "Leather Seat": "Leather Seat",
        "3rd Row Removable Seat": "3rd Row Removable Seat",
        "2nd Row Heated Seat": "2nd Row Heated Seat",
        "Front Power Lumbar Support": "Front Power Lumbar Support",
        "Driver Multi-Adjustable Power Seat": "Driver Multi-Adjustable Power Seat",
        "Passenger Multi-Adjustable Power Seat":
            "Passenger Multi-Adjustable Power Seat",
        "Second Row Multi-Adjustable Power Seat":
            "Second Row Multi-Adjustable Power Seat ",
        "Trunk Anti-Trap Device": "Trunk Anti-Trap Device",
        "Equipment - Storage": "Equipment - Storage",
        "Cargo Net": "Cargo Net",
        "Load Bearing Exterior Rack": "Load Bearing Exterior Rack",
        "Pickup Truck Bed Liner": "Pickup Truck Bed Liner",
        "Cargo Area Tiedowns": "Cargo Area Tiedowns",
        "Equipment - Roof": "Equipment - Roof",
        "Power Sunroof": "Power Sunroof",
        "Removable Top": "Removable Top",
        "Manual Sunroof": "Manual Sunroof",
        "Equipment - Exterior Lighting": "Equipment - Exterior Lighting",
        "Automatic Headlights": "Automatic Headlights",
        "Fog Lights": "Fog Lights",
        "Daytime Running Lights": "Daytime Running Lights",
        "High Intensity Discharge Headlights":
            "High Intensity Discharge Headlights",
        "Pickup Truck Cargo Box Light": "Pickup Truck Cargo Box Light",
        "Equipment - Exterior Features": "Equipment - Exterior Features",
        "Running Board": "Running Board",
        "Rear Spoiler": "Rear Spoiler",
        "Splash Guards": "Splash Guards",
        "Power Sliding Side Door": "Power Sliding Side Door",
        "Front Air Dam": "Front Air Dam",
        "Skid Plate": "Skid Plate",
        "Wind Deflect. Convertible": "Wind Deflect. Convertible",
        "Power Trunk Lid": "Power Trunk Lid",
        "Equipment - Tires & Wheels": "Equipment - Tires & Wheels",
        "Full Size Spare Tire": "Full Size Spare Tire",
        "Run Flat Tires": "Run Flat Tires",
        "Steel Wheels": "Steel Wheels",
        "Chrome Wheels": "Chrome Wheels",
        "Equipment - Mirrors & Windows & Wipers":
            "Equipment - Mirrors & Windows & Wipers",
        "Power Windows (Std.)": "Power Windows (Std.)",
        "Heated Exterior Mirror": "Heated Exterior Mirror",
        "Deep Tinted Glass": "Deep Tinted Glass",
        "Rear Window Defogger": "Rear Window Defogger",
        "Electrochromic Exterior Rearview Mirror":
            "Electrochromic Exterior Rearview Mirror",
        "Electrochromic Interior Rearview Mirror":
            "Electrochromic Interior Rearview Mirror",
        "Interval Wipers": "Interval Wipers",
        "Rear Wiper": "Rear Wiper",
        "Rain Sensing Wipers": "Rain Sensing Wipers",
        "Sliding Rear Pickup Wndw": "Sliding Rear Pickup Wndw",
        "Glass Rear Window on Convertible": "Glass Rear Window on Convertible",
        "Power Adjustable Exterior Mirror": "Power Adjustable Exterior Mirror",
        "Equipment - Towings": "Equipment - Towings",
        "Tow Hitch Receiver": "Tow Hitch Receiver",
        "Towing Preparation Package": "Towing Preparation Package",
        "Alloy Wheels": "Alloy Wheels",
        imageError: "Read image error",
        Parts: "Parts",
        Others: "Others",
        "View Entry Form": "View Entry Form",
        vendor: "Vendor",
        cost: "Cost",
        date: "Date",
        "Reference Number": "Reference Number",
        "Reference Payment Type": "Reference Payment Type",
        "Reference Payment Number": "Reference Payment Number",
        "Check Number": "Check Number",
        clear: "Clear",
        "Edit Reconditioning Cost": "Edit Reconditioning Cost",
        deleteCost:
            "Are you sure you want to delete this reconditioning cost entry?",
        "Type already exist": "Type already exist",
        "The name is the same": "The name is the same",
        "The name already exist": "The name already exist",
        "Principle Balance": "Principle Balance",
        "Finance Charges": "Finance Charges",
        "One time Fee": "One Time Fee",
        "Additional Fees": "Additional Fees",
        "Total Balance": "Total Balance",
        "# of Days": "# of Days",
        titleInformation: "Title Information",
        inspectionInformation: "Inspection Information",
        // "Principal Reduction":"Principal Reduction",
        criteria: "Criteria",
        // "Recurrent Fee":"Recurrent Fee",
        "One Time Fee": "One Time Fee",
        oneTimeFeeDefaults: "One Time Fee Defaults",
        princRedDefaults: "Principal Reduction Defaults",
        recurFeeDefaults: "Recurring Fee Defaults",
        "Days remaining": "Days remaining",
        "Days on Floor Plan": "Days on Floor Plan",
        "Recurrent Fee Amount": "Recurrent Fee Amount",
        "Recurrent Fee Frequency": "Recurrent Fee Frequency",
        "One Time Fee Amount": "One Time Fee Amount",
        "Add Reduction": "Add Reduction",
        "Reduction Amount": "Reduction Amount",
        "Reduction date": "Reduction date",
        payOff: "Pay Off",
        entries: "Entries",
        deleteFloorPlan: "Are you sure you want to delete this floor plan entry?",
        no: "No",
        "FP Name": "FP Name",
        "Status Date": "Status Date",
        "Current Principle": "Current Principle",
        "Cut Off Date": "Cut Off Date",
        Balance: "Balance",
        "email already in use": "email already in use",
        noName: "Please fill out the first and last name and email address",
        invitationsWasSend: "The invite email has been successfully sent.",
        copySuccess: "Copied Successfully",
        inventorySetupRules:
            "The starting on number cannot be less than 0 or less than the last assigned stock number",
        // Sales
        Sales: "Sales",
        "Sale List": "Sale List",
        "Sale Detail": "Sale Detail",
        "Buyers, Co-Buyers, and Cosigners": "Buyers, Co-Buyers, and Cosigners",
        "SSN": "SSN",
        totalSalesWithAfterSaleCharges: "Sales with After-Sale Charges"
    },
};
