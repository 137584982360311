import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// services
import { MasterService } from '../../../../../../services/master.service'
import { MessageService } from '../../../../../../services/message.service'
import * as languageLibrary from '../../../../../../services/language'
@Component({
  selector: 'accesories',
  templateUrl: './accesories.component.html',
  styleUrls: ['./accesories.component.scss']
})
export class AccesoriesComponent implements OnInit {
  // =============================
  // todo: Variables
  // =============================
  @Input() isUnLock = true
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  // toggle expanded accesorie
  public expandedAccessory: boolean = false;
  // define all accesories from database
  public accesories = []
  // define sale accesories from database
  public data = []
  // define sumatory price and cost
  public sumatory = { price: 0, cost: 0 }
  // define loading status
  public loading: boolean = false
  //
  public prepCharge = []
  //
  public prepChargeFlag: boolean
  //
  @Output() accessoriesEmit$: EventEmitter<Object> = new EventEmitter();
  // form validators
  public information = new FormGroup({
    id: new FormControl('', []),
    name: new FormControl('', [Validators.required]),
    price: new FormControl('', [Validators.required]),
    cost: new FormControl('', [Validators.required]),
    contactId: new FormControl('', [])
  })
  public informationSale = new FormGroup({
    saleAccessoryId: new FormControl('', []),
    accessoryId: new FormControl('', [Validators.required]),
    price: new FormControl('', [Validators.required]),
    cost: new FormControl('', [Validators.required]),
    contactId: new FormControl('', []),
  })
  //
  public vendors = []
  // define if the view is all accesories
  public viewAccesories: boolean = false
  // sale id
  @Input() saleId = ''
  // =============================
  // todo: functions
  // =============================
  // =============================
  // ? accesories
  // =============================
  // =============================
  // * reset accesories
  // =============================
  public resetAccesories=():void=>{
    // this.calcTotals(this.data.filter(el=>el['id']),this.prepCharge)
    this.reloadTable()
  }
  // =============================
  // * Read accesories
  // =============================
  public readAccesories = (): void => {
    this.loading = true
    this.master.sendPost('crudForAccessories', {
      activity: 'read',
    }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success

          this.accesories = res.data.accessories
          this.prepChargeFlag ? null : this.prepCharge = this.accesories.filter(el => el['name'] == "Prep Charge")
          this.accesories = this.accesories.filter(el => el['name'] != "Prep Charge")

          this.pushNewAccesories()
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })


  }
  // =============================
  // * push new accesory
  // =============================
  public pushNewAccesories = (): void => {
    this.accesories.push({
      id: null,
      name: '',
      price: '',
      cost: '',
      contactId: null
    })
  }
  // =============================
  // * check if new accesory is valid
  // =============================
  public checkValid = (data: any, index): void => {
    this.information.setValue({
      id: data.id,
      name: data.name,
      price: data.price,
      cost: data.cost,
      contactId: data.contactId
    })
    if (this.information.valid) {
      if (data.price == '' || data.cost == '' || data.price.toString().indexOf('-') >= 0 || data.cost.toString().indexOf('-') >= 0) {
        this.ms.sendMessage("alert", { type: "warning", text: 'Invalid Value' });
        return
      }
      this.information.reset()
      // data.id == null ? this.createAccesories(data) : this.updateAccesories(data)
      if (data.id == null && index == this.accesories.length - 1) {
        this.accesories[this.accesories.length - 1] = { ...data }
        this.pushNewAccesories()
      }
    }
  }
  // =============================
  // * Create accesories
  // =============================
  public createAccesories = (data: any[]): void => {
    data.forEach(el => {
      delete el['id']
      el.price = parseFloat(el.price),
        el.cost = parseFloat(el.cost)
    })
    this.loading = true
    this.master.sendPost('crudForAccessories', {
      activity: 'create',
      accessories: data
    }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.readAccesories()
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })

  }
  // =============================
  // * Update accesories
  // =============================
  public updateAccesories = (data: any[]): void => {
    data.forEach(el => {
      el.price = parseFloat(el.price),
        el.cost = parseFloat(el.cost)
    })

    this.loading = true
    this.master.sendPost('crudForAccessories', {
      activity: 'update',
      accessories: data
    }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          this.ms.channelComponents$.emit({ message: 'reloadHeader' })
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })


  }
  // =============================
  // * save accesories
  // =============================
  public saveAccessories = (): void => {

    let invalids = this.accesories.slice(0, this.accesories.length - 1).filter(el => !this.checkForm(el))
    if (invalids.length > 0) {
      this.ms.sendMessage("alert", { type: "warning", text: 'Some row is invalid' });
      return
    }
    let creates = this.accesories.filter(el => el['id'] == null)
    creates.pop()
    let updates = this.accesories.filter(el => el['id'] != null)


    creates.length > 0 ? this.createAccesories(creates) : null
    updates.length > 0 ? this.updateAccesories(updates) : null

  }
  // =============================
  // * toggle accesories
  // =============================
  public toggleAccessories = (): void => {
    if (this.viewAccesories) {
      let invalids = this.accesories.slice(0, this.accesories.length - 1).filter(el => !this.checkForm(el))
      if (invalids.length > 0) {
        this.viewAccesories = !this.viewAccesories
        this.readAccesories()
      } else {
        this.viewAccesories = !this.viewAccesories
      }
    } else {
      let invalids = this.data.slice(0, this.data.length - 1).filter(el => !this.checkSaleForm(el))
      if (invalids.length > 0) {
        this.viewAccesories = !this.viewAccesories
        this.readSaleAccesories(false)
      } else {
        this.viewAccesories = !this.viewAccesories
      }
    }
  }
  // =============================
  // * check form
  // =============================
  public checkForm = (data: any):boolean => {
    this.information.setValue({
      id: data.id,
      name: data.name,
      cost: data.cost,
      price: data.price,
      contactId: data.contactId
    })
    return this.information.valid
  }
  // =============================
  // * reload Table
  // =============================
  public reloadTable = ():void => {
    this.readAccesories()
    this.readSaleAccesories(false)
  }
  // =============================
  // * Delete accesories
  // =============================
  public deleteAccesories = (id: number, index): void => {
    this.loading = true
    this.master.sendPost('crudForAccessories', {
      activity: 'delete',
      accessoryId: id,
    }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.accesories.splice(index, 1)
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          this.ms.channelComponents$.emit({ message: 'reloadHeader' })
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })


  }

  // =============================
  // ? sale accesories
  // =============================
  // =============================
  // * Read accesories
  // =============================
  public readSaleAccesories = (save:boolean): void => {
    this.loading = true

    this.master.sendPost('crudForSaleAccessories', {
      activity: 'read',
      saleId: this.saleId
    }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.data = res.data.saleAccessories
          // todo: sumatory
          this.sumatory.price = this.data.reduce((total, value) => total + (value['price'] != '' ? parseFloat(value['price']) : 0), 0)
          this.sumatory.cost = this.data.reduce((total, value) => total + (value['cost'] != '' ? parseFloat(value['cost']) : 0), 0)
          this.prepChargeFlag = this.data.some(el => el['accessory']['name'] == 'Prep Charge')
          this.prepChargeFlag ? this.prepCharge = this.data.filter(el => el['accessory']['name'] == 'Prep Charge') : null
          this.data = this.data.filter(el => el['accessory']['name'] != 'Prep Charge')

          this.accessoriesEmit$.emit({ value: this.sumatory.price,save:save })

          this.pushNewSaleAccesories()
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })


  }
  // =============================
  // * push new accesory
  // =============================
  public pushNewSaleAccesories = (): void => {
    this.data.push({
      id: null,
      accessoryId: '',
      price: '',
      cost: '',
      contactId: null,
    })
  }
  // =============================
  // * push new accesory
  // =============================
  public checkValidSaleAccesory = (data: any, wasAccesory, i): void => {
    if (wasAccesory) {
      let accesoryData = this.accesories.filter(el => data['accessoryId'] == el['id'])
      this.data[i]['price'] = accesoryData[0]['price']
      this.data[i]['cost'] = accesoryData[0]['cost']
      this.data[i]['contactId'] = accesoryData[0]['contactId']
    }

    if (data.price == '' || data.cost == '' || data.price.toString().indexOf('-') >= 0 || data.cost.toString().indexOf('-') >= 0) {
      this.ms.sendMessage("alert", { type: "warning", text: 'Invalid Value' });
      return
    }

    this.informationSale.setValue({
      saleAccessoryId: data.id,
      accessoryId: data.accessoryId,
      price: data.price,
      cost: data.cost,
      contactId: data.contactId
    })

    if (this.informationSale.valid) {
      this.informationSale.reset()
      if (data.id == null && i == this.data.length - 1) {
        this.data[this.data.length - 1] = { ...data }
        this.calcTotals(this.data,this.prepCharge)
        this.pushNewSaleAccesories()
      }else{
        this.calcTotals(this.data,this.prepCharge)
      }

    }
  }
  //
  public calcTotals = (data:any[],prepCharge:any[]):void => {
    data=data.concat(prepCharge)
    this.sumatory.price = data.reduce((total, value) => total + (value['price'] != '' ? parseFloat(value['price']) : 0), 0)
    this.sumatory.cost = data.reduce((total, value) => total + (value['cost'] != '' ? parseFloat(value['cost']) : 0), 0)
  }
  // =============================
  // * Create accesories
  // =============================
  public createSaleAccesories = (data: any[]): void => {
    data.forEach(el => {
      delete el['id']
      delete el['saleAccessoryId']
      el.price = parseFloat(el.price)
      el.cost = parseFloat(el.cost)
      el['saleId'] = this.saleId
    })
    this.loading = true
    this.master.sendPost('crudForSaleAccessories', {
      activity: 'create',
      saleId: this.saleId,
      saleAccessories: data
    }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          this.readSaleAccesories(true)
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })

  }
  // =============================
  // * Update accesories
  // =============================
  public updateSaleAccesories = (data: any[]): void => {
    data.forEach(el => {
      el.price = parseFloat(el.price)
      el.cost = parseFloat(el.cost)
      el['saleId'] = this.saleId
    })
    this.loading = true
    this.master.sendPost('crudForSaleAccessories', {
      activity: 'update',
      saleId: this.saleId,
      saleAccessories: data
    }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          // todo: sumatory
          this.readSaleAccesories(true)
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          this.ms.channelComponents$.emit({ message: 'reloadHeader' })
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // =============================
  // * Delete accesories
  // =============================
  public deleteSaleAccesories = (id: number, index): void => {
    this.loading = true
    this.master.sendPost('crudForSaleAccessories', {
      activity: 'delete',
      saleId: this.saleId,
      saleAccessoryId: id,
    }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.data.splice(index, 1)
          // todo: sumatory

          this.sumatory.price = this.data.reduce((total, value) => total + (value['price'] != '' ? parseFloat(value['price']) : 0), 0)
          this.sumatory.cost = this.data.reduce((total, value) => total + (value['cost'] != '' ? parseFloat(value['cost']) : 0), 0)
          this.sumatory.price = this.sumatory.price + parseFloat(this.prepCharge[0].price)
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });

          this.accessoriesEmit$.emit({ value: this.sumatory.price })
          this.ms.channelComponents$.emit({ message: 'reloadHeader' })
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // =============================
  // * Save sale accessories
  // =============================
  public saveSaleAccessories = (): void => {
    let invalids = this.data.slice(0, this.data.length - 1).filter(el => !this.checkSaleForm(el))
    if (invalids.length > 0) {
      this.ms.sendMessage("alert", { type: "warning", text: 'Some row is invalid' });
      return
    }
    let creates = this.data.filter(el => el['id'] == null)
    creates.pop()
    let updates = this.data.filter(el => el['id'] != null)

    if (!this.prepChargeFlag) {
      if(this.prepCharge.length>0)this.prepCharge[0]['accessoryId'] = this.prepCharge[0]['id']
      creates = creates.concat(this.prepCharge)
    } else {
      updates = updates.concat(this.prepCharge)
    }
    console.log('update: ',updates);
    console.log('creates: ',creates);
    creates.length > 0 ? this.createSaleAccesories(creates) : null
    updates.length > 0 ? this.updateSaleAccesories(updates) : null
  }
  // =============================
  // * check Sale form
  // =============================
  public checkSaleForm = (data: any):boolean => {
    this.informationSale.setValue({
      saleAccessoryId: data.id,
      accessoryId: data.accessoryId,
      price: data.price,
      cost: data.cost,
      contactId: data.contactId
    })
    return this.informationSale.valid
  }
  // =============================
  // ? generic
  // =============================
  // =============================
  // * Get all vendors
  // =============================
  public getVendors = (): void => {
    this.loading = true
    this.master.sendPost("getContactsByTypeCorp?type=vendors", {}, (res) => {
      if (res) {
        if (res.status == 200) {

          this.vendors = res.data.contacts;
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", {
            type: "danger",
            text: res.data.error,
          });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    });


  }
  // =============================
  // todo: life cycles
  // =============================
  constructor(private master: MasterService, private ms: MessageService) { }

  ngOnInit() {
    // read all accesories
    this.readAccesories()
    // read sale accesories
    this.readSaleAccesories(false)
    // get all vendors
    this.getVendors()
  }

}
