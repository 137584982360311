import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import * as languageLibrary from '../../../../../../services/language'
// services
import { MessageService } from "../../../../../../services/message.service";
import { MasterService } from "../../../../../../services/master.service";
// import { EventEmitter } from 'events';
@Component({
  selector: 'cobuyers',
  templateUrl: './co-buyers.component.html',
  styleUrls: ['./co-buyers.component.scss']
})
export class CoBuyersComponent implements OnInit {
  /*
  * Variables
  */
  @Input() isEditing: boolean = false
  //
  @Input() isUnLock = true
  // define the cobuyers list
  @Input() coBuyers = []
  // define the contacts list (for lookup)
  @Input() contacts = []
  // define the SaleId
  @Input() saleId: number
  // define the lotId
  @Input() lotId: number
  // clone of contacts
  public contactComplete: Object[] = []
  // new contact created
  public viewDetail: boolean = false
  // define is anything is loading
  public loading: boolean = false
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  // set a cobuyer
  public cobuyerSelected: Object = {}
  //
  public countSave = 0
  // cobuyers list
  @Output() cobuyers$: EventEmitter<Object> = new EventEmitter();
  // displayList
  public displayList: boolean = false
  //
  public preselectContact: Object = {}
  /*
  * Functions
  */
  // add new coBuyer
  public addCoBuyer = (): void => {
    if (this.coBuyers.length < 2) {
      this.coBuyers.push({
        addresses: [],
        emails: [],
        cellphones: [],
        identifications: [],
        addressSelected: {},
        emailSelected: {},
        cellPhoneSelected: {},
        identificationSelected: {},
        contactName: ''
      })
    }
  }

  public showLookupList = (index): void => {
    this.displayList = !this.displayList

    if (this.displayList) {
      document.getElementById('coBuyerlookup-list_' + index).style.display = "block";
    } else
      document.getElementById('coBuyerlookup-list_' + index).style.display = "none";

  }

  //  todo:get contacts
  public getContacts = (): void => {
    this.loading = true
    this.master.sendPost('contactLookupForSales', {}, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          res.data.contactData.forEach(el => {
            el['companyName'] = el['companyName'] ? el['companyName'] : ''
          });
          this.contacts = res.data.contactData
        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })

  }
  // todo: select an user and get all data
  public changeContact = (contact: any, indexCoBuyer: number) => {

    let cellphones = contact.contactCommunications.filter(el => el['type'] == "cellphone" || el['type'] == "home" || el['type'] == "work")
    this.coBuyers[indexCoBuyer] = {
      contactId: contact.id,
      address: contact.addresses.length > 0 ? contact.addresses[0]['address'] : '',
      address2: contact.addresses.length > 0 ? contact.addresses[0]['address2'] : '',
      city: contact.addresses.length > 0 ? contact.addresses[0]['city'] : '',
      state: contact.addresses.length > 0 ? contact.addresses[0]['state'] : '',
      zipCode: contact.addresses.length > 0 ? contact.addresses[0]['zipCode'] : '',
      county: contact.addresses.length > 0 ? contact.addresses[0]['county'] : '',
      country: contact.addresses.length > 0 ? contact.addresses[0]['country'] : '',
      phoneType: cellphones.length > 0 ? cellphones[0]['type'] : '',
      phoneNumber: cellphones.length > 0 ? cellphones[0]['value'] : '',
      number: contact.contactIdentifications.length > 0 ? parseInt(contact.contactIdentifications[0]['number']) : 0,
      issuer: contact.contactIdentifications.length > 0 ? contact.contactIdentifications[0]['issuer'] : '',
      identificationTypeId: contact.contactIdentifications.length > 0 ? parseInt(contact.contactIdentifications[0]['identificationTypeId']) : null,
      saleBuyerRoleId: 2,
      saleId: this.saleId,
      lotId: this.lotId
    }

    this.cobuyers$.emit({ value: this.coBuyers.filter(el => el['contactId']), doSave: true })

  }
  // todo: set format to edit values
  public changeContactFromEdit = (contact: any, index: number) => {
    const address = this.coBuyers[index]['contact']['addresses'].find(el => el['address'] == this.coBuyers[index]['address'])
    const phone = this.coBuyers[index]['contact']['phones'].find(el => el['value'] == this.coBuyers[index]['phoneNumber'])
    const identification = this.coBuyers[index]['contact']['contactIdentifications'].find(el => el['number'] == this.coBuyers[index]['number'])

    this.coBuyers[index] = {
      ...this.coBuyers[index],
      contactId: contact['contactId'],
      address: address ? address['address'] : '',
      address2: address ? address['address2'] : '',
      city: address ? address['city'] : '',
      state: address ? address['state'] : '',
      zipCode: address ? address['zipCode'] : '',
      county: address ? address['county'] : '',
      country: address ? address['country'] : '',
      phoneType: phone['type']?phone['type']:'',
      phoneNumber: phone['value']?phone['value']:'',
      number: identification['number']?identification['number']:'',
      issuer: identification['issuer']?identification['issuer']:'',
      identificationTypeId: identification['identificationTypeId']?identification['identificationTypeId']:'',
      saleBuyerRoleId: contact['saleBuyerRoleId'],
      saleId: this.saleId,
      lotId: this.lotId
    }

    this.cobuyers$.emit({ value: this.coBuyers.filter(el => el['contactId']), doSave: false })

  }

  // todo:get data for contact selected
  public getContactDetail = (id: string, openDetail: boolean, callback?): void => {
    this.loading = true

    this.master.sendPost('getContactDetails', { contactId: id }, res => {
      this.loading = false
      // define if the modal detal is open
      this.viewDetail = openDetail
      if (res) {
        if (res.status == 200) {
          // * success

          this.cobuyerSelected = res.data.contact[0]
          if (callback) {
            callback(true)
          }
          setTimeout(() => {
            (document.getElementById('contactDetailCobuyer') as HTMLButtonElement).click()
          }, 100);
        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }

  // todo:get data all contacts (only name)
  public lookupContact = (index: number): void => {
    let word = (document.getElementById('lookupContactCobuyer_' + index) as HTMLInputElement).value.toLowerCase();
    let convert2String = (word: string): string => {
      return word + ''
    }
    this.contacts = this.contactComplete.filter(el =>
      convert2String(el['firstName']).toLowerCase().indexOf(word) >= 0 ||
      convert2String(el['lastName']).toLowerCase().indexOf(word) >= 0 ||
      convert2String(el['companyName']).toLowerCase().indexOf(word) >= 0 ||
      convert2String(el['address']).toLowerCase().indexOf(word) >= 0 ||
      convert2String(el['contactType']['type']).toLowerCase().indexOf(word) >= 0
    )


  }


  /*
  * Delete
  */
  // todo: select a cobuyer
  public selectCobuyer = (index: number) => {
    this.loading = true
    this.getContactDetail(this.coBuyers[index]['contactId'], true, res => {
      this.loading = false
      this.viewDetail = true
      this.cobuyerSelected['index'] = index
    });
  }
  // select a contact to delete
  public preselectCobuyerToDelete = (contact: Object, i: number): void => {
    this.preselectContact = { ...contact, index: i }
  }

  /*
  ? create contact
  */
  // todo: create new contact and open modal (only buyer)
  public createContact = (index: number): void => {
    this.loading = true;
    this.master.sendPost("createContactType", { type: 'buyers' }, (res) => {
      this.loading = false;
      if (res) {
        if (res.status == 200) {
          // * success

          this.getContactDetail(res.data.contact.id, true, res => {

            this.cobuyerSelected['index'] = index
          });

        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse '] });

      }
    });
  }

  // ? delete
  public questDelete = (): void => {

    // todo: check if the new contact is valid or have to delete it
    if (this.cobuyerSelected['firstName'] == "" || this.cobuyerSelected['firstName'] == null) {
      if (confirm('The information is not valid, if it leaves the view the contact will be eliminated, do you want to leave?')) {
        // delete

        this.deleteContact(this.cobuyerSelected['id'], this.cobuyerSelected['index'])
      }
    } else {

      this.changeContactFromModal(this.cobuyerSelected, this.cobuyerSelected['index'])
      this.viewDetail = false
      this.cobuyerSelected = {};
      (document.getElementById('btn-close-modal-delete') as HTMLButtonElement).click()
    }
  }
  // todo: save all
  public saveAll = () => {
    let buttons = [
      'btn-save-generalInformation',
      'btn-save-addresses', 'btn-save-communications', 'btn-save-identifications',
    ];
    buttons.forEach(el => {
      (document.getElementById(el) as HTMLButtonElement).click()
    });
  }
  // todo: refresh  contact
  public changeContactFromModal = (contact: any, index: number) => {

    let cellphones = contact.communications.filter(el => el['type'] == "cellphone" || el['type'] == "home" || el['type'] == "work")
    this.coBuyers[index] = {
      contactId: contact.id,
      address: contact.addresses.length > 0 ? contact.addresses[0]['address'] : '',
      address2: contact.addresses.length > 0 ? contact.addresses[0]['address2'] : '',
      city: contact.addresses.length > 0 ? contact.addresses[0]['city'] : '',
      state: contact.addresses.length > 0 ? contact.addresses[0]['state'] : '',
      zipCode: contact.addresses.length > 0 ? contact.addresses[0]['zipCode'] : '',
      county: contact.addresses.length > 0 ? contact.addresses[0]['county'] : '',
      country: contact.addresses.length > 0 ? contact.addresses[0]['country'] : '',
      phoneType: cellphones.length > 0 ? cellphones[0]['type'] : '',
      phoneNumber: cellphones.length > 0 ? cellphones[0]['value'] : '',
      number: contact.identifications.length > 0 ? parseInt(contact.identifications[0]['number']) : 0,
      issuer: contact.identifications.length > 0 ? contact.identifications[0]['issuer'] : '',
      identificationTypeId: contact.identifications.length > 0 ? parseInt(contact.identifications[0]['identificationTypeId']) : null,
      saleBuyerRoleId: 2,
      saleId: this.saleId,
      lotId: this.lotId
    }

    this.cobuyers$.emit({ value: this.coBuyers.filter(el => el['contactId']), doSave: true })

  }
  // todo: lieneter to general information
  public listenEndpoint = (e, index: number) => {

    switch (e.message) {
      case 'reloadContactDetail':
        if (e.firstName) {
          this.cobuyerSelected['firstName'] = e.firstName
        }

        this.getContactDetail(this.cobuyerSelected['id'], false, res => {
          this.cobuyerSelected['index'] = index
          this.questDelete()
        })
        break;
      default:
        break;
    }

  }
  // todo: listener to childrens
  public listenerChildrens = (e,index:number): void => {

    if (e.message == 'reloadContactDetail') {

      this.simpleRefresh(this.cobuyerSelected['id'],index)

    }
  }
  // simple refresh data to contact detail
  public simpleRefresh = (id: string,index:number): void => {

    this.master.sendPost('getContactDetails', { contactId: id }, res => {

      if (res) {
        if (res.status == 200) {
          // * success

          this.cobuyerSelected = {...res.data.contact[0], index:index}

        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }

  // todo: delete a contact
  public deleteContact = (id: string, index: number): void => {
    this.loading = true;
    this.master.sendPost("contactDelete", { contactId: id, query: false }, (res) => {
      this.loading = false;
      if (res) {
        if (res.status == 200) {
          // * success
          this.coBuyers.splice(index, 1)
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          this.viewDetail = false;
          (document.getElementById("btn-close-modal-delete") as HTMLButtonElement).click();
        } else {
          // ! error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    }
    );
  }

  // todo: delete cobuyer
  public deleteCobuyer = () => {

    if (this.preselectContact['id']) {


      this.loading = true;
      this.master.sendPost("deleteSaleBuyer", { saleBuyerId: this.preselectContact['id'] }, (res) => {
        this.loading = false;
        if (res) {
          if (res.status == 200) {
            // * success
            this.coBuyers.splice(this.preselectContact['index'], 1);
            (document.getElementById('btn-close-modal-delete-cobuyer') as HTMLButtonElement).click()
            this.cobuyers$.emit({ value: this.coBuyers, doSave: true })

          } else {
            // ! error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      }
      );

    } else {

      this.coBuyers.splice(this.preselectContact['index'], 1)
    }
  }
  /*
  * Life Cycles
  */
  constructor(
    private ms: MessageService,
    private master: MasterService
  ) {

  }

  ngOnInit() {

    this.contactComplete = this.contacts.slice()


  }

}
