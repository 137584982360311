import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MasterService } from '../../../../../services/master.service'
import { MessageService } from '../../../../../services/message.service'
import * as moment from 'moment';


@Component({
  selector: 'general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss']
})
export class GeneralInformationComponent implements OnInit {
  /*
  * variables
  */

  //  define if some changes not saved
  public savePending: boolean = false
  // save the language
  @Input() language: string =   'EN'
  // set all words
  @Input() words = {}
  //  define if loading state
  public loading: boolean = false
  // define if is individual
  public isIndividual: boolean = false
  // collapse/expand button
  public expanded: boolean = true;
  // contact object
  @Input() contact: Object = {}
  // define the  contact type
  @Input() type: string = ''
  // permissions
  @Input() permissions: string[] = []
  // define if is flat version
  @Input() isFlat:boolean=false
  //
  @Output() generalInformation$:EventEmitter<Object> = new EventEmitter()
  // data
  // @Output()
  // Group of inputs
  public individual = new FormGroup({
    contactId: new FormControl(0, [Validators.required]),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    suffix: new FormControl('', []),
    middleName: new FormControl('', []),
    title: new FormControl('', []),
    birthdate: new FormControl('', []),
  })
  // non individual
  public nonIndividual = new FormGroup({
    contactId: new FormControl(0, [Validators.required]),
    TIDNumber: new FormControl('', []),
    companyName: new FormControl('', [Validators.required]),
    firstName: new FormControl('', []),
    lastName: new FormControl('', []),
    relatedCompanyType: new FormControl('',[])
  })
  // methotds list
  public relatedCompanyTypeList: string[] = [
    'Related',
    'Non-Related',
  ]
  // get information of inputs
  get firstName() { return this.individual.get('firstName') }
  get lastName() { return this.individual.get('lastName') }
  get suffix() { return this.individual.get('suffix') }
  get middleName() { return this.individual.get('middleName') }
  get title() { return this.individual.get('title') }
  get firstNameNon() { return this.nonIndividual.get('firstName') }
  get lastNameNon() { return this.nonIndividual.get('lastName') }
  get companyName() { return this.nonIndividual.get('companyName') }
  get TIDNumber() { return this.nonIndividual.get('TIDNumber') }
  get relatedCompanyType() { return this.nonIndividual.get('relatedCompanyType') }
  /*
  ? individual
  */
  //  set individual form
  public setIndividualForm = (): void => {

    this.individual.setValue({
      contactId: this.contact['id'],
      firstName: this.contact['firstName'],
      lastName: this.contact['lastName'],
      suffix: this.contact['suffix'],
      middleName: this.contact['middleName'],
      title: this.contact['title'],
      birthdate: this.contact['birthdate']
    })
  }
  /*
  * functions
  */
/*
  todo: detect changes
  */

  //  save individual form
  public saveIndividual = (): void => {
    if (this.individual.valid) {
      this.loading = true
      // todo: if not birthdate
      if (this.individual.value['birthdate'] == null || this.individual.value['birthdate'] == "") {
        // set format suffix, (not null)
        this.individual.value['suffix'] == null ? this.individual.value['suffix'] = "" : this.individual.value['suffix']
        // set format middleName, (not null)
        this.individual.value['middleName'] == null ? this.individual.value['middleName'] = "" : this.individual.value['middleName']
        // set format title, (not null)
        this.individual.value['title'] == null ? this.individual.value['title'] = "" : this.individual.value['title']
        // set format birthdate (not null)
        this.individual.value['birthdate'] = ""
        // set individual object
        this.individual.value['isIndividual'] = 1

        // send object
        this.master.sendPost('updateContact', this.individual.value, res => {
          this.loading = false
          if (res) {
            if (res.status == 200) {
              // * success
              this.savePending = false
              this.generalInformation$.emit({ message: 'changesPendingContacts', section: 'generalInformationIndividual', value: false })
              // recharge user data
              this.generalInformation$.emit({ message: 'reloadContactDetail', firstName: this.individual.value['firstName'] })
              // show message
              this.ms.sendMessage("alert", { type: "success", text: res.data.message });
            } else {
              // ! send message in case error
              this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
            }
          } else {
            // in case API no response
            this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
          }
        })
      } else {
        // todo: if not birthdate
        let today = moment()
        let birthdate = moment(this.individual.value['birthdate'])
        // if the birthdate is correct
        if (birthdate < today) {
          // set format suffix, (not null)
          this.individual.value['suffix'] == null ? this.individual.value['suffix'] = "" : this.individual.value['suffix']
          // set format middleName, (not null)
          this.individual.value['middleName'] == null ? this.individual.value['middleName'] = "" : this.individual.value['middleName']
          // set format title, (not null)
          this.individual.value['title'] == null ? this.individual.value['title'] = "" : this.individual.value['title']
          // set individual object
          this.individual.value['isIndividual'] = 1

          // send object
          this.master.sendPost('updateContact', this.individual.value, res => {
            this.loading = false
            if (res) {
              if (res.status == 200) {
                // * success
                this.savePending = false
                this.generalInformation$.emit({ message: 'changesPendingContacts', section: 'generalInformationIndividual', value: false })
                // recharge user data
                  this.generalInformation$.emit({message: 'reloadContactDetail', firstName: this.individual.value['firstName'] })
                this.ms.sendMessage("alert", { type: "success", text: res.data.message });
              } else {
                // ! send message in case error
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
              }
            } else {
              // in case API no response
              this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
            }
          })
        } else {
          // ! birth date invalid
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['errorBirthDate'] });
          this.loading = false
        }
      }
    }
  }

  /*
  ? non individual
  */
  //  set individual form
  public setNonIndividualForm = (): void => {
    this.nonIndividual.setValue({
      contactId: this.contact['id'],
      firstName: this.contact['firstName'],
      lastName: this.contact['lastName'],
      companyName: this.contact['companyName'],
      TIDNumber: this.contact['TIDNumber'],
      relatedCompanyType: this.contact['relatedCompanyType']
    })

  }
  //  save individual form
  public saveNonIndividual = (): void => {
    if (this.nonIndividual.valid) {
      this.loading = true
      this.nonIndividual.value['isIndividual'] = 0
      // send object
      this.master.sendPost('updateContact', this.nonIndividual.value, res => {
        this.loading = false
        if (res) {
          if (res.status == 200) {
            // * success
            this.savePending = false
            this.generalInformation$.emit({ message: 'changesPendingContacts', section: 'generalInformationNonIndividual', value: false })
            // recharge user data
            this.generalInformation$.emit({ message: 'reloadContactDetail',companyName: this.nonIndividual.value['companyName'] })
            // show message
            this.ms.sendMessage("alert", { type: "success", text: res.data.message });
          } else {
            // ! send message in case error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })
    }
  }

  // change de individual
  public changeIndividual = (): void => {
    this.isIndividual = !this.isIndividual
    this.savePending = false
    this.generalInformation$.emit({ message: 'individualValue', value: this.isIndividual })
    if (this.isIndividual) {
      this.generalInformation$.emit({ message: 'changesPendingContacts', section: 'generalInformationNonIndividual', value: false })
      this.setIndividualForm()
    } else {
      this.generalInformation$.emit({ message: 'changesPendingContacts', section: 'generalInformationIndividual', value: false })
      this.setNonIndividualForm()
    }
  }
  // todo: detect changes
  public changesPending = (section: string):void => {

    if (!this.savePending) {
      this.savePending = true
      this.generalInformation$.emit({ message: 'changesPendingContacts', section: section, value: this.savePending })
    }
  }
  //

  /*
  * Life Cycles
  */
  constructor(private master: MasterService, private ms: MessageService) {


  }

  ngOnInit() {

    if (['vendors', 'finance', 'lenders', 'insurance', 'lienholders'].includes(this.type)) {
      //  is non individual
      this.contact['isIndividual'] = 0
    } else if (['leads', 'buyers'].includes(this.type)) {
      // is mixto
      this.contact['isIndividual'] == 1 ? this.isIndividual = true : this.isIndividual = false
    } else if (this.type == 'employees') {
      this.contact['isIndividual'] = 1
    }

    if (this.contact['isIndividual'] == 1) {
      // if is individual

      this.isIndividual = true
      this.setIndividualForm()
    } else {
      // if non individual
      this.isIndividual = false
      this.setNonIndividualForm()
    }
    this.generalInformation$.emit({ message: 'individualValue', value: this.isIndividual })
  }










}
