import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// services
import { MessageService } from '../../../../services/message.service'
import { MasterService } from '../../../../services/master.service'
import * as languageLibrary from '../../../../services/language'
@Component({
  selector: 'report-setup',
  templateUrl: './report-setup.component.html',
  styleUrls: ['./report-setup.component.scss']
})
export class ReportSetupComponent implements OnInit {
  /*
  * Variables
  */
  @Output() childEmitter$:EventEmitter<Object>=new EventEmitter()
  //  define if some changes not saved
  public savePending: boolean = false
  // save the language
  @Input() language: string = 'EN'
  // set all words
  public words = languageLibrary.language
  // define expanded
  public expanded: boolean = true
  public expandedBuyer: boolean = true
  public expandedReceipt: boolean = true
  // contact persom
  public contactPerson = ''
  // buyer is
  public buyerId = null
  // checkboxes
  public checkboxes: Object[] = [
    { name: 'warrantybyDealer', isChecked: false },
    { name: "manufacturerWarrantyStillApplies", isChecked: false },
    { name: "otherUsedVehicleWarrantyApplies", isChecked: false },
    { name: 'serviceContract', isChecked: false },
    { name: "manufacturerUsedVehicleWarrantyApplies", isChecked: false },
    { name: 'printDisclosures', isChecked: false },
    { name: 'customerSignatureRequiredonbackpage', isChecked: false },
    { name: 'fullWarranty', isChecked: false }
  ]
  // permissions
  @Input() permissions: string
  // entity
  @Input() entity: string
  // entity id
  @Input() entityId: string
  // entity id
  @Input() data: Object = { id: '', contactPerson: '', warrantDealer: '', manufacturedWarranty: '', manufacturedUsed: '', print: '', serviceContact: '', customerSignature: '', fullWarranty: '' }
  /*
  * functions
  */
  // todo: detect changes
  changesPending = () => {

    if (!this.savePending) {

      this.savePending = true
      this.childEmitter$.emit({ message: 'changesPendingCorporate', section: 'buyersGuide', value: this.savePending })
    }
  }
  // get data buyers
  public save = (): void => {

    let params = {
      "contactPerson": this.contactPerson,
      "warrantDealer": this.checkboxes[0]['isChecked'] ? 1 : 0,
      "manufacturedWarranty": this.checkboxes[1]['isChecked'] ? 1 : 0,
      "serviceContact": this.checkboxes[2]['isChecked'] ? 1 : 0,
      "manufacturedUsed": this.checkboxes[3]['isChecked'] ? 1 : 0,
      "print": this.checkboxes[4]['isChecked'] ? 1 : 0,
      "otherUsed": this.checkboxes[5]['isChecked'] ? 1 : 0,
      "customerSignature": this.checkboxes[6]['isChecked'] ? 1 : 0,
      "fullWarranty": this.checkboxes[7]['isChecked'] ? 1 : 0,
      "entity": this.entity,
      "entityId": this.entityId,
      "buyerGuideId": this.buyerId
    }

    this.master.sendPost('buyersGuide', params, res => {
      if (res) {
        if (res.status == 200) {
          // * success
          this.savePending = false
          this.childEmitter$.emit({ message: 'changesPendingCorporate', section: 'buyersGuide', value: this.savePending })
          if (this.buyerId == null) {
            this.buyerId = res.data.id
          }
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  /*
  * life cycles
  */
  constructor(private ms: MessageService, private master: MasterService) {

  }

  ngOnInit() {

    this.buyerId = this.data['id'] ? this.data['id'] : null
    this.contactPerson = this.data['contactPerson']

    this.checkboxes[0]['isChecked'] = this.data['warrantDealer']
    this.checkboxes[1]['isChecked'] = this.data['manufacturedWarranty']
    this.checkboxes[2]['isChecked'] = this.data['serviceContact']
    this.checkboxes[3]['isChecked'] = this.data['manufacturedUsed']
    //
    this.checkboxes[4]['isChecked'] = this.data['print']
    //
    this.checkboxes[5]['isChecked'] = this.data['otherUsed']
    this.checkboxes[6]['isChecked'] = this.data['customerSignature']
    this.checkboxes[7]['isChecked'] = this.data['fullWarranty']
  }

}
