import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from '../../../services/message.service'
import { MasterService } from '../../../services/master.service'
import { StoreService } from '../../../services/store.service'
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
// import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import * as languageLibrary from '../../../services/language'
// import { NgxXml2jsonService } from 'ngx-xml2json';
// extarnal
import * as moment from "moment/moment";
@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit {

  /*
* Variables
*/
  //
  public inventoryNulls:any[]=[]
  //
  public images = []
  // save the language
  public language: any = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  // save all data by type
  public data: Object[] = []
  // save all data by type (complete)
  public dataComplete: Object[] = []
  // define lot selected
  public lotSelected: any = null
  // table headers
  public headers: Object[] = [
    { name: 'Vehicle Image', view: true, disabled: true },
    { name: 'Stock #', view: true, disabled: true },
    { name: 'VIN #', view: true, disabled: true },
    { name: 'Year', view: true, disabled: true },
    { name: 'Make', view: true, disabled: true },
    { name: 'Model', view: true, disabled: true },
    { name: 'Trim Level', view: true, disabled: false },
    { name: 'Body', view: true, disabled: false },
    { name: 'Engine', view: true, disabled: false },
    { name: 'Cylinders', view: true, disabled: false },
    { name: 'Transmission', view: true, disabled: false },
    { name: 'Driveline', view: true, disabled: false },
    { name: 'Exterior Color', view: true, disabled: true },
    { name: 'Interior Color', view: true, disabled: false },
    { name: 'Exterior Color (Minor)', view: true, disabled: false },
    { name: 'Selling Price', view: true, disabled: false },
    { name: 'Type', view: true, disabled: false },
    { name: 'Miles', view: true, disabled: true },
    { name: 'Odometer', view: true, disabled: false },
    { name: 'Curb Weight', view: true, disabled: false },
    { name: 'CarryingWeight', view: true, disabled: false },
    { name: 'GVWR', view: true, disabled: false },
    { name: 'Tonnage', view: true, disabled: false },
    { name: 'Lot', view: true, disabled: true },
    { name: 'Status', view: true, disabled: true },
    { name: 'Days Old', view: true, disabled: true }
  ]
  // define the rows ordered
  public order = { isAscend: true, type: '' }
  // save the pagination settings
  public pagination = { index: 0, rowsByPage: 10, pages: [{ numPage: 0, start: 0, end: 8 }] }
  // define if is loading
  public loading: boolean = false
  // define if something is uploading
  public uploading: boolean = false
  // permissions
  public permissions: Object[] = []

  // define the object preselected
  public preselectedObject: Object = {}
  // list of status
  public statusList: string[] = []
  //
  private timeOutID: any;
  //
  public disabledSetup = false
  //
  public isAdmin = false
  /*
* Functions
*/

  // go to details
  public goToDetail = (id: string): void => {
    this.router.navigate(['/inventory/' + id]);
  }
  /*
todo: pagination and rows per view functions
*/
  // sort the users registers
  public sort = (param: string): void => {
    if (param == this.order.type) {
      this.order.isAscend = !this.order.isAscend
    } else {
      this.order.type = param
      this.order.isAscend = true
    }
    if (this.order.isAscend) {
      // ascend

      this.data = this.data.sort((a, b) => a[param] > b[param] ? 1 : -1)

    } else {
      // descend

      this.data = this.data.sort((a, b) => a[param] > b[param] ? -1 : 1)

    }
  }
  // change num page view
  public changePage = (page: number): void => {
    if (page == -1) {
      // back
      if (this.pagination.index >= 1) {
        this.pagination.index = this.pagination.index - 1
      }
    } else {
      // next
      if (this.pagination.index < this.pagination.pages.length - 1) {
        this.pagination.index = this.pagination.index + 1
      }
    }
  }
  // create a pagination
  public newPagination = (registers: Object[]): void => {
    this.pagination.pages = []
    this.pagination.index = 0
    this.pagination.pages['start'] = 0
    for (let index = 0; index < (registers.length / this.pagination.rowsByPage); index++) {
      this.pagination.pages.push({ numPage: index, start: this.pagination.rowsByPage * index, end: (this.pagination.rowsByPage * (index + 1)) })
    }
  }
  // change the rows per page
  public changeRows = (): void => {
    this.pagination.index = 0
    this.newPagination(this.data)
  }

  public exportToExcel = (): void => {
    let m = moment().format('YY-MM-DD h_m_sA');
    console.log(m)
    // this.exportAsService.save(this.exportAsConfig, 'InvList_' + m).subscribe(() => {
    // });
    // this.exportAsService.get(this.exportAsConfig).subscribe(content => {
    //   console.log(content);
    // });
  };

  // filter by word
  public search = (e): void => {
    if (e.key == 'Enter') {
      let word = ((document.getElementById('search') as HTMLInputElement).value).toLowerCase();

      let convert2String = (word: string): string => {
        return word + ''
      }
      let dataFiltered: any[] = []
      for (let index = 0; index < this.headers.length; index++) {
        dataFiltered = dataFiltered.concat(this.dataComplete.filter(el => convert2String(el[this.headers[index]['name']]).toLowerCase().indexOf(word) > -1))
      }

      this.data = dataFiltered.filter((v, i, a) => a.indexOf(v) == i)

      if (this.data.length >= 1) {
        this.pagination.rowsByPage = this.data.length
        // exist rows
        this.newPagination(this.data)
      } else {
        this.pagination.rowsByPage = 10
        this.ms.sendMessage("alert", { type: "info", text: this.words[this.language]['noFound'] });
      }
    }
  }

  // togle the cells view
  public toggleCell = (index?: number): void => {
    if (index != undefined) {
      this.headers[index]['view'] = !this.headers[index]['view']
    }
    let config = JSON.stringify(this.headers)
    this.master.sendPost('updateUser', { inventoryTable: config }, res => {
      if (res) {
        if (res.status == 200) {
          // * success
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  /*
  todo: ADD
  */
  //  create a new vehicle
  public addVehicle = (lot: string): void => {
    this.loading = true
    if (lot != null && !this.disabledSetup) {
      if(this.inventoryNulls.length>0){

        this.router.navigate(['/inventory/' + this.inventoryNulls[0]['id']]);
      }else{
        this.master.sendPost('createInventoryVehicle', { lotId: lot }, res => {
          this.loading = false
          if (res) {
            if (res.status == 200) {
              // * success
              this.router.navigate(['/inventory/' + res.data.id]);
            } else {
              // ! in case error
              this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
            }
          } else {
            // in case API no response
            this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
          }
        })
      }
    }
  }
  // get data of selected lot
  public changeLot = (lot: string, whatchMessages: boolean): void => {
    // this.loading=true

      this.master.sendPost('inventoryByLots', { lotId: lot }, res => {
        // this.loading=false
        if (res) {

          if (res.status == 200) {
            // * success
            this.lotSelected = lot
            this.statusList = res.data.statusCatalog

            this.data = this.buildData(res.data.inventory.filter(el => el['stockNumber']))
            this.dataComplete = this.buildData(res.data.inventory.filter(el => el['stockNumber']))
            this.inventoryNulls = res.data.inventory.filter(el => !el['stockNumber'])


            if (this.data.length > 0) {
              this.newPagination(this.data)
            }
            // set headers
            res.data.inventoryTable ? this.headers = JSON.parse(res.data.inventoryTable) : this.headers
            // message admin
            if (whatchMessages) {
              let admins = this.store.userAccount['userRoles'].userRoles.filter(el => el.role.name == 'Administrator')
              if (res.data.inventorySetup == null || res.data.inventorySetup.inventorySetupComplete == false) {
                this.disabledSetup = true;
                this.isAdmin = admins.length > 0 ? true : false;
                setTimeout(() => {
                  (document.getElementById('btn-alertModalSetup') as HTMLButtonElement).click()
                }, 300);
              }
            }
          } else {
            // ! in case error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })

  }
  // generate a inventory object real
  public buildData = (data: any[]): any[] => {
    let newData: any[] = []
    for (let index = 0; index < data.length; index++) {
      newData.push({
        'id': data[index]['id'],
        'Vehicle Image': data[index].vehicle.uploads.length > 0 ? data[index].vehicle.uploads[0]['fileURL'] + '480.jpg' : '',
        'Stock #': data[index].stockNumber,
        'VIN #': data[index].vehicle.vinNumber,
        'Year': data[index].vehicle.modelYear,
        'Make': data[index].vehicle.make,
        'Model': data[index].vehicle.model,
        'Trim Level': data[index].vehicle.trimLevel,
        'Body': data[index].vehicle.bodyStyle,
        'Engine': data[index].vehicle.engine,
        'Cylinders': data[index].vehicle.cylinders,
        'Transmission': data[index].vehicle.transmission,
        'Driveline': data[index].vehicle.driveline,
        'Exterior Color': data[index].vehicle.exteriorColor,
        'Interior Color': data[index].vehicle.interiorColor,
        'Exterior Color (Minor)': data[index].vehicle.exteriorColorMinor,
        'Selling Price': data[index].sellingPrice,
        'Type': data[index].vehicle.type,
        'Miles': data[index].vehicle.miles,
        'Odometer': data[index].vehicle.odometer,
        'Curb Weight': data[index].vehicle.curbWeight,
        'GVWR': data[index].vehicle.gvwr,
        'Tonnage': data[index].vehicle.tonnage,
        'Lot': data[index].lot.name,
        'Status': data[index].status.name,
        'StatusId': data[index].status.id,
        'Days Old': data[index].daysOld
      })
    }
    return newData
  }
  // change status
  public changeStatus = (vehicle: Object): void => {


    this.master.sendPost('updateInventory', { statusId: parseInt(vehicle['StatusId']), inventoryId: vehicle['id'], forUpdate: 'administrative', lotId: this.lotSelected }, res => {

      if (res) {
        if (res.status == 200) {
          // * success
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }

  /*
    todo: Delete
    */
  //  select a vehicle
  public selectVehicle = (vehicle: Object): void => {
    this.preselectedObject = { ...vehicle }
  }
  // delete the contact selected
  public delete = (): void => {
    this.loading = true;
    this.deleteImages(res => {
      this.loading = false;
      if (res) {
        this.master.sendPost('deleteInventory', { inventoryId: this.preselectedObject['id'] }, res => {
          if (res) {
            if (res.status == 200) {
              // * success
              this.ms.sendMessage("alert", { type: "success", text: res.data.message });
              // close delete modal
              (document.getElementById('btn-close-modal-delete') as HTMLButtonElement).click();
              // reload data
              this.changeLot(this.lotSelected, false)
            } else {
              // ! error
              this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
            }
          } else {
            // in case API no response
            this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
          }
        })

      } else {
        this.ms.sendMessage("alert", { type: "danger", text: 'Error' });
      }
    })
  }
  // delete images (this function is used befeore delete some row)
  public deleteImages = (callback) => {
    this.master.sendPost('deleteVehicleImages', { vehicleId: this.preselectedObject['id'] }, res => {
      if (res) {
        if (res.status == 200) {
          // * success
          callback(true)
        } else {
          // ! error
          callback(false)
        }
      } else {
        // in case API no response
        callback(false)
      }
    })
  }
  // move the position of columns in the table
  public drop(event: CdkDragDrop<string[]>) {

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    this.toggleCell()
    // this.changePosition(event.currentIndex, event.previousIndex)
  }
  /*
  todo: upload images
  */
  //  * valid vin number
  public checkForDupVIN = (vinCode, index) => {

    if (vinCode.length < 3)
      return;

    // let params = { corporateURL: this.corporateURL.value }
    clearTimeout(this.timeOutID);
    this.timeOutID = setTimeout(() => {
      this.master.sendPost('checkForDupVIN', { vinNumber: vinCode, lotId: this.lotSelected }, res => {
        if (res) {
          // check if API conecction success
          if (res.status == 200) {
            res.data.vehicleVINs.length > 0 ? this.images[index].errorVin = 'VIN Number already exist' : this.images[index].errorVin = ''
          } else {
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          this.ms.sendMessage("alert", { type: "danger", text: "API no response" });
        }
      })
    }, 750);


  }
  // * format bytes
  public formatBytes = (bytes: number): string => {
    return (bytes / 1024).toFixed(2) + " KB"
  }
  // * send post to get vin Number
  public getVinNumber = (files, index) => {

    // send post
    let formData = new FormData();
    formData.append("inputimage", files);
    let request = new XMLHttpRequest();
    request.open("POST", "https://www.recognition.ws/vinbarcode/v1?accesscode=f3297bcb-6a50-41d2-8b5e-f4859d5062f4&saveImage=TRUE&vindecode=TRUE");
    request.send(formData)
    let vm = this
    // listener for request
    // request.onload = function (oEvent) {

    //   if (request.status == 200) {
    //     const parser = new DOMParser();
    //     const xmlparser = new xml2js.Parser({ strict: false, trim: true });
    //     const xml2 = parser.parseFromString(request.response, 'text/xml');
    //     const obj = xmlparser.parseString(xml2, (err, result) => {
    //       return result;
    //     });
    //     // ? check scan success

    //     if (obj['VINbarcode'].VIN_Captured && obj['VINbarcode']['VINdecode']["@attributes"].Status == 'SUCCESS') {
    //       // * if success
    //       vm.images[index].code = obj['VINbarcode'].VIN_Captured
    //       vm.images[index].status = 'Decoded'
    //       // vm.images[index].stock=''
    //       vm.checkForDupVIN(vm.images[index].code, index)

    //       vm.checkStockNumber(vm.images)

    //     } else {

    //       // ! if error
    //       vm.checkStockNumber(vm.images)
    //       vm.ms.sendMessage("alert", { type: "danger", text: obj['VINbarcode']['VINdecode'] ? obj['VINbarcode']['VINdecode'].Message["@attributes"].Value : obj['VINbarcode'].Message["@attributes"].Value });
    //       vm.images[index].status = 'Recognition Failed'
    //     }

    //   } else {
    //     vm.ms.sendMessage("alert", { type: "danger", text: vm.words[vm.language]['imageError'] });
    //   }

    // };
  }
  // * check stock number
  public checkStockNumber = (images): void => {

    this.master.sendPost('getStockNumbersForScan', { lotId: this.lotSelected, arrayLength: images.length }, res => {
      if (res) {
        if (res.status == 200) {
          // * success

          for (let index = 0; index < res.data.data.length; index++) {

            images[index]['stock'] = res.data.data[index]['stockNumber'] ? res.data.data[index].stockNumber : ''
            images[index]['clean'] = res.data.data[index]['cleanStockNumber'] ? res.data.data[index].cleanStockNumber : index

            if (res.data.data[index]['vinNumber']) {
              images[index]['stock'] = res.data.data[index]['vinNumber'] ? images[index]['code'].substring(images[index]['code'].length - parseInt(res.data.data[index]['vinNumber']), images[index]['code'].length) : ''
              // images[index]['clean']=index
            }

          }
        } else {
          // ! error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // * remove items from pre-list
  public remove = (index, numImage): void => {
    this.images[index]['images'].splice(numImage, 1)
  }
  // *
  public removeFather = (index) => {
    this.images.splice(index, 1)
  }
  // * upload  car images
  public upload = (id, images, index): void => {
    this.uploading = true
    let arrImg = images.map(el => {
      return {
        '1024': el['1024'],
        '480': el['480']
      }
    })

    this.master.sendPost('uploadImages', { lastSort: 1, moduleType: 'inventory', imagesArr: arrImg, vehicleId: id, inventoryId: id }, res => {
      this.uploading = false

      if (res) {
        if (res.status == 200) {
          // * success
          // (document.getElementById('closeUpload') as HTMLButtonElement).click();
          this.images.splice(index, 1)
          // this.images=[]
          this.changeLot(this.lotSelected, false);
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // * upload vin number images
  public uploadImage = (e): void => {
    let files = []
    let reader = []

    let vm = this
    files = e.target.files
    for (let index = 0; index < files.length; index++) {
      // check if some file is invalid
      if (['image/jpg', 'image/jpeg', 'image/heic', 'image/heif', 'image/png'].includes(files[index].type)) {
        // * format valid
        reader[index] = new FileReader();
        reader[index].readAsDataURL(files[index]);
        // listener for reader
        reader[index].onload = function (event) {
          vm.images.push({
            image: `${reader[index].result}`,
            name: files[index].name,
            size: vm.formatBytes(files[index].size),
            code: '',
            stock: '',
            year: '',
            model: '',
            make: '',
            id: '',
            clean: '',
            errorStock: '',
            errorVin: '',
            images: [],
            toggleData: true,
            status: 'In progress'
          })

          vm.getVinNumber(files[index], vm.images.length - 1)
        };

      } else {
        // ! invalid format
        this.ms.sendMessage("alert", { type: "danger", text: vm.words[vm.language]['Invalid image format'] });
      }
    }



  }
  public toggleData = (index): void => {
    this.images[index].toggleData = !this.images[index].toggleData
  }
  //
  public saveAll = (): void => {

    let buttons = this.images.filter(el => el.status == 'Decoded')

    buttons.forEach(el => {
      (document.getElementById('btn_' + el.code) as HTMLButtonElement).click();
    })
  }
  //  * cut base64 string
  public subStringB64 = (word: string): string => {
    let index = word.indexOf(',')
    return word.substr(index + 1)
  }
  // * get iamege and convert to base64
  public changeImage = (e, inx: number): void => {
    let files = []
    let reader = []
    let vm = this
    files = e.target.files
    for (let index = 0; index < files.length; index++) {
      // check if some file is invalid
      if (['image/jpg', 'image/jpeg', 'image/heic', 'image/heif', 'image/png'].includes(files[index].type)) {
        // * format valid
        reader[index] = new FileReader();
        reader[index].readAsDataURL(files[index]);
        reader[index].onload = function (event) {
          vm.images[inx].images.push({
            'name': files[index].name,
            '1024': '',
            '480': '',
            'img': ''
          })
          vm.resizeImge(reader[index].result, 1024, 768, vm.images[inx].images.length - 1, vm.images[inx].images, '1024')
          vm.resizeImge(reader[index].result, 480, 360, vm.images[inx].images.length - 1, vm.images[inx].images, '480')
        };
      } else {
        // ! invalid format
        this.ms.sendMessage("alert", { type: "danger", text: vm.words[vm.language]['Invalid image format'] });
      }
    }

    // this.images[0].imageOpt = this.resizeImge(e.target.files[0], 100)
  }
  // * resize the base64 image
  public resizeImge(image, maxWidth, maxHeight, index, images, imageFormat) {
    let img = new Image();
    img.src = image;
    img.onload = () => {
      let canvas = (document.createElement('canvas') as HTMLCanvasElement)
      // size
      const MAX_WIDTH = maxWidth
      const MAX_HEIGHT = maxHeight
      let width = img.width
      let height = img.height

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width
          width = MAX_WIDTH
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height
          height = MAX_HEIGHT
        }
      }
      // size
      canvas.width = width
      canvas.height = height
      let cx = canvas.getContext('2d')

      cx.drawImage(img, 0, 0, width, height)
      images[index][imageFormat] = this.subStringB64(canvas.toDataURL())
      images[index]['img'] = canvas.toDataURL();
    }

  }
  // * create a new row in inventory, with vin number and stock number required
  public createInventory = (vin: string, stockNumber: string, clean: string, index: number): void => {
    this.images[index].status = 'In progress'

    this.master.sendPost('createInventoryVehicleFromVINQuery', { lotId: this.lotSelected, vinNumber: vin, stockNumber: stockNumber, cleanStockNumber: clean }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.images[index]['id'] = res.data.vehicleData.id
          this.images[index]['year'] = res.data.vehicleData.year
          this.images[index]['make'] = res.data.vehicleData.make
          this.images[index]['model'] = res.data.vehicleData.model
          this.images[index].status = 'Completed'
          this.changeLot(this.lotSelected, false);
        } else {
          // ! in case error
          this.images[index].status = 'Decoded'
          res.data.error.indexOf('Stock number') >= 0 ? this.images[index].errorStock = res.data.error : ''
          res.data.error.indexOf('VIN number') >= 0 ? this.images[index].errorVin = this.words[this.language]['Invalid VIN Number'] : ''
        }
      } else {
        // in case API no response
        this.images[index].status = 'Decoded'
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // todo: listen to output of navbar
  public listenerNav = (e): void => {

    switch (e['message']) {
      case 'changeLanguage':
        this.language = e['value']
        break;
      case 'setPermissions':
        // permissions on user list
        this.permissions = e.permissions[4].childrens[0].permissions
        // if not have permissions, return to account settings
        if (this.data.length == 0) {
          // todo: get from store

          this.changeLot(localStorage.getItem('lot') ? localStorage.getItem('lot') : this.store.lotSelected, true)
        }
        break;
      case 'changeLot':
        this.data=[]
        if (this.lotSelected != e.lot) {
          this.changeLot(e.lot, false)
        }
        break
      default:
        break;
    }
  }
  /*
  todo: Life Cycles
  */
  constructor(
    // private ngxXml2jsonService: NgxXml2jsonService,
    private router: Router,
    private ms: MessageService, private master: MasterService, private store: StoreService,
    // private exportAsService: ExportAsService
  ) {

  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    // normal
    if (this.store.userAccount['permissions']) {
      // permissions on user list
      this.permissions = this.store.userAccount['permissions'][4].childrens[0].permissions
      // if not have permissions, return to account settings
      if (this.data.length == 0) {
        // todo: get from store
        this.changeLot(localStorage.getItem('lot') ? localStorage.getItem('lot') : this.store.lotSelected, true)
      }
    }


  }



}
