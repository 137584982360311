import { Component, OnInit, Input,SimpleChanges, Output,EventEmitter } from '@angular/core';
import { MasterService } from "../../../../services/master.service";
import { MessageService } from "../../../../services/message.service";
import * as languageLibrary from '../../../../services/language'

@Component({
  selector: 'cpi-modal',
  templateUrl: './cpi-modal.component.html',
  styleUrls: ['./cpi-modal.component.scss']
})
export class CpiModalComponent implements OnInit {
  // ***************
  // variable
  // ***************
  public paymentStatusList:Object={}
  //
  public isOpenTable: boolean = false
  //
  public loading: boolean = false
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  //
  @Input() saleId: number = 0
  //
  public users: any[] = []
  //
  public usersComplete: any[] = []
  //
  public data = {}
  //
  public insurance = []
  //
  @Output() emitterModal$:EventEmitter<Object>=new EventEmitter()

  // ***************
  // functions
  // ***************
  //
  //  todo: seach users
  //
  public toggleTable = () => {
    this.isOpenTable = !this.isOpenTable
    if (this.isOpenTable) {
      this.readSales(0)
    }
  }
  //
  //  todo: seach users
  //
  public readSales = (saleId: number) => {
    saleId == 0 ? false : saleId
    this.loading = true;
    this.master.sendPost("CRUDForPayments", { saleId: saleId, activity: 'read' }, (res) => {
      this.loading = false;
      if (res) {
        if (res.status == 200) {
          // * success
          if (saleId) {

            this.paymentStatusList=res.data.body['paymentStatusList']
            this.isOpenTable = false
            this.saleId = saleId
            this.getCPI(this.saleId)
          } else {
            this.data={}
            this.usersComplete = res.data.allSales.filter(el => el['saleBuyers'].length > 0)
            this.users = this.usersComplete.slice()
          }
        } else {
          // ! error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    }
    );
  }
  // todo:reload cpi
  public listenerAddCpi = (e) => {
    if (e.message == 'reloadCpi') {
      this.emitterModal$.emit({message:'reloadTable'})
      this.getCPI(this.saleId)
    }
  }
  // todo: get after sales
  public getCPI = (id): void => {
    this.master.sendPost("cpiDispatcher", {
      params: {
        saleId: id
      }, activity: 'getSaleCpi'
    }, (res) => {
      if (res) {
        if (res.status == 200) {
          this.data = res.data.result


        } else {
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        this.ms.sendMessage("alert", {
          type: "danger",
          text: this.words[this.language]['apiNoResponse'],
        });
      }
    });
  };

  /*
    todo: getters
    */
  public getDataByType = (): void => {
    this.master.sendPost("getContactsByTypeCorp?type=insurance", {}, (res) => {
      if (res) {
        if (res.status == 200) {
          this.insurance = res.data.contacts;
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    });
  };
  /*
  ------------------------
   todo: open payment view in another window
  / ------------------------
  */
  public openPayment=()=>{
    let index=window.location.href.indexOf('#/')
    let route=window.location.href.substr(0,index+2)
    window.open(route+'payments/'+this.saleId,'fullscreen',
    'top=0,left=0,width='+(screen.availWidth)+',height ='+(screen.availHeight)+
    ',fullscreen=yes,toolbar=0 ,location=0,directories=0,status=0,menubar=0,resizable=0,scrolling=0,scrollbars=0')
  }
  // ***************
  // life cycles
  // ***************
  constructor(private master: MasterService, private ms: MessageService) { }

  ngOnInit() {
    this.getDataByType()
    // listener
    this.ms.channelComponents$.subscribe((res) => {
      if (res.message == "changeLanguage") {
        this.language = res.value
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if(changes.saleId){
      this.readSales(changes.saleId.currentValue)
    }
  }

}
