import { Component, OnInit, Input } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import * as languageLibrary from '../../../services/language'
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
// services
import { MessageService } from "../../../services/message.service";
import { MasterService } from "../../../services/master.service";
import { StoreService } from '../../../services/store.service'
import * as moment from "moment";


@Component({
  selector: "sales",
  templateUrl: "./sales.component.html",
  styleUrls: ["./sales.component.scss"],
})
export class SalesComponent implements OnInit {
  /*
 * Variables
 */
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  // save all data by type
  public data: Object[] = []
  // save all data by type (complete)
  public dataComplete: Object[] = []
  // table headers
  public headers: Object[] = [
    { name: 'Vehicle Image', param: 'image', view: true, disabled: false },
    { name: 'Buyer', param: 'buyer', view: true, disabled: false },
    { name: 'Out of State', param: 'outOfState', view: true, disabled: false },
    { name: 'Sale Date', param: 'saleDate', view: true, disabled: false },
    { name: 'Stock #', param: 'stockNumber', view: true, disabled: false },
    { name: 'VIN #', param: 'vinNumber', view: true, disabled: false },
    { name: 'Phone Number', param: 'phoneNumber', view: true, disabled: false },
    { name: 'Email', param: 'email', view: true, disabled: false },
    { name: 'Address', param: 'address', view: true, disabled: false },
    { name: 'Exterior Color', param: 'exteriorColor', view: true, disabled: false },
    { name: 'Year', param: 'modelYear', view: true, disabled: false },
    { name: 'Make', param: 'make', view: true, disabled: false },
    { name: 'Model', param: 'model', view: true, disabled: false },
    { name: 'Mileage', param: 'miles', view: true, disabled: false },
    { name: 'Primary Lienholder', param: 'companyName', view: true, disabled: false },
    { name: 'Stage', param: 'saleStage', view: true, disabled: false },
    { name: 'Status', param: 'saleStatus', view: true, disabled: false },
    { name: 'Co-Buyer', param: 'cobuyerName', view: true, disabled: false },
    { name: 'Sale Financing', param: 'saleFinancingType', view: true, disabled: false },
    { name: 'Sale Price', param: 'sellingPrice', view: true, disabled: false },
    { name: 'Inspection Expires', param: 'inspectionExpires', view: true, disabled: false },
    { name: 'Has Active Floor Plan', param: 'active', view: true, disabled: false },
    { name: 'Inventory Source', param: 'inventorySource', view: true, disabled: false },
    { name: 'Salesperson', param: 'salesperson', view: true, disabled: false },
    { name: 'Account Owner', param: 'account', view: true, disabled: false },
    { name: 'Lot', param: 'lotId', view: true, disabled: false },
    { name: 'Title Condition', param: 'titleCondition', view: true, disabled: false }
  ]
  // define the rows ordered
  public order = { isAscend: true, type: '' }
  // save the pagination settings
  public pagination = { index: 0, rowsByPage: 10, pages: [{ numPage: 0, start: 0, end: 8 }] }
  // define if is loading
  public loading: boolean = false
  // permissions
  public permissions: Object[] = []
  // define lot selected
  public lotSelected: string = null
  // define the object preselected
  public preselectedObject: Object = {}
  //
  public groupParams = []
  //
  public preselected = {}
  //
  public enableBHPH: boolean = false
  // all cobuyers
  public cobuyers:any[]=[]
  /*
 * Functions
 */
  // build data
  public buildData = (data: Object[],parent:Object): Object[] => {
    let newData = []
    let buyer={}
    for (let index = 0; index < data.length; index++) {

      buyer=this.cobuyers.find(el=>el['saleId']==data[index]['saleId'])

      newData.push({
        saleId: data[index]['saleId'],
        image: data[index]['sale'].saleVehicles[0].inventory?data[index]['sale'].saleVehicles[0].inventory.vehicle.uploads.length > 0 ? data[index]['sale'].saleVehicles[0].inventory.vehicle.uploads[0]['fileURL']+'480.jpg' : '':'',
        buyer: data[index]['contact'].firstName?
        `${data[index]['contact'].firstName} ${data[index]['contact'].lastName}`:
        data[index]['contact'].companyName,
        outOfState: data[index]['sale']['outOfState'] == 0 ? 'No' : 'Yes',
        saleDate: moment(data[index]['sale']['saleDate']).format('MM-DD-yyyy'),
        stockNumber: data[index]['sale'].saleVehicles[0].inventory?data[index]['sale'].saleVehicles[0].inventory.stockNumber:'',
        vinNumber: data[index]['sale'].saleVehicles[0].inventory?data[index]['sale'].saleVehicles[0].inventory.vehicle.vinNumber:'',
        phoneNumber: data[index]['contact'].contactCommunications.length>0?data[index]['contact'].contactCommunications.find(el=>el['type']=='cellphone'):null,
        email: data[index]['contact'].contactCommunications.length>0?data[index]['contact'].contactCommunications.find(el=>el['type']=='email'):null,
        address: data[index]['address'],
        exteriorColor: data[index]['sale'].saleVehicles[0].inventory?data[index]['sale'].saleVehicles[0]['inventory'].vehicle.exteriorColor:'',
        modelYear: data[index]['sale'].saleVehicles[0].inventory?data[index]['sale'].saleVehicles[0]['inventory'].vehicle.modelYear:'',
        make: data[index]['sale'].saleVehicles[0].inventory?data[index]['sale'].saleVehicles[0]['inventory'].vehicle.make:'',
        model: data[index]['sale'].saleVehicles[0].inventory?data[index]['sale'].saleVehicles[0]['inventory'].vehicle.model:'',
        miles: data[index]['sale'].saleVehicles[0].inventory?data[index]['sale'].saleVehicles[0].mileage:'',
        companyName: data[index]['sale'].saleLienholders.length>0 && (data[index]['sale'].saleLienholders[0].contact || data[index]['sale'].saleLienholders[0].lot)
        ? data[index]['sale'].saleLienholders[0].contact ?
        data[index]['sale'].saleLienholders[0].contact.companyName:
        data[index]['sale'].saleLienholders[0].lot['name']
        :'',
        saleStage: data[index]['sale'].saleStage.name,
        saleStatus: data[index]['sale'].saleStatus.name,
        cobuyerName: buyer? buyer['contact']['companyName']?buyer['contact']['companyName']:`${buyer['contact']['firstName']} ${buyer['contact']['lastName']}`:'',
        saleFinancingType: data[index]['sale'].saleFinancingType.name,
        sellingPrice: data[index]['sale'].salePrice,
        inspectionExpires: data[index]['sale'].saleVehicles[0].inventory?data[index]['sale'].saleVehicles[0].inventory.inspectionExpires:'',
        active: data[index]['sale'].saleVehicles[0].floorPlans==false?'No':'Yes',
        inventorySource: data[index]['sale'].saleVehicles[0].inventory?(data[index]['sale'].saleVehicles[0].inventory.contact['companyName'] || ''):'',
        salesperson: '',
        account: '',
        lotId: parent['name'],
        titleCondition: data[index]['sale'].saleVehicles[0].inventory?data[index]['sale'].saleVehicles[0].inventory.titleCondition:'',
        data:data[index]
      })

      newData[newData.length-1]['email']=newData[newData.length-1]['email']? newData[newData.length-1]['email']['value']:''
      newData[newData.length-1]['phoneNumber']=newData[newData.length-1]['phoneNumber']? newData[newData.length-1]['phoneNumber']['value']:''
    }
    return newData
  }

  // ? get data
  // get data of selected lot
  public changeLot = (lot: string): void => {
    // this.loading=true

    this.lotSelected = lot
      this.master.sendPost('salesDetails', { lotId: lot }, res => {
        // this.loading=false
        if (res) {
          if (res.status == 200) {
            // * success

            this.cobuyers=res.data.sales[0].saleBuyers.filter(el => el['saleBuyerRoleId'] == 2)
            this.data = this.buildData(res.data.sales.length > 0 ? res.data.sales[0].saleBuyers.filter(el => el['saleBuyerRoleId'] == 1) : [],res.data.sales[0])

            this.dataComplete = this.data.slice()
            if (this.data.length > 0) {
              this.newPagination(this.data)
            }
            // set headers
            res.data.salesTable ? this.headers = JSON.parse(res.data.salesTable) : this.headers
          } else {
            // ! in case error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })

  }
  //
  public preselect = (object) => {
    this.preselected = { ...object }
  }
  // ? custom table
  // togle the cells view
  public toggleCell = (index?: number): void => {
    if (index != undefined) {
      this.headers[index]['view'] = !this.headers[index]['view']
    }
    let config = JSON.stringify(this.headers)
    this.master.sendPost('updateUser', { salesTable: config }, res => {
      if (res) {
        if (res.status == 200) {
          // * success
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // move the position of columns in the table
  public drop(event: CdkDragDrop<string[]>) {

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    this.toggleCell()
    // this.changePosition(event.currentIndex, event.previousIndex)
  }
  // ? pagitation
  // return true if two words are equal, else return false
  public compareLower = (word1: string, word2: string): boolean => {
    return word1 != null && word2 != null ? word1.toLowerCase().indexOf(word2.toLowerCase()) >= 0 : false
  }
  // filter by word
  public search = (e): void => {
    if (e.key == 'Enter') {
      let word = ((document.getElementById('search') as HTMLInputElement).value).toLowerCase();

      let convert2String = (word: string): string => {
        return word + ''
      }
      let dataFiltered: any[] = []
      for (let index = 0; index < this.headers.length; index++) {
        dataFiltered = dataFiltered.concat(this.dataComplete.filter(el => convert2String(el[this.headers[index]['param']]).toLowerCase().indexOf(word) > -1))
      }
      this.data = dataFiltered.filter((v, i, a) => a.indexOf(v) == i)


      if (this.data.length >= 1) {
        this.pagination.rowsByPage = this.data.length
        // exist rows
        this.newPagination(this.data)
      } else {
        this.pagination.rowsByPage = 10
        this.ms.sendMessage("alert", { type: "info", text: this.words[this.language]['noFound'] });
      }
    }
  }

  // sort the users registers
  public sort = (type: string): void => {

    if (type == this.order.type) {
      this.order.isAscend = !this.order.isAscend
    } else {
      this.order.type = type
      this.order.isAscend = true
    }
    if (this.order.isAscend) {
      // ascend
      this.data = this.data.sort((a, b) => a[type] > b[type] ? 1 : -1)
    } else {
      // descend
      this.data = this.data.sort((a, b) => a[type] > b[type] ? -1 : 1)
    }
  }
  // change num page view
  public changePage = (page: number): void => {
    if (page == -1) {
      // back
      if (this.pagination.index >= 1) {
        this.pagination.index = this.pagination.index - 1
      }
    } else {
      // next
      if (this.pagination.index < this.pagination.pages.length - 1) {
        this.pagination.index = this.pagination.index + 1
      }
    }
  }
  // create a pagination
  public newPagination = (registers: Object[]): void => {
    this.pagination.pages = []
    this.pagination.index = 0
    this.pagination.pages['start'] = 0
    for (let index = 0; index < (registers.length / this.pagination.rowsByPage); index++) {
      this.pagination.pages.push({ numPage: index, start: this.pagination.rowsByPage * index, end: (this.pagination.rowsByPage * (index + 1)) })
    }
  }
  // change the rows per page
  public changeRows = (): void => {
    this.pagination.index = 0

    this.newPagination(this.data)
  }
  // ? create data
  //  create a new vehicle
  public addVehicle = (lot: string, type: number): void => {

    if (lot != null) {
      this.loading = true

      this.master.sendPost('createSale', { lotId: lot, saleFinancingTypeId: type }, res => {

        this.loading = false
        if (res) {
          if (res.status == 200) {

            // * success
            this.router.navigate(['/sales/' + this.lotSelected + "/" + res.data.id]);
          } else {
            // ! in case error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })
    }
  }
  // go to details
  public goToDetail = (id: string): void => {
    this.router.navigate(['/sales/' + this.lotSelected + "/" + id]);
  }
  // ? delete
  //  select a vehicle
  public selectSale = (sale: Object): void => {
    this.preselectedObject = { ...sale }
  }
  // delete the contact selected
  public delete = (): void => {

    let tradeIn = this.preselectedObject['data']['sale'].saleVehicles.filter(el => el['saleVehicleTransactionTypeId'] == 2)

    if (tradeIn.length > 0) {
      this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['The selected sale cannot be deleted because it has an associated trade-in'] });
      // close delete modal
      (document.getElementById('btn-close-modal-delete') as HTMLButtonElement).click();
    } else {

      if (this.preselectedObject['data']['sale'].saleVehicles.length > 0) {
        for (let index = 0; index < this.dataComplete.length; index++) {

          if (this.dataComplete[index]['data']['sale'].saleVehicles.some(el => el['inventory'] && el.inventory['originId'] && el.inventory['originId'] == this.preselectedObject['data']['sale'].saleVehicles[0].id)) {
            this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['The selected sale cannot be deleted because it has an associated trade-in'] });
            // close delete modal
            (document.getElementById('btn-close-modal-delete') as HTMLButtonElement).click();
            return
          }
        }
      }

      this.loading = true;
      this.master.sendPost('deleteSale', { saleId: this.preselectedObject['saleId'] }, res => {
        this.loading = false

        if (res) {
          if (res.status == 200) {
            // * success
            // reload data
            this.changeLot(this.lotSelected)
            this.ms.sendMessage("alert", { type: "success", text: res.data.message });
            // close delete modal
            (document.getElementById('btn-close-modal-delete') as HTMLButtonElement).click();
          } else {
            // ! error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })
    }
  }
  // seach by columns
  public searchColumn = (e,param): void => {


      let word = ((document.getElementById('input_'+param) as HTMLInputElement).value).toLowerCase();

      let convert2String = (word: string): string => {
        return word + ''
      }

      this.data = this.dataComplete.filter(el => convert2String(el[param]).toLowerCase().indexOf(word) > -1)

      if (this.data.length >= 1) {
        this.pagination.rowsByPage = this.data.length
        // exist rows
        this.newPagination(this.data)
      } else {
        this.pagination.rowsByPage = 10
        this.ms.sendMessage("alert", { type: "info", text: this.words[this.language]['noFound'] });
      }

  }

  // todo: listen to output of navbar
public listenerNav=(e):void=>{

  switch (e.message) {
    case 'changeLot':

      this.data = []

      this.enableBHPH = this.store.userAccount['user'].lots.find(el => el['id'] == this.store.lotSelected)
      this.enableBHPH = this.enableBHPH['enableBHPH'] ? this.enableBHPH['enableBHPH'] : false
      this.changeLot(e.lot)
      break;
    case 'changeLanguage':
      this.language = e.value
      break;
    case 'setPermissions':
      this.permissions = e.permissions;
      break;

    default:
      break;
  }
}



  /*
 * Life Cycles
 */
  constructor(
    private route: ActivatedRoute,
    private ms: MessageService,
    private master: MasterService,
    private store: StoreService,
    private router: Router
  ) {
    this.changeLot(localStorage.getItem('lot') ? localStorage.getItem('lot') : this.store.lotSelected)

    if(this.store['userAccount'] && this.store.userAccount['user']){
      this.permissions = store.userAccount['permissions'];
      this.enableBHPH = this.store.userAccount['user'].lots.find(el => el['id'] == this.store.lotSelected)
      this.enableBHPH = this.enableBHPH['enableBHPH'] ? this.enableBHPH['enableBHPH'] : false

    }
  }

  ngOnInit() {

  }

}
