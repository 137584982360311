import { Injectable, } from '@angular/core';
import { CanActivate } from "@angular/router";
import 'rxjs/add/operator/toPromise';
import { Observable } from 'rxjs/Observable';
import Axios from 'axios'
import { MessageService } from '../services/message.service'
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})

export class MasterService implements CanActivate {
  private headers: object = { headers: { 'Content-Type': 'application/json' } }
  private host: string = "https://rockitdms.com/api/"
  public hostApi: string = environment.urlGeneral
  public token = ''
  private accessCode: string = '8437d5a3-91c9-4544-9680-fdafb9ef39dc'
  constructor(private ms: MessageService) {
    // set token if exist
    localStorage.getItem('token') ? this.token = localStorage.getItem('token') : null
    // http://20.0.0.127:8081
    // https://localhost:8443/api/
    // set host
    // window.location.hostname == 'localhost' ? this.host = 'https://localhost:8443/api/' : this.host = window.location.protocol + '//' + window.location.hostname + (!!window.location.port ? ':' + window.location.port : '') + '/api/'
    this.host = environment.url

  }
  // Funtions
  /*
  *  send a post to back-end
  @action: define the type of action to be carried out
  @params: defines the information to be sent
  */
  public sendPost = (action: string, params: object, callback): void => {
    // check if exist token in params
    params['token'] ? params['token'] = params['token'] : params['token'] = this.token

    Axios.post(`${this.host}${action}`, params, this.headers).then(res => {

      callback(res)
    }).catch(error => {
      if ((error.response.data.error.indexOf('token') >= 0 || error.response.data.error.indexOf('invalidToken')) >= 0) {
        localStorage.removeItem('token')
        localStorage.removeItem('lot')
        this.token = ''
        window.location.href = "#/login"
        window.location.reload()
      } else {
        callback(error.response)
      }
    })
  }
  /*
    *  send a post to back-end
    @action: define the type of action to be carried out
    @params: defines the information to be sent
    */
  public sendPostAsync = async (action: string, params: object) => {
    // check if exist token in params
    params['token'] ? params['token'] = params['token'] : params['token'] = this.token
    return await Axios.post(`${this.host}${action}`, params, this.headers)
  }

  /*
  *  send a get to back-end
  @action: define the type of action to be carried out
  @params: defines the information to be sent
  */
  public sendGet = (action: string, callback): void => {
    Axios.get(`${this.host}${action}`, this.headers).then(res => {
      callback(res)
    }).catch(error => {
      if (error.response && (error.response.data.error.indexOf('token') >= 0 || error.response.data.error.indexOf('invalidToken') >= 0)) {
        localStorage.removeItem('token')
        localStorage.removeItem('lot')
        this.token = ''
        window.location.href = "#/login"
        window.location.reload()
      } else if (error.response && error.response.data.error.indexOf('The user has been deactivate') >= 0) {
        this.ms.sendMessage("alert", { type: "danger", text: 'The user has been deactivated.' });
        localStorage.removeItem('token')
        localStorage.removeItem('lot')
        this.token = ''
        window.location.href = "#/login"
        window.location.reload()
      } else {
        callback(error.response)
      }
    })
  }

  /*
    *  send a get to back-end
    @action: define the type of action to be carried out
    */
  public getCarleton = async (params: any) => {
    const config = {
      headers: {
        "Content-Type": "Application/json",
        ApiKey: 'd522722c-e03b-439d-8f1c-208ea2c270df',
      },
    };

    const res = await Axios.post(`https://carcalcswsqa.carletoninc.com/${environment.carletonVersion}/rest/OriginationPlusQuotes`, params, config).catch(error => {
      return error.response.data
    })
    // https://carcalcswsqa.carletoninc.com/{versionNumber}/rest/{ResourceName}
    return res
  }
  /*
    *  send a get to back-end
    @action: define the type of action to be carried out
    */
  public getCarletonAmortization = async (params: any) => {
    const config = {
      headers: {
        "Content-Type": "Application/json",
        ApiKey: 'd522722c-e03b-439d-8f1c-208ea2c270df',
      },
    };

    const res = await Axios.post(`https://paymentallocationwsqa.carletoninc.com/${environment.carletonAmortizationVersion}/rest/PaymentAllocations`, params, config).catch(error => {
      return error.response.data
    })
    // https://carcalcswsqa.carletoninc.com/{versionNumber}/rest/{ResourceName}
    return res
  }
  /*
    *  send a get to back-end
    @action: define the type of action to be carried out
    @params: defines the vin Number
    */
    public getXMLCarleton = async (params: any) => {
      const config = {
        headers: {
          "Content-Type": "Application/json",
          ApiKey: 'd522722c-e03b-439d-8f1c-208ea2c270df',
          "Accept": 'application/xml'
        },
      };

      const res = await Axios.post(`https://carcalcswsqa.carletoninc.com/${environment.carletonVersion}/rest/OriginationPlusQuotes`, params, config).catch(error => {
        return error.response.data
      })
      return res
    }
  /*
  *  send a get to back-end
  @action: define the type of action to be carried out
  @params: defines the vin Number
  */
  public getVinQuery = (format: string, reportType: string, vinNumber: string, callback) => {
    Axios.get(`https://www.recognition.ws/vindecode/v2?accesscode=${this.accessCode}&format=${format}&reportType=${reportType}&vin=${vinNumber}`).then(res => {
      callback(res)
    }).catch(error => {
      callback(error.response)
    })
  }
  //
  public getDataFromURL = (url:string,fileName:string,callback) => {
    Axios({
      url: url,
      method: 'GET',
      responseType: 'blob'
    }).then(res => {
      const url = window.URL
        .createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link)
      callback({status:true})
    }).catch(error=>{
      callback({status:false,error:error.response})
    })

  }

  /*
  *  Check if the session is valid to access dashboard.
  */
  canActivate(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.sendPost('mainDashboard', {}, message => {
        if (message != undefined && message.status == 400) {
          window.location.href = '#/login'
          window.location.reload()
        } else {
          observer.next(true);
        }
        observer.complete();
      });
    });
  }




}
