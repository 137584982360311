import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from "../../../services/message.service";
import { MasterService } from "../../../services/master.service";
import { StoreService } from "../../../services/store.service";
import * as words from '../../../services/language'
// import { ExportAsService, ExportAsConfig } from "ngx-export-as";
import * as moment from "moment/moment";
@Component({
  selector: "app-contacts",
  templateUrl: "./contacts.component.html",
  styleUrls: ["./contacts.component.scss"],
})
export class ContactsComponent implements OnInit {
  /*
   * Variables
   */
  //
  public nulls:Object[]=[]
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = words.language
  // define the contact type
  public type: string = "";
  // define the contact type (singular)
  public typePlural: Object = {
    leads: "leads",
    employees: "employees",
    buyers: "buyers",
    vendors: "vendors",
    finance: "financecompanies",
    lenders: "Floor Plans",
    insurance: "companies",
    lienholders: "lienholders",
  };
  // define the contact type (title)
  public typeTitle: Object = {
    leads: "Leads",
    employees: "Employees",
    buyers: "Buyers",
    vendors: "vendors",
    finance: "Companies",
    lenders: "Floor Plans",
    insurance: "Insurance",
    lienholders: "lienholders",
  };
  // save all data by type
  public data: Object[] = [];
  // save all data by type (complete)
  public dataComplete: Object[] = [];
  // save the users has been selecteds
  public usersSelecteds: Object[] = [];
  // define the contact Selected
  public contactSelected: Object = {};
  // table headers
  public headers: Object[] = [
    { name: "First Name", param: "firstName" },
    { name: "Last Name", param: "lastName" },
    { name: "Business Name", param: "company" },
    { name: "Email Address", param: "email" },
    { name: "Date Created", param: "createdAt" },
    { name: "Date Modified", param: "validateAt" },
  ];
  // define the rows ordered
  public order = { isAscend: true, type: "" };
  // save the pagination settings
  public pagination = {
    index: 0,
    rowsByPage: 10,
    pages: [{ numPage: 0, start: 0, end: 8 }],
  };
  // define if is loading
  public loading: boolean = false;
  // permissions
  public permissions: Object[] = [];
  // count of records
  public countOfRecords: number;
  //
  // exportAsConfig: ExportAsConfig = {
  //   type: "xlsx", // the type you want to download
  //   elementIdOrContent: "contactTable", // the id of html/table element
  // };
  /*
   * functions
   */
  public createContact() {
    if(this.nulls.length>0){

      this.router.navigate([
        "/contacts/" + this.type + "/" + this.nulls[0]['id'],
      ]);
    }else{

      this.loading = true;
      this.master.sendPost("createContactType", { type: this.type }, (res) => {
        this.loading = false;
        if (res) {
          if (res.status == 200) {
            // * success
            this.router.navigate([
              "/contacts/" + this.type + "/" + res.data.contact.id,
            ]);
          } else {
            // ! send message in case error
            this.ms.sendMessage("alert", {
              type: "danger",
              text: res.data.error,
            });
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse '] });

        }
      });
    }
  }

  public exportToExcel = (): void => {
    let m = moment().format("YY-MM-DD h_m_sA");
    let docName = this.type.toUpperCase()
    // this.exportAsService
    //   .save(this.exportAsConfig, docName + "_" + m)
    //   .subscribe(() => { });
  };

  // select a contact
  public selectContact = (contact: Object): void => {
    this.contactSelected = contact;
    this.loading = true;
    this.master.sendPost(
      "contactDelete",
      { contactId: this.contactSelected["id"], query: true },
      (res) => {
        this.loading = false;
        if (res) {
          if (res.status == 200) {
            // ! contact normal
            (document.getElementById(
              "btn-delete-modal"
            ) as HTMLButtonElement).click();
          } else {
            // * contact important
            (document.getElementById(
              "btn-deactivate-modal"
            ) as HTMLButtonElement).click();
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      }
    );
  };

  // delete the contact selected
  public deleteContact = (isDeleteModal): void => {
    this.loading = true;
    this.master.sendPost("contactDelete", { contactId: this.contactSelected["id"], query: false }, (res) => {
      this.loading = false;
      if (res) {
        if (res.status == 200) {
          // * success
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          if (isDeleteModal) {
            // close delete modal
            (document.getElementById("btn-close-modal-delete") as HTMLButtonElement).click();
            // todo: splice
            this.getDataByType(this.type);
          } else {
            // close deactivate modal
            (document.getElementById("btn-close-modal-deactivate") as HTMLButtonElement).click();
            // todo: splice
            this.getDataByType(this.type);
          }
        } else {
          // ! error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    }
    );
  };
  /*
 todo: pagination and rows per view functions
 */
  // sort the users registers
  public sort = (type: string): void => {
    if (type == this.order.type) {
      this.order.isAscend = !this.order.isAscend;
    } else {
      this.order.type = type;
      this.order.isAscend = true;
    }
    if (this.order.isAscend) {
      // ascend
      this.data = this.data.sort((a, b) => (a[type] > b[type] ? 1 : -1));
    } else {
      // descend
      this.data = this.data.sort((a, b) => (a[type] > b[type] ? -1 : 1));
    }
  };
  // change num page view
  public changePage = (page: number): void => {
    if (page == -1) {
      // back
      if (this.pagination.index >= 1) {
        this.pagination.index = this.pagination.index - 1;
      }
    } else {
      // next
      if (this.pagination.index < this.pagination.pages.length - 1) {
        this.pagination.index = this.pagination.index + 1;
      }
    }
  };
  // create a pagination
  public newPagination = (registers: Object[]): void => {
    this.pagination.pages = [];
    this.pagination.index = 0;
    // let registrosPerPage=2
    for (
      let index = 0;
      index < registers.length / this.pagination.rowsByPage;
      index++
    ) {
      this.pagination.pages.push({
        numPage: index,
        start: this.pagination.rowsByPage * index,
        end: this.pagination.rowsByPage * (index + 1),
      });
    }
  };
  // change the rows per page
  public changeRows = (): void => {
    this.pagination.index = 0;
    this.newPagination(this.data);
  };
  // filter by word
  public search = (e): void => {
    // if (e.key == 'Enter') {
    let word = (document.getElementById("search") as HTMLInputElement).value;
    this.data = this.dataComplete.filter(
      (el) =>
        el["firstName"].includes(word) ||
        el["email"].includes(word) ||
        el["lastName"].includes(word) ||
        el["id"].toString().includes(word) ||
        el["companyName"].includes(word)
    );
    if (this.data.length >= 1) {
      this.pagination.rowsByPage = this.data.length;
      // exist rows
      this.newPagination(this.data);
    } else {
      this.pagination.rowsByPage = 10;
      this.ms.sendMessage("alert", { type: "info", text: this.words[this.language]['noData'] });
    }
    // }
  };
  // todo: data contact type
  // get data from contact type
  public getDataByType = (type): void => {
    setTimeout(() => {
      this.master.sendPost("getContactsByTypeCorp?type=" + type, {}, (res) => {
        this.data = [];
        if (res) {
          if (res.status == 200) {
            this.data = res.data.contacts.filter(el=>el['firstName'] || el['companyName']);
            this.dataComplete = res.data.contacts.filter(el=>el['firstName'] || el['companyName']);
            this.nulls=res.data.contacts.filter(el=>!el['firstName'] && !el['companyName'])

            this.countOfRecords = this.data.length;
            if (this.data.length > 0) {
              this.pagination.rowsByPage = 10;
              this.newPagination(this.data);
            }
          } else {
            // ! send message in case error
            this.ms.sendMessage("alert", {
              type: "danger",
              text: res.data.error,
            });
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      });
    }, 1000);
  };


  // todo: listen to output of navbar
  public listenerNav = (e): void => {

    switch (e['message']) {
      case 'changeLanguage':
        this.language = e['value']
        break;
      case 'setPermissions':
        // permissions on user list

        this.permissions = e['permissions'][1].childrens[0].permissions;
        if (this.permissions.includes('view')) {
          // set data
          this.getDataByType(this.type)
        }
        break;
      case 'changeType':
        if (this.type != e.newType) {
          this.type = e.newType
          // set data
          this.getDataByType(this.type)
        }
        break
      default:
        break;
    }
  }
  // ************
  // life cycles
  // *************
  constructor(private route: ActivatedRoute, private router: Router, private ms: MessageService, private master: MasterService, private store: StoreService,
    // private exportAsService: ExportAsService
  ) {

    //
    this.type = this.route.snapshot.params["type"];
    // normal

    if (store.userAccount["permissions"]) {
      // permissions on user list
      this.permissions =
        store.userAccount["permissions"][1].childrens[0].permissions;
      // if not have permissions, return to account settings
      if (this.permissions.includes("view")) {
        // set data
        this.getDataByType(this.type);
      }
    }

  }

  ngOnInit() {

  }




}
