import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
// services
import { MessageService } from '../../../../services/message.service'
import { MasterService } from '../../../../services/master.service'
import { StoreService } from '../../../../services/store.service'
import * as languageLibrary from '../../../../services/language'
@Component({
  selector: "inventory-financials",
  templateUrl: "./inventory-financials.component.html",
  styleUrls: ["./inventory-financials.component.scss"],
})
export class InventoryFinancialsComponent implements OnInit {
  /*
    * Variables
    */
  //
  @Output() emitterInformation$: EventEmitter<Object> = new EventEmitter()
  //  define if some changes not saved
  public savePending: boolean = false
  // save the language
  @Input() language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  // contact object
  @Input() data: Object = {
    totalCosts: "",
    purchaseCosts: "",
    totalReconitioning: "",
    totalFloor: "",
    sellingPrice: "",
    downPayment: "",
    bookValue: "",
  };
  @Output() allData$: EventEmitter<Object> = new EventEmitter();
  // permissions
  @Input() data2: Object = {}
  // permissions
  @Input() permissions: string[] = [];
  // define the inventory id
  @Input() inventoryId: string = "";
  // define expanded
  public expanded: boolean = true;
  // Group of inputs
  public information = new FormGroup({
    purchaseCosts: new FormControl(0, []),
    totalReconitioning: new FormControl(0, []),
    totalFloor: new FormControl(0, []),
    totalCosts: new FormControl(0, []),
    sellingPrice: new FormControl(0, []),
    downPayment: new FormControl(0, []),
    bookValue: new FormControl(0, []),
  });
  // get information of inputs
  get purchaseCosts() {
    return this.information.get("purchaseCosts");
  }
  get totalReconitioning() {
    return this.information.get("totalReconitioning");
  }
  get totalFloor() {
    return this.information.get("totalFloor");
  }
  get totalCosts() {
    return this.information.get("totalCosts");
  }
  get sellingPrice() {
    return this.information.get("sellingPrice");
  }
  get downPayment() {
    return this.information.get("downPayment");
  }
  get bookValue() {
    return this.information.get("bookValue");
  }

  /*
   * Functions
   */
  //  save all data
  public save = (): void => {
    if (this.information.valid) {
      this.master.sendPost('updateInventory', { ...this.information.value, inventoryId: this.inventoryId, forUpdate: 'financials', lotId: localStorage.getItem('lot') ? localStorage.getItem('lot') : this.store.lotSelected }, res => {
        if (res) {
          if (res.status == 200) {
            // * success
            this.allData$.emit({ value: this.information.value })
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['successFinance'] });
            this.emitterInformation$.emit({ message: 'reloadInventoryDetails' })
            this.emitterInformation$.emit({ message: 'updateFinancials', purchaseCosts: this.information.value['purchaseCosts'] })
            this.savePending = false
            this.emitterInformation$.emit({ message: 'changesPendingInventory', section: 'financials', value: false })
          } else {
            // ! in case error
            // in case API no response
            this.ms.sendMessage("alert", {
              type: "danger",
              text: res.data.error,
            })
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })
    }
  }
  public calcTotalCost = () => {

    let purchaseCost = this.information.value['purchaseCosts'] ? parseFloat(this.information.value['purchaseCosts']) : 0
    let totalFloorPlan = this.information.value['totalFloor'] ? parseFloat(this.information.value['totalFloor']) : 0
    let totalReaconditionCost = this.information.value['totalReconitioning'] ? parseFloat(this.information.value['totalReconitioning']) : 0
    let total = purchaseCost + totalFloorPlan + totalReaconditionCost
    this.information.get('totalCosts').setValue(total.toFixed(2))
    // this.changesPending()
  }
  // get total of types
  public getTotalReconditioningCost = (): number => {
    let data = [];
    data = this.data2["reconditioningCosts"].map((el) => +el["cost"]);

    if (data.length > 0) {
      return data.reduce((total, value) => total + value, 0);
    }
  };
  // todo: detect changes
  changesPending = () => {

    if (!this.savePending) {
      this.savePending = true

      this.emitterInformation$.emit({ message: 'changesPendingInventory', section: 'financials', value: this.savePending })
    }
  }

  /*
 * life cycles
 */
  constructor(private route: ActivatedRoute, private ms: MessageService, private master: MasterService, private store: StoreService) {
    this.ms.channelComponents$.subscribe(res => {
      switch (res.message) {
        case 'changeFloorPlans':

          this.information.get('totalFloor').setValue(res.balance.toFixed(2))
          this.calcTotalCost()
          break;
        case 'changeReaconditioningCost':

          this.information.get('totalReconitioning').setValue(res.total.toFixed(2))
          this.calcTotalCost()
          break;
        default:
          break;
      }
    });
  }

  ngOnInit() {

    // set id
    // this.inventoryId = this.route.snapshot.params["id"];
    // set data from end-point
    this.information.setValue({ ...this.data })



  }

}
