import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {  ActivatedRoute } from '@angular/router';
import { MasterService } from '../../../services/master.service';
import { MessageService } from '../../../services/message.service';
import { StoreService } from '../../../services/store.service'

@Component({
  selector: 'app-invitations-activation',
  templateUrl: './invitations-activation.component.html',
  styleUrls: ['./invitations-activation.component.scss']
})
export class InvitationsActivationComponent implements OnInit {
  /*
  todo: Variables
  */
  //  define the loading status
  public loading: boolean = false
  //  user token
  public userToken = ''
  // define if password input is type text or password
  public typePassword: string = 'password'
  // 
  public needPassword: boolean = true

  public activationGroup = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(50), Validators.pattern(/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&?¿¡*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&?¿¡*()_+]{7,39}$/)]),
  })
  /*
  todo: Functions
  */
  // functions
  // change the input type, passsword or text
  public changeTypePassword(): void {
    if (this.typePassword == 'text') {
      this.typePassword = 'password'
    } else {
      this.typePassword = 'text'
    }
  }
  public login = () => {

    this.master.sendPost('authenticateRecovery', { "password": this.activationGroup.value['password'], "token": this.userToken }, res => {
      this.loading=true
      if (res) {
        if (res.status == 200) {
          localStorage.removeItem('token')
          this.master.token = ''
          this.store.userAccount = {}
          this.ms.sendMessage("alert", { type: "success", text: 'Your account has been activated' });
          this.loading=false
          window.location.href = '#/login'
        } else {
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          this.loading=false
        }
      } else {
        this.ms.sendMessage("alert", { type: "danger", text: "API no Response" });
        this.loading=false
      }
    })
  }
  // get information of inputs
  get password() { return this.activationGroup.get('password') }
  /*
  todo: Life Cycles
  */
  constructor(private route: ActivatedRoute, private master: MasterService, private ms: MessageService, private store: StoreService) {
    this.userToken = this.route.snapshot.queryParams['token'];

    if (this.userToken) {
      this.master.sendPost('tokenMailVerification', { type: 'guest', token: this.userToken }, res => {
        if (res) {
          // check if API conecction success
          if (res.status == 200) {
            this.userToken = res.data.token
            this.needPassword = !res.data.password
          } else {
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          this.ms.sendMessage("alert", { type: "danger", text: "API no response" });
        }
      })
    } else {
      this.ms.sendMessage("alert", { type: "danger", text: "token not found" });
    }

  }

  ngOnInit() {
  }

}
