import { Component, OnInit,Output,EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MasterService } from '../../../../services/master.service';
import { MessageService } from '../../../../services/message.service';
import {StoreService} from '../../../../services/store.service'
//externals

@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})

export class LoginFormComponent implements OnInit {


  // Variables
  // define the subdomain
  public subDomain = false
  // define if password input is type text or password
  public typePassword: string = 'password'
  // 
  public submitted: boolean = false;
  // display de loading state
  public loading: boolean = false
  // define if the corporate is valid
  public isValidCorporateURL: boolean = true;
  // 
  private timeOutID: any;
  // 
  @Output() image$: EventEmitter<Object> = new EventEmitter();

  public signInGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(150)]),
    password: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]),
    corporateURL: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(50), Validators.pattern('[A-Za-z0-9_-]{2,150}')])
  })
  // get information of inputs
  get email() { return this.signInGroup.get('email') }
  get password() { return this.signInGroup.get('password') }
  get corporateURL() { return this.signInGroup.get('corporateURL') }



  // functions
  // change the input type, passsword or text
  public changeTypePassword(): void {
    if (this.typePassword == 'text') {
      this.typePassword = 'password'
    } else {
      this.typePassword = 'text'
    }
  }
// verify if the corporate is already exist
  public corporateURLExists(): void {
    if (this.corporateURL.value.length < 3)
      return;

    let params = { corporateURL: this.corporateURL.value }
    clearTimeout(this.timeOutID);
    this.timeOutID = setTimeout(() => {
      this.master.sendPost('attributesExists', params, res => {
        if (res) {
          // check if API conecction success
          if (res.status == 200) {
            this.isValidCorporateURL = true;
          } else {
            this.isValidCorporateURL = false;
          }
        } else {
          this.ms.sendMessage("alert", { type: "danger", text: "API no response" });
        }
      })
    }, 750);

    /**/
  }
  // sign in method
  public signIn(): void {
    if (this.signInGroup.invalid || !this.isValidCorporateURL) {
      this.submitted = true;
      return;
    }

    let params = this.signInGroup.getRawValue();
    params['corporateURL'] = params['corporateURL'] + '.rockitdms.com'
    this.loading = true

    this.master.sendPost('authenticate', params, res => {
      // check if API conecction success
      this.loading = false;
      if (res) {
        // In case success
        if (res.status == 200) {
          localStorage.setItem('token', res.data.responseData.token)
          this.master.token = res.data.responseData.token
          this.store.userAccount={}
          localStorage.removeItem('lot')
          // window.location.href = '#/profile'
          this.router.navigate(['/dashboard']);
        } else {
          if(res.data.error.includes('deactivated') ){
            // in deactivated user
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }else{
            // in invalid credenctials
            this.ms.sendMessage("alert", { type: "warning", text: 'Email address and password combination is not found.' });
          }
        }
      } else {
        this.ms.sendMessage("alert", { type: "danger", text: "API no response" });
        
      }
    })
  }
  // get Image
  public getImage=(url:string)=>{
    this.master.sendPost('getCorporateLogo',{token:'',corpUrl:url},res=>{
      if(res){
        if(res.status==200){
          this.image$.emit({img:res.data.logo.corporateLogoURL,display:res.data.logo.displayLogoInternal==0?false:true})
          
        }else{
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      }else{
        this.ms.sendMessage("alert", { type: "danger", text: "API no response" });
      }
    })
  }

  // init
  ngOnInit() {
  }
  constructor(
    private master: MasterService,
    private ms: MessageService,
    private router: Router,
    private store:StoreService
  ) {
    let domain 
    window.location.hostname == 'localhost' ? domain = 'rockitdms.com':domain = window.location.hostname
    // let domain = 'rockdms.com'
    if ( domain != 'rockitdms.com' && domain!='rockdms.com' ) {
      let index=domain.indexOf('.')
      this.getImage(domain.substr(0,index)+'.rockitdms.com')
      // get subdomain
      this.subDomain = true
      this.signInGroup.setValue({ email: '', password: '', corporateURL: domain.substr(0, domain.indexOf('.')) })
      
    }
  }

}
