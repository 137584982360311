import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
// services
import { MasterService } from "../../services/master.service";
import { MessageService } from "../../services/message.service";
import { StoreService } from "../../services/store.service";
import * as languageLibrary from '../../services/language'
@Component({
  selector: "app-cpi",
  templateUrl: "./cpi.component.html",
  styleUrls: ["./cpi.component.scss"],
})
export class CPIComponent implements OnInit {
  /*
* Variables
*/
// permissions
public permissions: Object[] = []
  public filters = { saleStatus: '', policyStatus: '', search: '' }
  //
  public saleId: number = 0
  // save all data by type
  public data: Object[] = []
  // save all data by type
  public dataComplete: Object[] = []
  // define all lots
  public lots = []
  // define the lot selected
  public lotSelected = ''
  // define if is loading
  public loading: boolean = false
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  // set expandedDetails
  public expandedDetails: boolean = false
  //
  public order = { isAscend: true, type: '' }
  // save the pagination settings
  public pagination = { index: 0, rowsByPage: 10, pages: [{ numPage: 0, start: 0, end: 8 }] }

  /*
  * functions
  */
  // create a pagination
  public newPagination = (registers: Object[]): void => {
    this.pagination.pages = []
    this.pagination.index = 0
    this.pagination.pages['start'] = 0
    for (let index = 0; index < (registers.length / this.pagination.rowsByPage); index++) {
      this.pagination.pages.push({ numPage: index, start: this.pagination.rowsByPage * index, end: (this.pagination.rowsByPage * (index + 1)) })
    }
  }
  // change the rows per page
  public changeRows = (): void => {
    this.pagination.index = 0
    this.newPagination(this.data)
  }
  // todo: select Sale
  public selectSale = (saleId): void => {
    this.saleId = saleId
  }
  // search
  public filterData=(e,select)=>{
    if(select){
      // let policyStatus=this.filters.policyStatus=='true'?true:this.filters.policyStatus==''?'':false
      this.data=this.dataComplete.filter(el=>(el['policyStatus']+'').indexOf(this.filters.policyStatus)>=0
      && el['sale'].saleStatus.name.indexOf(this.filters.saleStatus)>=0)
    }

    if(e.key=='Enter'){
      this.filters.saleStatus=''
      this.filters.policyStatus=''

      let filtersName=['lastName','firstName','stockNumber','saleId']
      let filtered=[]
      for (let index = 0; index < filtersName.length; index++) {
        filtered=this.dataComplete.length==filtered.length?
        filtered:filtered.concat(this.findPath(this.filters.search,filtersName[index]))
      }
      this.data=filtered
    }

  }
  //
  public findPath=(word:string,field:string)=>{
    word=word.toLowerCase()
    switch (field) {
      case 'lastName':
        return this.dataComplete.filter(el=>(el['sale'].saleBuyers[0]
        .contact.lastName.toLowerCase()).indexOf(word)>=0)
        case 'firstName':
        return this.dataComplete.filter(el=>(el['sale'].saleBuyers[0]
        .contact.firstName.toLowerCase()).indexOf(word)>=0)
        case 'stockNumber':
        return this.dataComplete.filter(el=>(el['sale'].saleVehicles[0]
        .inventory.stockNumber.toLowerCase()).indexOf(word)>=0)
        case 'saleId':
        return this.dataComplete.filter(el=>(el['saleId']+''.toLowerCase()).indexOf(word)>=0)
    }
  }
  //
  /*
    todo: getters
    */
  public getData = (): void => {
    this.master.sendPost("cpiDispatcher", { activity: 'getCorporateCpi' }, (res) => {
      if (res) {
        if (res.status == 200) {
          this.data = res.data.corporateCpi
          this.dataComplete=res.data.corporateCpi
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    });
  };
  // listen modal
  public listenModal=(e)=>{
    if(e.message=='reloadTable'){
      this.getData()
    }
  }
      // todo: listen to output of navbar
public listenerNav=(e):void=>{

  switch (e['message']) {
    case 'changeLanguage':
      this.language=e['value']
      break;
    case 'setPermissions':
      // permissions on user list
      this.permissions = e.permissions

    break;

    default:
      break;
  }
}
 /*
* life cycles
*/
  constructor(
    private master: MasterService,
    private ms: MessageService,
    private store: StoreService,
    private router: Router
  ) {
    // normal
    this.getData()
  }

  ngOnInit() {
    // listener
    this.ms.channelComponents$.subscribe((res) => {
      if (res.message == "changeLanguage") {
        this.language = res.value
      }
    });
  }
}
