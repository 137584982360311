import { Component, OnInit, Input, Output,EventEmitter, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
// services
import { MessageService } from '../../../../services/message.service'
import { MasterService } from '../../../../services/master.service'
import * as languageLibrary from '../../../../services/language'
// extarnal
import * as moment from 'moment/moment'
@Component({
  selector: 'inventory-reconditioning-cost',
  templateUrl: './inventory-reconditioning-cost.component.html',
  styleUrls: ['./inventory-reconditioning-cost.component.scss']
})
export class InventoryReconditioningCostComponent implements OnInit {
  /*
    * Variables
    */
  @Output() reaconditionCost$: EventEmitter<Object> = new EventEmitter()
  //  define if some changes not saved
  public savePending: boolean = false
  // save the language
  @Input() language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  // contact object
  @Input() data: Object[] = []
  // permissions
  @Input() permissions: string[] = ['add']
  // define expanded
  public expanded: boolean = true
  // table headers
  public headers: Object[] = [
    { name: 'type', param: 'type' },
    { name: 'vendor', param: 'vendor' },
    { name: 'phoneNumber', param: 'phoneNumber' },
    { name: 'cost', param: 'cost' },
    { name: 'date', param: 'date' },
    { name: 'description', param: 'description' },
    { name: 'datePaid', param: 'datePaid' },
    { name: 'Reference Number', param: 'referenceNumber' },
    { name: 'Reference Payment Type', param: 'referencePaymentType' },
    { name: 'Reference Payment Number', param: 'referencePaymentNumber' },
    { name: 'Check Number', param: 'checkNumber' },
    { name: 'createdAt', param: 'createdAt' },
    { name: 'updatedAt', param: 'updatedAt' }

  ]
  // methotds list
  public methodsList: string[] = [
    'Cash',
    'Check',
    // 'Draft',
    // 'Money Order',
    // 'Repo',
    // 'Trade-in',
    'Credit/Debit Card',
    'Other'
  ]
  // define the types
  @Input() types = []
  // define the types
  @Input() vendors: Object[] = []
  // define the vehicleId
  @Input() vehicleId: string = ''
  // define the vendor id
  @Input() vendorId: string = ''
  // define the new type name
  public newTypeName: string = ''
  // define the name edited
  public nameEdit = {
    name: '',
    index: -1
  }
  // define the name edited
  public indexSelected: number = -1
  // define the rows ordered
  public order = { isAscend: true, type: '' }
  // save the pagination settings
  public pagination = { index: 0, rowsByPage: 10, pages: [{ numPage: 0, start: 0, end: 8 }] }
  // define if is loading
  public loading: boolean = false
  // define the totals
  public totals = {
    total: 0,
    labor: 0,
    parts: 0,
    others: 0
  }

  // form
  public information = new FormGroup({
    reconditioningTypeId: new FormControl('', [Validators.required]),
    vendor: new FormControl('', []),
    cost: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', []),
    date: new FormControl(new Date().toISOString().substring(0, 10), [Validators.required]),
    datePaid: new FormControl('', []),
    referenceNumber: new FormControl('', []),
    checkNumber: new FormControl('', []),
    referencePaymentType: new FormControl('', []),
    referencePaymentNumber: new FormControl('', []),
    description: new FormControl('', []),
  })
  public informationUpdate = new FormGroup({
    id: new FormControl('', []),
    vehicleId: new FormControl('', []),
    reconditioningTypeId: new FormControl('', [Validators.required]),
    vendor: new FormControl('', []),
    cost: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', []),
    date: new FormControl('', [Validators.required]),
    datePaid: new FormControl('', []),
    referenceNumber: new FormControl('', []),
    checkNumber: new FormControl('', []),
    referencePaymentType: new FormControl('', []),
    referencePaymentNumber: new FormControl('', []),
    description: new FormControl('', []),
    createdAt: new FormControl('', []),
    updatedAt: new FormControl('', []),
  })
  get reconditioningTypeId() { return this.information.get('reconditioningTypeId') }
  get cost() { return this.information.get('cost') }
  get phoneNumber() { return this.information.get('phoneNumber') }
  get date() { return this.information.get('date') }
  get datePaid() { return this.information.get('datePaid') }
  get referenceNumber() { return this.information.get('referenceNumber') }
  get checkNumber() { return this.information.get('checkNumber') }
  get referencePaymentType() { return this.information.get('referencePaymentType') }
  get referencePaymentNumber() { return this.information.get('referencePaymentNumber') }
  get description() { return this.information.get('description') }
  get vendor() { return this.information.get('vendor') }
  /*
 * Functions
 */
  /*
 todo: pagination and rows per view functions
 */
  // sort the users registers
  public sort = (type: string): void => {
    if (type == this.order.type) {
      this.order.isAscend = !this.order.isAscend
    } else {
      this.order.type = type
      this.order.isAscend = true
    }
    if (this.order.isAscend) {
      // ascend
      this.data = this.data.sort((a, b) => a[type] > b[type] ? 1 : -1)
    } else {
      // descend
      this.data = this.data.sort((a, b) => a[type] > b[type] ? -1 : 1)
    }
  }
  // change num page view
  public changePage = (page: number): void => {
    if (page == -1) {
      // back
      if (this.pagination.index >= 1) {
        this.pagination.index = this.pagination.index - 1
      }
    } else {
      // next
      if (this.pagination.index < this.pagination.pages.length - 1) {
        this.pagination.index = this.pagination.index + 1
      }
    }
  }
  // create a pagination
  public newPagination = (registers: Object[]): void => {
    this.pagination.pages = []
    this.pagination.index = 0
    // let registrosPerPage=2
    if (registers.length > 0) {
      for (let index = 0; index < (registers.length / this.pagination.rowsByPage); index++) {
        this.pagination.pages.push({ numPage: index, start: this.pagination.rowsByPage * index, end: (this.pagination.rowsByPage * (index + 1)) })
      }
    }

  }
  // change the rows per page
  public changeRows = (): void => {
    this.pagination.index = 0
    this.newPagination(this.data)
  }
  /*
  ? add new type
  */
  public addNewType = (name: string) => {

    if (name) {

      let repeat = []
      this.types == null ? repeat = [] : repeat = this.types.filter(el => el['name'].toLowerCase() == name.toLowerCase())

      // check not repeat
      if (repeat.length == 0) {
        this.loading = true
        this.master.sendPost('crudReconditioningType', { name: name, type: "create" }, res => {
          this.loading = false
          if (res) {
            if (res.status == 200) {
              // * success
              this.types.push({ id: res.data.id, name: name })
              this.newTypeName = null
              this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
            } else {
              // ! in case error
              this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
            }
          } else {
            // in case API no response
            this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
          }
        })
      } else {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['Type already exist'] });
      }
    }
  }
  // select to edit object type
  public selectType = (type: Object, index: number) => {
    this.nameEdit.name = type['name']
    this.nameEdit.index = index
  }
  // desselect tyoe
  public cancelType = (): void => {
    this.nameEdit.name = ''
    this.nameEdit.index = -1
  }
  // clear fields
  public clearFileds = (type: string) => {
    this.indexSelected = -1
    type == 'add' ? this.information.reset() : this.informationUpdate.reset()
    this.savePending = false
    this.reaconditionCost$.emit({ message: 'changesPendingInventory', section: 'reaconditioningCost', value: this.savePending })
  }
  public resetTypes = (id: string): void => {
    this.loading = true
    this.master.sendPost('inventoryDetails', { vehicleId: id }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success

          this.data = res.data.reconditioningCosts
          this.newPagination(this.data)
          this.setTotals()
        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  /*
  todo: edit  type
  */
  public editType = (name: string, type: Object, index: number) => {
    this.loading = true

    if (name) {
      // check the name is the same
      if (name == type['name']) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['The name is the same'] });
        return
      }
      // check not repeat
      let repeat = this.types.filter(el => el['name'].toLowerCase() == name.toLowerCase())
      if (repeat.length > 0) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['The name already exist'] });
        return
      }
      this.master.sendPost('crudReconditioningType', { id: type['id'], name: name, type: "update" }, res => {
        this.loading = false
        if (res) {
          if (res.status == 200) {
            // * success
            this.types[index] = { id: type['id'], name: name }
            this.nameEdit.name = ''
            this.nameEdit.index = -1
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
            // reset data
            this.resetTypes(this.route.snapshot.params['id'])
            this.information.reset()
          } else {
            // ! in case error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })
    }

  }
  /*
  ! delete  type
  */
  public deleteType = (type: Object, index: number) => {
    this.loading = true
    this.master.sendPost('crudReconditioningType', { id: type['id'], type: "delete" }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.types.splice(index, 1)
          this.information.reset()
          this.resetTypes(this.route.snapshot.params['id'])
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })

  }
  /*
  ? save and update
  */
  //  set phone to vendor
  public setPhone = () => {
    let vendorList = document.getElementById("vendors") as HTMLSelectElement;
    this.vendorId = vendorList.options[vendorList.selectedIndex].value
    this.information.get('phoneNumber').setValue('')
    const contact = this.vendors.find(el => el['id'] == this.information.value['vendor'])
    const phone = contact['contactCommunications'].find(el=>el['type']=='cellphone')
    if(phone){
      this.information.get('phoneNumber').setValue(phone.value)
    }
  }
  // get vendor
  public getVendor = (id) => {
    let vendorName = this.vendors.filter(el => el['id'] == id)
    return vendorName.length > 0 ? vendorName[0]['companyName'] : ''
  }
  // save data
  public save = (type: string, form: Object): void => {

    if (this.information.valid || this.informationUpdate.valid) {
      this.loading = true
      let params = {
        checkNumber: form['checkNumber'] == "" ? null : form['checkNumber'],
        cost: form['cost'] == "" ? null : form['cost'],
        date: form['date'] == "" ? null : form['date'],
        datePaid: form['datePaid'] == "" ? null : form['datePaid'],
        description: form['description'] == "" ? null : form['description'],
        phoneNumber: form['phoneNumber'] == "" ? null : form['phoneNumber'],
        referenceNumber: form['referenceNumber'] == "" ? null : form['referenceNumber'],
        referencePaymentNumber: form['referencePaymentNumber'] == "" ? null : form['referencePaymentNumber'],
        referencePaymentType: form['referencePaymentType'] == "" ? null : form['referencePaymentType'],
        reconditioningTypeId: form['reconditioningTypeId'] == "" ? null : form['reconditioningTypeId'],
        vendor: form['vendor'] == "" ? null : form['vendor']
      }

      this.master.sendPost('crudReconditioningCosts', { ...params, activity: type, vehicleId: this.vehicleId, entityId: form['id'] }, res => {
        this.loading = false

        if (res) {
          if (res.status == 200) {
            // * success
            if (type == 'update') {
              this.savePending = false
              this.reaconditionCost$.emit({ message: 'changesPendingInventory', section: 'reaconditioningCost', value: false })
              this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
              let type = this.types.filter(el => el['id'] == this.informationUpdate.value['reconditioningTypeId'])
              this.data[this.indexSelected] = { ...form, reconditioningType: { name: type[0].name, id: type[0].id } }
              this.reaconditionCost$.emit({ message: 'reloadInventoryDetails' })
              // this.informationUpdate.reset();
              this.clearFileds('update')
            } else {
              // let type = this.types.filter(el => el['id'] == this.information.value['reconditioningTypeId'])
              // get vendor
              this.resetTypes(this.route.snapshot.params['id'])
              // this.data.push({ id: res.data.id, ...this.information.value, reconditioningType: { name: type[0].name, id: type[0].name }, createdAt: moment().format('YYYY-MM-DD'), updatedAt: moment().format('YYYY-MM-DD') })
              // this.information.reset({ date: new Date().toISOString().substring(0, 10) })
              this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
              this.clearFileds('add')

            }
            // this.newPagination(this.data)
            // set totals
            this.setTotals();
            this.information.reset({ date: new Date().toISOString().substring(0, 10) })
          } else {
            // ! in case error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        }
      })
    }
  }
  /*
    ? select and object
    */
  public selectObject = (row: Object, index: number) => {
    this.indexSelected = index
    let data = { ...row }
    if (data['date']) {
      let x = data['date'].split('T')
      data['date'] = x[0]
    }
    if (data['datePaid']) {
      let y = data['datePaid'].split('T')
      data['datePaid'] = y[0]
    }
    delete data['reconditioningType']
    delete data['type']

    this.informationUpdate.setValue({ ...data, vehicleId: row['id'] })

  }
  /*
  ? delete
  */
  public delete = () => {
    this.loading = true

    this.master.sendPost('crudReconditioningCosts', { activity: 'delete', vehicleId: this.vehicleId, entityId: this.informationUpdate.value['id'] }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.data.splice(this.indexSelected, 1);
          this.indexSelected=-1;
          // close modal
          (document.getElementById('close-delete-modal') as HTMLButtonElement).click();

          this.newPagination(this.data)
          // set totals
          this.setTotals();

          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });

        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }


  // get total of types
  public getTotal = (type: string): number => {
    let data = []

    let toLowercase = (value: string) => { return value.toLowerCase() }
    data = type == 'others' ?
      this.data.filter(
        el => toLowercase(el['reconditioningType']['name']) != 'parts' && toLowercase(el['reconditioningType']['name']) != 'labor') :
      this.data.filter(el =>
        toLowercase(el['reconditioningType']['name']) == type
      )

    if (data.length > 0) {
      data = data.map(el => +el['cost'])
      return data.reduce((total, value) => total + value, 0)
    }
  }
  // set totals
  public setTotals = () => {

    this.totals.labor = this.getTotal('labor') ? this.getTotal('labor') : 0
    this.totals.parts = this.getTotal('parts') ? this.getTotal('parts') : 0
    this.totals.others = this.getTotal('others') ? this.getTotal('others') : 0
    this.totals.total = this.totals.labor + this.totals.parts + this.totals.others

    this.ms.channelComponents$.emit({ message: 'changeReaconditioningCost', total: this.totals.total })
  }
  // todo: detect changes
  changesPending = () => {

    if (!this.savePending) {
      this.savePending = true

      this.reaconditionCost$.emit({ message: 'changesPendingInventory', section: 'reaconditioningCost', value: this.savePending })
    }
  }
  /*
  * life cycles
  */
  constructor(private route: ActivatedRoute, private master: MasterService, private ms: MessageService) { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if(changes.data){
      this.setTotals()
    }
  }



}
