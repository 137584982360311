import { Component, OnInit, Input,EventEmitter,Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
// services
import { MasterService } from "../../../../services/master.service";
import { MessageService } from "../../../../services/message.service";
import { StoreService } from "../../../../services/store.service";
import * as languageLibrary from '../../../../services/language'
// extarnal
import * as moment from "moment/moment";
@Component({
  selector: "inventory-administrative",
  templateUrl: "./inventory-administrative.component.html",
  styleUrls: ["./inventory-administrative.component.scss"],
})
export class InventoryAdministrativeComponent implements OnInit {
  /*
   * Variables
   */
  @Output() emitterInformation$:EventEmitter<Object> =new EventEmitter()
  //  define if some changes not saved
  public savePending: boolean = false
  // save the language
  @Input() language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  // set vinNumber
  @Input() vinNumber: string = "";
  // contact object
  @Input() data: Object = {}
  // permissions
  @Input() permissions: string[] = [];
  // define the inventory id
  @Input() inventoryId: string = "";
  // define all lots
  public lots: Object[] = [];
  // define all lots
  @Input() vendors: Object[] = [];
  // status
  @Input() statusInput = ''
  // original datePurchased
  public orgDatePurchased: Date;
  // define expanded
  public expanded: boolean = true;
  // methotds list
  public methodsList: string[] = [
    "Cash",
    "Check",
    "Draft",
    "Money Order",
    "Repo",
    "Trade-in",
    "Other",
  ];
  // list of status
  @Input() statusList: string[] = [];
  // Group of inputs
  public information = new FormGroup({
    licensePlateNumber: new FormControl('', []),
    announcements: new FormControl('', []),
    dateTitleDeliveredCostumer: new FormControl('', []),
    dateOnLot: new FormControl('', []),
    inventorySource: new FormControl('', []),
    keyNumberAssigned: new FormControl('', []),
    airbagStatus: new FormControl('', []),
    inspectionData: new FormControl('', []),
    datePurchased: new FormControl('', []),
    titleCondition: new FormControl('', []),
    inspectionDue: new FormControl('', []),
    datePaid: new FormControl('', []),
    titleLocation: new FormControl('', []),
    assignLocation: new FormControl('', []),
    inspectionExpires: new FormControl('', []),
    paymentMethod: new FormControl('', []),
    titleNumber: new FormControl('', []),
    dateTitleReceived: new FormControl('', []),
    statusId: new FormControl('', []),
    daysOld: new FormControl('0', [])
  })
  // get information of inputs
  get licensePlateNumber() {
    return this.information.get("licensePlateNumber");
  }
  get announcements() {
    return this.information.get("announcements");
  }
  get dateTitleDeliveredCostumer() {
    return this.information.get("dateTitleDeliveredCostumer");
  }
  get dateOnLot() {
    return this.information.get("dateOnLot");
  }
  get inventorySource() {
    return this.information.get("inventorySource");
  }
  get keyNumberAssigned() {
    return this.information.get("keyNumberAssigned");
  }
  get airbagStatus() {
    return this.information.get("airbagStatus");
  }
  get inspectionData() {
    return this.information.get("inspectionData");
  }
  get datePurchased() {
    return this.information.get("datePurchased");
  }
  get titleCondition() {
    return this.information.get("titleCondition");
  }
  get inspectionDue() {
    return this.information.get("inspectionDue");
  }
  get datePaid() {
    return this.information.get("datePaid");
  }
  get titleLocation() {
    return this.information.get("titleLocation");
  }
  get inspectionExpires() {
    return this.information.get("inspectionExpires");
  }
  get paymentMethod() {
    return this.information.get("paymentMethod");
  }
  get titleNumber() {
    return this.information.get("titleNumber");
  }
  get dateTitleReceived() {
    return this.information.get("dateTitleReceived");
  }
  get assignLocation() {
    return this.information.get("assignLocation");
  }
  get statusId() {
    return this.information.get("statusId");
  }
  get daysOld() {
    return this.information.get("daysOld");
  }
  /*
   * Functions
   */
  /*
     todo: GET DATA
     */

  // save data
  public save = (): void => {
    if (this.information.valid) {
      this.master.sendPost('updateInventory', {
        ...this.information.value,
        statusId: parseInt(this.information.value['statusId']),
        lotId: localStorage.getItem('lot') ? localStorage.getItem('lot') : this.store.lotSelected,
        inventoryId: this.inventoryId, forUpdate: 'administrative'
      }, res => {
        if (res) {
          if (res.status == 200) {
            // * success
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['successAdministrative'] });
            this.emitterInformation$.emit({ message: 'reloadInventoryDetails' })
            // !fix
            let status = this.statusList.filter(el => el['id'] == this.information.value['statusId'])

            if(status.length>0){

              this.emitterInformation$.emit({ message: 'updateAdministraive', datePurchased: this.information.value['datePurchased'], status: status[0]['name'] })
            }
            this.savePending = false
            this.emitterInformation$.emit({ message: 'changesPendingInventory', section: 'administrative', value: false })
          } else {

            // in case API no response
            this.ms.sendMessage("alert", {
              type: "danger",
              text: res.data.error,
            });
          }
        }else{
          this.ms.sendMessage("alert", {
            type: "danger",
            text: this.words[this.language]['apiNoResponse'],
          });
        }
      });
    } else {
      this.daysOld.setValue(null);
      this.ms.sendMessage("alert", {
        type: "danger",
        text: "The Date Purchased cannot be in the future.",
      });
    }
  };
  // change days old
  public changeDaysOld = (purchaseDate: string) => {
    let purchase = moment(purchaseDate);
    let now = moment(moment().format('YYYY-MM-DD'))
    this.daysOld.setValue(now.diff(purchase, 'days') + 1)
  }

  public copyVIN() {
    // console.log("This is the VIN Number:" + this.vinNumber);
    navigator.clipboard.writeText(this.vinNumber).then(
      function () {
        console.log("VIN copied to clipboard.");
        window.open('http://www.mytxcar.org/txcar_net/SecurityCheck.aspx', 'popup', 'width=1000,height=600'); return false;
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }

  public notifyUser() {
    this.ms.sendMessage("alert", { type: "success", text: "The VIN number is already on your clipboard. Just paste the VIN." });
  }

  // todo: detect changes
  changesPending = () => {

    if (!this.savePending) {
      this.savePending = true

      this.emitterInformation$.emit({ message: 'changesPendingInventory', section: 'administrative', value: this.savePending })
    }
  }
  /*
   * life cycles
   */
  constructor(
    private route: ActivatedRoute,
    private master: MasterService,
    private ms: MessageService,
    private store: StoreService
  ) {

  }

  ngOnInit() {
    // set id
    // this.inventoryId = this.route.snapshot.params["id"];
    // get all lots
    if (this.store.userAccount["user"]) {
      this.lots = this.store.userAccount["user"].lots;
    }

    // set data from end-point

    this.information.setValue({ ...this.data, statusId: this.statusInput['statusId'] });
  }



}
