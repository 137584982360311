import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';

import { MasterService } from '../../../../../../services/master.service'
import { MessageService } from '../../../../../../services/message.service'
import { StoreService } from '../../../../../../services/store.service'
import * as languageLibrary from '../../../../../../services/language'
import * as moment from 'moment'

@Component({
  selector: 'ttl',
  templateUrl: './ttl.component.html',
  styleUrls: ['./ttl.component.scss']
})
export class TtlComponent implements OnInit {
  // =============================
  // todo: Variables
  // =============================
  @Input() isUnLock = true
  //
  @Input() totalSalePrice: number = 0
  //
  @Input() baseSalePrice: number = 0
  //
  @Input() inventory = ''
  //
  @Input() buyer = ''
  //
  @Input() saleType: string = ''
  //
  public saleTaxAndFees = null
  // define loading status
  public loading: boolean = false
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  //
  public expandedFees: boolean = true;
  //
  @Input() saleId = ''
  //
  @Output() ttlEmiter$: EventEmitter<Object> = new EventEmitter();
  // =============================
  // todo: functions
  // =============================
  // =============================
  // ? create drive out Price
  // =============================
  public createDriveOutPrice = async () => {

    const param = {
      "activity": "createDriveOutPriceDispatcher",
      "saleId": this.saleId,
      "saleTypeId": this.saleType,
      "amountDue": (+this.totalSalePrice) + (this.saleTaxAndFees != null ? this.saleTaxAndFees.totals : 0),
      "dueDate": moment().format('yyyy-MM-DD')
    }

    const res = await this.master.sendPostAsync('driveOutPriceDispatcher', param)

    if (res && res.status == 200) {
      this.ms.sendMessage("alert", { type: "info", text: 'Drive-out price was created' });
    } else {
      this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
    }
  }



  // =============================
  // ? read taxes
  // =============================
  public readForSaleTaxAndFees = (save: boolean,createInventoryTax:boolean): void => {
    this.loading = true
    this.master.sendPost('CRUDForSaleTaxAndFees', {
      activity: 'read',
      saleId: this.saleId
    }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.saleTaxAndFees = res.data.saleTaxAndFees.data
          if (this.saleTaxAndFees != null) {
            this.saleTaxAndFees = JSON.parse(this.saleTaxAndFees)
            // inject new fee in Fess's array
            // if(createInventoryTax){
            //   this.saleTaxAndFees['Fees'].unshift({
            //     ...this.saleTaxAndFees.fees,
            //     Name: 'Dealer Inventory Tax',
            //     Category:'Dealer',
            //     FeeFormula: `${+this.baseSalePrice} * ${this.saleTaxAndFees.fees['Amount']}%`
            //   })
            // }
            // this.saleTaxAndFees['Fees']=this.saleTaxAndFees['Fees'].sort((a:any,b:any)=>a['Category']>b['Category']?0:1)

            //
            this.saleTaxAndFees['id'] = res.data.saleTaxAndFees.id
            // initialize new fields of fees
            this.saleTaxAndFees['Fees'].forEach(el => {
              el['newAmount'] = el['newAmount'] ? el['newAmount'] : ''
              el['Amount'] = parseFloat(el['Amount']).toFixed(2)
            })
            // if(createInventoryTax){
            //   // create new fee dealer
            //   this.saleTaxAndFees['Fees'].unshift({
            //     Name:'Dealer Inventory Tax',
            //     Amount:(this.baseSalePrice || 0)* 0.005,
            //     newAmount:0,
            //     Category:'Dealer',
            //     FeeFormula: `${+this.baseSalePrice} * ${0.005}%`
            //   })
            // }
            // initialize new fields of taxes
            this.saleTaxAndFees['Taxes'].forEach(el => {
              el['Amount'] = ((el['Rate'] * (+this.totalSalePrice)) / 100)
              el['newAmount'] = el['newAmount'] ? (+el['newAmount']).toFixed(2) : ''
              el['newRate'] = el['newRate'] ? (+el['newRate']).toFixed(2) : ''

            })

            //  calcule the taxes total
            this.saleTaxAndFees['Taxes'][0]['totalTaxesAmount'] = this.saleTaxAndFees['Taxes'].reduce((total, el) => (el['newAmount'] ? +el['newAmount'] : +el['Amount']) + total, 0)
            // delete old taxes total
            // this.saleTaxAndFees.CategorySubtotals = this.saleTaxAndFees.CategorySubtotals.filter(el => el['Category'] != 'Taxes')
            this.saleTaxAndFees['CategorySubtotals'][0]['Subtotal'] = this.totalsCalculate(this.saleTaxAndFees['Fees'].filter(el => el['Category'] == "Registration"), 'Registration')
            this.saleTaxAndFees['CategorySubtotals'][1]['Subtotal'] = this.totalsCalculate(this.saleTaxAndFees['Fees'].filter(el => el['Category'] == "Dealer"), 'Dealer')
            this.saleTaxAndFees['CategorySubtotals'][2]['Subtotal'] = this.totalsCalculate(this.saleTaxAndFees['Fees'].filter(el => el['Category'] != "Registration" && el['Category'] != "Dealer"), 'Others')
            // set total taxes to categories sub total
            if(!this.saleTaxAndFees.CategorySubtotals.some((el:any)=>el['Category']=="Taxes")){
              this.saleTaxAndFees.CategorySubtotals.push({ Category: "Taxes", Subtotal: this.saleTaxAndFees['Taxes'][0]['totalTaxesAmount'] })
            }
            // calculate the total of sub categories
            this.saleTaxAndFees['totals'] = this.saleTaxAndFees['CategorySubtotals'].reduce((total, value) => total + value['Subtotal'], 0)

          }

          this.ttlEmiter$.emit({ value: this.saleTaxAndFees ? this.saleTaxAndFees['totals'] : 0, fees: this.saleTaxAndFees['Fees'],save:save,inventoryTaxRate:this.saleTaxAndFees.fees['Amount'] })
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // =============================
  // ? create taxes
  // =============================
  public calculate = (): void => {
    if (this.store.userAccount['corporateAddresses'].length == 0) {
      this.ms.sendMessage("alert", { type: "danger", text: 'Corporate address not exist' });
      return
    }
    if (!this.inventory['stockNumber']) {
      this.ms.sendMessage("alert", { type: "danger", text: 'Vehicle not selected' });
      return
    }

    if (this.buyer['county'] == "") {
      this.ms.sendMessage("alert", { type: "danger", text: 'Buyer not selected or not address' });
      return
    }

    if (this.inventory['data'].features.gvwr == null) {
      this.ms.sendMessage("alert", { type: "danger", text: 'GVWR is not defined' });
      return
    }

    if (this.inventory['data'].datePurchased == '') {
      this.ms.sendMessage("alert", { type: "danger", text: 'Data purchased not defined' });
      return
    }
    if (this.inventory['data']['features'] == undefined) {
      this.ms.sendMessage("alert", { type: "danger", text: 'Some features related to the vehicle are missing' });
      return
    }
    if (+this.totalSalePrice <= 0) {
      this.ms.sendMessage("alert", { type: "danger", text: 'Base sale price most be greater than 0' });
      return
    }
    //
    let engine = ''
    switch (this.inventory['data']['features'].engine) {
      case 'Diesel':
        engine = 'Dl'
        break;
      case 'Gas':
        engine = 'G'
        break;
      case 'Electric':
        engine = 'E'
        break;
      case 'Hibrid':
        engine = 'H'
        break;
      default:
        engine = ''
        break;
    }
    let lotSelected = this.store.userAccount['user'].lots.find(el => el['id'] == this.store.lotSelected)

    const requestParams = {
      "TransactionId": this.inventory['stockNumber'], // stock#
      "ApplicantState": this.store.states[this.buyer['state']],
      "ApplicantCounty": this.buyer['county'],
      "ApplicantCity": this.buyer['city'],
      "BasePrice": +this.totalSalePrice,
      "LienStatus": "false", // have lienholder
      "PlateTransfer": false, // NA
      "SellerAddress1": this.store.userAccount['corporateAddresses'][0]['address'],
      "SellerState": this.store.states[this.store.userAccount['corporateAddresses'][0]['state']],
      "SellerCounty": this.store.userAccount['corporateAddresses'][0]['county'],
      "SellerCity": this.store.userAccount['corporateAddresses'][0]['city'],
      "TitleStatus": "T",
      "TransactionType": this.saleType == '1' ? 'C' : 'P',
      "VehicleType": "PV",
      "VIN": this.inventory['data'].vinNumber,
      "Year": parseInt(this.inventory['data'].modelYear),
      //
      "AnonymousApplicant": this.buyer['city'] == "" ? "Yes" : "No",
      "OutsideCityLimits": "false",
      "BaseMSRP": +this.totalSalePrice,
      "FuelType": engine,
      "GVW": this.inventory['data'].features.gvwr ? parseInt(this.inventory['data'].features.gvwr) : 0,
      "GVWR": this.inventory['data'].features.gvwr ? parseInt(this.inventory['data'].features.gvwr) : 0,
      //
      "Weight": this.inventory['data']['features'].carryingWeight == "" ? 0 : parseInt(this.inventory['data']['features'].carryingWeight),
      "DaysSincePurchase": this.inventory['data'].datePurchased ? moment().diff(moment(this.inventory['data'].datePurchased), 'days') : 0,
      "datePurchased": this.inventory['data'].datePurchased,
      "PlateType": "R",
      "RegistrationOption": "B",
      "Cylinders": this.inventory['data']['features'].cylinders == '' ? 0 : parseInt(this.inventory['data']['features'].cylinders),
      "SellerName": lotSelected.name,
      "SellerZipcode": this.store.userAccount['corporateAddresses'][0]['zipCode'],
      "MPG": 0,
      "Length": this.inventory['data']['features'].length == '' ? 0 : parseInt(this.inventory['data']['features'].length),
      "CC": 0,
      "HP": 0,
      "RegistrationMonths": 12,
      "RegistrationRenewalMonth": "Undefined",
      //
      "AnnualMileage": 0,
      "ApplicantAddress": this.buyer['address'],
      // "Fees":{
      //   Name:'Dealer Inventory Tax',
      //   Amount:(this.baseSalePrice || 0)* 0.005,
      //   newAmount:0,
      //   Category:'Dealer',
      //   Base:"Cash",
      //   TitleId:"",
      //   FeeFormula: `${+this.baseSalePrice} * ${0.005}%`
      // }
    }

    this.loading = true
    this.master.sendPost('CRUDForSaleTaxAndFees', {
      activity: 'create',
      saleId: this.saleId,
      requestParams: requestParams
    }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          // this.ms.sendMessage("alert", { type: "success", text: res.data.message });

          this.readForSaleTaxAndFees(true,true)
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // todo: recalculate
  public reCalculate = () => {
    this.loading = true

    this.master.sendPost('CRUDForSaleTaxAndFees', {
      activity: 'delete',
      taxId: this.saleTaxAndFees.id,
    }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.ms.sendMessage("alert", { type: "success", text: 'Delete success' });
          this.calculate()
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // calulate taxes
  public calculateTaxes = (type: string, index: number) => {
    if (type == 'rate') {

      if (this.saleTaxAndFees.Taxes[index]['newRate'] == '') {
        this.saleTaxAndFees.Taxes[index]['newAmount'] = ''
      } else {
        // todo: calculate new amount
        this.saleTaxAndFees.Taxes[index].newAmount = ((this.saleTaxAndFees.Taxes[index]['newRate'] * (+this.totalSalePrice)) / 100).toFixed(2)
      }
    } else {
      if (this.saleTaxAndFees.Taxes[index]['newAmount'] == '') {
        this.saleTaxAndFees.Taxes[index]['newRate'] = ''
      } else {
        // todo: calculate new rate
        this.saleTaxAndFees.Taxes[index].newRate = ((+this.saleTaxAndFees.Taxes[index]['newAmount'] / (+this.totalSalePrice)) * 100).toFixed(2)
      }


    }
  }
  // =============================
  // ? update taxes
  // =============================
  public save = (): void => {
    let copyFees = this.saleTaxAndFees['Fees'].slice()
    copyFees.forEach(el => {
      el['newAmount'] = el['newAmount'] == null || el['newAmount'] == '' ? 0 : el['newAmount']
    });
    if (copyFees.some(el => +el['newAmount'] < 0)) {
      this.ms.sendMessage("alert", { type: "warning", text: 'Some value is invalid: Cannot be less of 0.' });
      return
    }

    // sumatory
    this.saleTaxAndFees['CategorySubtotals'][0]['Subtotal'] = this.totalsCalculate(this.saleTaxAndFees['Fees'].filter(el => el['Category'] == "Registration"), 'Registration')
    this.saleTaxAndFees['CategorySubtotals'][1]['Subtotal'] = this.totalsCalculate(this.saleTaxAndFees['Fees'].filter(el => el['Category'] == "Dealer"), 'Dealer')
    this.saleTaxAndFees['CategorySubtotals'][2]['Subtotal'] = this.totalsCalculate(this.saleTaxAndFees['Fees'].filter(el => el['Category'] != "Registration" && el['Category'] != "Dealer"), 'Others')
    let stringData = JSON.stringify(this.saleTaxAndFees)
    this.master.sendPost('CRUDForSaleTaxAndFees', {
      activity: 'update',
      saleId: this.saleId,
      jsonString: stringData
    }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success

          this.readForSaleTaxAndFees(true,false)
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // =============================
  // ? parse new amount
  // =============================
  public parseNewAmount = (index): void => {
    this.saleTaxAndFees['Fees'][index]['newAmount'] =
      this.saleTaxAndFees['Fees'][index]['newAmount'] == null ? '' :
        parseFloat(this.saleTaxAndFees['Fees'][index]['newAmount']).toFixed(2)
  }
  // =============================
  // ? totals calculate
  // =============================
  public totalsCalculate = (fees: any[], type): number => {
    switch (type) {
      case 'Registration':
        return fees.reduce((total, value) => total + parseFloat(value['newAmount'] != 0 ? value['newAmount'] : value['Amount']), 0)
      case 'Dealer':

        return fees.reduce((total, value) => total + parseFloat(value['newAmount'] != 0 ? value['newAmount'] : value['Amount']), 0)
      default:

        return fees.reduce((total, value) => total + parseFloat(value['newAmount'] != 0 ? value['newAmount'] : value['Amount']), 0)
    }

  }
  // =============================
  // todo: life cycles
  // =============================
  constructor(private master: MasterService, private ms: MessageService, private store: StoreService) { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.

    if (changes.baseSalePrice && !changes.baseSalePrice.firstChange) {

      this.readForSaleTaxAndFees(false,false)

    }
    if (changes.isUnLock && changes.isUnLock.currentValue == true) {


      if (!changes.isUnLock.firstChange) {

        if (this.saleType == '3') {
          this.createDriveOutPrice()
        }
      }

    }


  }

}
