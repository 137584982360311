import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
// services
import { MasterService } from "./services/master.service";
import { StoreService } from "./services/store.service";
// components
import { AppComponent } from './app.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterFormComponent } from './views/login/components/register-form/register-form.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { AlertComponent } from './components/alert/alert.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginFormComponent } from './views/login/components/login-form/login-form.component';
import { ProfileDashboardComponent } from './views/profile-dashboard/profile-dashboard.component';
import { CustomerInformationFormComponent } from './components/customer-information-form/customer-information-form.component';
import { AccountSettingsFormComponent } from './components/account-settings-form/account-settings-form.component';
import { EmailComponent } from './views/activation-views/email/email.component';
import { ActivateAccountComponent } from './views/activation-views/activate-account/activate-account.component';
import { LandingComponent } from './information-views/landing/landing.component';
import { ContactComponent } from './information-views/contact/contact.component';
import { PrivacyComponent } from './information-views/privacy/privacy.component';
import { PricingComponent } from './information-views/pricing/pricing.component';
import { PartnersComponent } from './information-views/partners/partners.component';
import { FeaturesComponent } from './information-views/features/features.component';
import { FaqComponent } from './information-views/faq/faq.component';
import { HelpComponent } from './information-views/help/help.component';
import { UsersComponent } from './views/users/users.component';
import { ResetPasswordComponent } from './views/activation-views/reset-password/reset-password.component';
import { ResetChangePasswordComponent } from './views/activation-views/reset-change-password/reset-change-password.component';
import { InvitationsActivationComponent } from './views/activation-views/invitations-activation/invitations-activation.component';
import { ContactsComponent } from './views/contacts-views/contacts/contacts.component';
import { TableUserOptionsComponent } from './views/users/components/table-user-options/table-user-options.component';
import { TableUserAddComponent } from './views/users/components/table-user-add/table-user-add.component';
import { ContactsDetailComponent } from './views/contacts-views/contacts/contacts-detail/contacts-detail.component';
import { GeneralInformationComponent } from './views/contacts-views/contacts/components/general-information/general-information.component';
import { AddressesComponent } from './views/contacts-views/contacts/components/addresses/addresses.component';
import { CommunicationsComponent } from './views/contacts-views/contacts/components/communications/communications.component';
import { IdentificationsComponent } from './views/contacts-views/contacts/components/identifications/identifications.component';
import { IncomeComponent } from './views/contacts-views/contacts/components/income/income.component';
import { ReferencesComponent } from './views/contacts-views/contacts/components/references/references.component';
import { CommissionComponent } from "./views/contacts-views/contacts/components/commission/commission.component";
import { NotesComponent } from './views/contacts-views/contacts/components/notes/notes.component';
import { HistoryComponent } from './views/contacts-views/contacts/history/history.component';
import { CorporationsComponent } from './views/corporateSetup-views/views/corporations/corporations.component';
import { CorporationsDetailComponent } from './views/corporateSetup-views/views/corporations-detail/corporations-detail.component';
import { GeneralInformationCorporateComponent } from './views/corporateSetup-views/components/general-information/general-information.component'
import { AddressesCorporateComponent } from './views/corporateSetup-views/components/addresses/addresses.component';
import { CommunicationsCorporateComponent } from './views/corporateSetup-views/components/communications/communications.component';
import { CommissionCorpComponent } from "./views/corporateSetup-views/components/commission-corp/commission-corp.component";
import { SecurityComponent } from './views/security/security.component';
import { RolesDetailComponent } from './views/security/components/roles-detail/roles-detail.component'
import { UsersRolesComponent } from './views/security/components/users/users.component';
import { InventoryComponent } from './views/inventory/inventory/inventory.component';
import { InventoryDetailComponent } from './views/inventory/inventory-detail/inventory-detail.component';
import { InventoryGeneralInformationComponent } from './views/inventory/components/inventory-general-information/inventory-general-information.component';
import { InventoryFinancialsComponent } from './views/inventory/components/inventory-financials/inventory-financials.component';
import { InventoryAdministrativeComponent } from './views/inventory/components/inventory-administrative/inventory-administrative.component';
import { InventoryReconditioningCostComponent } from './views/inventory/components/inventory-reconditioning-cost/inventory-reconditioning-cost.component';
import { InventoryFloorPlansComponent } from './views/inventory/components/inventory-floor-plans/inventory-floor-plans.component';
import { InventorySetupComponent } from './views/corporateSetup-views/components/inventory-setup/inventory-setup.component';
import { ReportSetupComponent } from './views/corporateSetup-views/components/report-setup/report-setup.component';
import { InventoryContactsComponent } from './views/contacts-views/contacts/components/fp-default-setup/fp-default-setup.component';
import { InventoryRecallsComponent } from './views/inventory/components/inventory-recalls/inventory-recalls.component'
import { BuyersReportComponent } from './views/reports/buyers-report/buyers-report.component';
import { PaymentReceiptComponent } from './views/reports/payment-receipt/payment-receipt.component';
import { NotesInventoryComponent } from './views/inventory/components/notes-inventory/notes-inventory.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { DragDropModule } from '@angular/cdk/drag-drop';
import { InventoryImagesListComponent } from './views/inventory/components/inventory-images-list/inventory-images-list.component';
import { InventoryFilesComponent } from './views/inventory/components/inventory-files/inventory-files.component';
import { SalesComponent } from "./views/sales/sales/sales.component";
import { SalesDetailComponent } from "./views/sales/sales-detail/sales-detail.component";
import { DealBuyerComponent } from "./views/sales/components/buyer/buyer.component";
import { DealStockComponent } from "./views/sales/components/stock/stock.component";
import { DealContractComponent } from "./views/sales/components/contract/contract.component";
import { SaleSetupComponent } from "./views/corporateSetup-views/components/sales-setup/sales-setup.component";
import { CommunicationCenterComponent } from "./views/communication-center/communication-center.component";
import { AfterSaleChargesComponent } from "./views/aftersalecharges-views/aftersalecharges.component";
import { IntegrationComponent } from "./views/integration-views/integration.component";
import { CanDeactivateGuard } from './services/can-deactivate-guard.service';
import { CoBuyersComponent } from './views/sales/components/buyer/components/co-buyers/co-buyers.component';
import { CosignersComponent } from './views/sales/components/buyer/components/cosigners/cosigners.component';
import { TradeInComponent } from './views/sales/components/stock/components/trade-in/trade-in.component';
import { AccesoriesComponent } from './views/sales/components/contract/components/accesories/accesories.component';
import { TtlComponent } from './views/sales/components/contract/components/ttl/ttl.component';
import { TermsComponent } from './views/sales/components/contract/components/terms/terms.component';
import { PaymentsDetailComponent } from './views/payments/payments-detail/payments-detail.component';
import { FooterPaymentsComponent } from './views/payments/components/footer-payments/footer-payments.component';
import { HeaderPaymentsComponent } from './views/payments/components/header-payments/header-payments.component';
import { BodyPaymentsComponent } from './views/payments/components/body-payments/body-payments.component';
import { CollectionsSetupComponent } from './views/corporateSetup-views/components/collections-setup/collections-setup.component';
import { NotesPaymentsComponent } from './views/payments/components/notes-payments/notes-payments.component';
import { CPIComponent } from './views/cpi-views/cpi.component';
import { CardCalculateComponent } from './views/sales/components/contract/components/terms/components/card-calculate/card-calculate.component'
import { CardCompareComponent } from './views/sales/components/contract/components/terms/components/card-compare/card-compare.component'
import { PrintCenterComponent } from './views/sales/components/print-center/print-center.component'
import { AfterSaleModalComponent } from './views/aftersalecharges-views/components/after-sale-modal/after-sale-modal.component';
import { ActiveAfterSalesComponent } from './views/aftersalecharges-views/components/active-after-sales/active-after-sales.component';
import { InactiveAfterSalesComponent } from './views/aftersalecharges-views/components/inactive-after-sales/inactive-after-sales.component';
import { ActiveCpiComponent } from './views/cpi-views/components/active-cpi/active-cpi.component';
import { InactiveCpiComponent } from './views/cpi-views/components/inactive-cpi/inactive-cpi.component';
import { AddCpiComponent } from './views/cpi-views/components/add-cpi/add-cpi.component';
import { CpiModalComponent } from './views/cpi-views/components/cpi-modal/cpi-modal.component';
import { PaymentsTermsComponent } from './views/sales/components/contract/components/terms/components/payments-terms/payments-terms.component';
import { ExpectedPaymentsTableComponent } from './views/payments/components/body-payments/components/expected-payments-table/expected-payments-table.component';
import { PromisesExpectedComponent } from './views/payments/components/body-payments/components/promises-expected/promises-expected.component';
import { HistoryPaymentsComponent } from './views/payments/components/body-payments/components/history-payments/history-payments.component';
import { OverviewPaymentComponent } from './views/payments/components/body-payments/components/overview-payment/overview-payment.component';
import { PromisesFormComponent } from './views/payments/components/body-payments/components/promises-form/promises-form.component';
import { PaymentsFormComponent } from './views/payments/components/body-payments/components/payments-form/payments-form.component';
import { CardBuyerComponent } from './views/sales/components/buyer/components/card-buyer/card-buyer.component'
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterFormComponent,
    DashboardComponent,
    SideMenuComponent,
    NavBarComponent,
    AlertComponent,
    FooterComponent,
    LoginFormComponent,
    ProfileDashboardComponent,
    CustomerInformationFormComponent,
    AccountSettingsFormComponent,
    EmailComponent,
    ActivateAccountComponent,
    LandingComponent,
    ContactComponent,
    PrivacyComponent,
    PricingComponent,
    PartnersComponent,
    FeaturesComponent,
    FaqComponent,
    HelpComponent,
    UsersComponent,
    ResetPasswordComponent,
    ResetChangePasswordComponent,
    InvitationsActivationComponent,
    ContactsComponent,
    TableUserOptionsComponent,
    TableUserAddComponent,
    ContactsDetailComponent,
    GeneralInformationComponent,
    AddressesComponent,
    CommunicationsComponent,
    IdentificationsComponent,
    CommissionComponent,
    CommissionCorpComponent,
    IncomeComponent,
    ReferencesComponent,
    NotesComponent,
    HistoryComponent,
    CorporationsComponent,
    CorporationsDetailComponent,
    GeneralInformationCorporateComponent,
    AddressesCorporateComponent,
    CommunicationsCorporateComponent,
    SecurityComponent,
    UsersRolesComponent,
    RolesDetailComponent,
    InventoryComponent,
    InventoryDetailComponent,
    InventoryGeneralInformationComponent,
    InventoryFinancialsComponent,
    InventoryAdministrativeComponent,
    InventoryReconditioningCostComponent,
    InventoryFloorPlansComponent,
    InventorySetupComponent,
    ReportSetupComponent,
    InventoryContactsComponent,
    BuyersReportComponent,
    PaymentReceiptComponent,
    InventoryRecallsComponent,
    NotesInventoryComponent,
    SalesComponent,
    SalesDetailComponent,
    DealBuyerComponent,
    DealStockComponent,
    DealContractComponent,
    InventoryImagesListComponent,
    InventoryFilesComponent,
    SaleSetupComponent,
    CoBuyersComponent,
    CosignersComponent,
    TradeInComponent,
    AccesoriesComponent,
    TtlComponent,
    TermsComponent,
    AfterSaleChargesComponent,
    IntegrationComponent,
    PaymentsDetailComponent,
    FooterPaymentsComponent,
    HeaderPaymentsComponent,
    BodyPaymentsComponent,
    CollectionsSetupComponent,
    NotesPaymentsComponent,
    CommunicationCenterComponent,
    CPIComponent,
    CardCalculateComponent,
    CardCompareComponent,
    PrintCenterComponent,
    AfterSaleModalComponent,
    ActiveAfterSalesComponent,
    InactiveAfterSalesComponent,
    ActiveCpiComponent,
    InactiveCpiComponent,
    AddCpiComponent,
    CpiModalComponent,
    PaymentsTermsComponent,
    ExpectedPaymentsTableComponent,
    PromisesExpectedComponent,
    HistoryPaymentsComponent,
    OverviewPaymentComponent,
    PromisesFormComponent,
    PaymentsFormComponent,
    CardBuyerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DragDropModule,
    // ButtonModule
  ],
  providers: [MasterService, StoreService, CanDeactivateGuard],
  bootstrap: [AppComponent],
})
export class AppModule { }
