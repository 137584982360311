/*
* libraries
*/
import { Component, OnInit,Output,EventEmitter, Input,SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from '../../services/message.service'
import { StoreService } from '../../services/store.service'
import * as words from '../../services/language'

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  /*
* Variables
*/
  // users menu
  public displayMenu: boolean = true
  // corporation setup
  public displayMenu2: boolean = true
  //
  public displaySalesMenu: boolean = true
  // permissions
  @Input() permissions: Object[] = []
  // save the language
  @Input() language: string = 'EN'
  // set all words
  public  words = words.language
  //
  public image = {}
  //
  @Output() sideNavMessage$:EventEmitter<object>=new EventEmitter()
  /*
  * functions
  */
 /*
  ------------------------
   todo: open payment view in another window
  / ------------------------
  */
 public openPayment=()=>{
  let index=window.location.href.indexOf('#/')
  let route=window.location.href.substr(0,index+2)
  window.open(route+'payments/0','fullscreen',
  'top=0,left=0,width='+(screen.availWidth)+',height ='+(screen.availHeight)+
  ',fullscreen=yes,toolbar=0 ,location=0,directories=0,status=0,menubar=0,resiz able=0,scrolling=0,scrollbars=0')
}
/*
  ------------------------
   todo: change contact type view
   @param type:string=new contact type selected
  / ------------------------
  */
  public changeContact = (type:string): void => {
    this.router.navigate(['/contacts/' + type]);
    this.sideNavMessage$.emit({ message: 'changeType', newType: type })
  }
   // todo: listen to output of navbar
   public listenerNav=(e):void=>{

    switch (e['message']) {
      case 'changeLanguage':
        this.language=e['value']
        break;
      case 'setPermissions':
        // permissions on user list

        this.permissions = e['permissions'];
        // image
        // if(this.store.userAccount['corporateLogo'].corporateLogoURL){
        //   this.image = { corporateLogoURL: this.store.userAccount['corporateLogo'].corporateLogoURL, display: this.store.userAccount['corporateLogo'].displayLogoInternal == 0 ? false : true }
        // }else{
        //   this.image={}
        // }
      break;
      case 'reloadLogo':
        this.image = e.value
        this.store.userAccount['corporateLogo']=e.value
      break
      default:
        break;
    }
  }
  /*
* Life cycles
*/
  constructor(private ms: MessageService, private router: Router, private store: StoreService) {
    // detect change language

  }

  ngOnInit() {
    if (this.store.userAccount['permissions']) {
      // permissions on user list
      this.permissions = this.store.userAccount['permissions']
      // image
      if(this.store.userAccount['corporateLogo'].corporateLogoURL){
        this.image = { corporateLogoURL: this.store.userAccount['corporateLogo'].corporateLogoURL, display: this.store.userAccount['corporateLogo'].displayLogoInternal == 0 ? false : true }
      }else{
        this.image={}
      }
    }
    // listener
    this.ms.channelComponents$.subscribe(res => {
      if(res.message=='reloadLogo'){
        this.image = res.value
        this.store.userAccount['corporateLogo']=res.value
      }
    })

  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if(changes.permissions && changes.permissions.currentValue!=undefined){

      this.permissions=changes.permissions.currentValue

    }
  }



}
