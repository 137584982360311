import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-buyer',
  templateUrl: './card-buyer.component.html',
  styleUrls: ['./card-buyer.component.scss']
})
export class CardBuyerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
