import { Component, OnInit } from '@angular/core';
import {  ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {
public email='youremail@domain.com'
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.email = this.route.snapshot.paramMap.get('email');
  }

}
