import { Component, OnInit } from '@angular/core';
import * as words from '../../../services/language'
//
import { ActivatedRoute } from "@angular/router";
@Component({
  selector: 'app-payments-detail',
  templateUrl: './payments-detail.component.html',
  styleUrls: ['./payments-detail.component.scss']
})
export class PaymentsDetailComponent implements OnInit {
  // **************************
  // ****** variables *********
  // **************************
  public data:Object = {}
  //
  public saleId:number=0
  //
// save the language
public language: string= 'EN'
// set all words
public words = words.language
  // **************************
  // ****** functions *********
  // **************************
  public getHeader=(e):void=>{
    this.data['header']=e.header
    this.data['body']=e.body
    this.data['body']['lotId']=e.lotId
    this.data['body']['paymentsExpectedAll']=e.body['paymentsExpected']
    this.data['body']['contactId']=e.header.buyer.contactId
    this.data['footer']=e.footer
    this.saleId=e.saleId
  }
  //
  public getBody=(e):void=>{
    this.data['header']=e.header

  }
  // todo: listen to output of navbar
  public listenerNav=(e):void=>{
    switch (e['message']) {
      case 'changeLanguage':
        this.language=e['value']
        break;
      case 'setPermissions':

      break;
      default:
        break;
    }
  }


  // **************************
  // ****** life cycles *******
  // **************************

  constructor(private route: ActivatedRoute,) { }

  ngOnInit() {
    this.saleId = parseInt(this.route.snapshot.params['id'])
  }

}
