import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// services
import { MessageService } from '../../../services/message.service'
import { MasterService } from '../../../services/master.service'
import { StoreService } from '../../../services/store.service'
@Component({
  selector: 'app-payment-receipt',
  templateUrl: './payment-receipt.component.html',
  styleUrls: ['./payment-receipt.component.scss']
})
export class PaymentReceiptComponent implements OnInit {
  /*
  * variables
  */
  public entity: string = ''
  public entityId: string = ''
  public data: string = ''
  /*
  * Functions
  */

  constructor(private store: StoreService, private route: ActivatedRoute, private ms: MessageService, private master: MasterService) {
    
    if (this.store.userAccount['permissions']) {
      // if not have permissions, return to account settings
      
      if (this.store.userAccount['permissions'][5]['childrens'][0].permissions.includes('view')) {
        this.entity = this.route.snapshot.params['entity']
        this.entityId = this.route.snapshot.params['entityId']
        
      }else{
        window.location.href = "#/profile"
      }
    }
    // listener
    this.ms.channelComponents$.subscribe(res => {
      
      if (res.message == 'setPermissions' && res.page && res.page.indexOf('reports') >= 0) {
        // permissions on user list
        if (res['permissions'][5]['childrens'][0].permissions.includes('view')) {
          this.entity = this.route.snapshot.params['entity']
          this.entityId = this.route.snapshot.params['entityId']
        }else{
          window.location.href = "#/profile"
        }
      } 
    })
  }

  ngOnInit() {

  }

}
