import { Component, OnInit,Input } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import * as languageLibrary from '../../../services/language'
// services
import { MessageService } from "../../../services/message.service";
import { MasterService } from "../../../services/master.service";
import { StoreService } from "../../../services/store.service";
//
// import { NgxXml2jsonService } from "ngx-xml2json";

import { Observable } from 'rxjs';
@Component({
  selector: "sales-detail",
  templateUrl: "./sales-detail.component.html",
  styleUrls: ["./sales-detail.component.scss"],
})
export class SalesDetailComponent implements OnInit {
  /*
  * Variables
  */
  public requestOrigination:any={}
  // permissions
  public permissions: Object[] = []
  //
  public salePrice = 0
  // save the language
  public language: any = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  //  define all contacts
  public contacts: Object[] = []
  //
  public loading: boolean = false
  //
  public buyer = {}
  //
  public inventory = {}
  // saleId
  public saleId: number
  // lotId
  public lotId: number
  //
  public headerData = {}
  //
  public buyerType = null
  //
  public enableBHPH: boolean = false
  //
  public lienholders:Object[] = []
  // displayDocumentSigningForm
  public displayDocumentSigningFormBuyer: boolean = false
  public enterPIN: boolean = false
  public displayDocumentSigningFormBuyer2: boolean = false
  public enterPIN2: boolean = false
  public signSig: boolean = false
  public signInit: boolean = false
  public signSig2: boolean = false
  public signInit2: boolean = false
  public PrintDocumentsOccured: boolean = false
  public signAndUploaded: boolean = false

  /*
  * Functions
  */
  // =============================
  // todo: get lienholder
  // =======================
  public getLienholders = () => {
    this.master.sendPost("getContactsByTypeCorp?type=Lienholders", {}, (res) => {

      if (res) {
        if (res.status == 200) {

          this.lienholders = res.data.contacts;

        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", {
            type: "danger",
            text: res.data.error,
          });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    });

  }
  //  todo:get contacts
  public getContacts = (): void => {
    this.loading = true
    this.master.sendPost('contactLookupForSales', {}, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.contacts = res.data.contactData
          res.data.contactData.forEach(el => {
            el['companyName'] = el['companyName'] ? el['companyName'] : ''
          });

          for (let index = 0; index < this.contacts.length; index++) {
            // cellphone
            this.contacts[index]['cellphone'] = this.contacts[index]['contactCommunications'].filter(el => el['type'] == 'cellphone' && el['primary'] == 1)
            this.contacts[index]['cellphone'] = this.contacts[index]['cellphone'].length > 0 ? this.contacts[index]['cellphone'][0]['value'] : null
            // email
            this.contacts[index]['email'] = this.contacts[index]['contactCommunications'].filter(el => el['type'] == 'email' && el['primary'] == 1)
            this.contacts[index]['email'] = this.contacts[index]['email'].length > 0 ? this.contacts[index]['email'][0]['value'] : null
            // address
            this.contacts[index]['address'] = this.contacts[index]['addresses'].filter(el => el['primaryAddress'] == 1)
            this.contacts[index]['address'] = this.contacts[index]['address'].length > 0 ? this.contacts[index]['address'][0]['address'] : null
            // address
            this.contacts[index]['indentification'] = this.contacts[index]['contactIdentifications'].filter(el => el['primary'] == 1)
            this.contacts[index]['indentification'] = this.contacts[index]['indentification'].length > 0 ? this.contacts[index]['indentification'][0]['number'] : null
          }

        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })

  }
  // =============================
  // todo: listener of buyers
  // =======================
  public listenerBuyer = (res) => {
    this.buyer = res.value
    if(res.reload){

      this.getSaleDetails()
    }
  }
  //
  public listenerTypeBuyer = (res) => {

    this.buyerType = { ...res.data, ...res.buyer }
  }
  // =============================
  // todo: listener of inventory
  // =======================
  public listenerInventory = (res) => {
    this.inventory = res.value

  }
  // =============================
  // todo: get sale details
  // =======================
  public getSaleDetails = () => {
    this.master.sendPost("singleSaleDetails", { saleId: this.saleId }, (res) => {
      if (res) {
        if (res.status == 200) {

          this.headerData['typeCatalog'] = res.data.saleTypeCatalog
          this.headerData['statusCatalog'] = res.data.saleStatusCatalog
          this.headerData['data'] = res.data.singleSaleData

          this.headerData['data']['saleFinancingTypeIdBack'] = this.headerData['data']['saleFinancingTypeId']
          this.headerData['data'].saleStageId = this.headerData['data'].saleStageId == 1 ? false : true
          this.headerData['data']['saleTypeFlag'] = this.headerData['data'].saleFinancingTypeId
          this.enableBHPH = this.store.userAccount['user'].lots.find(el => el['id'] == this.store.lotSelected)
          this.enableBHPH = this.enableBHPH['enableBHPH']
          if (!this.enableBHPH) this.headerData['typeCatalog'] = this.headerData['typeCatalog'].filter(el => el['id'] != 4)

        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", {
            type: "danger",
            text: res.data.error,
          });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    });

  }
  // =============================
  // todo: update headers
  // =======================

  public updateHeaders = (inputName:string) => {
    if (inputName=='saleType' && (this.headerData['data']['saleFinancingTypeIdBack'] == 3 || this.headerData['data']['saleFinancingTypeIdBack'] == 4)) {
      (document.getElementById('btn-warning-saletype') as HTMLButtonElement).click();
    } else {
      if(inputName=='lock'){
        this.headerData['data'].saleLock = this.headerData['data'].saleLock == 'true' ? true : false
      }
      this.headerData['data']['saleTypeFlag'] = this.headerData['data']['saleFinancingTypeId']

      this.master.sendPost("updateSale", {
        saleId: this.saleId,
        saleDate: this.headerData['data'].saleDate,
        saleTypeId: parseInt(this.headerData['data'].saleFinancingTypeId),
        outOfState: parseInt(this.headerData['data'].outOfState),
        saleStatusId: parseInt(this.headerData['data'].saleStatusId),
        saleStageId: this.headerData['data'].saleStageId ? 2 : 1,
        saleLock: this.headerData['data'].saleLock,
      }, (res) => {
        if (res) {
          if (res.status == 200) {
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          } else {
            // ! send message in case error
            this.ms.sendMessage("alert", {
              type: "danger",
              text: res.data.error,
            });
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      });
    }
    this.headerData['data']['saleFinancingTypeIdBack'] = this.headerData['data']['saleFinancingTypeId']
  }
  // =============================
  // todo: delete flag
  // =======================

  public deleteFlag = (deleteFlag) => {

    this.master.sendPost("updateSale", {
      saleId: this.saleId,
      deleteFlag: deleteFlag,
    }, (res) => {
      if (res) {
        if (res.status == 200) {
          if (deleteFlag) {
            this.updateHeaders('')
            this.deleteDriveOutPrice()
            let backup = this.headerData['data']['saleFinancingTypeId']
            this.headerData['data']['saleFinancingTypeId'] = 0
            setTimeout(() => {
              this.headerData['data']['saleFinancingTypeId'] = backup
              this.headerData['data']['saleFinancingTypeIdBack'] = this.headerData['data']['saleFinancingTypeId']
            }, 0);
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          } else {
            this.headerData['data']['saleFinancingTypeId'] = this.headerData['data']['saleTypeFlag']
            this.headerData['data']['saleFinancingTypeIdBack'] = this.headerData['data']['saleFinancingTypeId']
          }

        } else {
          this.headerData['data']['saleFinancingTypeId'] = this.headerData['data']['saleTypeFlag']
          this.headerData['data']['saleFinancingTypeIdBack'] = this.headerData['data']['saleFinancingTypeId']
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error, });
        }
      } else {
        this.headerData['data']['saleFinancingTypeId'] = this.headerData['data']['saleTypeFlag']
        this.headerData['data']['saleFinancingTypeIdBack'] = this.headerData['data']['saleFinancingTypeId']
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    });
  }
  // getSalePrice
  public getSalePrice = (e) => {
    switch (e.message) {
      case 'set request of calc origination plus':

        this.requestOrigination=e.value
        break;

      default:
        this.salePrice = e.value
        break;
    }
  }
  // todo: listen nav messages
  public listenerNav = (e) => {
    switch (e.message) {
      case 'changeLot':
        this.enableBHPH = this.store.userAccount['user'].lots.find(el => el['id'] == this.store.lotSelected)
        this.enableBHPH = this.enableBHPH['enableBHPH']

        if (!this.enableBHPH) this.headerData['typeCatalog'] = this.headerData['typeCatalog'].filter(el => el['id'] != 4)

        break;
      case 'changeLanguage':
        this.language = e.value
        break;
      case 'setPermissions':
        this.permissions = e['permissions'];
        break;

      default:
        break;
    }
  }
   // =============================
  // ? delete drive out Price
  // =============================
  public deleteDriveOutPrice=async ()=>{

    const param={
      "activity": "deleteDriveOutPriceDisptacher",
      "saleId": this.saleId ,
  }

    const res=await this.master.sendPostAsync('driveOutPriceDispatcher',param)
    if(res && res.status==200){
      this.ms.sendMessage("alert", { type: "warning", text: 'Drive-out price was deleted' });
    }else{
      this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
    }
  }
  /*
    * Lifle Cycles
    */
  constructor(
    private route: ActivatedRoute,
    private ms: MessageService,
    private master: MasterService,
    private store: StoreService
  ) {
    this.saleId = parseInt(this.route.snapshot.params['id'])
    this.lotId = parseInt(this.route.snapshot.params['lotId'])

  }

  ngOnInit() {
    //
    this.getContacts()
    //
    this.getSaleDetails()
    // get lienholders
    this.getLienholders()
  }

  // ! before exit
  canDeactivate(): Observable<boolean> | boolean {
    let caseLeave = 'justView'

    if (!this.inventory['inventoryId']) {
      caseLeave = 'invalidInformation'
    }
    let result
    switch (caseLeave) {
      // phantom row
      case 'invalidInformation':

        result = window.confirm('The information is not valid, if it leaves the view the contact will be eliminated, do you want to leave?');
        if (result) {
          this.loading = true;

           this.master.sendPost('deleteSale', { saleId: this.saleId }, res => {
            this.loading = false

            if (res) {
              if (res['status'] == 200) {
                // * success
                // reload data
                this.ms.sendMessage("alert", { type: "success", text: res['data'].message });
                // close delete modal
                (document.getElementById('btn-close-modal-delete') as HTMLButtonElement).click();
              } else {
                // ! error
                this.ms.sendMessage("alert", { type: "danger", text: res['data'].error });
              }
            } else {
              // in case API no response
              this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
            }
          })

          return true
        } else {
          return false
        }

      // just view
      default:
        return true

    }




  }

}
