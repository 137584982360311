import { Component, Input, OnInit,EventEmitter,Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
// services
import { MasterService } from "../../../../services/master.service";
import { MessageService } from "../../../../services/message.service";
import { StoreService } from "../../../../services/store.service";
import * as languageLibrary from '../../../../services/language'
import * as moment from 'moment'

@Component({
  selector: 'add-cpi',
  templateUrl: './add-cpi.component.html',
  styleUrls: ['./add-cpi.component.scss']
})
export class AddCpiComponent implements OnInit {
  // ***************
  // variable
  // ***************
   //
   @Input() actives:boolean=false
  // form contract line
  public cpiDetail = new FormGroup({
    contactId: new FormControl('', [Validators.required]),
    effectiveDate: new FormControl('', [Validators.required]),
    schedulePmtAmt: new FormControl('', [Validators.required]),
    paymentCycleId: new FormControl('', [Validators.required]),
    firstScheduledPmtDue: new FormControl('', [Validators.required]),
    numberOfPmt: new FormControl('', [Validators.required]),

    policyNumber: new FormControl('', []),
    amountPremiumBasedOn: new FormControl('', []),
    defaultDeductible: new FormControl('', []),
    policyEndDate: new FormControl('', []),
    premiumPerPayment: new FormControl('', []),
    policyInactiveDate: new FormControl('', []),
  })
  //
  @Output() addCpi$:EventEmitter<Object>=new EventEmitter()
  //
  @Input() insurance = []
  //
  @Input() saleId: number = 0
  // define if is loading
  public loading: boolean = false
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  //
  public paymentsSchedule = []
  // ***************
  // functions
  // ***************
  /*
   todo: save payments
   */
  public savePaymentSchedule = (): void => {
    if (this.cpiDetail.valid) {
      this.loading = true
      this.master.sendPost("cpiDispatcher", {
        params: {
          saleTerms: {
            paymentCycleId: this.cpiDetail.value['paymentCycleId'],
            firstScheduledPmtDue: this.cpiDetail.value['firstScheduledPmtDue'],
            numberOfPmt: this.cpiDetail.value['numberOfPmt'],
            schedulePmtAmt:this.cpiDetail.value['schedulePmtAmt']
          },
          cpiDetail: {
            ...this.cpiDetail.value,
            corpId: this.store.userAccount['user'].corporateId,
            policyStatus: true,
            saleId:this.saleId,
            contactId:this.cpiDetail.value['contactId'],
            effectiveDate:this.cpiDetail.value['effectiveDate'],
            policyNumber:this.cpiDetail.value['policyNumber'],
            defaultDeductible:this.cpiDetail.value['defaultDeductible']==''?0:this.cpiDetail.value['defaultDeductible'],
            amountPremiumBasedOn:this.cpiDetail.value['amountPremiumBasedOn'],
            policyEndDate:this.cpiDetail.value['policyEndDate'],
            premiumPerPayment:this.cpiDetail.value['premiumPerPayment'],
            policyInactiveDate:this.cpiDetail.value['policyInactiveDate']
          },
          saleId: this.saleId,
          calendar: this.paymentsSchedule,
        },
        activity:'createCpi'
      }, (res) => {
        this.loading = false
        if (res) {
          if (res.status == 200) {
            this.cpiDetail.reset()
            this.paymentsSchedule=[]
            this.addCpi$.emit({message:'reloadCpi'})
          } else {
            // ! send message in case error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      });
    }
  };




  // reset form
  public reset=()=>{
    this.cpiDetail.reset()
    this.cpiDetail.get('effectiveDate').setValue(moment().format('yyyy-MM-DD'))
    this.cpiDetail.get('firstScheduledPmtDue').setValue(moment().format('yyyy-MM-DD'))
  }
  /*
   todo: save payments
   */
  public makeCalculation = (): void => {
    if (this.cpiDetail.valid) {
      this.loading = true
      this.master.sendPost("cpiDispatcher", {
        params: {
          ...this.cpiDetail.value,

        }, activity: 'makeCalculations'
      }, (res) => {
        this.loading = false
        if (res) {
          if (res.status == 200) {
            this.paymentsSchedule=res.data.calendar
            // this.cpiDetail.get('numberOfPmt').setValue(res.data.numberOfPmt)
            // this.cpiDetail.get('schedulePmtAmt').setValue(res.data.schedulePmtAmt.toFixed(2))
          } else {
            // ! send message in case error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      });
    }
  };
  // ***************
  // life cycles
  // ***************
  constructor(
    private master: MasterService, private ms: MessageService, private store: StoreService) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.cpiDetail.get('effectiveDate').setValue(moment().format('yyyy-MM-DD'))
    this.cpiDetail.get('firstScheduledPmtDue').setValue(moment().format('yyyy-MM-DD'))
  }

}
