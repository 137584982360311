import { Component, OnInit, Input, SimpleChanges } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import * as languageLibrary from '../../../../services/language'
// services
import { MessageService } from "../../../../services/message.service";
import { MasterService } from "../../../../services/master.service";
import { StoreService } from "../../../../services/store.service";
//
import { FormControl, FormGroup, Validators } from '@angular/forms'
//
import * as printJs from 'print-js'
import * as moment from 'moment'


@Component({
  selector: 'print-center',
  templateUrl: './print-center.component.html',
  styleUrls: ['./print-center.component.scss']
})
export class PrintCenterComponent implements OnInit {
  // ---------------
  // *  variables
  // --------------
  @Input() requestOrigination: any = {}
  public expanded: boolean = true;
  public showMergeDocumentSection: boolean = false
  //
  public hideNoCopies: boolean = false
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  // oder of table
  public order: Object = { isAscend: false, type: '' }
  // files
  public files = []
  // laoding status
  public loading: boolean = false
  //
  @Input() saleId: string = ''
  //
  public emails: string[] = []
  //
  public emailForm = new FormGroup({
    subject: new FormControl('', [Validators.required]),
    bodyText: new FormControl('', [Validators.required])
  })
  //
  public documents: Object[] = []
  //
  public documentSelected: Object[] = []
  //
  public imagesValid: string[] = ['jpeg', 'jpg', 'png', 'bmp', 'gif', 'eps', 'svg', 'heic', 'heif']
  //
  @Input() buyer: Object = {}
  //
  public reports: Object[] = []
  //
  @Input() saleType: string = ''
  //
  @Input() inventory: Object = {}
  // ---------------
  // *  functions
  // --------------
  // toggle copies
  public toggleCopies = () => {
    this.hideNoCopies = !this.hideNoCopies
  }
  // sort columns
  public sort = (type: string) => {
    // pending
    if (type == this.order['type']) {
      this.order['isAscend'] = !this.order['isAscend']
    } else {
      this.order['type'] = type
      this.order['isAscend'] = true
    }
    if (this.order['isAscend']) {
      // ascend
      this.reports = this.reports.sort((a, b) => a[type] > b[type] ? 1 : -1)
    } else {
      // descend
      this.reports = this.reports.sort((a, b) => a[type] > b[type] ? -1 : 1)
    }
  }
  public selectDocument = (document: Object, activity: string) => {
    switch (activity) {
      case 'print':

        printJs({ printable: document['fileURL'], type: this.imagesValid.includes(document['type']) ? 'image' : 'pdf', base64: false })
        break;
      case 'download':
        window.open(document['fileURL'])
        break;
      default:
        this.documentSelected = [{ id: document['id'], numberCopies: 1 }]
        break;
    }


  }
  public changeImage = (e): void => {
    this.loading = true
    let files = e.target.files

    // for each file
    for (let index = 0; index < files.length; index++) {
      let reader = new FileReader();
      reader.readAsDataURL(files[index]);
      let vm = this
      reader.onload = function (event) {

        vm.files.push({
          base64Complete: `${reader.result}`,
          base64: `${reader.result.slice(reader.result.toString().indexOf(',') + 1)}`,
          name: files[index].name,
          type: vm.findFormat(reader.result.toString())
        })
        if (files.length == index + 1) {
          vm.loading = false
        }
      };
    }
  }
  // find out the format type
  public findFormat = (base64: string): string => {

    if (base64.indexOf('pdf') >= 0) {
      return 'pdf'
    } else if (base64.indexOf('image') >= 0) {
      return 'image'
    } else {
      return 'undefined'
    }

  }
  // upload images
  public uploadFiles = async () => {
    this.loading = true
    let base64Arr = []
    for (let index = 0; index < this.files.length; index++) {

      base64Arr.push({ file: this.files[index]['base64Complete'], name: this.files[index]['name'] })
    }


    const res = await this.master.sendPostAsync('uploadFiles', {
      "saleId": this.saleId,
      "moduleType": "application/pdf",
      "type": "",
      "documentArr": base64Arr
    })

    this.loading = false
    if (res) {
      if (res['status'] == 200) {
        this.getAllDocuments()
        this.files = [];
        (document.getElementById('fileReader') as HTMLInputElement).value = '';

        this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
      } else {
        this.ms.sendMessage("alert", { type: "danger", text: res['data'].error });
      }
    }
  }
  // todo: add new email
  public addEmail = () => {
    const email_input = (document.getElementById('email_input') as HTMLInputElement)

    if (email_input.validity.valid) {
      this.emails.push(email_input.value);
      (document.getElementById('email_input_message') as HTMLSpanElement).innerText = '';
      (document.getElementById('email_input') as HTMLInputElement).value = ''
    } else {
      (document.getElementById('email_input_message') as HTMLSpanElement).innerText = email_input.validationMessage
    }
  }
  // todo: send email
  public sendEmails = async () => {

    const res = await this.master.sendPostAsync('sendMailAttachment', {
      emails: this.emails,
      saleId: this.saleId,
      emailFormat: [
        this.emailForm.value
      ],
      attachmentFile: this.documentSelected
    })

    if (res && res['status'] == 200) {
      this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
    } else {
      // ! send message in case error
      this.ms.sendMessage("alert", { type: "danger", text: res['data'].error });
    }
  }
  // todo: get documents attachment
  public getAllDocuments = async () => {

    const res = await this.master.sendPostAsync('getAllDocuments', {
      saleId: this.saleId
    })
    if (res && res['status'] == 200) {
      this.documents = res.data.uploads
    } else {
      // ! send message in case error
      this.ms.sendMessage("alert", { type: "danger", text: res['data'].error });
    }
  }
  // todo: get reports
  public getAllReports = async () => {

    const res = await this.master.sendPostAsync('documents', {
      activity: 'read'
    })
    if (res && res['status'] == 200) {
      const salesTypes = {
        "1": 'cash',
        "2": 'wholeSale',
        "3": 'outSide',
        "4": 'bhph'
      }

      this.reports = res.data.filter(el => el[salesTypes[this.saleType]] == true)
      this.reports.forEach(el => {
        el['copies'] = 0
      })

    } else {
      // ! send message in case error
      this.ms.sendMessage("alert", { type: "danger", text: res['data'].error });
    }
  }
  public calculate = async (request) => {

    // params
    this.loading = true
    //
    let res = await this.master.getCarleton(request)
    this.loading = false
    if (res) {
      if (res['data'][0].ErrorCode == 0) {
        // * success

        return res['data']

      } else {
        // ! send message in case error
        this.ms.sendMessage("alert", { type: "danger", text: res['data'][0].ErrorMessage });
      }
    } else {
      // in case API no response
      this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
    }
  }
  // todo: get reports
  public generateReport = async (document: Object) => {
    this.loading = true
    let response = await this.calculate(this.requestOrigination)

    const res = await this.master.sendPostAsync('documents', {
      activity: 'createXml',
      "params": {
        saleId: this.saleId,
        response: response[0],
        request: this.requestOrigination
      }
    })
    if (res && res['status'] == 200) {

      console.info('route:', this.master.hostApi + res.data);
      this.master.getDataFromURL(this.master.hostApi + res.data, document['documentName'] + '.xml', res => {
        this.loading = false
        if (!res.status) {
          this.ms.sendMessage("alert", { type: "danger", text: res['error'] });
        }
      })


    } else {
      // ! send message in case error
      this.loading = false
      this.ms.sendMessage("alert", { type: "danger", text: res['data'].error });
    }
  }
  // ---------------
  // *  life cycles
  // --------------
  constructor(
    private ms: MessageService,
    private master: MasterService,
    private store: StoreService,
  ) {


  }
  ngOnInit() {
    this.getAllDocuments()
    this.getAllReports()
  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.buyer && changes.buyer.currentValue['contact']) {
      if (changes.buyer.currentValue.contact.contactCommunications.length > 0) {
        this.emails = []
        let email = changes.buyer.currentValue.contact.contactCommunications.find(el => el.primary == 1 && el.type == 'email')
        if (email) {
          this.emails.push(email.value)
        }
      }
    }

    if (this.buyer['contact'] && this.inventory['inventoryId']) {

      // const contact = changes.buyer.currentValue['contact']
      const lotName = this.store.userAccount['user'].lots.find(el => el['id'] == this.store.lotSelected)

      this.emailForm.setValue({
        subject: `Sale Documents – ${this.buyer['contact']['lastName']}, ${this.buyer['contact']['firstName']} – ${this.buyer['saleId']} – ${this.inventory['stockNumber']}`,
        bodyText: `Dear ${this.buyer['contact']['lastName']}, ${this.buyer['contact']['firstName']}:
          The attached documents govern the sale of the ${this.inventory['data']['modelYear']} ${this.inventory['data']['exteriorColor']} ${this.inventory['data']['make']} ${this.inventory['data']['model']} that you purchased from ${lotName['name']} on ${moment(this.inventory['data']['datePurchased']).format('ll')}.
          The vehicle VIN is${this.inventory['data'].vinNumber}, and the sale stock number is ${this.inventory['stockNumber']}.
          Thank you for your business.`
      })

    }
    // sale type
    if(changes.saleType){
      if (!changes.saleType.firstChange) {
        this.getAllReports()
      }
    }
  }
}
