import { Component, OnInit, Input,Output,EventEmitter,SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// services
import { MasterService } from '../../../../../services/master.service'
import { MessageService } from '../../../../../services/message.service'
@Component({
  selector: 'income',
  templateUrl: './income.component.html',
  styleUrls: ['./income.component.scss']
})
export class IncomeComponent implements OnInit {
  /*
  * variables
  */
  @Output() incomes$: EventEmitter<Object> = new EventEmitter()
  // save the language
  @Input() language: string =  'EN'
  // set all words
  @Input() words = {}
  //  define if loading state
  public loading: boolean = false
  // define the selected income
  public incomeSelected: Object = { object: {}, index: 0 }
  // ontact object
  @Input() contact: Object = {}
  // save all communications information
  @Input() incomeList: Object[] = []
  // permissions
  @Input() permissions: string[] = []
  // save sumatory values
  public sumatory: number = 0
  // save all communications type
  public types: string[] = ['rental homes', 'investments', 'job']
  // Group of inputs
  public information = new FormGroup({})
  // define if number is avaible
  public viewNumber: boolean = false
  //  define if some changes not saved
  public savePending: boolean = false
  // update
  public informationUpdate = new FormGroup({
    contactId: new FormControl(0, [Validators.required]),
    type: new FormControl('', [Validators.required]),
    value: new FormControl('', [Validators.required, Validators.pattern('[0-9.]{1,50}')])
  })
  // array form control
  public newsIncomes: Object[] = [
    {
      contactId: '',
      type: '',
      value: ''
    }
  ]
  // get information of inputs
  get value() { return this.informationUpdate.get('value') }

  /*
* functions
*/
  // todo: detect changes
  changesPending = () => {
    if (!this.savePending) {
      this.savePending = true
      this.incomes$.emit({ message: 'changesPendingContacts', section: 'incomes', value: this.savePending })
    }
  }

  // save changes
  public save = (): void => {
    this.newsIncomes.pop()
    this.newsIncomes.map(el => el['contactId'] = this.contact['id'])
    this.loading = true
    this.master.sendPost('createContactIncome', { incomes: this.newsIncomes }, res => {
      this.reloadFormules()
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.savePending = false
          this.incomes$.emit({ message: 'changesPendingContacts', section: 'incomes', value: this.savePending })
          // recharge user data
          this.incomes$.emit({ message: 'reloadContactDetail' });
          // close modal
          (document.getElementById('btn-cancel-income') as HTMLButtonElement).click();
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // clean Fields
  public cleanFields = (): void => {
    this.information.reset()
    this.information.setValue({
      contactId: this.contact['id'],
      type: '',
      value: ''
    })
  }
  /*
    todo: update
    */
  // update
  public update = (): void => {
    if (this.informationUpdate.valid) {
      this.loading = true
      let objectChanged = this.informationUpdate.value
      objectChanged['incomeId'] = this.incomeSelected['object']['id']

      this.master.sendPost('updateContactIncome', objectChanged, res => {
        // set loading state
        this.loading = false
        if (res) {
          if (res.status == 200) {
            // * success
            // send message
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
            // close modal
            (document.getElementById('btn-close-income') as HTMLButtonElement).click();
            // recharge user data
            this.incomeList[this.incomeSelected['index']]['value'] = this.informationUpdate.value['value']
            this.incomeList[this.incomeSelected['index']]['type'] = this.informationUpdate.value['type']
            // recarge the sumatory
            this.sumatory = this.totalSumaty()
            // clean the fields
            this.cleanFields()
          } else {
            // ! send message in case error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });

          }
        }
      })
    }

  }
  // select income update
  public selectIncomeUpdate = (income: Object, index): void => {
    this.incomeSelected['object'] = income
    this.incomeSelected['index'] = index
    this.informationUpdate.setValue({
      contactId: this.contact['id'],
      type: income['type'],
      value: income['value'],
    })

  }
  /*
  ! delete
  */
  // select address
  public selectIncomes = (income: Object, index: number): void => {
    this.incomeSelected['object'] = income
    this.incomeSelected['index'] = index
  }
  // delete address
  public delete = (): void => {
    this.loading = true

    this.master.sendPost('deleteEntity', { polimorphEntity: "income", polimorphId: this.incomeSelected['object'].id }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          (document.getElementById('close-delete-modal-income') as HTMLButtonElement).click();
          this.incomeList.splice(this.incomeSelected['index'], 1)
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          // recarge the sumatory
          this.sumatory = this.totalSumaty()
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });

        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });

      }
    })
  }
  // change the income-type
  changeType = (e): void => {
    this.changesPending()
    this.information.get('type').setValue(e.target.value, {
      onlySelf: true
    })
  }
  // sumatory of all income sources
  public totalSumaty = (): number => {
    let newLIst = this.incomeList.map(el => parseFloat((el['value'])))
    let sumatory = newLIst.reduce((total, value) => parseFloat((value + total).toFixed(2)), 0)
    return sumatory
  }

  // ? create a new communication
  // create a form
  public addIncome = (e, index: number, newId: number): void => {
    if (this.information.invalid && this.newsIncomes.length - 1 != index) {
      this.information.removeControl('type_' + (this.newsIncomes.length - 1))
      this.information.removeControl('value_' + (this.newsIncomes.length - 1))
      this.newsIncomes.pop()
    }

    if (index == this.newsIncomes.length - 1 && this.information.valid) {
      this.newsIncomes.push({ contactId: this.contact['id'], type: '', value: '' })
      this.information.addControl('type_' + newId, new FormControl('', [Validators.required]));
      this.information.addControl('value_' + newId, new FormControl('', [Validators.required, Validators.pattern('[0-9.]{1,50}')]));

    }
  }

  // reload formules
  public reloadFormules = (): void => {

    for (let index = 0; index < this.newsIncomes.length; index++) {
      this.information.removeControl('type_' + index)
      this.information.removeControl('value_' + index)
    }
    this.information.removeControl('type_' + (this.newsIncomes.length))
    this.information.removeControl('value_' + (this.newsIncomes.length))

    this.information.addControl('type_0', new FormControl('', [Validators.required]));
    this.information.addControl('value_0', new FormControl('', [Validators.required, Validators.pattern('[0-9.]{1,50}')]));
    //
    this.newsIncomes = []
    this.newsIncomes.push({ contactId: this.contact['id'], type: '', value: '' })

  }

  constructor(private master: MasterService, private ms: MessageService) {
    // add controlers
    this.information.addControl('type_0', new FormControl('', [Validators.required]));
    this.information.addControl('value_0', new FormControl('', [Validators.required, Validators.pattern('[0-9.]{1,50}')]));

  }

  ngOnInit() {

    this.incomeList = this.contact['incomes']
    this.sumatory = this.totalSumaty()

  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.

    if(changes.incomeList && changes.incomeList.currentValue!=undefined){

      this.incomeList = changes.incomeList.currentValue
      this.sumatory = this.totalSumaty()
    }
  }

}
