import { Component, OnInit, Input } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
// services
import { MessageService } from "../../../../services/message.service";
import { MasterService } from "../../../../services/master.service";
//
// import { NgxXml2jsonService } from "ngx-xml2json";

@Component({
  selector: "inventory-recalls",
  templateUrl: "./inventory-recalls.component.html",
  styleUrls: ["./inventory-recalls.component.scss"],
})
export class InventoryRecallsComponent implements OnInit {

  public notifyUser () {
    this.ms.sendMessage("alert", { type: "success", text: "The VIN number is already on your clipboard. Just paste the VIN." });
  }
  constructor(
    // private ngxXml2jsonService: NgxXml2jsonService,
    private route: ActivatedRoute,
    private ms: MessageService,
    private master: MasterService
  ) {}

  ngOnInit() {
  }

}
