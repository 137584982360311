import { Component, OnInit, Input,Output,EventEmitter, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// services
import { MessageService } from '../../../../services/message.service'
import { MasterService } from '../../../../services/master.service'
import * as languageLibrary from '../../../../services/language'
// extarnal
import * as moment from 'moment/moment'
@Component({
  selector: 'inventory-floor-plans',
  templateUrl: './inventory-floor-plans.component.html',
  styleUrls: ['./inventory-floor-plans.component.scss']
})
export class InventoryFloorPlansComponent implements OnInit {
  /*
    * Variables
    */
  @Output() floorPlans$: EventEmitter<Object> = new EventEmitter()
  //  define if some changes not saved
  public savePending: boolean = false
  // save the language
  @Input() language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  //
  public cloneFrom = {}
  // contact object
  @Input() details: Object[] = []
  // contact object
  public data: Object[] = []
  // data general
  @Input() dataGeneral: Object[] = []
  // define the balance
  @Input() balance: number = 0
  // contact object
  public dataCopy = []
  // permissions
  @Input() permissions: string[] = []
  // define  if some row is pay off status
  public lastPayOff: boolean = false
  // define expanded
  public expanded: boolean = true
  // table headers
  public headers: Object[] = [
    { name: 'FP Name', param: 'fpName' },
    { name: 'Reduction Amount', param: 'reductionAmount' },
    { name: 'Status Date', param: 'statusDate' },
    { name: 'Current Principle', param: 'currentPriciple' },
    { name: 'Cut Off Date', param: 'cutOffDate' },
    { name: 'Finance Charges', param: 'financeCharges' },
    { name: 'One Time Fee', param: 'oneTimeFee' },
    { name: 'Additional Fee', param: 'recurrentFeeAmount' },
    { name: 'Balance', param: 'balance' },
    { name: 'Status', param: 'status' }
  ]
  // define the name edited
  public indexSelected: number = -1
  // criterias array
  public criterias: string[] = ['Flat Rate', 'Percent']
  // frecuencys
  public frecuencys: string[] = ['Monthly', 'Weekly', 'Bi-Weekly', 'Semi-Monthly']
  // define the lenders
  @Input() lenders: Object[] = []
  // define the daysOnFloorPlan
  @Input() daysOnFloorPlan: string = ''
  // define the daysRemaining
  @Input() daysRemaining: string = ''
  // define the vehicleId
  @Input() vehicleId: string = ''
  // define the rows ordered
  public order = { isAscend: true, type: '' }
  // save the pagination settings
  public pagination = { index: 0, rowsByPage: 10, numFloorPlan: 0, pages: [{ numPage: 0, start: 0, end: 8 }] }
  // define if is loading
  public loading: boolean = false
  // define the totals
  public totals = {
    principleBalance: 0,
    financeCharges: 0,
    oneTimeFee: 0,
    additionalFees: 0,
    totalBalance: 0,
    totalFinance: 0
  }
  // form
  public information = new FormGroup({
    floorPlanSetupId: new FormControl('', []),
    contactId: new FormControl('', []),
    daysAllowed: new FormControl('', []),
    daysPerYear: new FormControl('', []),
    companyName: new FormControl('', [Validators.required]),
    dateOnFloorPlan: new FormControl('', [Validators.required]),
    accountNumber: new FormControl('', []),
    initialAmount: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*(\.[0-9]+)?$')]),

    reductionCriteria: new FormControl('', [Validators.required]),
    reductionAmount: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*(\.[0-9]+)?$')]),
    reductionFrecuency: new FormControl('', [ Validators.pattern('[0-9]{1,5}')]),

    recurrentFeeCriteria: new FormControl('', []),
    recurrentFeeFrecuency: new FormControl('', []),
    recurrentFeeAmount: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*(\.[0-9]+)?$')]),

    oneTimeFeeAmount: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*(\.[0-9]+)?$')]),
    oneTimeFeeCriteria: new FormControl('', [Validators.required]),

    apr: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*(\.[0-9]+)?$')]),
  })
  public informationUpdate = new FormGroup({
    entityId: new FormControl('', []),
    vehicleId: new FormControl('', []),
    reductionAmount: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*(\.[0-9]+)?$')]),
    statusDate: new FormControl('', [Validators.required]),
    financeCharges: new FormControl('', [Validators.required]),
    floorPlanId: new FormControl('', [Validators.required]),
    recurrentFeeAmount: new FormControl('', [Validators.required]),
  })
  public newReduction = new FormGroup({
    reductionAmount: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*(\.[0-9]+)?$')]),
    statusDate: new FormControl('', [Validators.required]),
    recurrentFeeAmount: new FormControl(0, [Validators.required]),
  })
  get companyName() { return this.information.get('companyName') }
  get accountNumber() { return this.information.get('accountNumber') }
  get dateOnFloorPlan() { return this.information.get('dateOnFloorPlan') }
  get initialAmount() { return this.information.get('initialAmount') }
  get reductionCriteria() { return this.information.get('reductionCriteria') }
  get reductionAmount() { return this.information.get('reductionAmount') }
  get daysAllowed() { return this.information.get('daysAllowed') }
  // get recurrentFeeCriteria() { return this.information.get('recurrentFeeCriteria') }
  get recurrentFeeFrecuency() { return this.information.get('recurrentFeeFrecuency') }
  get recurrentFeeAmount() { return this.information.get('recurrentFeeAmount') }
  get oneTimeFeeAmount() { return this.information.get('oneTimeFeeAmount') }
  get oneTimeFeeCriteria() { return this.information.get('oneTimeFeeCriteria') }
  get apr() { return this.information.get('apr') }
  // reduction form
  get newReductionAmount() { return this.newReduction.get('reductionAmount') }
  get statusDate() { return this.newReduction.get('statusDate') }
  /*
 * Functions
 */
  /*
    ? save and update
    */
  public groupFloorPlans = []
  public breakFloorPlan = (totalFloorPlans: Object[], index: number): any[] => {
    this.groupFloorPlans = []
    for (let index = 0; index < totalFloorPlans.length; index++) {
      // new floor plan
      if (totalFloorPlans[index]['status'] == 'Initial') {
        this.groupFloorPlans.push({
          name: totalFloorPlans[index]['companyName'],
          date: moment(totalFloorPlans[index]['statusDate']).format('MM/DD/yyy'),
          cuteOfDate: moment(totalFloorPlans[index]['cutOffDate']).format('MM/DD/yyy'),
          status: totalFloorPlans[index]['status'] == 'Payoff' ? 'Inactive' : 'Active',
          floorPlanId: totalFloorPlans[index]['floorPlanId'],
          data: []
        })
        // set reductions of index
        if (totalFloorPlans[index]['floorPlanId'] == this.groupFloorPlans[this.groupFloorPlans.length - 1]['floorPlanId']) {
          this.groupFloorPlans[this.groupFloorPlans.length - 1].data = totalFloorPlans.filter(el => el['floorPlanId'] == this.groupFloorPlans[this.groupFloorPlans.length - 1]['floorPlanId'])
        }
      }
    }
    for (let index = 0; index < this.groupFloorPlans.length; index++) {
      this.groupFloorPlans[index].status = this.groupFloorPlans[index].data[this.groupFloorPlans[index].data.length - 1].status == 'Payoff' ? 'Inactive' : 'Active'
      this.groupFloorPlans[index]['cuteOfDate'] = moment(this.groupFloorPlans[index].data[this.groupFloorPlans[index].data.length - 1]['cutOffDate']).format('MM/DD/yyy')
    }
    return this.groupFloorPlans[index] != undefined ? this.groupFloorPlans[index].data : this.groupFloorPlans[index - 1].data
  }
  // get data of reaconditioning cost and floor plans
  public getData = (id: string): void => {

    this.master.sendPost('inventoryDetails', { vehicleId: id }, res => {

      if (res) {
        if (res.status == 200) {
          // * success
          this.dataGeneral = res.data.floorPlans
          this.newReduction.get('recurrentFeeAmount').setValue(this.dataGeneral['recurrentFeeAmount'])

          this.concatData()

          this.getNewAmount(this.dataGeneral['initialAmount'], this.dataGeneral['reductionCriteria'], this.dataGeneral['reductionAmount'])
          //
          this.daysOnFloorPlan = res.data['daysOnFloorPlan']
          this.daysRemaining = res.data['daysRemaining']

          this.lastPayOff = this.dataGeneral['floorPlansReductions'][this.dataGeneral['floorPlansReductions'].length - 1]['status'] == "Payoff" ? true : false

          this.ms.channelComponents$.emit({ message: 'changeFloorPlans', balance: this.totals.totalBalance })
          // this.newPagination(this.data)
        }
      } else {

        // in case API no response
        // this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // save data
  public save = (form: Object): void => {

    if (this.information.valid) {

      // this.information.get('daysAllowed').setValue(parseFloat(this.information.value['reductionFrecuency']) )
      form['daysAllowed'] = parseFloat(this.information.value['daysAllowed'])
      form['reductionFrecuency'] = form['daysAllowed']
      form['recurrentFeeFrecuency'] = ""
      this.loading = true
      this.master.sendPost('crudFloorPlans', { ...form, activity: 'create', vehicleId: this.vehicleId, entityId: form['id'] }, res => {
        this.loading = false
        if (res) {
          if (res.status == 200) {
            // * success
            this.getData(this.vehicleId)
            this.information.reset()
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
            this.floorPlans$.emit({ message: 'reloadInventoryDetails' })
            this.savePending = false
            this.floorPlans$.emit({ message: 'changesPendingInventory', section: 'floorPlans', value: false })
          } else {
            // ! in case error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })
    }
  }
  // get data from lenders
  public setData = (companyName): void => {
    this.changesPending(false)
    let lender = this.lenders.filter(el => el['companyName'] == companyName.value)
    this.information.reset()
    this.information.get('companyName').setValue(lender[0]['companyName'])
    if (lender[0]['floorPlanSetup'] != null) {
      this.information.setValue({
        floorPlanSetupId: lender[0]['floorPlanSetup'].id,
        daysAllowed:   lender[0]['floorPlanSetup'].daysAllowed,
        daysPerYear: lender[0]['floorPlanSetup'].daysPerYear,
        companyName: lender[0]['companyName'],
        contactId: lender[0]['id'],
        dateOnFloorPlan: this.details['inventory']['administrative']['datePurchased'] ? this.details['inventory']['administrative']['datePurchased'] : moment().format("YYYY-MM-DD"),
        accountNumber: lender[0]['floorPlanSetup'].accountNumber,
        initialAmount: this.details['inventory']['financials']['purchaseCosts'] ? this.details['inventory']['financials']['purchaseCosts'] : 0,

        reductionFrecuency: lender[0]['floorPlanSetup'].daysAllowed,
        reductionCriteria: lender[0]['floorPlanSetup'].reductionCriteria,
        reductionAmount: lender[0]['floorPlanSetup'].reductionAmount,
        recurrentFeeFrecuency: lender[0]['floorPlanSetup'].recurrentFeeFrecuency,

        recurrentFeeCriteria: 'Flat Rate',
        recurrentFeeAmount: lender[0]['floorPlanSetup'].recurrentFeeAmount,

        oneTimeFeeAmount: lender[0]['floorPlanSetup'].oneTimeFeeAmount,
        oneTimeFeeCriteria: lender[0]['floorPlanSetup'].oneTimeFeeCriteria,

        apr: lender[0]['floorPlanSetup'].apr,
      })
    } else {
      this.information.get('contactId').setValue(lender[0]['id'])
      this.information.get('daysPerYear').setValue(365)
      this.information.get('floorPlanSetupId').setValue(null)
      this.information.get('dateOnFloorPlan').setValue(this.details['inventory']['administrative']['datePurchased'] ? this.details['inventory']['administrative']['datePurchased'] : moment().format("DD-MM-YYYY"),)
      this.information.get('initialAmount').setValue(this.details['inventory']['financials']['purchaseCosts'] ? this.details['inventory']['financials']['purchaseCosts'] : 0,)
    }

  }
  /*
 ? calc new amount
 */
  public getNewAmount = (initialAmount: number, criteria: string, reduction: number): void => {
    let value = criteria == 'Flat Rate' ? reduction : (reduction / 100) * initialAmount
    this.newReduction.get('statusDate').setValue(moment().format('YYYY-MM-DD'))
    this.newReduction.get('reductionAmount').setValue(value)

  }
  /*
 ? delete
 */
  public delete = () => {
    this.loading = true
    this.indexSelected == this.data.length

    this.master.sendPost('crudFloorPlans', { activity: 'delete', vehicleId: this.vehicleId, entityId: this.informationUpdate.value['entityId'], floorPlanId: this.informationUpdate.value['floorPlanId'] }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success

          // close modal
          (document.getElementById('close-delete-modal-floor-plans') as HTMLButtonElement).click();
          this.indexSelected = -1
          this.getData(this.vehicleId)

          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          this.floorPlans$.emit({ message: 'reloadInventoryDetails' })
        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  /*
   ? pay off
   */
  public payOff = () => {
    this.loading=true
    this.master.sendPost('floorPlansPayoff', { floorPlanId: this.dataGeneral['id'], toPayOff: this.totals.totalBalance }, res => {
      this.loading=false
      if (res) {
        if (res.status == 200) {
          // * success
          this.floorPlans$.emit({ message: 'changesPendingInventory', section: 'floorPlanReduction', value: false })
          this.getData(this.vehicleId)
          this.indexSelected = -1
        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }

  /*
    ? select and object
    */
  public selectObject = (row: Object, index: number) => {
    this.indexSelected = index
    let data = row['statusDate'].split('T')
    row['statusDate'] = data[0]

    this.informationUpdate.setValue({
      entityId: row['id'],
      vehicleId: this.vehicleId,
      reductionAmount: row['reductionAmount'],
      statusDate: row['statusDate'],
      financeCharges: row['financeCharges'],
      floorPlanId: row['floorPlanId'],
      recurrentFeeAmount: row['recurrentFeeAmount'],
    })
    this.cloneFrom = { ...this.informationUpdate.value }
  }
  // recovery
  public recovery = (object: Object, i: number): void => {

    this.selectObject(object, i)
    this.informationUpdate.get('financeCharges').setValue(null)
    this.preUpdate()
    // this.informationUpdate.get('financeCharges').setValue(this.dataCopy[index]['financeCharges'])
    // this.update()

  }
  /*
    todo: update row
    */
  public saveEnter = (e) => {
    if (e.key == 'Enter') {
      this.indexSelected = -1
      this.preUpdate()
    }
  }
  public preUpdate = () => {

    let keyChanges = []
    // todo: reductionAmount changed
    if (this.cloneFrom['reductionAmount'] != this.informationUpdate.value['reductionAmount']) {
      keyChanges.push({ reductionAmount: parseFloat(this.informationUpdate.value['reductionAmount']) })
    }
    // todo: the status date changed
    if (this.cloneFrom['statusDate'] != this.informationUpdate.value['statusDate']) {
      //
      let selected = moment(this.informationUpdate.value['statusDate'])
      // check if exist register before and is valid
      if (this.indexSelected > 0) {
        let before = moment(this.data[this.indexSelected - 1]['statusDate'])
        if (selected.diff(before, 'days') < 0) {
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['invalidDate'] });
          return
        }
      }
      // check if exist register after and is valid
      if (this.indexSelected < this.data.length - 1) {
        let after = moment(this.data[this.indexSelected + 1]['statusDate'])
        if (selected.diff(after, 'days') > 0) {
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['invalidDate'] });
          return
        }
      }
      //
      keyChanges.push({ statusDate: this.informationUpdate.value['statusDate'] })
    }

    // todo: the finance charges changed
    if (this.cloneFrom['financeCharges'] != this.informationUpdate.value['financeCharges']) {
      keyChanges.push({ financeCharges: parseFloat(this.informationUpdate.value['financeCharges']) })
    }
    // todo: the recurrent fee changed
    if (this.cloneFrom['recurrentFeeAmount'] != this.informationUpdate.value['recurrentFeeAmount']) {
      keyChanges.push({ recurrentFeeAmount: parseFloat(this.informationUpdate.value['recurrentFeeAmount']) })
    }

    // chek if the new amount is bigger as balance
    if (this.indexSelected > 0 && parseFloat(this.informationUpdate.value['reductionAmount']) >= this.data[this.indexSelected - 1]['balance']) {
      (document.getElementById('btn-modal-payOff') as HTMLButtonElement).click()
    } else {
      // set all fileds
      keyChanges.forEach(el => {
        this.update(el)
      });
    }
  }

  public update = (params: object): void => {
    this.loading = true

    let ids = {
      entityId: this.informationUpdate.value['entityId'],
      floorPlanId: this.informationUpdate.value['floorPlanId'],
      vehicleId: this.vehicleId,
    }

    // execute end-point
    this.master.sendPost('crudFloorPlans', { activity: 'update', ...params, ...ids }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.getData(this.vehicleId)

          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          this.floorPlans$.emit({ message: 'reloadInventoryDetails' })
          this.indexSelected = -1
        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  /*
 todo: pagination and rows per view functions
 */

  // change num page view
  public changePage = (page: number): void => {
    if (page == -1) {
      // back
      if (this.pagination.index >= 1) {
        this.pagination.index = this.pagination.index - 1
      }
    } else {
      // next
      if (this.pagination.index < this.pagination.pages.length - 1) {
        this.pagination.index = this.pagination.index + 1
      }
    }
  }
  // create a pagination
  public newPagination = (registers: Object[]): void => {
    this.pagination.pages = []
    this.pagination.index = 0
    // let registrosPerPage=2
    if (registers.length > 0) {

      // if (![10, 20, 50].includes(this.pagination.rowsByPage)) {
      // }
      this.pagination.rowsByPage = registers.length

      for (let index = 0; index < (registers.length / this.pagination.rowsByPage); index++) {
        this.pagination.pages.push({ numPage: index, start: this.pagination.rowsByPage * index, end: (this.pagination.rowsByPage * (index + 1)) })
      }
    }


  }
  // change the rows per page
  public changeRows = (value): void => {
    this.pagination.index = 0
    this.pagination.rowsByPage = parseInt(value)

    this.data = this.breakFloorPlan(this.dataGeneral['floorPlansReductions'], this.pagination.numFloorPlan)
    this.newPagination(this.data)
  }
  /*
todo: Add new reduction amount
*/
  public addNewReduction = () => {

    if (this.newReduction.valid) {
      // todo new decuction minor of balance

      if (parseFloat(this.newReduction.value['reductionAmount']) >= this.totals.totalBalance) {
        (document.getElementById('btn-modal-payOff') as HTMLButtonElement).click()
      } else {
        //
        this.loading = true
        // validations
        this.master.sendPost('floorPlansReduction', { ...this.newReduction.value, floorPlanId: this.dataGeneral['id'] }, res => {
          this.loading = false
          if (res) {
            if (res.status == 200) {
              // * success
              (document.getElementById('close-payOff') as HTMLButtonElement).click()

              this.getData(this.vehicleId)
              this.newReduction.get('recurrentFeeAmount').setValue(this.dataGeneral['recurrentFeeAmount'])
              // this.newReduction.reset()
              this.getNewAmount(this.dataGeneral['initialAmount'], this.dataGeneral['reductionCriteria'], this.dataGeneral['reductionAmount'])
              this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
              this.savePending = false;

              this.floorPlans$.emit({ message: 'changesPendingInventory', section: 'floorPlanReduction', value: this.savePending })
            } else {
              // ! in case error
              this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
            }
          } else {
            // in case API no response
            this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
          }
        })
      }
    }
  }
  // get the sumatory values
  public concatData = () => {

    let floorPlanActive = []
    this.data = []
    // create data
    this.dataGeneral['floorPlansReductions'].forEach(el => {
      el['companyName'] = this.dataGeneral['companyName']
      el['isOpen'] = el['floorPlanId'] == this.dataGeneral['id'] ? true : false
    })
    // set totals kpi
    floorPlanActive = this.dataGeneral['floorPlansReductions'].filter(el => el.isOpen == true)
    this.totals.financeCharges = floorPlanActive.reduce((a, b) => parseFloat(b['financeCharges']) + a, 0)
    this.totals.oneTimeFee = floorPlanActive.reduce((total, el) => parseFloat(el['oneTimeFeeAmount']) + total, 0)
    this.totals.additionalFees = floorPlanActive.reduce((total, el) => parseFloat(el['recurrentFeeAmount']) + total, 0)
    this.totals.totalBalance = floorPlanActive[floorPlanActive.length - 1]['balance']
    this.totals.principleBalance = floorPlanActive[floorPlanActive.length - 1]['currentPrinciple']
    // set total finance charges + one time fee + aditional fee all floor plan
    let financeCharges = this.dataGeneral['floorPlansReductions'].reduce((a, b) => parseFloat(b['financeCharges']) + a, 0)
    let oneTimeFeeAmount = this.dataGeneral['floorPlansReductions'].reduce((a, b) => parseFloat(b['oneTimeFeeAmount']) + a, 0)
    let recurrentFeeAmount = this.dataGeneral['floorPlansReductions'].reduce((a, b) => parseFloat(b['recurrentFeeAmount']) + a, 0)
    this.totals.totalFinance = financeCharges + oneTimeFeeAmount + recurrentFeeAmount

    this.ms.channelComponents$.emit({ message: 'changeFloorPlans', balance: this.totals.totalBalance })
    // pagination and copy data
    this.data = this.breakFloorPlan(this.dataGeneral['floorPlansReductions'], this.pagination.numFloorPlan)
    this.pagination.numFloorPlan = this.groupFloorPlans.length - 1
    // this.newPagination(this.data)
    this.changeRows(this.pagination.numFloorPlan)

    this.lastPayOff = this.dataGeneral['floorPlansReductions'][this.dataGeneral['floorPlansReductions'].length - 1]['status'] == "Payoff" ? true : false
    // this.newPagination(this.data)
    this.dataCopy = this.data.slice()
  }
  // todo: detect changes
  public changesPending = (reduction: boolean) => {

    if (!this.savePending) {
      this.savePending = true
      this.floorPlans$.emit({ message: 'changesPendingInventory', section: reduction ? 'floorPlanReduction' : 'floorPlans', value: this.savePending })
    }
  }
  /*
 * life cycles
 */
  constructor(private master: MasterService, private ms: MessageService) {

  }

  ngOnInit() {

    // set data
    if (this.dataGeneral['floorPlansReductions'] && this.dataGeneral['floorPlansReductions'].length > 0) {

      this.newReduction.get('recurrentFeeAmount').setValue(this.dataGeneral['recurrentFeeAmount'])
      this.getNewAmount(this.dataGeneral['initialAmount'], this.dataGeneral['reductionCriteria'], this.dataGeneral['reductionAmount'])
    }


  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if(changes.dataGeneral){
      this.concatData()
    }
    if(changes.balance){

    }
  }



}
