import { Component, OnInit,Input, SimpleChanges  } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as moment from 'moment';
import * as languageLibrary from '../../../../services/language'
import { MasterService } from "../../../../services/master.service";
import { MessageService } from "../../../../services/message.service";
@Component({
  selector: 'inactive-cpi',
  templateUrl: './inactive-cpi.component.html',
  styleUrls: ['./inactive-cpi.component.scss']
})
export class InactiveCpiComponent implements OnInit {
 // ***************
  // variable
  // ***************
  //
  @Input() data = {}
  // form contract line
  public cpiDetail = new FormGroup({
    contactId: new FormControl('', [Validators.required]),
    effectiveDate: new FormControl('', [Validators.required]),
    policyNumber: new FormControl('', [Validators.required]),
    amountPremiumBasedOn: new FormControl('', [Validators.required]),
    defaultDeductible: new FormControl('', [Validators.required]),
    policyEndDate: new FormControl('', [Validators.required]),
    premiumPerPayment: new FormControl('', [Validators.required]),
    policyInactiveDate: new FormControl('', [Validators.required]),
    paymentCycleId: new FormControl('', [Validators.required]),
    firstScheduledPmtDue: new FormControl('', [Validators.required]),
    numberOfPmt: new FormControl('', [Validators.required]),
    schedulePmtAmt: new FormControl('', [Validators.required]),
  })
  // define if is loading
  public loading: boolean = false
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  //
  @Input() insurance = []
  // ***************
  // functions
  // ***************
// todo: get after sales
public getCpiDetail = async (id) => {
  const res= await this.master.sendPostAsync("cpiDispatcher", {
    params: {
      id: id
    }, activity: 'getCpiDetail'
  });

  if (res) {
    if (res['status'] == 200) {

      this.data['paymentsExpecteds']=res.data.paymentExpectds
      // this.data = res['data'].result
    } else {
      this.ms.sendMessage("alert", { type: "danger", text: res['data'].error });
    }
  } else {
    this.ms.sendMessage("alert", {
      type: "danger",
      text: this.words[this.language]['apiNoResponse'],
    })
  }
};
  // ***************
  // life cycles
  // ***************
  constructor(private master: MasterService, private ms: MessageService) { }

  ngOnInit() {
    this.getCpiDetail(this.data['id'])
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.data) {
      this.cpiDetail.setValue({
        contactId: changes.data['currentValue']['contactId'],
        effectiveDate: changes.data['currentValue']['effectiveDate'],
        policyNumber: changes.data['currentValue']['policyNumber'],
        amountPremiumBasedOn: changes.data['currentValue']['amountPremiumBasedOn'],
        defaultDeductible: changes.data['currentValue']['defaultDeductible'],
        policyEndDate: changes.data['currentValue']['policyEndDate'],
        premiumPerPayment: changes.data['currentValue']['premiumPerPayment'],
        policyInactiveDate: changes.data['currentValue']['policyInactiveDate'],
        paymentCycleId:  changes.data['currentValue']['saleTerms'][0].paymentCycleId,
        firstScheduledPmtDue: moment(changes.data['currentValue']['saleTerms'][0].firstScheduledPmtDue).format('yyyy-MM-DD'),
        numberOfPmt: changes.data['currentValue']['saleTerms'][0].numberOfPmt==true?1:changes.data['currentValue']['saleTerms'][0].numberOfPmt,
        schedulePmtAmt: changes.data['currentValue']['saleTerms'][0].schedulePmtAmt ,
      })
    }
  }

}
