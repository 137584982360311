import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MessageService } from "../../../../services/message.service";
import { MasterService } from "../../../../services/master.service";

@Component({
  selector: 'footer-payments',
  templateUrl: './footer-payments.component.html',
  styleUrls: ['./footer-payments.component.scss']
})
export class FooterPaymentsComponent implements OnInit {
  // **************************
  // ****** variables *********
  // **************************
  @Input() data:any = {}
  //
  @Input() header:Object = {}

  // save the language
  @Input() language: string = 'EN'
  // set all words
  @Input() words:Object = {}
  //
  public loading: boolean = false
  //
  // **************************
  // ****** functions *********
  // **************************
  // create new email
  public createEmail = (): void => {
    let email = (document.getElementById('emailInput') as HTMLInputElement)

    if (email.validity.valid) {
      this.loading = true

      this.master.sendPost('createContactCommunitaction', {
        communications: [{
          contactId: this.header['buyer'].contactId,
          type: 'email',
          value: email.value
        }]
      }, res => {
        this.loading = false
        if (res) {
          if (res.status == 200) {
            // * success
            if (this.data['emails'] == null) {
              this.data['emails'] = []
            }
            this.data['emails'].push(email.value);
            (document.getElementById('emailInput') as HTMLInputElement).value = null;
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          } else {
            // ! send message in case error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })
    }

  }
  // delete email
  public delete = (index: number) => {
    this.data['emails'].splice(index, 1)
  }
  // **************************
  // ****** life cycles *********
  // **************************
  constructor(
    private ms: MessageService,
    private master: MasterService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.data && changes.data.currentValue != undefined) {

      this.data['emails'] = this.data['allMails']
    }
  }

}
