import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'

// services
import { MessageService } from '../../../services/message.service'
import { MasterService } from '../../../services/master.service'
import { StoreService } from '../../../services/store.service'
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
@Component({
  selector: 'app-buyers-report',
  templateUrl: './buyers-report.component.html',
  styleUrls: ['./buyers-report.component.scss']
})
export class BuyersReportComponent implements OnInit {
  /*
  * variables
  */
  public entity: string = ''
  public entityId: string = ''
  public data: string = ''
  /*
  * Functions
  */

  public async createPdf() {
    const pdfDoc = await PDFDocument.create()
    const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)

    const page = pdfDoc.addPage()
    const { width, height } = page.getSize()
    const fontSize = 30
    page.drawText('IS THIS REAL!', {
      x: 50,
      y: height - 4 * fontSize,
      size: fontSize,
      font: timesRomanFont,
      color: rgb(0, 0.53, 0.71),
    })

    const pdfBytes = await pdfDoc.save()
    // Trigger the browser to download the PDF document
    // download(pdfBytes, "pdf-lib_creation_example.pdf", "application/pdf");
  }

  public async fillPdf() {
    const formUrl = '../../../../assets/reports/MaxGAP125.pdf'
    const formPdfBytes = await fetch(formUrl).then(res => res.arrayBuffer())

    const pdfDoc = await PDFDocument.load(formPdfBytes)
    const form = pdfDoc.getForm()
    const vehicleYear = form.getTextField('Year')
    const vehicleMake = form.getTextField('Make')
    const vehicleModel = form.getTextField('Model')
    vehicleYear.setText('2022')
    vehicleMake.setText('Honda')
    vehicleModel.setText('CR-V' )

    const pdfBytes = await pdfDoc.save()
    // Trigger the browser to download the PDF document
    // download(pdfBytes, "MaxGAP125.pdf", "application/pdf");
  }

  public getData = (entity: string, entityId: string): void => {
    this.master.sendPost('getBuyersGuide', { entity: entity, entityId: entityId }, res => {
      if (res) {
        if (res.status == 200) {
          // * success
          this.data = res.data.buyerGuide
        } else {
          // ! in case error 
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: "API no response" });
      }
    })
  }

  constructor(private store: StoreService, private route: ActivatedRoute, private ms: MessageService, private master: MasterService) {

    if (this.store.userAccount['permissions']) {
      // if not have permissions, return to account settings

      if (this.store.userAccount['permissions'][5]['childrens'][0].permissions.includes('view')) {
        this.entity = this.route.snapshot.params['entity']
        this.entityId = this.route.snapshot.params['entityId']

        this.getData(this.entity, this.entityId)
      } else {
        window.location.href = "#/profile"
      }
    }
    // listener
    this.ms.channelComponents$.subscribe(res => {

      if (res.message == 'setPermissions' && res.page && res.page.indexOf('reports') >= 0) {
        // permissions on user list
        if (res['permissions'][5]['childrens'][0].permissions.includes('view')) {
          this.entity = this.route.snapshot.params['entity']
          this.entityId = this.route.snapshot.params['entityId']
          this.getData(this.entity, this.entityId)
        } else {
          window.location.href = "#/profile"
        }
      }
    })
  }

  ngOnInit() {

  }

}
