import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output } from '@angular/core';
// services
import { MasterService } from '../../../../../../services/master.service'
import { StoreService } from '../../../../../../services/store.service'
import { MessageService } from '../../../../../../services/message.service'
import * as languageLibrary from '../../../../../../services/language'
import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as moment from 'moment'
@Component({
  selector: 'terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  // ======================
  // * variables
  // ======================
  @Input() inventoryTaxRate:number=0
  //
  @Input() isUnLock = true
  //
  @Input() saleType:number = 0
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  // define loading status
  public loading: boolean = false
  // define expandedTerms
  public expandedTerms: boolean = false;
  //
  public compareTerms: boolean = false;
  // array of lienholder
  @Input() lienholders = []
  //
  public lienholderSelecteds = []
  //
  public paymentTerm = {}
  // sale id
  @Input() saleId = ''
  // sale price
  @Input() totalSalePrice: number = 0
  //
  @Input() baseSalePrice: number = 0
  //
  @Input() driveoutPrice: number = 0
  //
  public lienholdersBackup = ''
  //
  @Input() inventory = ''
  //
  @Input() buyer = ''
  //
  public payments = []
  //
  public totalPayments = 0
  //
  public saleDate = ''
  //
  public showMore: boolean = false
  //
  public lots: any[] = []
  //
  public lotSelected: string = ''
  //
  public paymentsConfig: any = {}
  //
  public loanTerms = new FormGroup({
    paymentCycleId: new FormControl("", [Validators.required]),
    schedulePmtAmt: new FormControl("", [Validators.required]),
    APR: new FormControl("", [Validators.required]),
    lastPmtAmt: new FormControl("", [Validators.required]),
    numberOfPmt: new FormControl("", [Validators.required]),
    firstScheduledPmtDue: new FormControl("", [Validators.required]),
    months: new FormControl("", [Validators.required]),
    totalPaymentAmount: new FormControl("", [Validators.required]),
  });
  //
  public amortization: Object = {}
  //
  public loanTermsComparition = []
  //
  @Output() termsEmitter$: EventEmitter<Object> = new EventEmitter()
  //
  @Input() fees: Object[] = []
  // ======================
  // * Functions
  // ======================
  // ======================
  // ? read all (lienholder detail, payment table and loan card)
  // ======================
  public readAll = (reloadTerm: boolean) => {
    this.loading = true

    this.master.sendPost('CRUDForSaleLienholders', { saleId: this.saleId, activity: 'read' }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          // the first time check if the term was created
          if ( res.data.saleLienholders.length > 0) {
            this.lienholdersBackup = JSON.stringify(res.data.saleLienholders)
            this.lienholderSelecteds = res.data.saleLienholders

            if (reloadTerm) {
              // read the terms principal (lienholders details)

              this.readTerms()
              // read the card calculate and card comparison
              this.readLoanTerms()
            }
          } else {

            if(res.data.saleLienholders['length'] ){
              // else create the term and read again
              this.createTerm()
            }
          }
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // ======================
  // ? update an lionholders
  // ======================
  public saveAll = () => {
    this.lienholderSelecteds[0]['bankFee'] = parseFloat(this.lienholderSelecteds[0]['bankFee']).toFixed(2)
    this.lienholderSelecteds[0]['buyRate'] = parseFloat(this.lienholderSelecteds[0]['buyRate']).toFixed(2)
    this.lienholderSelecteds[0]['splitRate'] = parseFloat(this.lienholderSelecteds[0]['splitRate']).toFixed(2)


    this.loading = true

    this.master.sendPost('CRUDForSaleLienholders', { updatedLienholders: this.lienholderSelecteds, activity: 'update' }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.readAll(false)
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['successLienholders'] });
          // update the lienholders details
          this.updateTerms(this.paymentTerm)
          // loan terms
          this.createLoanTerms();
          // save payments table
          (document.getElementById('save-payment-table') as HTMLButtonElement).click()
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // ======================
  // todo: module lienholders details
  // ======================

  // ======================
  // ? select an lionholder
  // ======================
  public selectLienholder = (id: number, index) => {
    this.loading = true
    let repeats = this.lienholderSelecteds.filter(el => el['lienholderContactId'] == id)
    if (repeats.length == 2) {
      this.ms.sendMessage("alert", { type: "warning", text: 'Some values are repeat' });
      let backup = JSON.parse(this.lienholdersBackup)
      setTimeout(() => {
        this.lienholderSelecteds[index]['lienholderContactId'] = backup[index]['lienholderContactId']
        this.lienholderSelecteds[index]['address'] = backup[index]['address']
        return
      }, 10);
    } else {
      this.master.sendPost('getContactDetails', { contactId: id }, res => {
        this.loading = false
        if (res) {
          if (res.status == 200) {
            // * success
            let address = res.data.contact[0]['addresses'].filter(el => el['primaryAddress'] == 1)
            this.lienholderSelecteds[index]['address'] = address.length > 0 ? address[0] : null
            this.lienholderSelecteds[index]['address'] = `${this.lienholderSelecteds[index]['address']['address']}, ${this.lienholderSelecteds[index]['address']['city']}, ${this.lienholderSelecteds[index]['address']['state']} ${this.lienholderSelecteds[index]['address']['zipCode']}`
            this.lienholdersBackup = JSON.stringify(this.lienholderSelecteds)
          } else {
            // ! send message in case error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })
    }
  }
  // ======================
  // ? clear a lionholder
  // ======================
  public clear = (index) => {
    this.lienholderSelecteds[index]['lienholderContactId'] = null
    this.lienholderSelecteds[index]['address'] = null
    this.lienholdersBackup = JSON.stringify(this.lienholderSelecteds)
  }
  // ======================
  // ? set a lionholder to terms
  // ======================
  public createTerm = () => {
    this.loading = true

    this.master.sendPost('CRUDForSaleLienholders', { saleId: this.saleId, activity: 'create' }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success

          this.readAll(true)
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }

  // ======================
  // todo: module payment table
  // ======================
  public getPaymentTable = (e) => {

    if (e['message'] && e['message'] == 'reloadAll') {

      this.readAll(true)
    } else {
      this.expandedTerms = e.expandedTerms

      this.payments = e.payments.slice()
      this.paymentsConfig = e.paymentsConfig
      this.totalPayments = e.totalPayments

      // catalogs
      this.paymentsConfig['categories'] = e.categories
      this.paymentsConfig['statuslist'] = e.statuslist
    }



  }

  // ======================
  // todo: module loan cards
  // ======================



  // ======================
  // ? read all terms
  // ======================
  public readTerms = () => {
    this.loading = true

    this.master.sendPost('CRUDForSaleTerms', { saleId: this.saleId, activity: 'read' }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success

          this.paymentTerm = res.data
          this.lots = this.paymentTerm['lotsInCorp']
          this.expandedTerms = this.paymentTerm['saleTerms'].deferredSecondOp

          this.termsEmitter$.emit({ message: 'set request of calc origination plus', value: JSON.parse(res.data['saleTerms'].request) })
          // loan terms

          this.loanTerms.setValue({
            paymentCycleId: this.paymentTerm['saleTerms'].paymentCycleId,
            schedulePmtAmt: this.paymentTerm['saleTerms'].schedulePmtAmt,
            APR: this.paymentTerm['saleTerms'].APR,
            lastPmtAmt: this.paymentTerm['saleTerms'].lastPmtAmt,
            numberOfPmt: this.paymentTerm['saleTerms'].numberOfPmt,
            firstScheduledPmtDue: this.paymentTerm['saleTerms']['firstScheduledPmtDue'] ? moment(this.paymentTerm['saleTerms']['firstScheduledPmtDue']).add(1, 'day').format('yyyy-MM-DD') : moment().format('yyyy-MM-DD'),
            months: this.paymentTerm['saleTerms'].months,
            totalPaymentAmount: this.paymentTerm['saleTerms'].totalPaymentAmount
          })

          this.paymentTerm['saleTerms']['paymentcycle'] = this.paymentTerm['cyclesCatalog'].find(el => this.paymentTerm['saleTerms']['paymentCycleId'] == el['id'])

          this.paymentTerm['saleTerms']['firstScheduledPmtDue'] = this.paymentTerm['saleTerms']['firstScheduledPmtDue'] != null ?
            moment(this.paymentTerm['saleTerms']['firstScheduledPmtDue']).add(1, 'day').format('yyyy-MM-DD') : moment().format('yyyy-MM-DD')

          this.termsEmitter$.emit({
            subjectToFinance: this.paymentTerm['saleTerms']['amountoFinance'] || 0,
            totalPaymentAmount: this.paymentTerm['saleTerms']['totalPaymentAmount'] || 0,
            financeCharges: this.paymentTerm['saleTerms']['financeCharges'] || 0
          })
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // ======================
  // ? update  terms (right)
  // ======================
  public updateTerms = async (paymentTerm) => {

    let cycle = this.paymentTerm['cyclesCatalog'].find(el => this.paymentsConfig.value['PaymentCycle'] == el['name'])

    // check if the last deferred down is lower to first schedule payment due
    if (this.payments.some(el => (moment(el['dueDate']) > moment(this.loanTerms.value['firstScheduledPmtDue']) && el['amountDue'] > 0))) {
      this.ms.sendMessage("alert", { type: "warning", text: 'Invalid Date: The last deferred down should be lower to first schedule payment due' });
      return
    }


    let param = {
      ...paymentTerm['saleTerms'],
      ...this.loanTerms.value,
      paymentCategoryId: 2,
      saleLotId: parseInt(this.store.lotSelected),
      deferredSecondOp: this.expandedTerms,
      deferredPaymentCycleId: cycle ? cycle.id : null,
      deferredPaymentAmount: this.paymentsConfig.value['paymentAmount'] == '' ? null : this.paymentsConfig.value['paymentAmount'],
      deferredNumberOfPmts: this.paymentsConfig.value['numberPayments'] == '' ? null : this.paymentsConfig.value['numberPayments'],
      firstDeferredPmtDate: this.paymentsConfig.value['datePayment'] == '' ? null : this.paymentsConfig.value['datePayment'],

    }


    this.loading = true

    this.master.sendPost('CRUDForSaleTerms', { saleTerms: { ...param }, activity: 'update' }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success

          this.readTerms()
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['successTerms'] });
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          return
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })

  }



  // =======================
  // todo: carlton
  // ======================
  public getLoanTerms = (e): void => {

    this.loanTerms.setValue({
      paymentCycleId: e.value.paymentCycleId,
      schedulePmtAmt: e.value.scheduledPaymentAmount,
      APR: e.value.contractRate,
      lastPmtAmt: e.value.lastPaymentAmount,
      numberOfPmt: e.value.numberOfRegularPayments,
      firstScheduledPmtDue: e.value.firstScheduledPaymentDate,
      months: e.value.months,
      totalPaymentAmount: e.value.totalPaymentAmount
    })

    this.paymentTerm['saleTerms']['financeCharges'] = e.quotes ? e.quotes['FinanceCharge'] : 0
    this.paymentTerm['saleTerms']['amountoFinance'] = e.quotes ? e.quotes['AmountFinanced'] : 0
    this.paymentTerm['saleTerms']['totalPaymentAmount'] = e.quotes ? e.quotes['TotalOfPayments'] : 0
    this.paymentTerm['saleTerms']['response'] = e.response
    this.paymentTerm['saleTerms']['request'] = JSON.stringify(e.request)
    this.paymentTerm['saleTerms']['quotes'] = JSON.stringify(e.quotes)


  }

  // todo: create comparisons
  public createLoanTerms = () => {

    let creates = this.loanTermsComparition.filter(el => el['id'] == null)

    if (creates.length > 0) {

      this.master.sendPost('saleTermsComparisonDispatcher', { params: creates, activity: 'createComparison' }, res => {
        if (res) {
          if (res.status == 200) {
            // * success
            this.updateLoanTerms()
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          } else {
            // ! send message in case error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })
    } else {
      // todo: just update
      this.updateLoanTerms()
    }
  }
  // todo: update
  public updateLoanTerms = () => {

    let updates = this.loanTermsComparition.filter(el => el['id'] != null)

    if (updates.length > 0) {
      this.master.sendPost('saleTermsComparisonDispatcher', { params: updates, activity: 'updateComparison' }, res => {
        if (res) {
          if (res.status == 200) {
            // * success

            this.readLoanTerms()
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          } else {
            // ! send message in case error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })
    }
  }
  // todo: delete
  public deleteLoanTerms = (id, index) => {

    if (id == null) {

      this.loanTermsComparition.splice(this.loanTermsComparition.findIndex(el => (el['optionIndex'] - 1) == index), 1)

    } else {
      this.master.sendPost('saleTermsComparisonDispatcher', { params: { termId: id }, activity: 'deleteComparison' }, res => {
        if (res) {
          if (res.status == 200) {
            // * success

            this.loanTermsComparition.splice(this.loanTermsComparition.findIndex(el => (el['optionIndex'] - 1) == index), 1)
            this.ms.sendMessage("alert", { type: "success", text: 'Delete success' });
          } else {
            // ! send message in case error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })
    }

  }
  // todo: delete all terms
  public deleteAllLoanTerms = () => {
    this.master.sendPost('saleTermsComparisonDispatcher', { params: { saleId: this.saleId }, activity: 'deleteAllComparisons' }, res => {
      if (res) {
        if (res.status == 200) {
          // * success

          this.loanTermsComparition = []
          this.ms.sendMessage("alert", { type: "success", text: 'Delete success' });
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })

  }
  // todo: choose
  public chooseLoanTerm = (e) => {

    if (e.isDelete) {
      this.deleteLoanTerms(e.value.id, e.value.optionIndex - 1)
    } else {
      let cycle = this.paymentTerm['cyclesCatalog'].find(el => e.value.paymentCycle == el['name'])

      this.loanTerms.setValue({
        paymentCycleId: cycle.id,
        schedulePmtAmt: e.value.scheduledPaymentAmount,
        APR: e.value.contractRate,
        lastPmtAmt: e.value.lastPaymentAmount,
        numberOfPmt: e.value.numberOfRegularPayments,
        firstScheduledPmtDue: e.value.firstScheduledPaymentDate,
        months: e.value.months,
        totalPaymentAmount: e.value.totalPaymentAmount
      })

      if(e.value.quotes){
        e.value.quotes=JSON.parse(e.value.quotes)
        this.paymentTerm['saleTerms']['financeCharges'] =  e.value.quotes['FinanceCharge']
        this.paymentTerm['saleTerms']['amountoFinance'] =  e.value.quotes['AmountFinanced']
        this.paymentTerm['saleTerms']['totalPaymentAmount'] = e.value.quotes['TotalOfPayments']
        this.paymentTerm['saleTerms']['quotes'] = JSON.stringify(e.value.quotes)
      }
      if(e.value.response){
        this.paymentTerm['saleTerms']['response'] =e.value.response
      }
      if(e.value.request){
        this.paymentTerm['saleTerms']['request'] = e.value.request
      }

      this.deleteAllLoanTerms()
    }
  }
  // todo: read
  public readLoanTerms = () => {

    this.master.sendPost('saleTermsComparisonDispatcher', { params: { saleId: this.saleId }, activity: 'getComparisons' }, res => {
      if (res) {
        if (res.status == 200) {
          // * success

          this.loanTermsComparition = res.data.comparisons

        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // todo: push new card
  public pushCardCompare = (): void => {

    if (this.loanTermsComparition.length == 0) {
      this.compareTerms = !this.compareTerms

      this.loanTermsComparition.push({
        id: null,
        saleId: this.saleId,
        optionIndex: this.loanTermsComparition.length + 1,
        paymentCycle: this.loanTerms.value['paymentCycleId'],
        scheduledPaymentAmount: this.loanTerms.value['schedulePmtAmt'],
        contractRate: this.loanTerms.value['APR'],
        lastPaymentAmount: this.loanTerms.value['lastPmtAmt'],
        numberOfRegularPayments: this.loanTerms.value['numberOfPmt'],
        firstScheduledPaymentDate: moment(this.loanTerms.value['firstScheduledPmtDue']).format('yyyy-MM-DD'),
        months: this.loanTerms.value['months'],
        totalPaymentAmount: this.loanTerms.value['totalPaymentAmount'],
        response: this.paymentTerm['saleTerms'].response,
        quotes: this.paymentTerm['saleTerms'].quotes,
        request: this.paymentTerm['saleTerms'].request,
      })

    } else {
      this.loanTermsComparition.push({
        id: null,
        saleId: this.saleId,
        optionIndex: this.loanTermsComparition.length + 1,
        paymentCycle: '1',
        scheduledPaymentAmount: 0,
        contractRate: 0,
        lastPaymentAmount: 0,
        numberOfRegularPayments: 0,
        firstScheduledPaymentDate: moment().format('yyyy-MM-DD'),
        months: 0,
        totalPaymentAmount: 0,
        response: null,
        quotes: null,
        request: null,
      })
    }

  }
  // todo: get loan compare
  public getLoanTermsCompare = (e) => {

    this.loanTermsComparition[e.value.optionIndex - 1] = { ...e.value }
  }
  // ======================
  // ! calculate payments automatic
  // ======================
  public createScheduledPayments = () => {

    let param = {
      principal: this.loanTerms.value['schedulePmtAmt'],
      principalStartPaymentDate: this.loanTerms.value['firstScheduledPmtDue'],
      principalEndPaymentDate: moment(this.getCategory('Monthly', this.loanTerms.value['firstScheduledPmtDue'])).add(+this.loanTerms.value['months'] - 1, 'month').format('YYYY-MM-DD'),
      rate: this.loanTerms.value['APR'],
      lastPmtAmt: this.loanTerms.value['lastPmtAmt'],
      contractDate: moment().format('yyyy-MM-DD'),
      paymentFrequency: this.loanTerms.value['paymentCycleId'],
      term: this.loanTerms.value['months'],
      sellingPrice: this.loanTerms.value['totalPaymentAmount'],
      saleId: this.saleId,
      referenceNumber: null,
      memo: null,
    }

    // set last oayment due (start and end)
    param['lastStartPaymentDate'] = moment(param['principalEndPaymentDate']).add(1, 'month').format('YYYY-MM-DD')
    param['lastEndPaymentDate'] = moment(param['lastStartPaymentDate']).add(1, 'month').format('YYYY-MM-DD')

    this.master.sendPost('scheduledPaymentsDispatcher', { ...param, activity: 'createSchedulePayments' }, res => {
      if (res) {
        if (res.status == 200) {
          // * success

          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });

        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }

      this.readTerms()
    })
  }
  // ======================
  // ? get date interval depending of the cycle
  // ======================
  public getCategory = (category, date) => {
    switch (category) {
      case 'Monthly':
        return moment(date).add(1, 'month').format('YYYY-MM-DD')
      case 'Semi-Monthly':
        let daysInMonth = Math.floor(moment(date).daysInMonth() / 2)
        return moment(date).add(daysInMonth, 'days').format('YYYY-MM-DD')
      case 'Weekly':
        return moment(date).add(1, 'week').format('YYYY-MM-DD')
      case 'Bi-Weekly':
        return moment(date).add(2, 'week').format('YYYY-MM-DD')
      default:
        break;
    }
  }
  // ======================
  // ? get amortization API
  // ======================
  public getAmortizationAPI = async () => {
    if (this.loanTerms.valid) {
      let params = {
        "ExcludePaymentAllocationParameters": "No",
        "BaseParameters": [
          {
            "UserCode": "INSANDBOX02",
            "PrincipalBalance": 0,
            "TOPBalance": 0.0,
            "Payment": 0.0,
            "ScheduledPayment": this.loanTerms.value['schedulePmtAmt'],
            "Charges": [
              {
                "Id": "1",
                "Amount": this.driveoutPrice - this.totalPayments,
                "Escrow": (this.paymentTerm['saleTerms'] ? this.paymentTerm['saleTerms']['totalPaymentAmount'] : 0) -
                  (this.driveoutPrice - this.totalPayments)
              }
            ],
            "Rate": this.loanTerms.value['APR'],
            "TaxRate": 0.0,
            "TaxDeferPart": 0.0,
            "TotalInterest": 0.0,
            "DisclosedTOP": 0.0,
            "NetTaxableBasis": 0.0,
            "TaxBalance": 0.0,
            "AmountDue": 0.0,
            "ContractDate": "2022-05-01T00:00:00-04:00",
            "FirstPaymentDate": this.loanTerms.value['firstScheduledPmtDue'],
            "PreviousPaymentDate": "2022-05-01T00:00:00-04:00",
            "PaymentDate": "2022-06-06T00:00:00",
            "MaturityDate": "0001-01-01T00:00:00",
            "DaysPeriodEarned": 0,
            "IsPrecomputed": "No",
            "IsLease": "No",
            "AmortizationMethod": "SimpleInterest",
            "TimeCounting": "PerDiem",
            "DaysInYear": "Days365",
            "PaymentFrequency": "Monthly",
            "TransactionId": "1"
          }
        ]
      }

      const res = await this.master.getCarletonAmortization(params)
      if (res) {
        if (res.data[0].ErrorMessage == null) {
          const value = res.data[0].PaymentAllocation['ChargesPaid'][0].Escrow
          return value
        } else {
          return 0
        }
      } else {
        return 0
      }

    }
  }
  // ======================
  // * life cycle
  // ======================
  constructor(private master: MasterService, private ms: MessageService, private store: StoreService) {
    this.ms.channelComponents$.subscribe(res => {

      switch (res.message) {
        case 'setPermissions':
        // this.lots = this.paymentTerm['lotsInCorp']
        case 'changeLot':
          this.lotSelected = this.store.lotSelected
        default:
          break;
      }
    })
  }

  ngOnInit() {
    this.readAll(true)
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.totalSalePrice) {
      this.totalSalePrice = changes.totalSalePrice.currentValue
    }
    if (changes.driveoutPrice) {
      this.driveoutPrice = changes.driveoutPrice.currentValue
    }
    if (changes.baseSalePrice) {
      this.baseSalePrice = changes.baseSalePrice.currentValue
    }
    if(changes.lienholders){
      // this.lienholders['lienholderContactId'] = this.lienholders['id']
    }
    // generate schedule payments

    if (changes.isUnLock && changes.isUnLock.currentValue == true) {


      if (!changes.isUnLock.firstChange) {

        if (this.loanTerms.valid && this.saleType==4) {

          this.createScheduledPayments()

        }
      }

    }
  }


}
