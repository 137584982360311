import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
// services
import { MessageService } from "../../../../services/message.service";
import { MasterService } from "../../../../services/master.service";
import { StoreService } from '../../../../services/store.service';

@Component({
  selector: 'header-payments',
  templateUrl: './header-payments.component.html',
  styleUrls: ['./header-payments.component.scss']
})
export class HeaderPaymentsComponent implements OnInit {
  // **************************
  // ****** variables *********
  // **************************
  //
  public permissionsIdentifications = [
    {},
    {},
    {},
    {},
    {},
    {
      "id": 7,
      "name": "Identifications",
      "permissions": [
        "add",
        "view",
        "edit",
        "delete"
      ]
    },
    {
      "id": 8,
      "name": "Number",
      "permissions": [
        "add",
        "view",
        "edit",
        "delete"
      ]
    }
  ]
  //
  public isOpenTable: boolean = false
  //
  public loading: boolean = false
  //
  @Input() saleId:number = 0
  // save the language
  @Input() language: string = 'EN'
  // set all words
  @Input() words:any = {}
  //
  public users: any[] = []
  //
  public usersComplete = []
  //
  public userFilter = ''
  //
  public data: any[] = []
  //
  @Input() header: any[] = []
  //
  public auxContact = {}
  //
  public viewDetail: boolean = false
  //
  @Output() header$: EventEmitter<Object> = new EventEmitter();
  //
  // **************************
  // ****** functions *********
  // **************************
  // filter users
  public filter = (e, userFilter) => {
    userFilter=userFilter.toLowerCase()
    let dataFound=[]
    if (e.key == 'Enter') {
      dataFound = dataFound.concat(this.usersComplete.filter(el => (el.saleBuyers[0].contact.firstName || '').toLowerCase().indexOf(userFilter) > -1))

      dataFound = dataFound.concat( this.usersComplete.filter(el => (el.saleBuyers[0].contact.lastName || '').toLowerCase().indexOf(userFilter) > -1))

      dataFound = dataFound.concat(this.usersComplete.filter(el => el.saleVehicles[0].inventory.stockNumber.toLowerCase().indexOf(userFilter) > -1))
      dataFound = dataFound.concat(this.usersComplete.filter(el => (el.saleVehicles[0].inventory.vehicle.exteriorColor || '').toLowerCase().indexOf(userFilter) > -1))
      dataFound = dataFound.concat( this.usersComplete.filter(el => (el.saleVehicles[0].inventory.vehicle.make || '').toLowerCase().indexOf(userFilter) > -1))
      dataFound = dataFound.concat( this.usersComplete.filter(el => (el.saleVehicles[0].inventory.vehicle.model || '').toLowerCase().indexOf(userFilter) > -1))
      dataFound = dataFound.concat( this.usersComplete.filter(el => (el.saleVehicles[0].inventory.vehicle.modelYear || '').toLowerCase().indexOf(userFilter) > -1))

      this.users = dataFound.filter((v,i,a)=>a.indexOf(v)==i)

    }
  }

  // todo: listener to childrens
  public listenEndpoint = (e) => {

    switch (e.message) {
      case 'reloadContactDetail':
        this.readPayments(this.saleId);
    this.closeModal();
    (document.getElementById('btn-close-modal-delete') as HTMLButtonElement).click()
        break;
      default:
        break;
    }

  }
  //
  //  todo: close modal and reset variables
  //
  public closeModal = () => {
    this.auxContact = {};
    this.viewDetail = false;
  }
  //
  //  todo: simule a click to all components
  //
  public saveAll = () => {
    let buttons = [
      'btn-save-generalInformation',
      'btn-save-addresses', 'btn-save-communications', 'btn-save-identifications',
    ];
    buttons.forEach(el => {
      (document.getElementById(el) as HTMLButtonElement).click()
    });
  }
  //
  //  todo: seach users
  //
  public toggleTable = () => {
    this.isOpenTable = !this.isOpenTable
    if (this.isOpenTable) {
      this.readPayments(0)
    }
  }
  //
  //  todo: seach users
  //
  public readPayments = (saleId) => {
    saleId == 0 ? false : saleId
    this.loading = true;
    this.master.sendPost("CRUDForPayments", { saleId: saleId, activity: 'read' }, (res) => {
      this.loading = false;
      if (res) {
        if (res.status == 200) {
          // * success
          if (saleId) {
            this.isOpenTable = false
            this.saleId = saleId
            this.data = res.data.header

            this.header$.emit({
              header: res.data.header,
              footer: res.data.footer,
              body: {
                ...res.data.body,
                promisedToPayHistory: res.data.promisedToPayHistory,
                promisedToPay: res.data.promisedToPay.slice(res.data.promisedToPay.length - res.data.body['paymentsExpected'].length, res.data.promisedToPay.length)
              },
              saleId: this.saleId, lotId: this.store.lotSelected
            })

          } else {

            this.usersComplete = res.data.allSales.filter(el => el['saleBuyers'].length > 0 && el['saleVehicles'][0].inventory!=null)

            this.users = this.usersComplete.slice()
          }
        } else {
          // ! error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    }
    );
  }
  //
  //  todo: view detail user
  //
  public getContactDetail = (id) => {
    this.loading = true
    this.master.sendPost('getContactDetails', { contactId: id }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.viewDetail = true
          this.auxContact = res.data.contact[0]
          setTimeout(() => {
            (document.getElementById('contactDetailBuyerModal') as HTMLButtonElement).click()
          }, 100);

        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }

  // **************************
  // ****** life cycles *********
  // **************************
  constructor(

    private ms: MessageService,
    private master: MasterService,
    private store:StoreService
  ) {


  }

  ngOnInit() {
    this.readPayments(this.saleId)

  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.data && changes.data.currentValue != undefined) {
      this.data = this.header
    }
  }
}
