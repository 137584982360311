import { Injectable,EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class MessageService 
{
    public channelComponents$=new EventEmitter<object>()
    
    private subject = new Subject<any>();

    //Send a message with id and data
    public sendMessage(id, message) 
    {
        this.subject.next({id:id, data:message});
    }

    //Detects if a message has been sent
    onMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}