import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from "../../../services/message.service";
import { MasterService } from "../../../services/master.service";
import { StoreService } from "../../../services/store.service";
import * as languageLibrary from '../../../services/language'
// extarnal
import * as moment from "moment/moment";
import { Observable } from 'rxjs';
@Component({
  selector: "app-inventory-detail",
  templateUrl: "./inventory-detail.component.html",
  styleUrls: ["./inventory-detail.component.scss"],
})
export class InventoryDetailComponent implements OnInit {
  /*
   * Variables
   */
  //  sections pending save
  private sections = [
    { name: 'generalInformationInventory', savePending: false, btn: 'btn-save-generalInformation' },
    { name: 'financials', savePending: false, btn: 'btn-save-financials' },
    { name: 'administrative', savePending: false, btn: 'btn-save-administrative' },
    { name: 'reaconditioningCost', savePending: false, btn: 'btn-save-reaconditioning' },
    { name: 'floorPlans', savePending: false, btn: 'btn-save-floorPlans' },
    { name: 'floorPlansReductions', savePending: false, btn: 'btn-add-floorPlan' },
  ]
  // define if exist
  public savePending: boolean = false
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  // permissions
  public permissions: string[] = [];
  // define the inventory id
  public inventoryId: string = "";
  // set all data (general information, history, financials and administrative)
  public data: Object = {};
  // set all data (reaconditioning cost, notes and floor plans)
  public data2: Object = {};
  // define if some field was updated
  public isGhost: boolean = false;
  // VIN to display
  public stockNumber: string = "";
  public vinNumber: string = "";
  // loading
  public loading: boolean = true;
  // error type
  public error: string = ''
  /*
   * Functions
   */
  //  get data of vehicle
  public getData = (id: string, lotId: string): void => {
    this.loading = true;
    this.master.sendPost("getInventoryVehicle", { inventoryId: id, lotId: lotId }, (res) => {
      this.loading = false;
      if (res) {
        if (res.status == 200) {
          // * success

          this.data = res.data;
          this.vinNumber = res.data.inventory.generalInformation.vinNumber;
          this.stockNumber =
            res.data.inventory.generalInformation.stockNumber;


        } else {
          // ! in case error

          if (res.data.error.indexOf("not exists") >= 0) {

            this.error = 'not exist'
            window.location.href = "#/inventory"
            // this.router.navigate(["/inventory"]);
          }
          this.ms.sendMessage("alert", {
            type: "danger",
            text: res.data.error,
          });

        }
      }
    }
    );
  };
  // get data of reaconditioning cost and floor plans
  public getData2 = (id: string): void => {
    this.loading = true;
    this.master.sendPost("inventoryDetails", { vehicleId: id }, (res) => {
      this.loading = false;
      if (res) {
        if (res.status == 200) {
          // * success

          this.data2 = res.data;
        } else {
          // ! in case error
          this.ms.sendMessage("alert", {
            type: "danger",
            text: res.data.error,
          });

          if (res.data.error.indexOf("Cannot read property") >= 0) {
            this.error = 'not exist'
            this.router.navigate(["/inventory"]);

          }
        }
      } else {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
      }
    });
  };

  public copyVIN() {
    navigator.clipboard.writeText(this.vinNumber).then(
      function () {
        console.log("VIN copied to clipboard.");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }
  // save all
  public saveAll = () => {
    (document.getElementById("btn-save-generalInformation") as HTMLButtonElement).click();
    (document.getElementById(
      "btn-save-financials"
    ) as HTMLButtonElement).click();
    (document.getElementById(
      "btn-save-administrative"
    ) as HTMLButtonElement).click();
  };

// todo: listen to output of navbar
public listenerNav=(e):void=>{

  switch (e['message']) {
    case 'changeLanguage':
      this.language=e['value']
      break;
    case 'setPermissions':
      // permissions on user list
      this.permissions = e.permissions;
      this.getData(this.inventoryId, localStorage.getItem("lot") ? localStorage.getItem("lot") : this.store.lotSelected);
      this.getData2(this.inventoryId);
    break;

    default:
      break;
  }
}
// listener childrens
public listenerChildrens=(e):void=>{
  switch (e.message) {
    // todo:update administrative
    case 'updateAdministraive':
      // this.data["inventory"].administrative.datePurchased = res.datePurchased;

      if (this.data['history'].length == 0 ||
        (this.data['history'].length > 0 && this.data['history'][this.data['history'].length - 1].status.name != e.status)) {
        // this.data["inventory"].administrative.status = res.status;

        this.data["history"].push({
          daysAgo: 0,
          modifiedBy: this.store.userAccount["user"].firstName + " " + this.store.userAccount["user"].lastName,
          status: { name: e.status },
        });
      }
      break;
    // todo:update financials
    case 'updateFinancials':
      this.data["inventory"].financials.purchaseCosts = e.purchaseCosts;
      break;
    // todo: reload inventory details
    case 'reloadInventoryDetails':
      this.data2["modifiedBy"].modifiedAt = moment().format(
        "YYYY-MM-DD HH:mm"
      );
      this.data2["modifiedBy"].name =
        this.store.userAccount["user"].firstName +
        " " +
        this.store.userAccount["user"].lastName;
      if (e.vinNumber) {
        this.data["inventory"]["generalInformation"].vinNumber =
          e.vinNumber;
      }
      if (e.stockNumber) {
        this.data["inventory"]["generalInformation"].stockNumber =
          e.stockNumber;
      }
      break;
      // todo: get total of reaconditioning cost
      case 'changeReaconditioningCost':
        this.data['inventory'].financials['totalReconitioning']=e.total
      break
      // todo: get  floor plans balance
      case 'changeFloorPlans':

        this.data['inventory'].financials['totalFloor']=e.balance
        break;
      // todo: changes pending
    case 'changesPendingInventory':
      // changes pending
      switch (e.section) {
        case 'generalInformationInventory':
          this.sections[0].savePending = e.value
          break;
        case 'financials':

          this.sections[1].savePending = e.value
          break
        case 'administrative':
          this.sections[2].savePending = e.value
          break
        case 'reaconditioningCost':
          this.sections[3].savePending = e.value
          break
        case 'floorPlans':

          this.sections[4].savePending = e.value
          break
        case 'floorPlanReduction':

          this.sections[5].savePending = e.value
          break
        default:
          break;
      }

      break;
    default:
      break;
  }
}
  /*
   * Life cycles
   */
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ms: MessageService,
    private master: MasterService,
    private store: StoreService
  ) {
    this.inventoryId = this.route.snapshot.params["id"];
    // normal

  }

  ngOnInit() {
    // todo:store
    if (this.store.userAccount["permissions"]) {
      // permissions on user list
      this.permissions = this.store.userAccount["permissions"];
      // if not have permissions, return to account settings
      if (this.permissions[4]["childrens"][0].permissions.includes("view")) {
        this.getData(
          this.inventoryId,
          localStorage.getItem("lot")
            ? localStorage.getItem("lot")
            : this.store.lotSelected
        );
        this.getData2(this.inventoryId);
      }
    }

  }


  canDeactivate(): Observable<boolean> | boolean {

    if (this.error == 'not exist') {
      window.location.href = "#/inventory"
      return true
    }
    let caseLeave = 'justView'
    // ! save pending
    let buttons = this.sections.filter(el => el['savePending'] == true)

    if (buttons.length > 0) {
      caseLeave = 'savePending'
    }
    // !case invalid information
    let vinNumberError = this.data["inventory"]["generalInformation"].vinNumber == null ||
      this.data["inventory"]["generalInformation"].vinNumber == '' ? true : false;
    let stockNumberError = this.data["inventory"]["generalInformation"].stockNumber == null ||
      this.data["inventory"]["generalInformation"].stockNumber == '' ? true : false;
    if (this.data != undefined && (vinNumberError || stockNumberError)) {
      caseLeave = 'invalidInformation'
    }

    let result;

    switch (caseLeave) {
      // todo: in case invalid information
      case 'invalidInformation':
        result = window.confirm('The information is not valid, if it leaves the view the inventory will be eliminated, do you want to leave?');
        if (result) {
          this.master.sendPost("deleteInventory", { inventoryId: this.data["inventory"]["id"] }, (res) => {
            if (res) {
              if (res.status != 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
              }
            } else {
              // in case API no response
              this.ms.sendMessage("alert", {
                type: "danger",
                text: this.words[this.language]['apiNoResponse'],
              });
            }
          }
          );
          return true
        } else {
          return false
        }
      case 'savePending':
        // todo:change pending

        result = window.confirm('Do you want to save the changes before exiting?');
        if (result) {

          buttons.forEach(el => {
            (document.getElementById(el['btn']) as HTMLButtonElement).click()
          })
          return true
        } else {
          return false
        }
        break;
      default:
        // todo: just view
        return true

    }

  }

}
