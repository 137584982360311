/*
* libraries
*/
import { Component, OnInit, Input,EventEmitter,Output } from '@angular/core';
import { Router } from '@angular/router';
// services
import { MasterService } from '../../services/master.service'
import { MessageService } from '../../services/message.service';
import { StoreService } from '../../services/store.service'
import * as words from '../../services/language'

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  /*
* Variables
*/
  @Input() sideBar = true
  //
  @Output() navMessage$:EventEmitter<object>=new EventEmitter()
  //
  public words = words
  // define the languages
  public languages: Object[] = [{ name: 'english', value: 'EN' }, { name: 'spanish', value: 'ES' }]
  // define the language selected
  public languageSelect: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // define the user data
  public user = { firstName: '', lastName: '', corpID: '', corpName: '' }
  // define all lots
  public lots = []
  // define the lot selected
  public lotSelected = ''
  /*
* functions
*/
  /*
  ------------------------
   todo: change Language
   @param language:string=language selected
  / ------------------------
  */
  public changeLanguage = (language: string): void => {
    this.languageSelect = language
    localStorage.setItem('language', this.languageSelect)
    this.navMessage$.emit({  message: 'changeLanguage', value: this.languageSelect })
    this.ms.channelComponents$.emit({ message: 'changeLanguage', value: this.languageSelect })
  }
  /*
  ------------------------
   todo: log out
  / ------------------------
  */
  public logout = (): void => {
    this.master.sendGet('closeSession', res => {
      if (res) {
        if (res.status == 200) {
          // redirect to login
          localStorage.removeItem('token')
          this.master.token = ''
          this.store.userAccount = {}
          localStorage.removeItem('lot')
          // window.location.href = '/login'
          this.router.navigate(['/login']);
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: "API no response" });
      }
    })
  }
  /*
  ------------------------
   todo: get user data
  / ------------------------
  */
  public setUserAccount = (): void => {
    this.master.sendPost('getUserAccount', {}, res => {
      if (res) {
        if (res.status == 200) {
          // set data to store service
          this.store.userAccount = res['data']
          let route = window.location.hash
          let page = route.substr(2, route.length)
          this.validPermissions(this.store.userAccount['permissions'], page)
          // send permissions for all components
          this.ms.channelComponents$.emit({
            message: 'setPermissions',
            permissions: this.store.userAccount['permissions'],
            integrations: this.store.userAccount['integrationsPermissions'], page: page
          })
          this.navMessage$.emit({  message: 'setPermissions', value: this.languageSelect ,permissions: this.store.userAccount['permissions'],
          integrations: this.store.userAccount['integrationsPermissions']})
          // set data to object userInformation
          this.user = { ...this.store.userAccount['user'] }
          this.changeLanguage(this.user['languagePreference'])
          this.lots = this.store.userAccount['user'].lots
          // get lots
          if (this.lots.length > 0) {
            if (localStorage.getItem('lot') === null) {
              // ! not exist localstorage
              this.lotSelected = this.lots[0].id
              localStorage.setItem('lot', this.lotSelected)

              this.changeLot(this.lotSelected)
            } else {
              // * exist localstorage
              this.lotSelected = localStorage.getItem('lot')
              let exist = this.lots.some(el => el['id'] == this.lotSelected)
              exist ? this.lotSelected : this.lotSelected = this.lots[0].id

              this.changeLot(this.lotSelected)
            }
          } else {
            localStorage.removeItem('lot')
            this.store.lotSelected = null
          }
          // send user account
          this.ms.channelComponents$.emit({ message: 'getUserAccount', data: this.store.userAccount['user'] })
          this.navMessage$.emit({  message: 'getUserAccount', data: this.store.userAccount['user'] })
        } else {
          // send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: "API no response" });
      }
    })
  }
  /*
  ------------------------
   todo: check permissions in each page
   @param permissions: array of permissions
   @param page: current page
  / ------------------------
  */
  public validPermissions = (permissions: any[], page: string) => {
    // contacts
    if (page.indexOf('users') >= 0 && !permissions[0].childrens[0].permissions.includes('view')) {
      // if don´t have permissions return
      window.history.back()
    }
    let contact = page.split('/')
    // contacts
    if (page.indexOf('contacts') > 0 && contact.length == 2 && !permissions[1].childrens[0].permissions.includes('view')) {
      // if don´t have permissions return
      window.history.back()
    }
    // contact detail
    if (page.indexOf('contacts') > 0 && contact.length == 3 && !permissions[1].childrens[0].permissions.includes('edit')) {
      // if don´t have permissions return

      window.history.back()
    }
    // corporations
    if (page === 'corporations' && !permissions[2].childrens[0].permissions.includes('view')) {

      // if don´t have permissions return
      window.history.back()
    }
    // corporations detail
    if (page.indexOf('corporations/') >= 0 && !permissions[2].childrens[0].permissions.includes('edit')) {
      // if don´t have permissions return

      window.location.href = "#/profile"
    }
    // permissions
    if (page === 'permissions' && !permissions[3].childrens[0].permissions.includes('view')) {
      // if don´t have permissions return
      window.history.back()
    }
    // inventory
    if (page === 'inventory' && !permissions[4].childrens[0].permissions.includes('view')) {
      // if don´t have permissions return

      window.history.back()
    }
    // inventory detail
    if (page.indexOf('inventory/') >= 0 && !permissions[4].childrens[0].permissions.includes('edit')) {

      // if don´t have permissions return
      window.location.href = "#/profile"
    }
    // reports
    if (page.indexOf('reports/') >= 0 && !permissions[5].childrens[0].permissions.includes('view')) {

      // if don´t have permissions return
      window.location.href = "#/profile"
    }
  }
  /*
  ------------------------
   todo: log out
   @param lot:current lot
  / ------------------------
  */
  public changeLot = (lot: string): void => {
    // save lot in local storage
    localStorage.setItem('lot', lot)
    this.store.lotSelected = lot
    // page in
    let route = window.location.hash
    let page = route.substr(2, route.length)
    // emit message
    this.navMessage$.emit({ message: 'changeLot', lot: lot, page: page })
    this.ms.channelComponents$.emit({ message: 'changeLot', lot: lot, page: page })
  }

  /*
* life cicles
*/
  constructor(private master: MasterService, private ms: MessageService, private store: StoreService, private router: Router) {
    // set data
    if (this.store.userAccount['user']) {
      this.user = this.store.userAccount['user']
      this.changeLanguage(localStorage.getItem('language') ? localStorage.getItem('language') : this.user['languagePreference'])
      this.lots = this.store.userAccount['user'].lots
      if (this.lots.length > 0) {
        if (localStorage.getItem('lot') === null) {
          // ! in case not exist in localstorage
          this.lotSelected = this.lots[0].id
          localStorage.setItem('lot', this.lotSelected)
          this.changeLot(this.lotSelected)
        } else {
          // ! in case exist localstorage
          this.lotSelected = localStorage.getItem('lot')
          let exist = this.lots.some(el => el['id'] == this.lotSelected)
          exist ? this.lotSelected : this.lotSelected = this.lots[0].id
          this.changeLot(this.lotSelected)
        }
      } else {
        localStorage.removeItem('lot')
        this.store.lotSelected = null
      }
      let route = window.location.hash
      let page = route.substr(2, route.length)
      this.validPermissions(this.store.userAccount['permissions'], page)
    } else {
      this.setUserAccount()
    }

  }

  ngOnInit() {
    // listener
    this.ms.channelComponents$.subscribe(res => {
      if (res.message == 'updateInformation') {

        this.user.firstName = res.data.firstName
        this.user.lastName = res.data.lastName
        this.user['phone'] = res.data.phone

        this.store.userAccount['user'] = { ...this.user }

      } else if (res.message == 'updateLotList') {

        this.lots = res.lots
        this.store.userAccount['user'].lots = this.lots
        if (this.lots.length > 0) {
          // set new lot
          let exist = this.lots.some(el => el['id'] == this.lotSelected)
          exist ? this.lotSelected : this.lotSelected = this.lots[0].id
          localStorage.setItem('lot', this.lotSelected)
          this.store.lotSelected = this.lotSelected
        } else {

          localStorage.removeItem('lot')
          this.store.lotSelected = null
        }
      }
    })
  }




}
