import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnDestroy 
{  
  private subscription: Subscription;
  public messages: object[] = [];

  constructor(
    private ms: MessageService
  ){
    //creates a suscription to message service, waits for a message
    this.subscription = this.ms.onMessage().subscribe(msg => 
    {
      if(msg.id == "alert")
      {
        this.messages.push(msg.data);
        setTimeout(()=>{
          this.messages.shift();
        }, 3000);
      }
    });
  }

  //Destroys suscription to message service
  ngOnDestroy()
  {
    this.subscription.unsubscribe();
  }
}
