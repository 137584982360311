import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MasterService } from '../../../../../services/master.service'
import { MessageService } from '../../../../../services/message.service'
@Component({
  selector: 'references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.scss']
})
export class ReferencesComponent implements OnInit {
  /*
  * variables
  */
  @Output() references$: EventEmitter<Object> = new EventEmitter()
  // save the language
  @Input() language: string = 'EN'
  // set all words
  @Input() words = {}
  //  implement contact object
  @Input() contact: Object = {}
  // save all references
  @Input() data: Object[] = []
  // table headers
  public headers: Object[] = [
    { name: 'firstName', param: 'firstName' },
    { name: 'lastName', param: 'lastName' },
    { name: 'relationship', param: 'relationship' },
    { name: 'relation', param: 'relation' },
    { name: 'startDate', param: 'start' },
    { name: 'endDate', param: 'end' },
    { name: 'duration', param: 'duration' },
    { name: 'phoneNumber', param: 'phoneNumber' },
    { name: 'emailAddress', param: 'emailAddress' }]
  // define the rows ordered
  public order = { isAscend: true, type: '' }
  // permissions
  @Input() permissions: string[] = []
  // save the pagination settings
  public pagination = { index: 0, rowsByPage: 10, pages: [{ numPage: 0, start: 0, end: 8 }] }
  // save new comminucarions
  public formList = [{ type_1: '' }]
  // save all retationsships type
  public relationsShips: string[] = []
  // save all retationsships type
  public relationsShipsTypes: string[] = []
  // relation ship selected
  public relationShipSelected: string = 'Family'
  // relation selected
  public relationSelected: string = ''
  // save the id select
  public referenceSelected: Object = {}
  // define if loading state
  public loading: boolean = false
  // set time out
  private timeOutID: any;
  //  define if some changes not saved
  public savePending: boolean = false
  // contact list
  public contactList: Object[] = []
  // reference selected
  public isReferenceSelected: boolean = false
  // Group of inputs
  public information = new FormGroup({
    contactId: new FormControl('', [Validators.required]),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    relationship: new FormControl('', [Validators.required]),
    relation: new FormControl('', [Validators.required]),
    startDate: new FormControl(new Date().toISOString().substring(0, 10), []),
    endDate: new FormControl('', []),
    phoneNumber: new FormControl('', []),
    emailAddress: new FormControl('', []),
  })
  // get information of inputs
  get firstName() { return this.information.get('firstName') }
  get lastName() { return this.information.get('lastName') }
  get relationship() { return this.information.get('relationship') }
  get relation() { return this.information.get('relation') }
  get start() { return this.information.get('startDate') }
  get end() { return this.information.get('endDate') }
  get phoneNumber() { return this.information.get('phoneNumber') }
  get emailAddress() { return this.information.get('emailAddress') }

  /*
* functions
*/
  // todo: detect changes
  changesPending = () => {
    if (!this.savePending) {
      this.savePending = true
      this.references$.emit({ message: 'changesPendingContacts', section: 'references', value: this.savePending })
    }
  }

  // save changes
  public save = (afterClose): void => {
    if (this.information.valid) {
      this.loading = true

      // * date success
      let idRelation = this.relationsShips.filter(el => el['type'] == this.relationShipSelected && el['relation'] == this.relationSelected)
      // set relation id
      let object = this.information.value
      object['relationshipTypeId'] = parseInt(idRelation[0]['id'])
      // set dates
      object['startDate'] == "" ? object['startDate'] = null : object['startDate'] = object['startDate'] + ' 00:00:00';
      // set end-date
      object['endDate'] == "" ? object['endDate'] = null : object['endDate'] = object['endDate'] + ' 00:00:00'

      // send post
      this.master.sendPost('createContactRelationship', object, res => {
        if (res) {
          if (res.status == 200) {
            // * success
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
            // save pending false
            this.savePending = false
            this.references$.emit({ message: 'changesPendingContacts', section: 'references', value: this.savePending })
            // close modal
            if (afterClose) {
              // close modal
              (document.getElementById('btn-cancel-references-new') as HTMLButtonElement).click();
              // recharge user data

              this.references$.emit({ message: 'reloadContactDetail' })
            } else {
              // clean
              this.cleanFields()
              // recharge user data

              this.references$.emit({ message: 'reloadContactDetail' })
            }

            this.loading = false
          } else {
            // ! send message in case error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
            this.loading = false
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
          this.loading = false
        }
      })

    }
  }
  // clean Fields
  public cleanFields = (): void => {
    this.information.reset()
    // remove the controls
    this.information.removeControl('toContactId')
    this.information.removeControl('fromContactId')
    this.information.removeControl('relationshipId')
    this.contactList = []
    // set values
    this.information.setValue({
      contactId: this.contact['id'],
      firstName: '',
      lastName: '',
      relationship: '',

      relation: '',
      startDate: '',
      endDate: '',
      phoneNumber: '',
      emailAddress: ''
    })
    this.referenceSelected = {}
    this.isReferenceSelected = false
  }
  // change the comminucation-type
  public changeType = (e, input): void => {
    this.information.get(input).setValue(e.target.value, {
      onlySelf: true
    })
    if (input == 'relationship') {
      this.relationSelected = ''
    }
  }
  /*
  !: delete functions
  */
  //  select the id
  public selectUser = (reference): void => {
    this.referenceSelected = reference;
    this.information.setValue({
      contactId: this.contact['id'],
      firstName: reference['firstName'],
      lastName: reference['lastName'],
      relationship: reference['type'],

      relation: reference['relation'],
      startDate: reference['startDate'],
      endDate: reference['endDate'],
      phoneNumber: reference['phoneNumber'],
      emailAddress: reference['emailAddress'],
    })

  }
  // delete address
  public delete = (): void => {
    this.loading = true
    this.master.sendPost('deleteEntity', { polimorphEntity: "references", polimorphId: this.referenceSelected['id'] }, res => {
      if (res) {
        if (res.status == 200) {
          // * success
          let btnClose = (document.getElementById('close-delete-modal-reference') as HTMLButtonElement);
          btnClose.click()
          //
          this.loading = false
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          // recharge user data
          this.references$.emit({ message: 'reloadContactDetail' })
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          this.loading = false
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        this.loading = false
      }
    })
  }
  /*
?: pagination and rows per view functions
*/
  // sort the users registers
  public sort = (type: string): void => {
    if (type == this.order.type) {
      this.order.isAscend = !this.order.isAscend
    } else {
      this.order.type = type
      this.order.isAscend = true
    }
    if (this.order.isAscend) {
      // ascend
      this.data = this.data.sort((a, b) => a[type] > b[type] ? 1 : -1)
    } else {
      // descend
      this.data = this.data.sort((a, b) => a[type] > b[type] ? -1 : 1)
    }
  }
  // change num page view
  public changePage = (page: number): void => {
    if (page == -1) {
      // back
      if (this.pagination.index >= 1) {
        this.pagination.index = this.pagination.index - 1
      }
    } else {
      // next
      if (this.pagination.index < this.pagination.pages.length - 1) {
        this.pagination.index = this.pagination.index + 1
      }
    }
  }
  // create a pagination
  public newPagination = (registers: Object[]): void => {
    this.pagination.pages = []
    this.pagination.index = 0
    // let registrosPerPage=2
    for (let index = 0; index < (registers.length / this.pagination.rowsByPage); index++) {
      this.pagination.pages.push({ numPage: index, start: this.pagination.rowsByPage * index, end: (this.pagination.rowsByPage * (index + 1)) })
    }

  }
  // change the rows per page
  public changeRows = (): void => {
    this.pagination.index = 0
    this.newPagination(this.data)
  }
  /*
  todo: update functions
  */
  public update = (): void => {

    if (this.information.valid) {
      this.loading = true;

      // * date success
      //  find the relation id
      let idRelation = this.relationsShips.filter(el => el['type'] == this.relationShipSelected && el['relation'] == this.relationSelected)
      // set relation id
      let object = this.information.value
      // set relation id
      object['relationshipTypeId'] = parseInt(idRelation[0]['id'])

      object['referenceId'] = this.referenceSelected['id']
      object['startDate'] = object['startDate'] + ' 00:00:00'
      // set hour
      delete this.information.value['relationship']
      delete this.information.value['relation']

      this.master.sendPost('updateContactRelationship', object, res => {

        if (res) {
          if (res.status == 200) {
            // * success
            let btnClose = (document.getElementById('close-update-modal-reference') as HTMLButtonElement);
            btnClose.click()
            //
            this.loading = false
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
            // recharge user data
            this.references$.emit({ message: 'reloadContactDetail' })
          } else {
            // ! send message in case error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
            this.loading = false
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
          this.loading = false
        }
      })

    }
  }

  /*
    ?:  reference functions
    */
  // verify if the corporate is already exist
  public contactExist(): void {
    if (this.firstName.value.length < 3) {

      return;
    }

    clearTimeout(this.timeOutID);
    this.timeOutID = setTimeout(() => {
      this.contactList = []
      this.master.sendPost('contactLookup', { firstName: this.firstName.value }, res => {
        if (res) {
          // check if API conecction success
          if (res.status == 200) {
            this.contactList = res.data.contacts
          } else {
            // this.ms.sendMessage("alert", { type: "danger", text: "API no response" });
          }
        } else {
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })
    }, 750);

    /**/
  }

  // set the reference exist
  public selectReference = (contact: Object): void => {
    this.referenceSelected = contact
    this.information.setValue({
      contactId: this.contact['id'],
      firstName: contact['firstName'],
      lastName: contact['lastName'],
      relationship: '',
      relation: '',
      startDate: '',
      endDate: '',
      phoneNumber: '',
      emailAddress: '',
    })

    this.information.addControl('toContactId', new FormControl(contact['id'], [Validators.required]));
    this.information.addControl('fromContactId', new FormControl(this.contact['id'], [Validators.required]));
    if (this.referenceSelected['relationshipId']) {
      this.information.addControl('relationshipId', new FormControl(this.referenceSelected['relationshipId'], [Validators.required]));
    }
    this.isReferenceSelected = true
    this.contactList = []
  }


  constructor(private master: MasterService, private ms: MessageService) {
    this.master.sendPost('getRelationshipTypes', {}, res => {
      if (res) {
        if (res.status == 200) {
          // * success
          this.relationsShips = res.data.relationshipTypes
          // recharge the relations type
          res.data.relationshipTypes.forEach(el => {
            // if the array not include it, then add, else omit it
            if (!this.relationsShipsTypes.includes(el['type'])) {
              this.relationsShipsTypes.push(el['type'])
            }
          })
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }

  ngOnInit() {
    this.data = this.contact['relationships']
    if (this.data.length > 0) {
      this.newPagination(this.data)
    }
    this.cleanFields()

  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.

    if(changes.data && changes.data.currentValue!=undefined){
      if (this.data.length > 0) {
        this.newPagination(this.data)
      }
    }
  }

}
