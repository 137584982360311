import { Component, OnInit, Input } from '@angular/core';

// services
import { MasterService } from '../../services/master.service'
import { MessageService } from '../../services/message.service';
import { StoreService } from '../../services/store.service';
import * as languageLibrary from '../../services/language'
@Component({
  selector: 'app-profile-dashboard',
  templateUrl: './profile-dashboard.component.html',
  styleUrls: ['./profile-dashboard.component.scss']
})

export class ProfileDashboardComponent implements OnInit {
  /*
* Variables
*/
// permissions
public permissions: string[] = [];
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language'):'EN'
  // set all words
  public words = languageLibrary.language
  // define if sector is account settings or customer information (default is customer information)
  public section = 1
  // display de loading state
  public loading: boolean = true
  // user information
  public userInformation: Object = {email: '', firstName: '', lastName: '', phone: '', corporateURL: '' }
   /*
* functions
*/
// todo: listen to output of navbar
public listenerNav=(e):void=>{

  switch (e['message']) {
    case 'changeLanguage':
      this.language=e['value']
      break;
    case 'getUserAccount':
      // permissions on user list
      this.loading=false
      this.userInformation = { ...e.data}
    break;
    case 'setPermissions':
      this.permissions = e.permissions;
      break
    default:
      break;
  }
}
 /*
* life cycles
*/
  constructor(private master: MasterService, private ms: MessageService, private store: StoreService) {
    this.loading=true
    if(this.store.userAccount['user']){
      this.loading=false
      this.permissions = store.userAccount['permissions']
      this.userInformation = { ...this.store.userAccount['user']}
    }
  }

  ngOnInit() {


  }





}
