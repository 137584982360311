import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MasterService } from '../../../../../services/master.service'
import { MessageService } from '../../../../../services/message.service'
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss']
})
export class AddressesComponent implements OnInit {
  /*
  * variables
  */
  //
  @Input() isFlat: boolean = false
  //  define if is individual
  @Input() isIndividual: boolean = false
  //
  public type = {}
  // save the language
  @Input() language:  'EN'
  // set all words
  @Input() words = {}
  //  implement contact object
  @Input() contact: Object = {}
  // define the selected address
  public addressSelected: Object = { object: {}, index: 0 }
  // define the address selected
  public selected: Object = { address: 'none' }
  // save all addresses
  @Input() addressesList: Object[] = []
  // save the types
  public types: Object[] = [{ id: 1, name: 'Billing' }, { id: 2, name: 'Home' }, { id: 3, name: 'Mailing' }, { id: 4, name: 'Work' }]
  // save the rent or own
  public rentOwn: string[] = ['Rent', 'Own', 'Other']
  // define if loading state
  public loading: boolean = false
  // zip code list
  public zipCodeList: Object[] = []
  // permissions
  @Input() permissions: string[] = []
  // set time out
  private timeOutID: any;
  //  define if some changes not saved
  public savePending: boolean = false
  // addresslokup
  public addressLookup: Object = {
    city: "",
    county: "",
    state: "",
    zipcode: "",
    country: ""
  }
  // Group of inputs
  public information = new FormGroup({
    contactId: new FormControl('', [Validators.required]),
    addressTypeId: new FormControl('', [Validators.required]),
    status: new FormControl(''),
    address: new FormControl('', [Validators.required]),
    address2: new FormControl('', []),
    zipCode: new FormControl('', [Validators.required, Validators.pattern('[0-9]{3,10}')]),
    city: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    county: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required]),
    startDate: new FormControl(''),
    endDate: new FormControl(''),
  })
  //
  @Output() addresses$: EventEmitter<Object> = new EventEmitter()
  // get information of inputs
  get address() { return this.information.get('address') }
  get address2() { return this.information.get('address2') }
  get zipCode() { return this.information.get('zipCode') }
  get startDate() { return this.information.get('startDate') }
  get endDate() { return this.information.get('endDate') }

  /*
  * functions
  */
  // todo: detect changes
  changesPending = () => {

    if (!this.savePending) {
      this.savePending = true

      this.addresses$.emit({ message: 'changesPendingContacts', section: 'addresses', value: this.savePending })
    }
  }


  // change the addresses-type
  changeType = (e, type): void => {
    this.changesPending()
    this.information.get(type).setValue(e.target.value, {
      onlySelf: true
    })
  }

  // clean Fields
  public cleanFields = (): void => {

    this.addressSelected = { object: {}, index: 0 }
    this.zipCodeList = []
    this.information.reset()

    this.information.setValue({
      contactId: this.contact['id'],
      addressTypeId: '',
      status: '',
      address: '',

      address2: '',
      zipCode: '',
      city: '',

      state: '',
      county: '',
      country: '',

      startDate: '',
      endDate: ''
    })
  }
  /*
  ?: create
  */
  // save
  public save = (afterClose): void => {
    if (this.information.valid) {
      this.loading = true
      // check the end date value
      // let endDate
      // * date success
      let object = { ...this.information.value }
      object['addressTypeId'] = parseInt(this.information.value['addressTypeId'])
      // set hour
      object['startDate'] == "" ? object['startDate'] = null : object['startDate'] = object['startDate'] + ' 00:00:00'
      object['endDate'] == "" ? object['endDate'] = null : object['endDate'] = object['endDate'] + ' 00:00:00'
      this.isIndividual ? object['status'] : object['status'] = "Other"

      // check if start date is before to end date
      if (object['startDate'] && object['endDate']) {
        if (moment(object['startDate']) >= moment(object['endDate'])) {
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['startDateOrEndDateInvalid'] });
          this.loading = false
          return
        }
      }
      if (this.contact['isIndividual'] === 0) {
        object['status'] = "Other"
      }
      //  save address
      this.master.sendPost('createContactAddress', { addresses: [object] }, res => {
        // set loading state
        this.loading = false
        if (res) {
          if (res.status == 200) {

            // * success
            this.savePending = false
            this.addresses$.emit({ message: 'changesPendingContacts', section: 'addresses', value: this.savePending })
            if (afterClose && !this.isFlat) {
              // close modal

              (document.getElementById('btn-cancel-addresses') as HTMLButtonElement).click();
            }
            this.addresses$.emit({ message: 'reloadContactDetail' });
            // clean the fields
            this.cleanFields()
            // send message
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          } else {
            // ! send message in case error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });

          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });

        }
      })
    }

  }

  /*
  todo: update
  */
  // save
  public update = (): void => {
    if (this.information.valid) {
      // * date success
      let objectChanged = { ...this.information.value }
      this.loading = true
      if (this.contact['isIndividual'] === 0) {
        objectChanged['status'] = "Other"
      }
      objectChanged['addressTypeId'] = parseInt(this.information.value['addressTypeId'])
      objectChanged['addressId'] = this.addressSelected['object']['id']
      // set format date
      objectChanged['endDate'] == null || objectChanged['endDate'] == "" ? objectChanged['endDate'] = null : objectChanged['endDate'] = objectChanged['endDate'] + ' 00:00:00'
      objectChanged['startDate'] == '' ||  objectChanged['startDate'] == null  ? objectChanged['startDate'] = null : objectChanged['startDate'] = objectChanged['startDate'] + ' 00:00:00'
      // check if start date is before to end date
      if (objectChanged['startDate'] && objectChanged['endDate']) {
        if (moment(objectChanged['startDate']) >= moment(objectChanged['endDate'])) {
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['startDateOrEndDateInvalid'] });
          this.loading = false
          return
        }
      }
      //  save address
      this.master.sendPost('updateContactAddress', objectChanged, res => {
        // set loading state
        this.loading = false
        if (res) {
          if (res.status == 200) {
            // * success
            if (!this.isFlat) {
              // recharge user data
              this.addresses$.emit({ message: 'reloadContactDetail' });
            }
            // close modal
            if (!this.isFlat) {
              (document.getElementById('btn-close-addresses-update') as HTMLButtonElement).click();
            }
            // clean the fields
            this.cleanFields()
            // send message
            if (this.addressSelected['object']['primaryAddress'] == 1) {
              // in case default address
              this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
            } else {
              // in another case
              this.ms.sendMessage("alert", { type: "success", text: res.data.message });
            }
          } else {
            // ! send message in case error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });

          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });

        }
      })
    } else {
      // ! start date  invalid
      this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['startDateOrEndDateInvalid'] });
    }


  }
  // select address update
  public selectAddressUpdate = (address: Object): void => {
    this.addressSelected['object'] = address

    this.information.setValue({
      contactId: this.contact['id'],
      addressTypeId: address['addressTypeId'],
      status: address['status'],
      address: address['address'],
      address2: address['address2'],
      zipCode: address['zipCode'],
      city: address['city'],
      state: address['state'],
      county: address['county'],
      country: address['country'],
      startDate: address['startDate'] == null || address['startDate'] == 'Invalid date' ? "" : address['startDate'],
      endDate: address['endDate'] == null || address['endDate'] == 'Invalid date' ? "" : address['endDate'],
    })

  }
  /*
 ! delete
 */
  // select address
  public selectAddress = (address: Object, index: number): void => {
    this.addressSelected['object'] = address
    this.addressSelected['index'] = index
  }
  // delete address
  public delete = (): void => {
    this.loading = true
    this.master.sendPost('deleteEntity', { polimorphEntity: "address", polimorphId: this.addressSelected['object'].id }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          if (!this.isFlat) {
            (document.getElementById('close-delete-modal') as HTMLButtonElement).click();
          }
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });

          if (this.addressSelected['object']['primaryAddress'] == 1) {
            // assign the new address, in case address primary was deleted
            let address = this.addressesList.filter(el => el['primaryAddress'] == 0)
            if (address.length > 0) {
              this.selectDefault(address[0])
            } else {
              this.selected = { address: 'none' }
            }
          }
          // delete from array
          this.addressesList.splice(this.addressSelected['index'], 1)
          this.cleanFields()
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          this.loading = false
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        this.loading = false
      }
    })
  }
  // todo:alert quest delete
  public questDelete = (address: Object, index: number) => {
    if (confirm(this.words[this.language]['deleteAddress'])) {
      this.selectAddress(address, index)
      this.delete()
    }
  }
  /*
 * select defaul
 */
  // define the address default
  public selectDefault = (address: Object): void => {
    this.master.sendPost('selectPrimaryEntity', { polimorphId: address['id'], contactId: this.contact['id'], polimorphEntity: "address" }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          // reload all to false
          this.addressesList.map(el => el['primaryAddress'] = 0)
          // set new default
          address['primaryAddress'] = 1
          this.selected = address
          // send message
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });

        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }

  // ? address loockup
  // verify if the corporate is already exist
  public searchZipCode(): void {

    if (this.addressLookup['zipcode'].length < 3) {
      return;
    }

    clearTimeout(this.timeOutID);
    this.timeOutID = setTimeout(() => {
      this.zipCodeList = []
      this.master.sendPost('getLocationByZipcode', { zipcode: this.addressLookup['zipcode'] }, res => {
        if (res) {
          // check if API conecction success
          if (res.status == 200) {
            // * success
            this.zipCodeList = res.data.locations
            // autocomplete
            if (this.addressLookup['zipcode'].length >= 5) {
              this.selectReference(this.zipCodeList[0])
            }
          }
        } else {
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })
    }, 750);

    /**/
  }

  // set the address exist
  public selectReference = (address: Object): void => {
    // set address lockup only view
    this.addressLookup['city'] = address['city']
    this.addressLookup['state'] = address['state']
    this.addressLookup['county'] = address['county']
    this.addressLookup['zipcode'] = address['zipcode']
    this.addressLookup['country'] = 'United States'
    // set address for formuler
    this.information.value['city'] = address['city']
    this.information.value['state'] = address['state']
    this.information.value['county'] = address['county']
    this.information.value['zipCode'] = address['zipcode']
    this.information.value['country'] = 'United States'

    this.zipCodeList = []
  }

  // open the location
  public openLocation = (address: string, county: string, state: string): void => {
    address = address.replace(' ', '+')
    county = county.replace(' ', '+')
    state = state.replace(' ', '+')
    console.info(`https://www.google.com/maps/search/?api=1&query=${address}+${county}+${state}`);

    window.open(`https://www.google.com/maps/search/?api=1&query=${address}+${county}+${state}`, '_blank')
  }

  /*
  * Life cicles
  */
  constructor(private master: MasterService, private ms: MessageService, private route: ActivatedRoute) {
    this.type = this.route.snapshot.params['type']
    // listen when the individual changed

  }

  ngOnInit() {
    // clean the form
    this.cleanFields()
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if(changes.addressesList && changes.addressesList.currentValue!=undefined){
      if (changes.addressesList.currentValue.length > 0) {
        let object = changes.addressesList.currentValue.filter(el => el['primaryAddress'] == 1)
        if (object.length > 0) {
          this.selected = object[0]
        } else {
          this.selected = { address: 'none' }
        }
      }
    }
  }


}
