import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MasterService } from '../../../services/master.service';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss']
})
export class ActivateAccountComponent implements OnInit 
{
  private subscription: Subscription;
  private token: string;
  private type: string;
  public textMessage: string;
  public textTitle: string;

  constructor(
    private master: MasterService,
    private route: ActivatedRoute,
    ) {
      this.subscription = this.route.params.subscribe(params => 
        {
          this.token = params['token'];
          if(params['type'] != undefined)
            this.type = params['type'];
          else
            this.type = "email";

          this.confirmAccount();
        });
     }

  ngOnInit() {
    
  }

  confirmAccount()
  {
    
    this.master.sendPost('tokenMailVerification', {type:this.type,token:this.token}, res => {
      if(res){
        // check if API conecction success
        if (res.status == 200) 
        {  
          this.master.token = res.data.token;
          this.textTitle = "Account activated successfully";
          this.textMessage = "Your account has been activated.";
          window.location.href = '#/dashboard'
        }else{
          this.textTitle = "";
          this.textMessage = res.data.error;
        }
      }
    })
  }
}
