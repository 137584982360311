import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// services
import { MasterService } from '../../../../services/master.service'
import { MessageService } from '../../../../services/message.service';

@Component({
  selector: 'register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent implements OnInit {
  // Variables
  // define if password input is type text or password
  public typePassword: string = 'password'
  // define if the email is already user
  public emailExist: boolean = false
  // display de loading state
  public loading: boolean = false
  // check accept Terms and Conditions
  public acceptTerms = false
  // corporates user list
  public corporateUserList=[]
  // have Password
  public havePassword:boolean=false
  // 
  public userId=''
  // 
  public isWww:boolean=false
  // create new corporate variable
  public newCorporate:boolean=false
  // Group of inputs
  public signUpGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(150), Validators.minLength(5),Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/)]),
    firstName: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(1), Validators.pattern('[A-Za-zÑñÁáÉéÍíÓóÚú ]{1,50}')]),
    lastName: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(1), Validators.pattern('[A-Za-zÑñÁáÉéÍíÓóÚú _-]{1,50}')]),
    password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&?¿¡*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&?¿¡*()_+]{7,39}$/)]),
    corporate: new FormControl('', [Validators.required, Validators.maxLength(150), Validators.minLength(3)]),
    corporateURL: new FormControl('', [Validators.required, Validators.maxLength(150), Validators.minLength(3), Validators.pattern('[A-Za-z0-9_-]{3,150}')]),
    phone:new FormControl('', [Validators.required,  Validators.pattern('[A-Za-z0-9_-]{3,150}')]),
    dealerNumber:new FormControl('', [Validators.required, Validators.maxLength(150), Validators.minLength(3)]),
    position:new FormControl('', [Validators.required]),
    isBillingAdmin:new FormControl(true, [Validators.required]),
  })
  // get information of inputs
  get email() { return this.signUpGroup.get('email') }
  get firstName() { return this.signUpGroup.get('firstName') }
  get lastName() { return this.signUpGroup.get('lastName') }
  get password() { return this.signUpGroup.get('password') }
  get corporateURL() { return this.signUpGroup.get('corporateURL') }
  get corporate() { return this.signUpGroup.get('corporate') }
  get phone() { return this.signUpGroup.get('phone') }
  get dealerNumber() { return this.signUpGroup.get('dealerNumber') }
  // functions

  // change the input type, passsword or text
  public changeTypePassword = (): void => {
    if (this.typePassword == 'text') {
      this.typePassword = 'password'
    } else {
      this.typePassword = 'text'
    }
  }
  // set information of all inputs ? 1- i case user not exist
  public getInformationUserNotExist = (): void => {
    // check if information is correct
    if (this.signUpGroup.valid && this.acceptTerms) {
      // is loading state
      this.loading = true
      // concat the url to corporate
      let corporateURL=this.signUpGroup.value['corporateURL']
      this.signUpGroup.value['corporateURL'] = this.signUpGroup.value['corporateURL'] + '.rockitdms.com'
      
      // send the post with de action accountCreation and the formuler
      this.master.sendPost('accountCreation', this.signUpGroup.value, res => {
        // isn`t loading state
        this.loading = false
        // check if API conecction success
        if (res) {
          // In case success
          if (res.status == 200) {
            // display the message
            this.ms.sendMessage("alert", { type: "success", text: res.data.message });
            // redirect to login
            // this.ms.channelComponents$.emit({message:'changeSection',value:false})
            window.location.href = '#/email/' + this.signUpGroup.value['email']
          } else {
            // in case error
            this.ms.sendMessage("alert", { type: "warning", text: res.data.error });
            
          }
          // set corporateURL
          this.signUpGroup.value['corporateURL'] = corporateURL
        } else {
          // If no connect with API
          this.ms.sendMessage("alert", { type: "danger", text: "API no Response" });
          
        }
      })
    }
  }
  // set information of all inputs ? 2- i case user exist
  public getInformationUserExist = (): void => {
    if(this.havePassword){
      this.signUpGroup.value['password']="Password6?"
    }
    // this.signUpGroup.value['password']="Password6?"
    this.signUpGroup.value['userId']=this.userId
    // check if information is correct
    if ( this.acceptTerms  ) {
      // is loading state
      this.loading = true
      // add user ID
      
      // concat the url to corporate
      let corporateURL=this.signUpGroup.value['corporateURL']
      this.signUpGroup.value['corporateURL'] = this.signUpGroup.value['corporateURL'] + '.rockitdms.com'
      
      // send the post with de action accountCreation and the formuler
      this.master.sendPost('accountNewCorp', this.signUpGroup.value, res => {
        // isn`t loading state
        this.loading = false
        // check if API conecction success
        if (res) {
          // In case success
          if (res.status == 200) {
            // display the message
            this.ms.sendMessage("alert", { type: "success", text: res.data.message });
            // redirect to login
            // this.ms.channelComponents$.emit({message:'changeSection',value:false})
            window.location.href = '#/email/' + this.signUpGroup.value['email']
          } else {
            // in case error
            this.ms.sendMessage("alert", { type: "warning", text: res.data.error });
            
          }
          // set corporateURL
          this.signUpGroup.value['corporateURL'] = corporateURL
        } else {
          // If no connect with API
          this.ms.sendMessage("alert", { type: "danger", text: "API no response" });
          
        }
      })
    }
  }
  // find the user in the data base
  public findUser = (): void => {
    // if email valid
    
    if (!this.email.invalid) {
      this.loading = true
      this.master.sendPost('checkUserCorporates', { "email": this.signUpGroup.value['email'] }, res => {
        this.loading = false
        if(res){
          if (res.status == 200) {
            // user exist
            this.corporateUserList=res.data.corporates
            // get have password
            this.havePassword=res.data.havePassword
            // 
            this.userId = res.data.userId
            this.emailExist = true
            this.newCorporate=false
            
          } else {
            // user not exist
            this.corporateUserList=[]
            this.emailExist = false
          }
        }else{
          
          // If no connect with API
          this.ms.sendMessage("alert", { type: "danger", text: "API no response" });
        }
      })
    }
  }

  public checkWww=()=>{
    
    this.signUpGroup.value['corporateURL'].toLowerCase().indexOf('www')>=0? this.isWww=true:this.isWww=false
    
  }

  // implements the master services and message services
  constructor(private master: MasterService, private ms: MessageService, private router: Router) { }

  ngOnInit() {

  }

}
