import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from '../../../../services/message.service'
import { MasterService } from '../../../../services/master.service'
import { StoreService } from '../../../../services/store.service'
import * as languageLibrary from '../../../../services/language'
import axios from 'axios'

@Component({
  selector: 'app-corporations',
  templateUrl: './corporations.component.html',
  styleUrls: ['./corporations.component.scss']
})
export class CorporationsComponent implements OnInit {
  /*
  * Variables
  */
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  // save all data by type
  public data: Object[] = []
  // define the contact type
  public type: string = ''
  // table headers
  public headers: Object[] = [
    { name: 'Company Name', param: 'companyName' },
    // { name: 'Dealership Number', param: 'firstName' },
    { name: 'Address', param: 'address' },
    // { name: 'Phone Number', param: 'email' },
    { name: 'Doing-Business-As Name', param: 'dba' }]
  // define if is loading
  public loading: boolean = false
  // define if is adding
  public adding: boolean = false
  // define item Selected
  public itemSelected: Object = {}
  // permissions
  public permissions: Object[] = []
  // expanded/collapsed
  public expanded = true
  /*
* Functions
*/
  // get corporate, dealerships and lots
  public chargeData = (watchLoading: boolean): void => {
    this.data = []
    if (watchLoading) {
      this.loading = true
    }
    this.master.sendPost('getCorporatesEntities', {}, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success

          this.data =  res.data.corporate

          this.data['dealerships']= res.data.corporate['dealerships'].filter(el => el['name']!=null)

          for (let index = 0; index < res.data.corporate['dealerships'].length; index++) {
            this.data['dealerships'][index]['lots'] = res.data.corporate['dealerships'][index]['lots'].filter(el => el['name']!=null)
          }

        } else {
          // ! error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // api no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // add new  dealership
  public createDealership = (): void => {
    this.adding = true
    this.master.sendPost('createDealership', {}, res => {
      this.adding = false
      if (res) {
        if (res.status == 200) {
          // * success

          this.router.navigate(['/corporations/dealership/' + res.data.dealership.id]);
        } else {
          // ! error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // api no responde
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // add new lot
  public createLot = (dealershipId: number): void => {
    this.adding = true
    this.master.sendPost('createLot', { dealershipId: dealershipId }, res => {
      this.adding = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.router.navigate(['/corporations/lot/' + res.data.lot.id]);
        } else {
          // ! error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // api no responde
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }


  // select a item
  public selectItem = (entity: string, id: number, active: string): void => {

    this.itemSelected = { entity: entity, entityId: id, type: active }
    this.itemSelected['type'] == 'Active' ? this.itemSelected['type'] = 'Disabled' : this.itemSelected['type'] = 'Active'
    if (active == 'Active') {
      // ? only active
      this.master.sendPost('checkEntityRealtionship', { entity: entity, entityId: id }, res => {
        if (res) {
          if (res.status == 200) {
            // ? normal
            (document.getElementById('btn-delete-modal') as HTMLButtonElement).click();

          } else {
            // ! important
            (document.getElementById('btn-deactivate-modal') as HTMLButtonElement).click();

          }
        } else {
          // api no responde
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })
    } else {
      // ? only disabled
      (document.getElementById('btn-active-modal') as HTMLButtonElement).click();

    }

  }

  /*
! DELETE functions
*/
  public delete = (message: string, btnName: string): void => {
    this.adding = true

    this.master.sendPost('deleteCorpByEntity', this.itemSelected, res => {
      this.adding = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          // close button
          (document.getElementById(btnName) as HTMLButtonElement).click();
          // charge data
          this.chargeData(false)
          // send new lots
          this.reloadLots()
        } else {
          // ! error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // api no responde
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }

  public reloadLots = (): void => {
    this.master.sendPost('getUserAccount', {}, res => {
      if (res) {
        if (res.status == 200) {
          // * success
          this.ms.channelComponents$.emit({ message: 'updateLotList', lots: res.data.user.lots })
        } else {
          // ! error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // api no responde
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
// todo: listen to output of navbar
public listenerNav=(e):void=>{

  switch (e['message']) {
    case 'changeLanguage':
      this.language=e['value']
      break;
    case 'setPermissions':
      // permissions on user list
      this.permissions = e.permissions[2].childrens[0].permissions
      // if not have permissions, return to account settings

      if (this.permissions.includes('view') && this.data.length == 0) {
        this.chargeData(true)
      }
    break;

    default:
      break;
  }
}
  /*
  * life cycles
  */
  constructor(private route: ActivatedRoute, private store: StoreService, private router: Router, private ms: MessageService, private master: MasterService) {
    this.type = this.route.snapshot.params['type']


  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    // normal
    if (this.store.userAccount['permissions']) {
      // permissions on user list
      this.permissions = this.store.userAccount['permissions'][2].childrens[0].permissions
      // if not have permissions, return to account settings
      if (this.permissions.includes('view') && this.data.length == 0) {

        this.chargeData(true)
      }
    }


  }

}
