import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
//services
import { MasterService } from '../../../services/master.service'
import { MessageService } from '../../../services/message.service';

@Component({
  selector: 'app-reset-change-password',
  templateUrl: './reset-change-password.component.html',
  styleUrls: ['./reset-change-password.component.scss']
})
export class ResetChangePasswordComponent 
{
  constructor(
    private master: MasterService,
    private ms: MessageService,
    private route: ActivatedRoute
  ){
      this.subscription = this.route.params.subscribe(params => 
      {
        this.type = params['type'];
        this.token = params['token'];
      });

      this.master.sendPost('tokenMailVerification',{token:this.token,type:this.type},res=>{
        this.token=res.data.token
      })

  }

  private subscription: Subscription;
  private token: string;
  private type: string;
  private typePassword: string = "password";
  public loading: boolean = false;
  public resetPassForm = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&?¿¡*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&?¿¡*()_+]{7,39}$/)]),
    confirmPassword: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]),
  })
  get password() { return this.resetPassForm.get('password') }
  get confirmPassword() { return this.resetPassForm.get('confirmPassword') }
  
  changeTypePassword = (): void => {
    if (this.typePassword == 'text') {
      this.typePassword = 'password'
    } else {
      this.typePassword = 'text'
    }
  }

  resetPassword(): void
  {
    if(!this.resetPassForm.valid || this.password.value != this.confirmPassword.value) return;
    let params = {password:this.password.value,token:this.token}
    // this.master.token = this.token;
    this.loading = true;
    this.master.sendPost('authenticateRecovery', params, res => {
      // check if API conecction success
          console.log(res);
      if (res) {
        // In case success
        if (res.status == 200) {
          localStorage.setItem('token', res.data.responseData.token)
          this.master.token = res.data.responseData.token
          window.location.href = '#/profile'
        } else {
          this.loading = false;
          this.ms.sendMessage("alert", { type: "warning", text: res.data.error });
        }
      }
    })
  }
}