import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
// services
import { StoreService } from '../../../../services/store.service'
import { MessageService } from "../../../../services/message.service";
import { MasterService } from "../../../../services/master.service";
import { ActivatedRoute } from "@angular/router";
//
import * as languageLibrary from '../../../../services/language'
@Component({
  selector: "stock",
  templateUrl: "./stock.component.html",
  styleUrls: ["./stock.component.scss"],
})
export class DealStockComponent implements OnInit {
  /*
  * variables
  */
  @Input() lienholders:Object[] = []
  //
  @Input() salePrice = 0
  //
  @Input() isUnLock = true
  // aux
  public auxVehicle = {}
  // define expanded
  public expanded: boolean = true;
  // invetory
  public inventorys = []
  //
  public inventorysComplete = []
  // addresslokup
  public vinLookup = ''
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  // vehicles
  public vehicle = { mileage: 0, odometerStatus: '', sellingPrice: 0, inventoryId: null }
  // list of odometers
  public odometers: string[] = ['Actual Mileage',
    'Not Actual Mileage (WARNING - ODOMETER DISCREPANCY)',
    'Exceeds Mechanical Limits',
    'Exempt'
  ]
  @Output() inventory$: EventEmitter<Object> = new EventEmitter();
  // sale ID
  public saleId = ''
  //
  public saleVehicleId = ''
  //
  auxiliar = ''
  loading = false
  data = {}
  data2 = {}
  vinNumber = null
  stockNumber = null
  viewDetail = false
  lotId = ''
  allData = {
    generalInformation: {},
    financials: {},
    administrative: {}
  }
  //
  //
  public numSaves = 0
  //
  public isEdit = false
  //
  public isAdmin = false
  // tradesIn
  public tradesIn = []
  // displayList
    public displayList: boolean = false
  //
  @Input() buyer
  /*
 * functions
 */

  // ? inventory loockup
  public searchStockNumber(): void {

    this.master.sendPost('saleVehicleLookup', {}, res => {
      if (res) {
        // check if API conecction success
        if (res.status == 200) {
          // * success

          this.inventorys = res.data.data
          this.inventorysComplete = res.data.data


        }
      } else {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })


    /**/
  }


  public showLookupList = (): void => {
    this.displayList = !this.displayList
    if (this.displayList) {
      document.getElementById('vehicleLookup-list').style.display = "block";
    } else
      document.getElementById('vehicleLookup-list').style.display = "none";

  }

  public searchData = (word: string, e) => {
    word = word.toLowerCase()
    this.inventorys = this.inventorysComplete.filter(el =>
      this.convertString(el['stockNumber']).indexOf(word) >= 0 ||
      this.convertString(el.vehicle['vinNumber']).indexOf(word) >= 0 ||
      this.convertString(el.vehicle['modelYear']).indexOf(word) >= 0 ||
      this.convertString(el.vehicle['make']).indexOf(word) >= 0 ||
      this.convertString(el.vehicle['model']).indexOf(word) >= 0 ||
      this.convertString(el.vehicle['exteriorColor']).indexOf(word) >= 0 ||
      this.convertString(el.lot.name).indexOf(word) >= 0
    )
  }
  public convertString = (word: any): string => {
    if (word == null) {
      return ''
    } else {
      return word.toString().toLowerCase()
    }

  }
  //
  public changeVehicle = (data: Object, index: number): void => {

    if (!this.buyer.saleId) {
      this.ms.sendMessage("alert", { type: "warning", text: 'You need to select a buyer first' });
      return
    }

    if(this.tradesIn.some(el=>data['stockNumber']==el['inventory']['stockNumber'])){
      this.ms.sendMessage("alert", { type: "warning", text: 'You can´t select this vehicle because is in your trade-in' });
      return
    }
    if (this.vehicle['inventoryId']) {
      this.clearFields(true)
    }
    this.vehicle['mileage'] = 0
    this.vehicle['odometerStatus'] = ''
    this.vehicle['inventoryId'] = data['id']
    this.vehicle['saleVehicleTransactionTypeId'] = 1
    this.vehicle['sellingPrice'] = data['sellingPrice']
    this.vehicle['data'] = { ...data }
    this.vehicle['stockNumber'] = data['stockNumber']
    // this.auxiliar = this.vehicle['inventoryId']
    this.vinLookup = data['stockNumber']
    this.vehicle['features'] = data['features']
    this.inventory$.emit({ value: this.vehicle })
    // save
    // this.inventorys.splice(index,1)

    this.save(false, this.vehicle)

  }
  //
  public setData = (data: Object) => {

    this.saleVehicleId = data['id']
    this.vehicle['mileage'] = data['mileage']
    this.vehicle['odometerStatus'] = data['odometerStatus']
    this.vehicle['saleVehicleTransactionTypeId'] = data['saleVehicleTransactionTypeId']
    this.vehicle['sellingPrice'] = data['inventory']['sellingPrice']
    this.vehicle['inventoryId'] = data['inventory']['id']
    this.vehicle['data'] = data['inventory']['vehicle']
    this.vehicle['stockNumber'] = data['inventory']['stockNumber']
    this.vinLookup = data['inventory']['stockNumber']
    this.auxiliar = data['inventory']['id']
    this.vehicle['data']['features'] = data['features']
    this.vehicle['data']['datePurchased'] = data['inventory'].datePurchased

    this.inventory$.emit({ value: this.vehicle })
  }

  public save = (isClear: boolean, data) => {

    this.vehicle = { ...data }
    // const inventory = this.vehicle['inventoryId']
    // this.vehicle['inventoryId'] = inventoryId
    // inventoryId == null ? this.vehicle['inventoryId'] = null : this.vehicle['inventoryId']

    this.vehicle['statusId'] = 2

    this.master.sendPost('updateSaleVehicle', {
      statusId: this.vehicle['statusId'],
      saleVehicleData: this.vehicle,
      inventoryId: data['inventoryId'],
      saleVehicleId: this.saleVehicleId,
      clear: isClear
    }, res => {
      if (res) {
        if (res.status == 200) {

          // && this.auxiliar != "" || inventoryId == null ? true : false
          if (this.auxiliar != this.vehicle['inventoryId'] && this.auxiliar != "") {
            this.returnAvaible(this.auxiliar, this.vehicle['inventoryId'] ? this.vehicle['inventoryId'] : "")
          }
          this.searchStockNumber()

          this.inventory$.emit({ value: this.vehicle })
          this.getVehicleDetail()
          this.auxiliar = this.vehicle['inventoryId']
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }

  public returnAvaible = (auxiliar, newAuxiliar) => {
    this.master.sendPost('returnToAvailableStatus', { inventoryId: auxiliar }, res => {
      if (res) {
        if (res.status == 200) {
          this.auxiliar = newAuxiliar
          setTimeout(() => {
            this.searchStockNumber()
          }, 500);
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  public createVehicle = () => {
    this.master.sendPost('createSaleVehicle', {
      saleId: this.saleId
    }, res => {
      if (res) {
        if (res.status == 200) {

          this.saleVehicleId = res.data.data

        } else {
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // get detail
  public getVehicleDetail = () => {

    this.master.sendPost('getSaleVehicleDetails', { saleId: this.saleId }, res => {
      if (res) {
        if (res.status == 200) {
          if (res.data.data.length > 0) {

            // trade in
            this.tradesIn = res.data.tradeIns

            // retail vehicle
            if (res.data.vehicleFeatures['features']) {
              res.data.data[0]['features'] = JSON.parse(res.data.vehicleFeatures.features)
            }

            this.setData(res.data.data[0])
          } else {
            this.createVehicle()
          }
        } else {
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }

  // =====================================
  // todo           drawer
  // ======================================
  //  get data of vehicle
  public getData = (id: string, lotId: string, isEdit): void => {
    this.isEdit = isEdit
    this.loading = true;
    this.master.sendPost("getInventoryVehicle", { inventoryId: id, lotId: lotId }, (res) => {
      if (res) {
        if (res.status == 200) {
          // * success
          this.data = res.data;

          this.vinNumber = res.data.inventory.generalInformation.vinNumber;
          this.stockNumber = res.data.inventory.generalInformation.stockNumber;
          // this.vehicle['vinNumber'] = this.vinNumber
          // this.vehicle['stockNumber'] = this.stockNumber
          this.auxVehicle['vinNumber'] = this.vinNumber
          this.auxVehicle['stockNumber'] = this.stockNumber
          this.auxVehicle['inventoryId'] = id
          //
          this.auxVehicle['mileage'] = res.data.inventory.generalInformation['miles']
          this.auxVehicle['vinNumber'] = res.data.inventory.generalInformation['vinNumber']
          this.auxVehicle['stockNumber'] = res.data.inventory.generalInformation['stockNumber']
          this.auxVehicle['odometerStatus'] = res.data.inventory.generalInformation['odometer']
          this.auxVehicle['saleVehicleTransactionTypeId'] = 1
          this.auxVehicle['data'] = {
            "vinNumber": res.data.inventory.generalInformation.vinNumber,
            "modelYear": res.data.inventory.generalInformation['modelYear'],
            "make": res.data.inventory.generalInformation['make'],
            "model": res.data.inventory.generalInformation['model'],
            "exteriorColor": res.data.inventory.generalInformation['exteriorColor']
          }
          // 

          this.getData2(id)

        } else {
          this.ms.sendMessage("alert", {
            type: "danger",
            text: res.data.error,
          });

        }
      }
    }
    );
  };
  // get data of reaconditioning cost and floor plans
  public getData2 = (id: string): void => {
    this.master.sendPost("inventoryDetails", { vehicleId: id }, (res) => {
      this.loading = false;
      if (res) {
        if (res.status == 200) {
          // * success

          this.data2 = res.data;

          this.viewDetail = true;
          // open modal
          setTimeout(() => {
            (document.getElementById('inventoryModalButton') as HTMLButtonElement).click()
          }, 100);
        } else {
          // ! in case error
          this.ms.sendMessage("alert", {
            type: "danger",
            text: res.data.error,
          });

        }
      } else {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
      }
    });
  };

  //  create a new vehicle
  public addVehicle = (lot: string): void => {
    this.loading = true
    if (lot != null) {
      this.master.sendPost('createInventoryVehicle', { lotId: lot }, res => {
        this.loading = false
        if (res) {
          if (res.status == 200) {
            // * success
            if (!res.data.inventorySetupComplete) {

              this.deleteVehicle(res.data.id)
              let admins = this.store.userAccount['userRoles'].userRoles.filter(el => el.role.name == 'Administrator')
              this.isAdmin = admins.length > 0 ? true : false;
              setTimeout(() => {
                (document.getElementById('btn-alertModalSetup') as HTMLButtonElement).click()
              }, 300);

            } else {

              // this.vehicle['inventoryId'] = res.data.id
              this.auxVehicle['inventoryId'] = res.data.id
              this.getData(this.auxVehicle['inventoryId'], this.lotId, false)
            }
          } else {
            // ! in case error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })
    }
  }
  // save all
  public saveAll = () => {
    (document.getElementById("btn-save-generalInformation") as HTMLButtonElement).click();
    (document.getElementById(
      "btn-save-financials"
    ) as HTMLButtonElement).click();
    (document.getElementById(
      "btn-save-administrative"
    ) as HTMLButtonElement).click();
  };

  // =============================
  // todo: inventory detail
  // =======================
  public getGeneralInformation = (res) => {

    // this.allData.generalInformation = res.value
    // this.auxVehicle['mileage'] = res.value['miles']
    this.auxVehicle['vinNumber'] = res.value['vinNumber']
    this.auxVehicle['stockNumber'] = res.value['stockNumber']
    // this.auxVehicle['odometerStatus'] = res.value['odometer']
    this.auxVehicle['saleVehicleTransactionTypeId'] = 1
    // this.vinLookup = res.value['stockNumber']
    this.auxVehicle['data'] = {
      "vinNumber": res.value['vinNumber'],
      "modelYear": res.value['modelYear'],
      "make": res.value['make'],
      "model": res.value['model'],
      "exteriorColor": res.value['exteriorColor']
    }
    // this.save()
    // this.auxiliar = this
    this.numSaves = this.numSaves + 1

    if (this.numSaves == 2) {
      this.numSaves = 0
      this.questDelete(true)
    }

  }
  public getFinancials = (res) => {

    // this.auxVehicle['sellingPrice'] = res.value['sellingPrice']
    // this.allData.financials = res.value
    this.numSaves = this.numSaves + 1
    this.auxVehicle['sellingPrice'] = res.value['sellingPrice']
    if (this.numSaves == 2) {
      this.numSaves = 0
      this.questDelete(true)
    }
  }

  // =============================
  // todo: delete
  // =======================
  public questDelete = (useThis: boolean) => {

    if (this.auxVehicle['vinNumber'] && this.auxVehicle['stockNumber']) {
      //
      if (useThis) {
        this.vinLookup = this.auxVehicle['stockNumber']
        this.save(false, this.auxVehicle);
      } else {
        this.auxVehicle = {}
      }
      setTimeout(() => {
        this.viewDetail = false;
        (document.getElementById('closeInventoryModal') as HTMLButtonElement).click()
      }, 500);
    } else {
      // ! invalid information
      let result = window.confirm('The information is not valid, if it leaves the view the inventory will be eliminated, do you want to leave?');

      if (result) {
        this.master.sendPost("deleteInventory", { inventoryId: this.auxVehicle['inventoryId'] }, (res) => {
          if (res) {
            if (res.status == 200) {
              setTimeout(() => {
                // this.clearFields(false)
                this.viewDetail = false;
                (document.getElementById('closeInventoryModal') as HTMLButtonElement).click()
              }, 500);
            } else {
              this.ms.sendMessage("alert", { type: "danger", text: res.data.error });

            }
          } else {
            // in case API no response
            this.ms.sendMessage("alert", {
              type: "danger",
              text: this.words[this.language]['apiNoResponse'],
            });
          }
        }
        );
      }
    }
  }

  public deleteVehicle = (id) => {
    this.master.sendPost("deleteInventory", { inventoryId: id }, (res) => {
      if (res) {
        if (res.status == 200) {
          // this.ms.sendMessage("alert", { type: "success", text: 'delete' });
        } else {
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", {
          type: "danger",
          text: this.words[this.language]['apiNoResponse'],
        });
      }
    }
    );
  }
  public clearFields = (doSave: boolean) => {

    this.vehicle['mileage'] = 0
    this.vehicle['odometerStatus'] = ''
    this.vehicle['saleVehicleTransactionTypeId'] = 1
    this.vehicle['sellingPrice'] = 0
    this.vehicle['data'] = {
      "vinNumber": '',
      "modelYear": '',
      "make": '',
      "model": '',
      "exteriorColor": ''
    }

    this.vinLookup = ''
    // save

    if (doSave) {
      this.save(true, this.vehicle)
    } else {
      this.auxiliar = this.vehicle['inventoryId']
      this.vehicle['inventoryId'] = ''
    }
    setTimeout(() => {
      (document.getElementById('btn-close-modal-delete-stock') as HTMLButtonElement).click()
    }, 200);

  }
  /*
* life cycles
*/
  constructor(
    private ms: MessageService,
    private master: MasterService,
    private route: ActivatedRoute,
    private store: StoreService
  ) {
    this.saleId = this.route.snapshot.params["id"];
    this.lotId = this.route.snapshot.params["lotId"];
  }

  ngOnInit() {
    this.getVehicleDetail()
    //
    this.searchStockNumber()
  }
}
