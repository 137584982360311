import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MasterService } from "../../../../services/master.service";
import { MessageService } from "../../../../services/message.service";
import { Router, ActivatedRoute } from "@angular/router";
import * as languageLibrary from '../../../../services/language'
import { StoreService } from "src/app/services/store.service";

@Component({
  selector: "general-information-corporate",
  templateUrl: "./general-information.component.html",
  styleUrls: ["./general-information.component.scss"],
})
export class GeneralInformationCorporateComponent implements OnInit {
  /*
   * variables
   */
  @Output() childEmitter$:EventEmitter<Object>=new EventEmitter()
  // save the language
  @Input() language: string = 'EN'
  // set all words
  public words = languageLibrary.language
  //  define if loading state
  public loading: boolean = false;
  //  define if some changes not saved
  public savePending: boolean = false
  // save all data by type
  @Input() data: Object[] = [];
  // permissions
  @Input() permissions: Object[] = [];
  // define the contact type
  public type: string = "";
  // define the contact type
  public id: number = 0;
  // collapse/expand button
  public expanded: boolean = true;
  //
  public image = {}
  // Group of inputs
  public information = new FormGroup({
    entityId: new FormControl(0, [Validators.required]),
    entity: new FormControl("entity", [Validators.required]),
    website: new FormControl("", [
      Validators.pattern(/^([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/),
    ]),
    firstName: new FormControl("", []),
    lastName: new FormControl("", []),
    name: new FormControl("", [Validators.required]),
    dbaName: new FormControl("", []),
    dealerNumber: new FormControl("", [Validators.required]),
    einNumber: new FormControl("", []),
    createCommunication: new FormControl(false, [Validators.required]),
    updateGeneralInfo: new FormControl(true, [Validators.required]),
    createAddress: new FormControl(false, [Validators.required]),
    enableBHPH: new FormControl(false, [Validators.required])
  });
  // get information of inputs
  get website() {
    return this.information.get("website");
  }
  get firstName() {
    return this.information.get("firstName");
  }
  get lastName() {
    return this.information.get("lastName");
  }
  get dbaName() {
    return this.information.get("dbaName");
  }
  get dealerNumber() {
    return this.information.get("dealerNumber");
  }
  get einNumber() {
    return this.information.get("einNumber");
  }
  get name() {
    return this.information.get("name");
  }
  /*
   * Functions
   */
  // todo: detect changes
  changesPending = () => {

    if (!this.savePending) {
      this.savePending = true
      this.childEmitter$.emit({ message: 'changesPendingCorporate', section: 'generalInformation', value: this.savePending })
    }
  }
  public save = (): void => {
    if (this.information.valid) {
      this.loading = true;
      // in case lot
      if (this.type == "lot") {
        let dealership = parseInt(
          (document.getElementById("select_dealership") as HTMLSelectElement)
            .value
        );
        this.information.value["dealershipId"] = dealership;
      }
      if (this.type != 'lot') delete this.information.value['enableBHPH']

      this.master.sendPost('updateEntityContact', this.information.value, res => {
        this.loading = false
        if (res) {
          if (res.status == 200) {
            // * success

            this.childEmitter$.emit({ message: 'changesPendingCorporate', section: 'generalInformation', value: false })
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
            // reload date
            this.childEmitter$.emit({
              message: 'reloadCorporations',
              dealerNumber: this.information.value['dealerNumber'],
              name: this.information.value['name']
            })
            this.savePending = false

            let lotSelected=this.store.userAccount['user'].lots.findIndex(el => el['id'] == this.id)
            this.store.userAccount['user'].lots[lotSelected].enableBHPH=this.information.value['enableBHPH']

            // update lot list
            this.getLots()
          } else {

            let lotSelected=this.store.userAccount['user'].lots.findIndex(el => el['id'] == this.id)
            this.store.userAccount['user'].lots[lotSelected].enableBHPH=this.information.value['enableBHPH']

            // error
            this.ms.sendMessage("alert", {
              type: "danger",
              text: res.data.error,
            });
          }
        } else {
          // api no response
          this.ms.sendMessage("alert", {
            type: "danger",
            text: this.words[this.language]['apiNoResponse'],
          });
        }
      });
    }
  };

  //  get all lots
  public getLots = (): void => {
    this.master.sendPost('getUserAccount', {}, res => {
      if (res) {
        if (res.status == 200) {
          // * success
          this.ms.channelComponents$.emit({ message: 'updateLotList', lots: res.data.user.lots })
        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }

  // get iamege and convert to base64
  public changeImage = (e): void => {

    let files = []
    let reader = []
    let vm = this
    files = e.target.files
    for (let index = 0; index < files.length; index++) {
      // check if some file is invalid
      if (['image/jpg', 'image/jpeg', 'image/x-icon', 'image/png'].includes(files[index].type)) {
        // * format valid
        reader[index] = new FileReader();
        reader[index].readAsDataURL(files[index]);
        reader[index].onload = function (event) {
          console.log(reader[index].result);
          vm.image = {
            'corporateLogoURL': reader[index].result,
            'display': true,
          }
          vm.uploadImage(vm.image['corporateLogoURL'])
        }

      } else {
        // ! invalid format
        this.ms.sendMessage("alert", { type: "danger", text: 'Invalid image format' });
      }
    }
  }
  // remove image
  public removeImage = () => {
    (document.getElementById('image-upload-input') as HTMLInputElement).value = "";
    let index = this.image['corporateLogoURL'].indexOf('logos/')
    let file = this.image['corporateLogoURL'].substr(index + 6, this.image['corporateLogoURL'].length)
    this.image = {}
    this.master.sendPost('crudForLogo', { activity: 'delete', key: file }, res => {
      if (res) {
        if (res.status == 200) {
          // * success

          this.ms.channelComponents$.emit({ message: 'reloadLogo', value: {} })
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          // error
          this.ms.sendMessage("alert", {
            type: "danger",
            text: res.data.error,
          });
        }
      } else {
        // api no response
        this.ms.sendMessage("alert", {
          type: "danger",
          text: this.words[this.language]['apiNoResponse'],
        });
      }
    });
  }
  // upload image
  public uploadImage = (url: string): void => {
    this.loading = true
    let from = url.indexOf(',')
    let urlShort = url.substr(from + 1, url.length)
    this.master.sendPost('crudForLogo', { logo: urlShort, activity: 'upload' }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          setTimeout(() => {
            this.image = { corporateLogoURL: res.data.logoUrl, display: true }

            this.ms.channelComponents$.emit({ message: 'reloadLogo', value: this.image })
          }, 100);
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          // error
          this.ms.sendMessage("alert", {
            type: "danger",
            text: res.data.error,
          });
        }
      } else {
        // api no response
        this.ms.sendMessage("alert", {
          type: "danger",
          text: this.words[this.language]['apiNoResponse'],
        });
      }
    });

  };
  // upload image
  public toggleDisplay = (display: boolean): void => {
    this.master.sendPost('crudForLogo', { display: display, activity: 'update' }, res => {
      if (res) {
        if (res.status == 200) {
          // * success
          this.ms.channelComponents$.emit({ message: 'reloadLogo', value: this.image })
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          // error
          this.ms.sendMessage("alert", {
            type: "danger",
            text: res.data.error,
          });
        }
      } else {
        // api no response
        this.ms.sendMessage("alert", {
          type: "danger",
          text: this.words[this.language]['apiNoResponse'],
        });
      }
    });

  };

  /*
   * Life cycles
   */
  constructor(
    private route: ActivatedRoute,
    private ms: MessageService,
    private master: MasterService,
    private store: StoreService
  ) {
    this.type = this.route.snapshot.params["type"];
    this.id = parseInt(this.route.snapshot.params["id"]);

  }

  ngOnInit() {

    this.image = { corporateLogoURL: this.data['corporateLogoURL'], display: this.data['displayLogoInternal'] }

    this.information.setValue({
      entityId: this.id,
      entity: this.type,
      website: this.data["website"],
      firstName: this.data["firstName"],
      lastName: this.data["lastName"],
      name: this.data["name"],
      dbaName: this.data["dbaName"],
      dealerNumber: this.data["dealerNumber"],
      einNumber: this.data["einNumber"],
      createCommunication: false,
      updateGeneralInfo: true,
      createAddress: false,
      enableBHPH: this.type == 'lot' ? this.data["enableBHPH"] : false
    });
  }
}
