import { Component, OnInit, Input,Output,EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// services
import { MasterService } from '../../../../../services/master.service'
import { MessageService } from '../../../../../services/message.service'

@Component({
  selector: 'communications',
  templateUrl: './communications.component.html',
  styleUrls: ['./communications.component.scss']
})
export class CommunicationsComponent implements OnInit {
  /*
* variables
*/
  // save the language
  @Input() language: string =  'EN'
  // set all words
  @Input() words = {}
  //  define if loading state
  public loading: boolean = false
  // define the selected communication
  public communicationSelected: Object = { object: {}, index: -1 }
  // ontact object
  @Input() contact: Object = {}
  // permissions
  @Input() permissions: string[] = []
  // define if the design is minimalist
  @Input() isFlat:boolean=false
  // save all communications information
  @Input() communicationsList: Object[] = []
  // save the news communications
  public newsCommunications: Object[] = [
    {
      contactId: '',
      type: '',
      value: ''
    }
  ]
  //  define if some changes not saved
  public savePending: boolean = false
  // save all communications type
  public types: string[] = ['home', 'cellphone', 'fax', 'work', 'email', 'facebook', 'website']
  // Group of inputs
  public information = new FormGroup({})
  // update
  public informationUpdate = new FormGroup({
    contactId: new FormControl(0, [Validators.required]),
    type: new FormControl('', [Validators.required]),
    value: new FormControl('', [Validators.required])
  })
  //
  @Output() communications$:EventEmitter<Object> = new EventEmitter()
  // get information of inputs
  get value() { return this.informationUpdate.get('value') }
  get type() { return this.informationUpdate.get('type') }

  /*
* functions
*/

  /*
     ? Add
     */
  // todo: detect changes
  public changesPending = (): void => {
    if (!this.savePending) {
      this.savePending = true
      this.communications$.emit({ message: 'changesPendingContacts', section: 'communications', value: this.savePending })
    }
  }

  /*
  todo: save changes
  */
  public save = (): void => {
    this.newsCommunications = this.newsCommunications.filter(el => el['type'] != '' && el['value'] != '')

    this.newsCommunications.map(el => el['contactId'] = this.contact['id'])
    this.loading = true
    this.master.sendPost('createContactCommunitaction', { communications: this.newsCommunications }, res => {
      this.reloadFormules()
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.communications$.emit({  })
          //
          this.savePending = false
          // close modal
          if(!this.isFlat){
            // recharge user data
            this.communications$.emit({ message: 'changesPendingContacts', section: 'communications', value: this.savePending })
            // update data(date)
            this.communications$.emit({ message: 'reloadContactDetail' });
            (document.getElementById('close-modal-communications') as HTMLButtonElement).click();
          }
          // celan communication selected
          this.reloadCommunication()
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }

  /*
  todo: reload and clean the inputs
  */
  public reloadFormules = (): void => {
    let object = { ...this.information.value }
    let keys = Object.keys(object)
    for (let index = 0; index < keys.length; index++) {
      this.information.removeControl(keys[index])
    }
    this.information.addControl('type_0', new FormControl('', [Validators.required]));
    this.information.addControl('value_0', new FormControl('', [Validators.required]));
    //
    this.newsCommunications = []
    this.newsCommunications.push({ type: '', value: '' })

  }
  /*
  todo: clean Fields
  */
  public cleanFields = (): void => {
    this.information.reset()
    this.information.setValue({
      contactId: this.contact['id'],
      type: '',
      value: ''
    })

  }
  //todo: clean communication selected
  public reloadCommunication=()=>{
    this.communicationSelected = { object: {}, index: -1 }
  }
  /*
   ? delete
   */

  /*
  todo: select communication
  */
  public selectCommunication = (communication: Object, index: number): void => {
    this.communicationSelected['object'] = communication
    this.communicationSelected['index'] = index
  }
  /*
  todo: delete communication
  */
  public delete = (): void => {
    this.loading = true
    this.master.sendPost('deleteEntity', { polimorphEntity: "communication", polimorphId: this.communicationSelected['object'].id }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          this.reloadCommunication()
          this.communicationsList.splice(this.communicationSelected['index'], 1)
          if(!this.isFlat){
            (document.getElementById('close-delete-modal-communications') as HTMLButtonElement).click();
          }
          // ? in case email
          if (this.communicationSelected['object'].primary == 1) {
            let object = this.communicationsList.filter(el => el['type'] == this.communicationSelected['object'].type && el['primary'] == 0)

            if (object.length > 0) {
              object[0]['primary'] = 1
              object[0]['communicationId'] = object[0]['id']
              object[0]['contactId'] = this.contact['id']

              // select the new email
              if (this.communicationSelected['object'].type == 'email') {
                this.communications$.emit({ message: 'reloadEmail', email: object[0]['value'] })
              }
              this.togglePrimary(object[0], true)
            } else {
              // if no more email
              if (this.communicationSelected['object'].type == 'email') {
                this.communications$.emit({ message: 'reloadEmail', email: '' })
              }
            }
          }

        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // todo:alert quest delete
  public questDelete=(communication: Object, index: number)=>{
    if(confirm(this.words[this.language]['deleteCommunication'])){
      this.selectCommunication(communication,index)
      this.delete()
    }
  }
  /*
  ? update
  */

  /*
  todo: update some row
  */
  public update = (): void => {
    if (this.informationUpdate.valid) {
      this.loading = true
      let objectChanged = { ...this.informationUpdate.value }

      objectChanged['communicationId'] = this.communicationSelected['object']['id']

      this.master.sendPost('updateContactCommunitaction', objectChanged, res => {
        // set loading state
        this.loading = false
        if (res) {
          if (res.status == 200) {
            // * success
            // send message
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
            // recharge user data
            this.communicationsList[this.communicationSelected['index']]['value'] = this.informationUpdate.value['value']
            this.communicationsList[this.communicationSelected['index']]['type'] = this.informationUpdate.value['type']

            // close modal
            if(!this.isFlat){
              (document.getElementById('btn-close-communications-update') as HTMLButtonElement).click();
            }
            // clean the fields
            this.reloadCommunication()
            this.cleanFields()
          } else {
            // ! send message in case error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        }
      })
    }

  }
  /*
  todo: select communication update
  */
  public selectCommunicationUpdate = (communication: Object, index: number): void => {
    this.communicationSelected['object'] = communication
    this.communicationSelected['index'] = index
    this.informationUpdate.setValue({
      contactId: this.contact['id'],
      type: communication['type'],
      value: communication['value'],
    })
  }

  /*
  todo: change the comminucation-type
  */
  public changeType = (e): void => {
    this.changesPending()
    // this.information.get('type').setValue(e.target.value, {
    //   onlySelf: true
    // })
  }

  /*
 todo: define the identification default
 */
  public selectDefault = (objectCommunication: Object, type: string): void => {
    let communicationNegative = []
    // no toggle
    if (objectCommunication['primary'] == 1) {
      return
    }
    if (type == 'email') {
      communicationNegative = this.communicationsList.filter(el => el['type'] == 'email' && el['primary'] == 1)
      this.communications$.emit({ message: 'reloadEmail', email: objectCommunication['value'] })
    }
    // check the old primary
    communicationNegative = this.communicationsList.filter(el => el['type'] == type && el['primary'] == 1 && el['id'] != objectCommunication['id'])
    // deativate the primary
    if (communicationNegative.length > 0) {
      communicationNegative[0].primary = 0;
      communicationNegative[0]['communicationId'] = communicationNegative[0]['id']
      communicationNegative[0]['contactId'] = this.contact['id']
      this.togglePrimary(communicationNegative[0], false)
    }
    // change primary value
    objectCommunication['primary'] == 1 ? objectCommunication['primary'] = 0 : objectCommunication['primary'] = 1;
    // assignation of comminucationId and contactId
    objectCommunication['communicationId'] = objectCommunication['id']
    objectCommunication['contactId'] = this.contact['id']
    // send post toggle
    this.togglePrimary(objectCommunication, true)

  }

  /*
  todo: send post toggle
  */
  public togglePrimary = (objectCommunication: Object, sendMessage: boolean): void => {
    this.master.sendPost('updateContactCommunitaction', objectCommunication, res => {
      // set loading state
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          // send message
          if (sendMessage) {
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          }
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  /*
  todo: create a new communication
  */
  public addCommunication = (e, index: number, newId: number): void => {
    // remove the invalid form
    if (this.information.invalid && this.newsCommunications.length - 1 != index) {
      this.information.removeControl('type_' + (this.newsCommunications.length - 1))
      this.information.removeControl('value_' + (this.newsCommunications.length - 1))
      this.newsCommunications.pop()
    }

    if (index == this.newsCommunications.length - 1 && this.information.valid) {
      this.newsCommunications.push({ type: '', value: '' })
      this.information.addControl('type_' + newId, new FormControl('', [Validators.required]));
      this.information.addControl('value_' + newId, new FormControl('', [Validators.required]));

    }
  }

  /*
  * Life Cycles
  */
  constructor(private master: MasterService, private ms: MessageService) {
    // add controlers
    this.information.addControl('type_0', new FormControl('', [Validators.required]));
    this.information.addControl('value_0', new FormControl('', [Validators.required]));


  }

  ngOnInit() {

    this.informationUpdate.setValue({
      contactId: this.contact['id'],
      type: '',
      value: ''
    })
  }



}
