import { Component, Input, OnInit,EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// services
import { MessageService } from '../../../../services/message.service'
import { MasterService } from '../../../../services/master.service'
import * as languageLibrary from '../../../../services/language'
@Component({
  selector: 'inventory-setup',
  templateUrl: './inventory-setup.component.html',
  styleUrls: ['./inventory-setup.component.scss']
})
export class InventorySetupComponent implements OnInit {
  /*
  * Variables
  */
  @Output() childEmitter$:EventEmitter<Object>=new EventEmitter()
  //  state of loading
  public loading: boolean = false
  //  define if some changes not saved
  public savePending: boolean = false
  // save the language
  @Input() language: string = 'EN'
  // set all words
  public words = languageLibrary.language
  // define expanded
  public expanded: boolean = true
  // define the stock limit
  public lastStockNumber: number = 0
  // define the stages
  public stages: Object[] = []
  public inventorySetup: string = 'autoIncrement';
  // id inventory
  public inventoryId: string = null
  // model of setup value
  public SetupValues = {
    vinNumber: '6',
    increaseBy: '1',
    startingOn: '0'
  }
  // permissions
  @Input() permissions: string
  // entity
  @Input() entity: string
  // entity id
  @Input() entityId: string
  // entity id
  @Input() data: Object = { setup: '', inventoryStages: '', value: '', lastStock: '' }
  //
  @Input() inventories: boolean = false

  /*
  * functions
  */
  // todo: detect changes
  changesPending = () => {
    if (!this.savePending) {
      this.savePending = true
      this.childEmitter$.emit({ message: 'changesPendingCorporate', section: 'inventorySetup', value: this.savePending })
    }
  }
  // change value to stock number setup
  public saveInventorySetup = (): void => {
    //
    let params = {
      "entity": this.entity,
      "entityId": this.entityId,
      "setup": this.inventorySetup,
      "value": this.setValue(this.inventorySetup),
      "stages": this.stages,
      "inventorySetupId": this.inventoryId,
      "useOriginalOnTrade": this.data['useOriginalOnTrade'],
      "inventorySetupComplete": true
    }
    const tradeData = this.stages.filter(el => el['stage'] == 'Trade-in')
    // check trade-in
    if (this.data['useOriginalOnTrade'] && tradeData[0]['prefix'] == "" && tradeData[0]['suffix'] == "") {
      this.ms.sendMessage("alert", { type: "danger", text: 'In order to use the original Stock numbers for trade-ins, an add on must be set up in order to prevent duplicate Stock numbers"' });
      return
    }
    if (!(params['value'] == undefined && params['setup'] == 'increaseBy')) {
      if (!this.checkRepeat(params['stages'])) {
        this.loading = true
        this.master.sendPost('inventorySetup', params, res => {
          this.loading = false
          if (res) {
            if (res.status == 200) {
              this.savePending = false
              this.childEmitter$.emit({ message: 'changesPendingCorporate', section: 'inventorySetup', value: this.savePending })
              if (!this.inventoryId) {
                this.inventoryId = res.data.inventorySetupId
                this.childEmitter$.emit({ message: 'reloadCorporationDetail' })
              }
              // * success
              this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
            } else {
              // ! in case error
              this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
            }
          } else {
            // in case API no response
            this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
          }
        })
      } else {
        this.ms.sendMessage("alert", { type: "warning", text: 'Some values are repeat' });
      }
    }
  }
  // chek repeat
  public checkRepeat = (stages: any[]): boolean => {

    let prefixArray = []
    let suffixArray = []
    stages.forEach(element => {
      if (element.prefix != null) {
        prefixArray.push((element.prefix).toLowerCase())
      }
      if (element.suffix != null) {
        suffixArray.push((element.suffix).toLowerCase())
      }
    });
    suffixArray = suffixArray.filter(el => el != '')
    prefixArray = prefixArray.filter(el => el != '')

    let isPrefixUnique = prefixArray.length === new Set(prefixArray).size
    let isSuffixUnique = suffixArray.length === new Set(suffixArray).size


    return !isSuffixUnique || !isPrefixUnique

  }
  // build a value to inventory setup
  public setValue = (type: string): string => {
    switch (type) {
      case 'manually':
        return null;
      case 'vinNumber':
        return this.SetupValues.vinNumber
      case 'increaseBy':
        let value = this.SetupValues.increaseBy
        let value2 = this.SetupValues.startingOn
        return value + '-' + value2

      // if ((value && +value > 0) && (value2 && +value2 >= 0) && (+value2 >= this.lastStockNumber)) {
      //   // this.lastStockNumber= +value2
      // } else {
      //   this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language][ 'inventorySetupRules' ]});
      // }

    }


  }
  // get value of setup
  public setInputValue = (type: string, value: string): void => {
    switch (type) {
      case 'vinNumber':
        this.SetupValues.vinNumber = value;
        break;
      case 'increaseBy':
        let index = value.indexOf('-');
        this.SetupValues.increaseBy = value.substring(0, index);
        this.SetupValues.startingOn = value.substring(index + 1, value.length);
      default:
        break;
    }
  }

  /*
  * life cycles
  */
  constructor(private ms: MessageService, private master: MasterService) {

  }

  ngOnInit() {
    this.inventorySetup = this.data['setup'] ? this.data['setup'] : "manually"
    if (this.data['value'] == null) {
      this.SetupValues.startingOn = ''
    }
    this.stages = this.data['inventoryStages']
    this.inventoryId = this.data['inventorySetupId']
    this.setInputValue(this.inventorySetup, this.data['value'])
    this.lastStockNumber = this.data['lastShowStock'] ? this.data['lastShowStock'] : 0

  }


}
