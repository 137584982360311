import { Component, OnInit } from '@angular/core';
import { MasterService } from '../../services/master.service'
import { MessageService } from '../../services/message.service'
import { StoreService } from '../../services/store.service'
import * as languageLibrary from '../../services/language'
import { useAnimation } from '@angular/animations';
@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss']
})
export class SecurityComponent implements OnInit {
  /*
  * Variables
  */
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  // define  rol name selected
  public roleSelected: Object = { name: '', id: '' }
  // define rol object selected
  public roleTarget: Object = {}
  // define the role in editing
  public roleEditing: Object = { editing: false, role: {} }
  //  define the roles Array
  public roles: Object[] = []
  // define the name of new rol
  public newRole: string = ''
  // define if the input to add new rol is visible
  public newInputVisible: boolean = false
  // define the status loading
  public loading: boolean = true
  // permissions
  public permissions: Object[] = []
  //
  public section = 0
  //
  public integrations = []
  /*
    * Functions
    */
  // integrations
  // get permissions
  public getintegrations = (): void => {
    // this.loading = true
    this.section = 1
    this.master.sendPost('CRUDForIntegrationsPermissions', { activity: 'read', roleId: this.roleSelected['id'] }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.integrations = res.data.integrations
        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // toggle function to show or hide the input to add new rol
  public toggleInput = (): void => {
    this.newInputVisible = !this.newInputVisible
    this.newRole = ''
  }
  // toggle function to show or hide the input to add new rol
  public addRole = (e): void => {
    if ((e.key == 'Enter' || e.type == "click") && this.newRole.length > 0) {
      // insert new role
      this.master.sendPost('createRole', { name: this.newRole }, res => {
        if (res) {
          if (res.status == 200) {
            // * success
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
            // reload roles
            this.getRoles(false)
            // reload roles
            this.ms.channelComponents$.emit({ message: 'reloadRoles' })
          } else {
            // ! in case error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })
      // change input state
      this.toggleInput()
    }
  }
  // set new rol selected
  public toggleRole = (role: Object): void => {
    if (this.roleSelected['name'] == '') {
      this.roleSelected = { ...role }
      this.ms.channelComponents$.emit({ message: 'changeRole', role: role })
    } else if (this.roleSelected['name'] != role['name']) {
      this.roleSelected = { ...role }
      this.ms.channelComponents$.emit({ message: 'changeRole', role: role })
    } else {
      this.roleSelected['name'] = ''

    }
  }
  // changePermission
  public changePermission = (integrationId: number, use: boolean, configure: boolean) => {
    this.master.sendPost('CRUDForIntegrationsPermissions', { activity: 'update', permission: { roleId: this.roleSelected['id'], integrationId: integrationId, use: use, configure: configure } }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          for (let index = 0; index < this.store.userAccount['integrationsPermissions'].length; index++) {
            if (this.store.userAccount['integrationsPermissions'][index]['id'] == this.roleSelected['id']) {
              this.store.userAccount['integrationsPermissions'][index].integrationsPermissions.forEach(el => {
                if (el.id == integrationId) {
                  el['use'] = use
                  el['configure'] = configure
                }
              });
            }
          }

          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // select a role
  public selectRole = (role: Object): void => {
    this.loading = true
    setTimeout(() => {
      this.roleSelected = { ...role }
      this.getintegrations()
      this.loading = false
    }, 0);
  }
  /*
  ? define role is updating
  */
  public toggleUpdateRole = (role: Object): void => {
    this.roleEditing['editing'] = !this.roleEditing['editing']
    this.roleEditing['role'] = role
  }
  /*
  * save new name role
  */
  public updateName = (e, role: Object): void => {
    // check if event is click o key enter, and check if role name is valid
    if ((e.key == 'Enter' || e.type == 'click') && role['name'] != '') {
      // start update
      this.master.sendPost('updateRoles', { roleId: role['id'], name: role['name'] }, res => {
        if (res) {
          if (res.status == 200) {
            // * success
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
            // chage status
            this.roleEditing['editing'] = false
            if (this.roleSelected['name'] == '') {
              // reload roles
              this.ms.channelComponents$.emit({ message: 'reloadRoles' })
            } else {
              // change roleName selected
              this.roleSelected['name'] = role['name']
            }
          } else {
            // ! error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          // api no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })

    }
  }
  /*
  * copy role
  */
  public copyRole = (role: Object): void => {
    this.master.sendPost('copyRole', { roleId: role['id'] }, res => {
      if (res) {
        if (res.status == 200) {
          // * success
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          // reload roles
          this.getRoles(false)
          // reload roles
          this.ms.channelComponents$.emit({ message: 'reloadRoles' })
        } else {
          // ! error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // api no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // preseleted a role
  public showModal = (role: Object): void => {
    (document.getElementById('delete-modal') as HTMLButtonElement).click()
    this.roleTarget = { ...role };
  }
  /*
  * delete role
  */
  public deleteRole = (role: Object): void => {

    this.master.sendPost('deleteRole', { roleId: role['id'] }, res => {
      if (res) {
        if (res.status == 200) {
          // * success
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          // reload views
          this.roleSelected['name'] = ''
          // reload roles
          this.getRoles(false)
          // reload roles
          this.ms.channelComponents$.emit({ message: 'reloadRoles' });
          // close modal
          (document.getElementById('closeModalDelete') as HTMLButtonElement).click()
        } else {
          // ! error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // api no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  /*
  ? get all roles
  @params: loading define if set value to loading
  */

  public getRoles = (loading: boolean): void => {
    if (loading) {
      this.loading = true
    }
    this.master.sendPost('getRoles', {}, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.roles = res.data.roles
        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
      // todo: listen to output of navbar
public listenerNav=(e):void=>{

  switch (e.message) {

    case 'changeLanguage':
      this.language = e.value
      break;
    case 'setPermissions':
      // permissions on user list
      this.permissions = e.permissions[3]['childrens']

        this.getRoles(false)
      break;

    default:
      break;
  }
}
  /*
    * Life cycles
    */

  constructor(private master: MasterService, private ms: MessageService, private store: StoreService) {

  }

  ngOnInit() {

    // normal
    if (this.store.userAccount['permissions']) {
      // permissions on user list
      this.permissions = this.store.userAccount['permissions'][3]['childrens']
      // if not have permissions, return to account settings
      if (this.permissions[0]['permissions'].includes('view')) {
        this.getRoles(true)
      }
    }


  }



}
