import { Component, Input, OnInit } from '@angular/core';
import { StoreService } from '../../../../../../services/store.service'
import { MessageService } from "../../../../../../services/message.service";
import { MasterService } from "../../../../../../services/master.service";
import { ActivatedRoute } from "@angular/router";
//
import * as languageLibrary from '../../../../../../services/language'
@Component({
  selector: 'trade-in',
  templateUrl: './trade-in.component.html',
  styleUrls: ['./trade-in.component.scss']
})
export class TradeInComponent implements OnInit {
  // =========================
  // todo: ----- variables -----
  // =========================
  @Input() isUnLock:boolean = true
  //
  public loading:boolean = false
  // contacins vehicle
  @Input() vehicleSelected:Object = {}
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words:any = languageLibrary.language
  // sale ID
  public saleId:string = ''
  //
  public lotId:string = ''
  //
  public previousPurchases:Object[] = []
  //
  public previousPurchasesComplete:any[] = []
  //
  @Input() tradesIn:Object[] = []
  //
  public auxVehicle:Object = {}
  //
  public viewDetail: boolean = false
  //
  public data:Object
  //
  public vinNumber:string
  //
  public stockNumber:string
  //
  public data2:Object
  //
  public isTradeIn:boolean = false
  //
  public isAdmin:boolean = false
  //
  public preSelected:Object = {}
  //
  public numSaves:number = 0
  //
  @Input() lienholders:Object[] = []
  // displayList
    public displayList: boolean = false
  // =========================
  // todo: ----- functions -----
  // =========================
  // add new sale
  public addSale = () => {

    this.tradesIn.push({
      inventory: {
        stockNumber: "",
        vehicleId: "",
        vehicle: {
          vinNumber: "",
          modelYear: "",
          make: "",
          model: "",
          exteriorColor: "",
          miles: "",
          lotId: 1,
          lot: {
            "name": "Lot 1"
          }
        }
      },


    })
  }
// toggle of modal trade-in modal
  public showLookupList = (i:number): void => {
    this.displayList =  !this.displayList
    if (this.displayList) {
      (document.getElementById('tradeInlookup-list_'+i) as HTMLInputElement).style.display = "block";
    } else
      (document.getElementById('tradeInlookup-list_'+i)as HTMLInputElement).style.display = "none";

  }
// get all previous vehicles purchased
  public getCustomerPurchases = ():void => {

    this.master.sendPost('getCustomerPurchases', { saleId: this.saleId }, res => {
      if (res) {
        if (res.status == 200) {
          let previos = res.data.previousPurchases.filter(el => el['sale'].saleVehicles.length > 0)
          previos.length > 0 ? this.previousPurchases = previos : this.previousPurchases = []
          this.previousPurchases.forEach(el => el['sale']['saleVehicles'][0].inventory['prevSaleId'] = el['sale'].id)

          this.previousPurchasesComplete = this.previousPurchases.slice()

        } else {
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
// select the new trade in
  public selectSale = (data:Object, index:number):void => {
    // check if the vehicle selected does not selected previously
    let repeats = this.tradesIn.filter(el => el['inventory']['originStockNumber'] == data['stockNumber'])

    if (repeats.length == 0) {

      if (this.tradesIn[index]['id']) {
        // todo: when replace
        this.deleteTradeIn(this.tradesIn[index]['id'], index, res => {
          if (res) {

            this.tradesIn[index]['inventory'] = { ...data }
            // save
            this.createNew(this.tradesIn[index]['inventory']['id'], data)
          }
        })
      } else {
        // ? when create
        this.tradesIn[index]['inventory'] = { ...data }
        // save
        this.createNew(this.tradesIn[index]['inventory']['id'], data)
      }
    } else {
      this.ms.sendMessage("alert", { type: "warning", text: 'This vehicle already exist in this sale like trade-in' });
    }
  }
// create new trade in
  public createNew = (inventoryId, data) => {

    this.master.sendPost('createTradeIn', { inventoryId: inventoryId, lotId: this.lotId, saleId: this.saleId, prevSaleId: data['prevSaleId'] }, res => {
      if (res) {
        if (res.status == 200) {

          this.refreshTradeIn()

        } else {
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // check if the new vehicle is valid or not
  public questDelete = (useThis: boolean, index) => {

    if (this.auxVehicle['vinNumber'] && this.auxVehicle['stockNumber']) {
      //
      if (useThis) {
        // ? isTradeIn = true => new asosiate
        // ? isTradeIn = false => just edit
        if (this.isTradeIn) {
          this.asosiateTrateIn(this.saleId, this.auxVehicle['inventoryId'])
        } else {
          this.auxVehicle = {}
          this.refreshTradeIn()
        }
      } else {
        this.auxVehicle = {}
      }
      setTimeout(() => {
        this.viewDetail = false;
        (document.getElementById('closeInventoryModal') as HTMLButtonElement).click()
      }, 500);
    } else {
      // ! invalid information
      let result = window.confirm('The information is not valid, if it leaves the view the inventory will be eliminated, do you want to leave?');

      if (result) {
        this.master.sendPost("deleteInventory", { inventoryId: this.auxVehicle['inventoryId'] }, (res) => {
          if (res) {
            if (res.status == 200) {
              setTimeout(() => {
                // this.clearFields(false)
                this.viewDetail = false;
                (document.getElementById('closeInventoryModal') as HTMLButtonElement).click()
              }, 500);
            } else {
              this.ms.sendMessage("alert", { type: "danger", text: res.data.error });

            }
          } else {
            // in case API no response
            this.ms.sendMessage("alert", {
              type: "danger",
              text: this.words[this.language]['apiNoResponse'],
            });
          }
        }
        );
      }
    }
  }
// reload trade in
  public refreshTradeIn = ():void => {
    this.master.sendPost('getSaleVehicleDetails', { saleId: this.saleId }, res => {
      if (res) {
        if (res.status == 200) {
          if (res.data.data.length > 0) {

            // trade in
            this.tradesIn = res.data.tradeIns
            // retail vehicle
            this.getCustomerPurchases()
          }
        } else {
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
// assosiate a vehicle to trade in
  public asosiateTrateIn = (saleId, inventoryId):void => {
    this.master.sendPost('associateExternalTradeIn', { inventoryId: inventoryId, saleId: saleId }, res => {
      if (res) {
        if (res.status == 200) {
          this.auxVehicle = {}
          this.refreshTradeIn()

        } else {
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
// search vehicle on table
  public searchData = (word: string, e:any):void => {
    word = word.toLowerCase()

    this.previousPurchases = this.previousPurchasesComplete.filter(el =>
      this.convertString(el.sale.saleVehicles[0].inventory['stockNumber']).indexOf(word) >= 0 ||
      this.convertString(el.sale.saleVehicles[0].inventory.vehicle['vinNumber']).indexOf(word) >= 0 ||
      this.convertString(el.sale.saleVehicles[0].inventory.vehicle['modelYear']).indexOf(word) >= 0 ||
      this.convertString(el.sale.saleVehicles[0].inventory.vehicle['make']).indexOf(word) >= 0 ||
      this.convertString(el.sale.saleVehicles[0].inventory.vehicle['model']).indexOf(word) >= 0 ||
      this.convertString(el.sale.saleVehicles[0].inventory.vehicle['exteriorColor']).indexOf(word) >= 0 ||
      this.convertString(el.sale.saleVehicles[0].inventory.vehicle.lot.name).indexOf(word) >= 0
    )


  }
  // converto to string for search
  public convertString = (word: any): string => {
    if (word == null) {
      return ''
    } else {
      return word.toString().toLowerCase()
    }

  }
  //  get data of vehicle
  public getData = (id: string, lotId: string): void => {

    this.loading = true;
    this.master.sendPost("getInventoryVehicle", { inventoryId: id, lotId: lotId }, (res) => {
      if (res) {
        if (res.status == 200) {
          // * success
          this.data = res.data;
          this.vinNumber = res.data.inventory.generalInformation.vinNumber;
          this.stockNumber = res.data.inventory.generalInformation.stockNumber;
          this.auxVehicle['vinNumber'] = this.vinNumber
          this.auxVehicle['stockNumber'] = this.stockNumber
          this.auxVehicle['inventoryId'] = id
          this.auxVehicle['mileage'] = res.data.inventory.generalInformation['miles']
          this.auxVehicle['vinNumber'] = res.data.inventory.generalInformation['vinNumber']
          this.auxVehicle['stockNumber'] = res.data.inventory.generalInformation['stockNumber']
          this.auxVehicle['odometerStatus'] = res.data.inventory.generalInformation['odometer']
          this.auxVehicle['saleVehicleTransactionTypeId'] = 1
          this.auxVehicle['data'] = {
            "vinNumber": res.data.inventory.generalInformation.vinNumber,
            "modelYear": res.data.inventory.generalInformation['modelYear'],
            "make": res.data.inventory.generalInformation['make'],
            "model": res.data.inventory.generalInformation['model'],
            "exteriorColor": res.data.inventory.generalInformation['exteriorColor']
          }
          //
          this.getData2(id)

        } else {
          this.ms.sendMessage("alert", {
            type: "danger",
            text: res.data.error,
          });

        }
      }
    }
    );
  };
  // get data of reaconditioning cost and floor plans
  public getData2 = (id: string): void => {
    this.master.sendPost("inventoryDetails", { vehicleId: id }, (res) => {
      this.loading = false;
      if (res) {
        if (res.status == 200) {
          // * success

          this.data2 = res.data;

          this.viewDetail = true;
          // open modal
          setTimeout(() => {
            (document.getElementById('tradeInModalButton') as HTMLButtonElement).click()
          }, 100);
        } else {
          // ! in case error
          this.ms.sendMessage("alert", {
            type: "danger",
            text: res.data.error,
          });

        }
      } else {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
      }
    });
  };
  //  create a new vehicle
  public addVehicle = (lot: string, index: number): void => {
    this.loading = true
    this.isTradeIn = true
    if (lot != null) {
      this.master.sendPost('createInventoryVehicle', { lotId: lot }, res => {
        this.loading = false
        if (res) {
          if (res.status == 200) {
            // * success

            if (!res.data.inventorySetupComplete) {
              this.deleteVehicle(res.data.id)
              let admins = this.store.userAccount['userRoles'].userRoles.filter(el => el.role.name == 'Administrator')
              this.isAdmin = admins.length > 0 ? true : false;
              setTimeout(() => {
                (document.getElementById('btn-alertModalSetupTI') as HTMLButtonElement).click()
              }, 300);

            } else {

              // this.vehicle['inventoryId'] = res.data.id
              this.auxVehicle['inventoryId'] = res.data.id
              this.auxVehicle['index'] = index

              this.getData(this.auxVehicle['inventoryId'], this.lotId)
            }
          } else {
            // ! in case error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })
    }
  }
  // delete a vehicle
  public deleteVehicle = (id:void):void => {
    this.master.sendPost("deleteInventory", { inventoryId: id }, (res) => {
      if (res) {
        if (res.status == 200) {
          // this.ms.sendMessage("alert", { type: "success", text: 'delete' });
        } else {
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", {
          type: "danger",
          text: this.words[this.language]['apiNoResponse'],
        });
      }
    }
    );
  }
  // =============================
  // todo: inventory detail
  // =======================
  // listener to general information
  public getGeneralInformation = (res):void => {

    this.auxVehicle['vinNumber'] = res.value['vinNumber']
    this.auxVehicle['stockNumber'] = res.value['stockNumber']
    this.auxVehicle['saleVehicleTransactionTypeId'] = 1
    this.auxVehicle['data'] = {
      "vinNumber": res.value['vinNumber'],
      "modelYear": res.value['modelYear'],
      "make": res.value['make'],
      "model": res.value['model'],
      "exteriorColor": res.value['exteriorColor']
    }
    this.numSaves = this.numSaves + 1
    if (this.numSaves == 2) {
      this.numSaves = 0
      this.questDelete(true, this.auxVehicle['index'])
    }
  }
  // listener to financials
  public getFinancials = (res):void => {

    this.auxVehicle['sellingPrice'] = res.value['sellingPrice']
    // this.allData.financials = res.value
    this.numSaves = this.numSaves + 1

    if (this.numSaves == 2) {
      this.numSaves = 0
      this.questDelete(true, this.auxVehicle['index'])
    }
  }
  // save all
  public saveAll = () => {
    (document.getElementById("btn-save-generalInformation") as HTMLButtonElement).click();
    (document.getElementById(
      "btn-save-financials"
    ) as HTMLButtonElement).click();
    (document.getElementById(
      "btn-save-administrative"
    ) as HTMLButtonElement).click();
    //


  };
// delete trade in
  public deleteTradeIn = (inventoryId:number, index:number, callback?):void => {
    this.loading=true
    this.master.sendPost('deleteTradeIn', { saleVehicleId: inventoryId, prevSaleId: this.tradesIn[index]['inventory'].prevSaleId }, res => {
      this.loading=false
      if (res) {
        if (res.status == 200) {
          if (callback) {
            // in case  select another vehicle
            callback(true)
          } else {
            // in case delete trade in
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
            this.tradesIn.splice(index, 1)
            setTimeout(() => {
              this.preSelected = {};
              this.getCustomerPurchases();
              (document.getElementById('btn-close-modal-delete-trade') as HTMLButtonElement).click();
            }, 100);
          }
        } else {
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })

  }
// select a vehicle for view detail
  public selectForDetail = (inventoryId, index) => {
    this.auxVehicle['inventoryId'] = inventoryId
    this.auxVehicle['index'] = index
    this.isTradeIn = false
    this.getData(this.auxVehicle['inventoryId'], this.lotId)
  }
  //
  public preData = (id, index) => {
    this.preSelected = { id: id, index: index }
  }

  // =============================
  // todo: save
  // =======================
  public save = (data) => {

    this.master.sendPost('updateSaleVehicle', {
      saleVehicleData: data,
      saleVehicleId: data['id'],
      clear: false
    }, res => {
      if (res) {
        if (res.status == 200) {

          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }

  // =========================
  // todo: ----- life cycles -----
  // =========================
  constructor(
    private ms: MessageService,
    private master: MasterService,
    private route: ActivatedRoute,
    private store: StoreService
  ) {
    this.saleId = this.route.snapshot.params["id"];
    this.lotId = this.route.snapshot.params["lotId"];

  }

  ngOnInit() {
    this.getCustomerPurchases()

  }

}
