import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// services
import { MasterService } from '../../services/master.service'
import { MessageService } from '../../services/message.service';
import { StoreService } from '../../services/store.service';
import * as languageLibrary from '../../services/language'

@Component({
  selector: 'account-settings-form',
  templateUrl: './account-settings-form.component.html',
  styleUrls: ['./account-settings-form.component.scss']
})
export class AccountSettingsFormComponent implements OnInit {
  /*
* Variables
*/
  public dataCorporate = {}
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  // user informaion
  @Input() user
  // define the status of end-point
  public loading: boolean = false
  // define if the security is enabled
  public isDisabled: boolean = true
  // list of languages
  public languagesList: object[] = [{ name: 'english', value: 'EN' }, { name: 'spanish', value: 'ES' }]
  // Group of inputs
  public settingsGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    GDN: new FormControl('', [Validators.required]),
    corpId: new FormControl('', [Validators.required]),
  })
  // Group of inputs
  public billingInformation = new FormGroup({
    contactFirstName: new FormControl('', [Validators.required]),
    contactLastName: new FormControl('', [Validators.required]),
    billingAdmin: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.email]),
    phoneNumber: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    address2: new FormControl('', [Validators.required]),
    zipCode: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
  })
  // get information of inputs
  get email() { return this.settingsGroup.get('email') }
  // get information of language
  get languagePreference() { return this.settingsGroup.get('languagePreference') }
  // get nformation of method recovery
  get methodControl() { return this.settingsGroup.get('methodControl') }
  // get information to code access security
  get codeSecurity() { return this.settingsGroup.get('codeSecurity') }

  /*
  * functions
  */


  /*
todo: change the language preferences
*/
  public changeLanguage = (e): void => {
    this.settingsGroup.get('name').setValue(e.target.value, {
      onlySelf: true
    })
  }
  /*
  todo: enable the security inputs
  */

  public enableSecurity = (): void => {
    this.isDisabled = !this.isDisabled
    let input = (document.getElementById('smsrb') as HTMLInputElement)
    input.disabled = this.isDisabled
    input = (document.getElementById('emailrb') as HTMLInputElement)
    input.disabled = this.isDisabled
  }

  // get data
  public getData = (id): void => {
    this.master.sendPost('corporateBillingAdminDispatcher', { params: { corporateId: id }, activity: 'getCorporateBillingAdmin' }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.dataCorporate = res.data
          this.billingInformation.get('contactFirstName').setValue(res.data.billingAdmin[0].corporatesUsers[0].contact.firstName)
          this.billingInformation.get('contactLastName').setValue(res.data.billingAdmin[0].corporatesUsers[0].contact.lastName)
          this.billingInformation.get('email').setValue(res.data.billingAdmin[0].email)
          this.billingInformation.get('phoneNumber').setValue(res.data.billingAdmin[0].corporatesUsers[0].corporate.phone)
          this.billingInformation.get('billingAdmin').setValue(res.data.billingAdmin[0].corporatesUsers[0].id)
          this.getCorporateDetail(id)

        } else {
          // ! error 
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // api no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // change billing
  public changeBilling = () => {
    let user=this.dataCorporate['allUsers'].find(el => el.id === +this.billingInformation.value['billingAdmin'])
    this.billingInformation.get('contactFirstName').setValue(user.corporatesUsers[0].contact.firstName)
    this.billingInformation.get('contactLastName').setValue(user.corporatesUsers[0].contact.lastName)
    this.billingInformation.get('email').setValue(user.email)
  }
  public save=()=>{
    this.loading=true
    this.master.sendPost('corporateBillingAdminDispatcher', { params: {old:this.dataCorporate['billingAdmin'][0].id,new:+this.billingInformation.value['billingAdmin']}, activity: 'changeBillingAdmin' }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          // ! error 
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // api no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }

  /*
  ?: get corporate detail
  */
  public getCorporateDetail = (id): void => {
    this.master.sendPost('corporateDetails', { entity: 'corporate', entityId: id }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.settingsGroup.setValue({
            name: res.data.data.name,
            GDN: res.data.data.dealerNumber,
            corpId: res.data.data.id,
          })
          // select address
          let address = res.data.data.addresses.find(el => el.primaryAddress == 1 && el.type == 'Billing')
          this.billingInformation.get('address').setValue(address ? address.address : '')
          this.billingInformation.get('address2').setValue(address ? address.address2 : '')
          this.billingInformation.get('zipCode').setValue(address ? address.zipCode : '')
          this.billingInformation.get('city').setValue(address ? address.city : '')
          this.billingInformation.get('state').setValue(address ? address.state : '')
        } else {
          // ! error 
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // api no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }

  /*
  todo: life cycles
  */
  constructor(private master: MasterService, private ms: MessageService, private store: StoreService) {
    // detect change language
    this.ms.channelComponents$.subscribe(res => {
      if (res.message == "changeLanguage") {
        this.language = res.value
      }
    })

  }

  ngOnInit() {
    this.getData(this.user.corporateId)
  }








}
