import { Component, OnInit , EventEmitter,Output, Input} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { MasterService } from '../../../../services/master.service';
import { MessageService } from '../../../../services/message.service';
import * as languageLibrary from '../../../../services/language'
@Component({
  selector: 'table-user-add',
  templateUrl: './table-user-add.component.html',
  styleUrls: ['./table-user-add.component.scss']
})
export class TableUserAddComponent implements OnInit {
  /*
* variables
*/
  // save the language
  @Input() language: string = 'EN'
  // set all words
  public words = languageLibrary.language
  // New Users inviteds
  public usersInviteds: Object[] = [{ firstName: '', lastName: '', email: '' }]
  // save the invities success and failed
  public invitations = { success: [], failed: [] }
  // form
  public usersForm = new FormGroup({})
  // define if is loading
  public loading: boolean = false
  // save the inputs status
  public disabled: boolean = false
  // define if the modal is open
  public isOpen: boolean = false
  //
  @Output()  tableEmitter$:EventEmitter<Object>=new EventEmitter()
  /*
  * functions
  */

  /*
  todo: reload the inputs
  */
  public reloadInputs = (): void => {
    this.usersForm.reset()
    for (let index = 1; index < this.usersInviteds.length; index++) {
      this.usersForm.removeControl('email_' + index)
      this.usersForm.removeControl('lastName_' + index)
      this.usersForm.removeControl('firstName_' + index)
    }

    this.disabled = false
    this.loading = false
    this.usersInviteds = [{ firstName: '', lastName: '', email: '' }]
    this.invitations = { success: [], failed: [] }
  }
  /*
  todo: add or remove  inputs to form
  */
  public addUserInvited = (e, index: number, newId: number): void => {
    if (this.usersForm.invalid && this.usersInviteds.length - 1 != index) {
      this.usersForm.removeControl('firstName_' + (this.usersInviteds.length - 1))
      this.usersForm.removeControl('lastName_' + (this.usersInviteds.length - 1))
      this.usersForm.removeControl('email_' + (this.usersInviteds.length - 1))
      this.usersInviteds.pop()
    }

    if (index == this.usersInviteds.length - 1 && this.usersForm.valid) {
      this.usersInviteds.push({ firstName: '', lastName: '', email: '' })
      this.usersForm.addControl('firstName_' + newId, new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(1)]));
      this.usersForm.addControl('lastName_' + newId, new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(1)]));
      this.usersForm.addControl('email_' + newId, new FormControl('', [Validators.required, Validators.email, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/)]));
    }
  }
  /*
  todo: check if all users data is fine
  */
  public invitedUsers = () => {

    // ? check if the last inputs group have data
    if (this.usersForm.value['firstName_' + (this.usersInviteds.length - 1)] == '' &&
      this.usersForm.value['lastName_' + (this.usersInviteds.length - 1)] == '' &&
      this.usersForm.value['email_' + (this.usersInviteds.length - 1)] == '') {
      // ! remove the last item
      this.usersForm.removeControl('firstName_' + (this.usersInviteds.length - 1))
      this.usersForm.removeControl('lastName_' + (this.usersInviteds.length - 1))
      this.usersForm.removeControl('email_' + (this.usersInviteds.length - 1))
      this.usersInviteds.pop()
    }

    if (this.usersForm.valid) {
      this.loading = true
      //  define the emails free
      let numEmailsFree = 0
      //  check emails if free
      for (let index = 0; index < this.usersInviteds.length; index++) {
        this.emailFree(this.usersForm.value['email_' + index], free => {
          if (free) {
            numEmailsFree = numEmailsFree + 1
            //  check if all emails has been verificated, just if all emails are free the invitations will send it
            if (this.usersInviteds.length == numEmailsFree) {
              this.sendInvitations(this.usersInviteds)
            }
          } //end of email verify
        })

      }
    } else {
      this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['noName'] });
    }


  }
  /*
  todo: send invitations to users
  */
  public sendInvitations = (users: Object[]) => {
    // send invitations
    this.master.sendPost('inviteUsers', { users: users }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // set empty invitations
          this.invitations = { success: [], failed: [] }
          // todo: set invitations success
          res.data.users.success.forEach(el => {
            this.invitations.success.push(el.email)
          });
          // todo: set invitations failure
          res.data.users.failed.forEach(el => {
            this.invitations.failed.push(el.email)
          });
          this.disabled = true
          // reload users list
          this.tableEmitter$.emit({ message: 'updateUserList' })
        } else {
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }

    })
  }
  /*
  todo: find the email in the data base
  */
  public emailFree = (email, callback) => {
    // if email valid
    this.master.sendPost('attributesExists', { "email": email }, res => {
      if (res.status == 200) {
        // * status 200 when the email not exist
        callback(true)
      } else {
        // ! status 400 when the email is already use
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['email already in use'] });
        this.loading = false
        callback(false)
      }
    })
  }
  /*
  * life cycles
  */
  constructor(private master: MasterService, private ms: MessageService) {

    this.usersForm.addControl('firstName_0', new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(2)]));
    this.usersForm.addControl('lastName_0', new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(2)]));
    this.usersForm.addControl('email_0', new FormControl('', [Validators.required, Validators.email, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/)]));
  }

  ngOnInit() {
    this.ms.channelComponents$.subscribe(res => {
      if (res.message == "changeLanguage") {
        this.language = res.value
      }
    })
  }

}
