import { Component, Input, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
// services
import { MessageService } from "../../../../services/message.service";
import { MasterService } from "../../../../services/master.service";
import * as moment from 'moment'

@Component({
  selector: 'body-payments',
  templateUrl: './body-payments.component.html',
  styleUrls: ['./body-payments.component.scss']
})
export class BodyPaymentsComponent implements OnInit {
  // ************************
  // **** variables ********
  // ***********************
  //
  public paymentMethodList: any = {}
  //
  public paymentCategories: any = {}
  //
  public statusCategories: any = {}
  //
  public dateSelected = ''
  //
  public dates = []
  //
  public promises = []
  //
  public filtersSelected = []
  //
  public paymentSelected = {}
  //
  @Input() saleId = 0
  // save the language
  @Input() language: string = 'EN'
  // set all words
  @Input() words = {}
  //
  public loading: boolean = false
  //
  @Input() data
  //
  @Input() header = {}
  //
  public parents = []
  //
  public dateNow = moment().format('YYYY-MM-DD')
  //
  @Output() body$: EventEmitter<Object> = new EventEmitter();
  //



  // ************************
  // **** functions ********
  // ***********************
  // -----------------------
  // todo: read  data
  // ---------------------
  //
  public readPayments = (saleId: number): void => {
    this.loading = true

    this.master.sendPost("CRUDForPayments", { saleId: saleId, activity: 'read' }, (res) => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.data = res.data.body

          this.paymentCategories = res.data.body.paymentCategories
          this.statusCategories = res.data.body.paymentStatusList

          this.paymentMethodList=res.data.body.paymentMethodList

          this.data.promisedToPay = res.data.promisedToPay.slice(res.data.promisedToPay.length - this.data['paymentsExpected'].length, res.data.promisedToPay.length)
          this.data.promisedToPayHistory = res.data.promisedToPayHistory
          // order by herarchy
          // this.data['paymentsExpected'] = this.orderCategories(this.data['hierarchy'])
          // this.reset()
          this.body$.emit({ header: res.data.header })
        } else {
          // ! error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    }
    );
  }
  // -----------------------
  // todo: listeners
  // ---------------------
  // todo: listener history
  public listenerHistory = (e: Object): void => {
    if (e['message'] == 'reload Payments') {
      this.readPayments(this.saleId);
    }
  }
  // todo:listener promises
  public listenerPromises = (e: Object): void => {
    if (e['message'] == 'reload Payments') {
      this.readPayments(this.saleId);
    }
  }
  // todo:listener paymentExpected
  public listenerPaymentExpected = (e: Object) => {
    switch (e['message']) {
      case 'set amount Paid':
        this.paymentSelected = { ...e['paymentSelected'] }
        break;
      case 'set payment selected':
        this.promises = []
        this.dates = []
        this.paymentSelected = { ...e['paymentSelected'] }
        this.dateSelected = moment(this.paymentSelected ? this.paymentSelected['dueDate'] : '').format('YYYY-MM-DD')
        break
        case 'reloadPayments':
          this.readPayments(this.saleId)
          break
      default:
        break;
    }
  }
  // todo: listener promises form
  public listenerPromisesForm = (e) => {
    switch (e['message']) {
      case 'reload Payments':

        this.readPayments(this.saleId)
        break;
      case 'clear':
        this.paymentSelected = {}
        break;
      default:
        break;
    }
    this.promises = []
    this.dates = []

  }

  // todo: listener promises form
  public listenerPaymentsForm = (e) => {
    switch (e['message']) {
      case 'reload Payments':
        this.filtersSelected = []
        this.readPayments(this.saleId)
        break;
      default:
        break;
    }
  }

  // ************************
  // **** life cycles ******
  // ***********************
  constructor(
    private ms: MessageService,
    private master: MasterService,
  ) {

  }

  ngOnInit() {

  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue != undefined) {


      this.paymentCategories = this.data.paymentCategories
      this.statusCategories = this.data.paymentStatusList

      this.paymentMethodList=this.data.paymentMethodList

      // history
      this.data['history'].forEach(el => {
        if (el['parentPaymentId'] != null) {
          this.parents.push(el['parentPaymentId'])
        }
        el['checked'] = el['postedDate'] == this.dateNow ? true : false
      })

    }

  }


}
