
import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { MasterService } from '../../../../../../../../services/master.service'
import { StoreService } from '../../../../../../../../services/store.service'
import { MessageService } from '../../../../../../../../services/message.service'
import * as languageLibrary from '../../../../../../../../services/language'
import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as moment from 'moment';

@Component({
  selector: 'payments-terms',
  templateUrl: './payments-terms.component.html',
  styleUrls: ['./payments-terms.component.scss']
})
export class PaymentsTermsComponent implements OnInit {
  /************
   * todo: variables
   **********/
  public categories: Object = { categories: {}, statuslist: {} }
  //
  @Input() payments: any[] = []
  //
  @Input() expandedTerms: boolean = false
  //
  @Input() saleId: number = 0
  //
  @Input() saleType: number = 0
  //
  public saleDate: string = ''
  //
  @Input() paymentTerm: any = {}
  //
  public totalPayments: number = 0
  //
  public loading: boolean = false
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  //  emitter object
  @Output() paymentTable$: EventEmitter<Object> = new EventEmitter()
  public information = new FormGroup({
    PaymentCycle: new FormControl('Monthly', [Validators.required]),
    datePayment: new FormControl('', [Validators.required]),
    paymentAmount: new FormControl("", [Validators.required]),
    numberPayments: new FormControl("", [Validators.required])
  });
  /************
   * todo: functions
   **********/

  // ======================
  // todo: sale payments table CRUD
  // ======================
  // save sale payments table
  public saveSalePayments = () => {

    // automatic case
    if (this.expandedTerms) {
      // get payments calculated
      this.payments = this.calculatePayments(this.information.value)
    }
    // ignore the amount is less of 0
    this.payments = this.payments.filter(el => parseInt(el['amountDue']) > 0 || el['paymentCategory'].name == 'Initial Down')
    // set total payments
    this.totalPayments = this.payments.reduce((total, value) => total + parseFloat(value['amountDue']), 0)
    for (let index = 0; index < this.payments.length; index++) {
      // chek if the sale date is before to first payment
      if (moment(this.payments[index]['dueDate']).format('YYYY-MM-DD') < moment(this.saleDate).format('YYYY-MM-DD')) {
        this.ms.sendMessage("alert", { type: "warning", text: 'Invalid value for Date Due: Cannot be before the sale date.' });
        return
      }
      // check if some deferred down is lower to initial down
      if(moment(this.payments[index]['dueDate']).format('YYYY-MM-DD') < moment(this.payments[0]['dueDate']).format('YYYY-MM-DD')){

        this.ms.sendMessage("alert", { type: "warning", text: 'Invalid value for Deferred Down or Initial Down' });
        return
      }
    }

    // todo: initial down && deferred down
    if (this.paymentTerm['saleTerms'].deferredSecondOp == this.expandedTerms) {
      // ? equal
      this.deleteAll(this.saleId, res => {
        if (res) {

          // create and update (inside function create) payments table
          this.createSalePayments(this.payments)

        } else {
          // if some error just reload terms
          this.readSalePayments(true)
        }
      })
    } else {

      if (this.paymentTerm['saleTerms'].deferredSecondOp == true && this.expandedTerms == false) {
        //? automatic to manual
        // create and update (inside function create) payments table
        this.createSalePayments(this.payments)
      } else if (this.paymentTerm['saleTerms'].deferredSecondOp == false && this.expandedTerms == true) {
        //? change manual-automatic
        this.deleteAll(this.saleId, res => {
          if (res) {
            // this.updateSalePayments(this.payments)
            this.createSalePayments(this.payments)
          } else {
            // if some error just reload terms
            this.readSalePayments(true)
          }
        })
      }
    }
  }
  // ======================
  // ? read sale payments table
  // ======================
  public readSalePayments = (reload:boolean) => {
    this.loading = true

    this.master.sendPost('CRUDForSalePayments', { saleId: this.saleId, activity: 'read' }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.payments = res.data.payments
          this.categories['categories'] = res.data.categories
          this.categories['statuslist'] = res.data.statuslist
          this.payments[0].amountDue = parseFloat(this.payments[0].amountDue)
          this.saleDate = res.data.saleDate
          this.totalPayments = this.payments.reduce((total, value) => total + parseFloat(value['amountDue']), 0)
          // send information to father (term component)
          this.sendEmit()
          if(reload){
            this.paymentTable$.emit({message:'reloadAll'})
          }
          // this.readAll()
          //  ? just the manual terms can push new row
          if (!this.paymentTerm['saleTerms'].deferredSecondOp) {
            this.pushPayment(this.payments.length - 1)
          }
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // ======================
  // ? send emit
  // ======================
  public sendEmit = () => {
    this.paymentTable$.emit({ totalPayments: this.totalPayments, expandedTerms: this.expandedTerms, payments: this.payments, paymentsConfig: this.information, categories: this.categories['categories'], statuslist: this.categories['statuslist'] })
  }
  // ======================
  // ? update a sale payment table
  // ======================
  public updateSalePayments = (payments) => {
    let param = payments.filter(el => el['id'])
    if (param.length > 0) {

      param.forEach(el => {
        el['amountDue'] = parseFloat(el['amountDue'])
      })

      this.loading = true

      this.master.sendPost('CRUDForSalePayments', { saleId: this.saleId, updatedPayments: param, activity: 'update' }, res => {
        this.loading = false
        if (res) {
          if (res.status == 200) {
            // * success
            this.readSalePayments(true)
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['successPayments'] });
          } else {
            // ! send message in case error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
            // if some error just reload terms
            this.readSalePayments(true)
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })
    }
  }
  // ======================
  // ? create and update a new sale payment table
  // ======================
  public createSalePayments = (payments) => {

    let param = payments.filter(el => !(el['id']) && el['paymentCategory'].name != 'Initial Down')
    // param.pop()

    if (param.length > 0) {
      param.forEach(el => {
        el['amountDue'] = parseFloat(el['amountDue'])
      })

      // * delete all success
      this.loading = true
      this.master.sendPost('CRUDForSalePayments', { saleId: this.saleId, createPayments: param, activity: 'create' }, res => {
        this.loading = false
        if (res) {
          if (res.status == 200) {
            // * success
            this.updateSalePayments(payments)


          } else {
            // ! send message in case error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
            // if some error just reload terms
            this.readSalePayments(true)
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })

    } else {
      // just update
      this.updateSalePayments(payments)
    }


  }
  // ======================
  // ? delete all payments
  // ======================
  public deleteAll = (id, callback) => {
    // just the manual to manual can´t delete, else delete another cases (auto -> auto or auto->manual, manual->auto)

    if (this.paymentTerm['saleTerms'].deferredSecondOp == false && this.expandedTerms == false) {
      callback(true)
      return
    }

    this.master.sendPost('CRUDForSalePayments', { saleId: id, activity: 'clearDeferred' }, res => {

      if (res) {
        if (res.status == 200) {
          // * success

          callback(true)
        } else {
          // ! send message in case error

          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          this.readSalePayments(true)
          callback(false)
        }
      } else {
        // in case API no response
        callback(false)
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // ======================
  // todo: sale payments temporal (push and pop)
  // ======================
  // ? push new payment
  // ======================
  public pushPayment = (index) => {

    this.payments[index]['amountDue']=(+this.payments[index]['amountDue'])*Math.sign(+this.payments[index]['amountDue'])

    if (
      !this.payments.some(el => el['amountDue'] == '' || el['dueDate'] == '') &&
      index == this.payments.length - 1 &&
      !this.expandedTerms &&
      // outside = 1 deferred down and BHPH = 4 deferred down
      (this.payments.length <= 1 && this.saleType != 4 || this.payments.length <= 4 && this.saleType == 4)
    ) {
      this.payments.push({
        saleId: this.saleId,
        amountDue: 0,
        dueDate: moment(this.saleDate).add(this.payments.length, 'month').format('YYYY-MM-DD'),
        paymentCategory: { name: 'Deferred Down' },
        paymentCategoryId: 2,
        referenceNumber: null,
        memo: null,
        paymentStatusId: 1,
        paymentStatus: { name: 'Unpaid' },
        paymentTypeId: null
      })
    }

    this.sendEmit()
  }
  // ======================
  // ? delete sale payment
  // ======================
  public deleteSalePayment = (id: number, index: number) => {
    this.loading = true

    if(id==undefined){

      this.payments.splice(index, 1)
    }else{
      // if have id
      this.master.sendPost('CRUDForSalePayments', { paymentId: id, activity: 'delete' }, res => {
        this.loading = false
        if (res) {
          if (res.status == 200) {
            // * success

            this.payments.splice(index, 1)
            // this.pushPayment(index - 1)
          } else {
            // ! send message in case error

            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })
    }
  }
  // ======================
  // ? calculate payments automatic
  // ======================
  public calculatePayments = (information) => {
    let payment = []

    for (let index = 0; index < information.numberPayments; index++) {
      payment.push({
        saleId: this.saleId,
        amountDue: information.paymentAmount,
        dueDate: index == 0 ? information.datePayment : this.getCategory(information.PaymentCycle, payment[index - 1].dueDate),
        paymentCategory: { name: 'Deferred Down' },
        paymentCategoryId: 2,
        referenceNumber: null,
        memo: null,
        paymentStatusId: 1,
        paymentStatus: { name: 'Unpaid' },
        paymentTypeId: null
      })
    }

    payment.unshift(this.payments[0])

    return payment
  }
  // ======================
  // ? get date interval depending of the cycle
  // ======================
  public getCategory = (category, date) => {
    switch (category) {
      case 'Monthly':
        return moment(date).add(1, 'month').format('YYYY-MM-DD')
      case 'Semi-Monthly':
        let daysInMonth = Math.floor(moment(date).daysInMonth() / 2)
        return moment(date).add(daysInMonth, 'days').format('YYYY-MM-DD')
      case 'Weekly':
        return moment(date).add(1, 'week').format('YYYY-MM-DD')
      case 'Bi-Weekly':
        return moment(date).add(2, 'week').format('YYYY-MM-DD')
      default:
        break;
    }
  }

  public changeExpandedTerms = () => {
    this.expandedTerms = !this.expandedTerms
    this.sendEmit()
  }

  /************
   * todo: life cycles
   **********/
  constructor(private master: MasterService, private ms: MessageService, private store: StoreService) { }

  ngOnInit() {
    this.readSalePayments(false)
  }

  ngOnChanges(changes: SimpleChanges): void {

    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.paymentTerm) {

      if (changes.paymentTerm.currentValue['saleTerms']) {

        this.information.setValue({
          PaymentCycle: this.paymentTerm['saleTerms'].paymentcycle ? this.paymentTerm['saleTerms'].paymentcycle['name'] : '',
          datePayment: this.paymentTerm['saleTerms'].firstDeferredPmtDate ? moment(this.paymentTerm['saleTerms'].firstDeferredPmtDate).format('yyyy-MM-DD') : '',
          paymentAmount: this.paymentTerm['saleTerms'].deferredPaymentAmount ? this.paymentTerm['saleTerms'].deferredPaymentAmount : '',
          numberPayments: this.paymentTerm['saleTerms'].deferredNumberOfPmts ? this.paymentTerm['saleTerms'].deferredNumberOfPmts : ''
        })
      }
    }

  }

}
