import { Component, OnInit, Input, SimpleChanges,Output,EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as moment from 'moment';
import * as languageLibrary from '../../../../services/language'
import { MasterService } from "../../../../services/master.service";
import { MessageService } from "../../../../services/message.service";
@Component({
  selector: 'active-cpi',
  templateUrl: './active-cpi.component.html',
  styleUrls: ['./active-cpi.component.scss']
})
export class ActiveCpiComponent implements OnInit {
  // ***************
  // variable
  // ***************
  @Input() paymentStatusList:Object={}
  //
  @Output() addCpi$:EventEmitter<Object>=new EventEmitter()
  //
  @Input() data = {}
  // form contract line
  public cpiDetail = new FormGroup({
    contactId: new FormControl('', [Validators.required]),
    effectiveDate: new FormControl('', [Validators.required]),
    policyNumber: new FormControl('', [Validators.required]),
    amountPremiumBasedOn: new FormControl('', [Validators.required]),
    defaultDeductible: new FormControl('', [Validators.required]),
    policyEndDate: new FormControl('', [Validators.required]),
    premiumPerPayment: new FormControl('', [Validators.required]),
    policyInactiveDate: new FormControl('', [Validators.required]),
    paymentCycleId: new FormControl('', [Validators.required]),
    firstScheduledPmtDue: new FormControl('', [Validators.required]),
    numberOfPmt: new FormControl('', [Validators.required]),
    schedulePmtAmt: new FormControl('', [Validators.required]),
  })
  // define if is loading
  public loading: boolean = false
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  //
  @Input() insurance = []
  // ***************
  // functions
  // ***************
/*
   todo: save payments
   */
   public deleteCpi = (id): void => {
     this.loading = true
     this.master.sendPost("cpiDispatcher", {
       params: {
         cpiId:id
       }, activity: 'deactivateCpi'
     }, (res) => {
       this.loading = false
       if (res) {
         if (res.status == 200) {
          this.addCpi$.emit({message:'reloadCpi'})
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
         } else {
           // ! send message in case error
           this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
         }
       } else {
         // in case API no response
         this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
       }
     });
  };
  // todo: get after sales
  public getCpiDetail = async (id) => {
    const res= await this.master.sendPostAsync("cpiDispatcher", {
      params: {
        id: id
      }, activity: 'getCpiDetail'
    });

    if (res) {
      if (res['status'] == 200) {

        this.data['paymentsExpecteds']=res.data.paymentExpectds
        // this.data = res['data'].result
      } else {
        this.ms.sendMessage("alert", { type: "danger", text: res['data'].error });
      }
    } else {
      this.ms.sendMessage("alert", {
        type: "danger",
        text: this.words[this.language]['apiNoResponse'],
      })
    }
  };
  // ***************
  // life cycles
  // ***************
  constructor(private master: MasterService, private ms: MessageService) { }

  ngOnInit() {

    this.getCpiDetail(this.data['id'])
    // listener
    this.ms.channelComponents$.subscribe((res) => {
      if (res.message == "changeLanguage") {
        this.language = res.value
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.data) {

      this.cpiDetail.setValue({
        contactId: changes.data['currentValue']['contactId'],
        effectiveDate: changes.data['currentValue']['effectiveDate'],
        policyNumber: changes.data['currentValue']['policyNumber'],
        amountPremiumBasedOn: changes.data['currentValue']['amountPremiumBasedOn'],
        defaultDeductible: changes.data['currentValue']['defaultDeductible'],
        policyEndDate: changes.data['currentValue']['policyEndDate'],
        premiumPerPayment: changes.data['currentValue']['premiumPerPayment'],
        policyInactiveDate: changes.data['currentValue']['policyInactiveDate'],
        paymentCycleId:  changes.data['currentValue']['saleTerms'][0].paymentCycleId,
        firstScheduledPmtDue: moment(changes.data['currentValue']['saleTerms'][0].firstScheduledPmtDue).add(1,'day').format('yyyy-MM-DD'),
        numberOfPmt: changes.data['currentValue']['saleTerms'][0].numberOfPmt==true?1:changes.data['currentValue']['saleTerms'][0].numberOfPmt,
        schedulePmtAmt: changes.data['currentValue']['saleTerms'][0].schedulePmtAmt ,
      })

    }
  }

}
