import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { MasterService } from '../../../../../../../../services/master.service'
import { StoreService } from '../../../../../../../../services/store.service'
import { MessageService } from '../../../../../../../../services/message.service'
import * as languageLibrary from '../../../../../../../../services/language'
import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as moment from 'moment'

@Component({
  selector: 'card-calculate',
  templateUrl: './card-calculate.component.html',
  styleUrls: ['./card-calculate.component.scss']
})
export class CardCalculateComponent implements OnInit {
  // **********************
  // variables
  // *******************
  @Input() inventoryTaxRate:number=0
  //
  @Input() payments:any=[]
  //
  @Input() catalogCycles:any = []
  //
  @Input() loanTerms:any = []
  //
  @Output() loanTerms$: EventEmitter<object> = new EventEmitter()
  //
  public quotes:any = []
  //
  @Input() saleId = ''
  //
  @Input() saleType = ''
  //
  @Input() totalSalePrice = ''
  //
  @Input() buyer = ''
  //
  @Input() inventory = ''
  //
  @Input() fees:any=[]
  //
  @Input() initialDown:any=[]
  //
  @Input() deferredDownPayments:any=[]
  //
  public loading: boolean = false
  //
  public minDate:string=moment().format('yyyy-MM-DD')
  //
  public diccionaryCyclesPlural = {
    'Monthly': 'Months',
    'Semimonthly': 'Months',
    'Weekly': 'Weeks',
    'Biweekly': 'Weeks'
  }
  //
  public diccionaryCycles = {
    'Monthly': 'Monthly',
    'Semimonthly': 'Semi-monthly',
    'Weekly': 'Weekly',
    'Biweekly': 'Biweekly'
  }
  //
  public cyclesCatalog = ['Monthly', 'Semimonthly', 'Weekly', 'Biweekly']
  //
  public edit: boolean = false
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  //
  public information = new FormGroup({
    apr: new FormControl(0, []),
    months: new FormControl(0, []),
    scheduledPaymentAmt: new FormControl('', []),
    paymentCycle: new FormControl('Monthly', [Validators.required]),
    firstPaymentDate: new FormControl(moment().format('yyyy-MM-DD'), [Validators.required]),
    numberOfPayments: new FormControl('', []),
    lastPayment: new FormControl(null, []),
    totalPaymentAmount: new FormControl(null, [])
  })
  // **********************
  // functions
  // *******************
  // =============================
  // ? get type of solve
  // =============================
  public getTypeSolve = (apr: number, paymentAmount: number, numberOfPmt: number): string => {

    if (!(apr == null || apr == 0) && !(paymentAmount == null || paymentAmount == 0)) {

      return 'Term'
    }
    if (!(numberOfPmt == null || numberOfPmt == 0) && !(paymentAmount == null || paymentAmount == 0)) {

      return 'Rate'
    }
    if (!(numberOfPmt == null || numberOfPmt == 0) && !(apr == null || apr == 0)) {

      return 'Payment'
    } else {

      return 'error'
    }
  }
  // =============================
  // ? get type of engine
  // =============================
  public getEngine = (engine: string): string => {

    switch (engine) {
      case 'Diesel':
        return engine = 'Dl'
      case 'Gas':
        return engine = 'G'
      case 'Electric':
        return engine = 'E'
      case 'Hibrid':
        return engine = 'H'
      default:
        return engine = 'G'
    }
  }
  // =============================
  // ? create taxes
  // =============================
  public calculate = async () => {

    if (this.store.userAccount['corporateAddresses'].length == 0) {
      this.ms.sendMessage("alert", { type: "danger", text: 'Corporate address not exist' });
      return
    }
    if (!this.inventory['stockNumber']) {
      this.ms.sendMessage("alert", { type: "danger", text: 'Vehicle not selected' });
      return
    }

    if (this.buyer['county'] == "") {
      this.ms.sendMessage("alert", { type: "danger", text: 'Buyer not selected or not address' });
      return
    }

    if (this.inventory['data'].datePurchased == '' || this.inventory['data'].datePurchased == null) {
      this.ms.sendMessage("alert", { type: "danger", text: 'Data purchased not defined' });
      return
    }

    if (this.inventory['data']['features'] == undefined) {
      this.ms.sendMessage("alert", { type: "danger", text: 'Some features related to the vehicle are missing' });
      return
    }

    if (parseFloat(this.totalSalePrice) <= 0) {
      this.ms.sendMessage("alert", { type: "danger", text: 'Base sale price most be greater than 0' });
      return
    }

    //
    let engine = this.getEngine(this.inventory['data']['features'].engine)
    let paramFees=this.initialDown.concat(this.fees)
    paramFees.push({Amount: this.inventoryTaxRate, TitleId: '1069', Base:'Cash'})

    let lotSelected = this.store.userAccount['user'].lots.find(el => el['id'] == this.store.lotSelected)
    let typeOfSolve = this.getTypeSolve(this.information.value['apr'], this.information.value['scheduledPaymentAmt'], this.information.value['numberOfPayments'])
    if (typeOfSolve == 'error') {
      this.ms.sendMessage("alert", { type: "danger", text: 'Invalid values on APR, amount by month or number of payments' });
      return
    }

    this.deferredDownPayments=this.deferredDownPayments.filter(el=>+el['Payment']>0 || el['Payment']!='')

    // params
    const request = {
      "OptionalParameters": {
        "ExcludeFromCalculation": [],
        "ExcludeFromOutput": [],
        "ApplyExclusionsToInputs": "No"
      },
      "FeeAndSalesTaxParameters": {
        "TransactionId": this.inventory['stockNumber'], // stock#
        "ApplicantState": this.store.states[this.buyer['state']],
        "ApplicantCounty": this.buyer['county'],
        "ApplicantCity": this.buyer['city'],
        "ApplicantAddress": this.buyer['address'],
        "BasePrice": parseFloat(this.totalSalePrice),
        "LienStatus": "false", // have lienholder
        "PlateTransfer": false, // NA
        "SellerAddress1": this.store.userAccount['corporateAddresses'][0]['address'],
        "SellerState": this.store.states[this.store.userAccount['corporateAddresses'][0]['state']],
        "SellerCounty": this.store.userAccount['corporateAddresses'][0]['county'],
        "SellerCity": this.store.userAccount['corporateAddresses'][0]['city'],
        "TitleStatus": "T",
        "TransactionType": this.saleType == '1' ? 'C' : 'P',
        "VehicleType": "PV",
        "VIN": this.inventory['data'].vinNumber,
        "Year": parseInt(this.inventory['data'].modelYear),
        //
        "AnonymousApplicant": this.buyer['city'] == "" ? "Yes" : "No",
        "OutsideCityLimits": "false",
        "BaseMSRP": parseFloat(this.totalSalePrice),
        "FuelType": engine,
        "GVW": parseInt(this.inventory['data'].features.gvwr || 0),
        "GVWR": parseInt(this.inventory['data'].features.gvwr || 0),
        //
        "Weight": this.inventory['data']['features'].carryingWeight == "" ? 0 : parseInt(this.inventory['data']['features'].carryingWeight),
        "DaysSincePurchase": moment().diff(moment(this.inventory['data'].datePurchased), 'days'),
        "datePurchased": this.inventory['data'].datePurchased,
        "PlateType": "R",
        "RegistrationOption": "B",
        "Cylinders": this.inventory['data']['features'].cylinders == '' ? 0 : parseInt(this.inventory['data']['features'].cylinders),
        "SellerName": lotSelected.name,
        "SellerZipcode": this.store.userAccount['corporateAddresses'][0]['zipCode'],
        "MPG": 0,
        "Length": this.inventory['data']['features'].length == '' ? 0 : parseInt(this.inventory['data']['features'].length),
        "CC": 0,
        "HP": 0,
        "RegistrationMonths": 12,
        "RegistrationRenewalMonth": "Undefined",
      },
      "CalculationSettings": {
        "BypassRateReduction": "No",
        "Compliance": {
          "MaximumAPR": 0.0
        },
        "RateDifferential": 0.01
      },

      "LTVTiers": [],
      "CalculateBaseQuotes": "No",
      "ExcludeQuoteParameters": "No",
      "BaseParameters": [
        {
          "MSRP": +this.totalSalePrice,
          "DealerInvoiceAmount": 0,
          "TransactionId": "",
          "UserCode": "TXSANDBOXDST",
          "Rate": this.information.value['apr'] ? this.information.value['apr'] : 0,
          "Payment": this.information.value['scheduledPaymentAmt'] ? this.information.value['scheduledPaymentAmt'] : 0,
          "Balloon": 0,
          "TaxDeferPortion": 0,
          "TotalDownNoDefer": 0,
          "MaximumPropertyCoverage": 0,
          "TaxMaxBase": 0,
          "TaxCredit": 0,
          "ContractType": "Installment",
          "PaymentFrequency": this.information.value['paymentCycle'],
          "UseBankersFormula": "No",
          "BankersFormulaMethod": "Undefined",
          "InterestMethod": "SimpleUSRule",
          "SolveFor": typeOfSolve,
          "InterestStartDate": moment(this.information.value['firstPaymentDate']).subtract(1, 'day'),
          "FirstPaymentDate": this.information.value['firstPaymentDate'],
          "PaymentDueDayOverride": 0,
          "SemimonthlySecondPaymentDay": 0,
          "Term": this.information.value['numberOfPayments'] ? parseInt(this.information.value['numberOfPayments']) : 0,
          "AmortizationTerm": 0,
          "LifeCode": "NoLife",
          "AHCode": "NoAH",
          "AHSchedule": "None",
          "DPCode": 0,
          "IUICode": "NoIUI",
          "PropertyCode": "NoProperty",
          "ADDCode": "NoADD",
          "GapCode": "NoGap",
          "InsuranceMethod": "Undefined",
          "ContractDate": moment(this.buyer['createdAt']).format('yyyy-MM-DD'),
          "WholeDollarPayment": "No",
          "WholeDollarPaymentType": "AdjustFinalPayment",
          "IncludeRebateInTotalDown": "No",
          "Netting": "No",
          "TimeCounting": "PerDiem",
          "TimeCountingWeekly": "PerDiem",
          "TimeCountingSemiMonthly": "PerDiem",
          "DaysInYear": "Days360",
          "DaysInYearWeekly": "Days360",
          "DaysInYearSemiMonthly": "Days360",
          "OddDaysInterestMethod": "UseConfigFile",
          "UseLevelRates": "No",
          "TaxIndex": null,
          "SecondaryTaxIndex": null,
          "SecondaryTaxIndexHandling": "Undefined",
          "PayTaxesOutsideOfClosing": "Undefined",
          "RoundPayments": "Low",
          "RoundFinalPayment": "Low",
          "RoundSinglePayment": "Low",
          "SkippedPaymentsExtendTerm": "No",
          "SkippedMaturityDateHandling": "ReduceTerm",
          "UseLevelPayments": "Default",
          "Applicants": [],
          "Fees": paramFees,
          "Taxes": [],
          "IrregularPayments": [],
          "DeferredDownPayments":this.deferredDownPayments
        }
      ],
      "DeskingOptionSets": [
        {
          "TransactionId": " ",
          "DeskingOptionSetId": " "
        }
      ]
    }


    // params
    this.loading = true
    //
    let res = await this.master.getCarleton(request)
    this.loading = false
    if (res) {
      if (res['data'][0].ErrorCode == 0) {
        // * success
        this.quotes = res['data']


        this.information.setValue({
          // ! quest APR
          apr: res['data'][0].Quote.Rate,
          months: this.getDiffDate(res['data'][0].Quote.PaymentStreams[0].StartDate, res['data'][0].Quote.PaymentStreams[res['data'][0].Quote.PaymentStreams.length > 1 ? 1 : 0].EndDate, this.information.value['paymentCycle']),
          scheduledPaymentAmt: res['data'][0].Quote.PaymentStreams[0].Payment,
          paymentCycle: this.information.value['paymentCycle'],
          firstPaymentDate: moment(res['data'][0].Quote.PaymentStreams[0].StartDate).format('yyyy-MM-DD'),
          numberOfPayments: res['data'][0].Quote.NumberOfPayments,
          lastPayment: res['data'][0].Quote.PaymentStreams.length > 1 ? res['data'][0].Quote.PaymentStreams[1].Payment : 0,
          totalPaymentAmount: res['data'][0].Quote.TotalOfPayments
        })

        this.edit = false

        let cycle = this.catalogCycles.find(el => this.information.value['paymentCycle'] == el['carletonApiName'])
        let params = {
          saleId: this.saleId,
          optionIndex: 1,
          paymentCycleId: cycle['id'],
          contractRate: this.information.value['apr'],
          scheduledPaymentAmount: this.information.value['scheduledPaymentAmt'],
          firstScheduledPaymentDate: moment(this.information.value['firstPaymentDate']).format('yyyy-MM-DD'),
          lastPaymentAmount: this.information.value['lastPayment'],
          percentLateFeeCharged: null,
          numberOfRegularPayments: this.information.value['numberOfPayments'],
          months: this.information.value['months'],
          totalPaymentAmount: this.information.value['totalPaymentAmount']
        }
        const xml=await this.master.getXMLCarleton(request)
        this.loanTerms$.emit({ value: params,quotes:res['data'][0].Quote,request:request,response:xml.data })
        this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });

      } else {
        // ! send message in case error
        this.ms.sendMessage("alert", { type: "danger", text: res['data'][0].ErrorMessage });
      }
    } else {
      // in case API no response
      this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
    }
  }
  //
  public getDiffDate = (dateStart, dateEnd, cycle) => {
    switch (cycle) {
      case 'Weekly':
        return moment(dateEnd).diff(dateStart, 'weeks')
      case 'Semimonthly':
        return moment(dateEnd).diff(dateStart, 'weeks')
      case 'Monthly':
        return moment(dateEnd).diff(dateStart, 'months')
      case 'Biweekly':
        return moment(dateEnd).diff(dateStart, 'months')
      default:
        break;
    }

  }


  // **********************
  // life cycles
  // *******************
  constructor(private master: MasterService, private ms: MessageService, private store: StoreService) { }

  ngOnInit() {
    this.ms.channelComponents$.subscribe(res => {

      switch (res.message) {
        case 'setPermissions':
        // this.lots = this.paymentTerm['lotsInCorp']
        case 'changeLanguage':
          this.language = res.value
        default:
          break;
      }
    })

  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if(changes.payments){
      if(changes.payments.currentValue.length>0){
        this.information.value['firstPaymentDate']=changes.payments.currentValue[changes.payments.currentValue.length-1].dueDate
        this.information.get('firstPaymentDate').setValue(changes.payments.currentValue[changes.payments.currentValue.length-1].dueDate)
        this.minDate=changes.payments.currentValue[changes.payments.currentValue.length-1].dueDate
        this.payments=changes.payments.currentValue.filter(el=>el['amountDue']>0)
      }

    }
    if (changes.loanTerms && this.catalogCycles && this.catalogCycles.length>0) {

      let cycle = changes.loanTerms.currentValue['paymentCycleId'] ? this.catalogCycles.find(el => changes.loanTerms.currentValue['paymentCycleId'] == el['id']) : null

      this.information.setValue({
        apr: changes.loanTerms.currentValue.APR,
        months: changes.loanTerms.currentValue.months,
        scheduledPaymentAmt: changes.loanTerms.currentValue.schedulePmtAmt,
        paymentCycle: cycle ? cycle['carletonApiName']: 'Monthly',
        firstPaymentDate: changes.loanTerms.currentValue.firstScheduledPmtDue ? moment(changes.loanTerms.currentValue.firstScheduledPmtDue).format('yyyy-MM-DD') : moment().format('yyyy-MM-DD'),
        numberOfPayments: changes.loanTerms.currentValue.numberOfPmt,
        lastPayment: changes.loanTerms.currentValue.lastPmtAmt,
        totalPaymentAmount: changes.loanTerms.currentValue.totalPaymentAmount
      })

    }
    if(changes.deferredDownPayments && changes.deferredDownPayments.currentValue.length>0){

      this.deferredDownPayments=changes.deferredDownPayments.currentValue.map(el=>{return{"Payment": el.amountDue, "PaymentDate":el.dueDate,"Handling": "CashDown"}})

    }
    if(changes.initialDown && changes.initialDown.currentValue.length>0){

      this.initialDown=changes.initialDown.currentValue.map(el=>{return{"Amount": -el.amountDue, "TitleId":"10013"}})

    }
    if(changes.fees && changes.fees.currentValue.length>0){
      this.fees=changes.fees.currentValue.map(el=>{return{"Amount": el['newAmount']==''?+el['Amount']:+el['newAmount'], "TitleId":el['TitleId']}})
    }
  }



}
