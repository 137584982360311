import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
// services
import { MasterService } from "../../services/master.service";
import { MessageService } from "../../services/message.service";
import { StoreService } from "../../services/store.service";
import * as languageLibrary from '../../services/language'
@Component({
  selector: "app-aftersalecharges",
  templateUrl: "./aftersalecharges.component.html",
  styleUrls: ["./aftersalecharges.component.scss"],
})
export class AfterSaleChargesComponent implements OnInit {
  /*
* Variables
*/
  //
  public rows: number = 0
  //
  public kpi = {}
  //
  public saleId: number = 0
  // save all data by type
  public data: Object = {}
  //
  public dataComplete: Object[] = []
  //
  public user = { firstName: '', lastName: '', corpID: '', corpName: '' }
  // define all vendors
  public vendors = []
  // define the lot selected
  public lotSelected = ''
  // permissions
  public permissions: Object[] = []
  // define if is loading
  public loading: boolean = false
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  //
  public order = { isAscend: true, type: '' }
  // save the pagination settings
  public pagination = { index: 0, rowsByPage: 10, pages: [{ numPage: 0, start: 0, end: 8 }] }
  // expanded boolean
  public expanded: boolean = true
  /*
  * functions
  */
  // ======================
  // ?  pagination and search
  // ======================
  // create a pagination
  public newPagination = (registers: Object[]): void => {
    this.pagination.pages = []
    this.pagination.index = 0
    this.pagination.pages['start'] = 0
    for (let index = 0; index < (registers.length / this.pagination.rowsByPage); index++) {
      this.pagination.pages.push({ numPage: index, start: this.pagination.rowsByPage * index, end: (this.pagination.rowsByPage * (index + 1)) })
    }
  }
  // change the rows per page
  public changeRows = (): void => {
    this.pagination.index = 0
    // this.newPagination(this.data)
  }
  // search
  public search = (e): void => {
    let word = (document.getElementById('wordSearch') as HTMLInputElement).value;
    if (e.key == 'Enter') {
      for (const key in this.dataComplete) {
        for (let index = 0; index < this.dataComplete[key]['length']; index++) {

          this.data[key] = this.convertObject2Array(this.dataComplete[key]).filter(
            el => el.saleData.saleBuyers[0].contact.firstName.indexOf(word) >= 0
              || el.saleData.saleBuyers[0].contact.lastName.indexOf(word) >= 0 ||
              el.saleData.saleVehicles[0].inventory.stockNumber.indexOf(word) >= 0 ||
              (el.saleId + '').indexOf(word) >= 0

          )

        }
      }

    }
  }
  // tool for convert objects in array
  public convertObject2Array = (array: any): any => {
    return array
  }
  // build Data
  public buildData=(data:Object):Object=>{

    for (const key in data) {
      for (let index = 0; index < data[key]['length']; index++) {
        // kpi individual
        data[key][index]['chargedCustomer']=data[key][index]['paymentExpecteds'].reduce((total, value)=>(+value['amountDue'] || 0) + total,0)
        data[key][index]['paidCustomer']=data[key][index]['paymentExpecteds'].reduce((total, value)=>(+value['amountPayment'] || 0) + total,0)
        data[key][index]['costCustomer']=data[key][index]['afterSaleLines'].reduce((total, value)=>(+value['cost'] || 0) + total,0)
      }
      // kpi general
      data[key][0]['chargedCustomerTotal']=data[key].reduce((total,value)=>value['chargedCustomer']+total,0)
      data[key][0]['paidCustomerTotal']=data[key].reduce((total,value)=>value['paidCustomer']+total,0)
      data[key][0]['costCustomerTotal']=data[key].reduce((total,value)=>value['costCustomer']+total,0)

    }
    return data
  }

   // ======================
  // ?  get data
  // ======================
  /*
  todo: get catalog of vendors
  */
  public getDataByType = (): void => {
    this.master.sendPost("getContactsByTypeCorp?type=vendors", {}, (res) => {
      if (res) {
        if (res.status == 200) {
          this.vendors = res.data.contacts;
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    });
  };
  /*
  todo: get after sales
  */
  public getAfterSalesByCorp = (): void => {
    this.master.sendPost("afterSalesDispatcher", {
      params: {
      }, activity: 'getAfterSalesByCorp'
    }, (res) => {
      if (res) {
        if (res.status == 200) {
          this.data = this.buildData(res.data.afterSalesByCorp)

          this.dataComplete = { ...res.data.afterSalesByCorp }
        } else {
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        this.ms.sendMessage("alert", {
          type: "danger",
          text: this.words[this.language]['apiNoResponse'],
        });
      }
    });
  };
  // todo: select Sale
  public selectSale = (saleId, kpi): void => {
    this.kpi = kpi
    this.saleId = saleId
  }
  /*
  todo: listener of after sales
  */
  // listen modal
  public listenModal = (e) => {

    if (e.message == 'reset') {
      this.saleId=0
      this.getAfterSalesByCorp()
    }
  }
  // todo: listen to output of navbar
  public listenerNav = (e): void => {

    switch (e['message']) {
      case 'changeLanguage':
        this.language = e['value']
        break;
      case 'setPermissions':
        // permissions on user list
        this.permissions = e.permissions

        break;

      default:
        break;
    }
  }
  /*
    * life cycles
    */
  constructor(
    private master: MasterService,
    private ms: MessageService,
    private store: StoreService,
    private router: Router
  ) {
    // normal
    this.getDataByType()
    this.getAfterSalesByCorp()
  }

  ngOnInit() {

  }
}
