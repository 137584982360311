import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// services
import { MasterService } from '../../../../../services/master.service'
import { MessageService } from '../../../../../services/message.service'
import * as languageLibrary from '../../../../../services/language'

@Component({
  selector: 'notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {
  /*
* variables
*/
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  // define note selected
  public noteSelected: number = 0
  // define the index of notes
  public indexNote: number = 0
  // define if form window is open
  public windowOpen: boolean = true
  // loading state
  public loading: boolean = false
  // contact object
  @Input() contact: Object = {}
  // define if view alert
  @Input() viewAlert: boolean = false
  // permissions
  @Input() permissions: string[] = []
  // 
  @Input() isEdit:boolean=true
  @Input() isFlat:boolean=false
  // save the note
  public notes = []
  // Group of inputs
  public information = new FormGroup({
    text: new FormControl('', [Validators.required]),
    alertFlag: new FormControl(false, [Validators.required])
  })
  // Group of inputs
  public informationUpdate = new FormGroup({
    entityId: new FormControl(0, [Validators.required]),
    text: new FormControl('', [Validators.required]),
    noteId: new FormControl('', [Validators.required]),
    alertFlag: new FormControl(false, [Validators.required]),
    createdAt: new FormControl(false, [Validators.required]),
    createdBy: new FormControl(false, [Validators.required])
  })
  /*
* functions
*/

  // get notes
  public getNotes = (id?): void => {
    
    this.master.sendPost('getNotes', { entityId: id ? id : this.contact['id'], type: 'contact' }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.notes = res.data.alertTransform.concat(res.data.normalTransform)
          // open alerts
          
          if (this.notes.length > 0 && this.viewAlert) {
            
            let alerts = this.notes.filter(el => el['alertFlag'] == true)
            
            if (alerts.length > 0) {
              (document.getElementById('btn-note-alert') as HTMLButtonElement).click();
            }
          }
        } else {
          // ! send message in case error 
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });

        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });

      }
    })
  }
  // add new note
  public save = (): void => {
    if (this.information.value) {
      this.loading = true
      this.master.sendPost('createNote', { ...this.information.value, entityId: this.contact['id'], type: 'contact' }, res => {
        this.loading = false

        if (res) {
          if (res.status == 200) {
            // * success

            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
            this.notes.push({ ...res.data.note })
            this.information.get('text').setValue('')
            this.information.get('alertFlag').setValue(false)
          } else {
            // ! send message in case error 
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })
    }

  }

  public cancel = (): void => {
    this.noteSelected = 0
  }
  /*
  todo: update functions
  */
  // set note form for update
  public setNote = (note: Object, index: number): void => {
    this.noteSelected = note['id']
    this.indexNote = index
    this.informationUpdate.setValue({
      entityId: this.contact['id'],
      text: note['text'],
      noteId: note['id'],
      alertFlag: note['alertFlag'],
      createdAt: note['createdAt'],
      createdBy: note['createdBy']
    })
  }
  // send form for update
  public update = (): void => {
    if (this.informationUpdate.valid) {
      this.loading = true
      this.master.sendPost('updateNote', { ...this.informationUpdate.value, type: 'contacts' }, res => {
        this.loading = false
        if (res) {
          if (res.status == 200) {
            // * success
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
            this.noteSelected = 0
            this.notes[this.indexNote] = { ...this.informationUpdate.value }
          } else {
            // ! send message in case error 
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })

    }
  }
  /*
  !: delete functions
  */
  public delete = (id, index: number): void => {
    this.loading = true

    this.master.sendPost('deleteNote', { noteId: id, type: 'contact', entityId: this.contact['id'] }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          this.notes.splice(index, 1)
        } else {
          // ! send message in case error 
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });

        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });

      }
    })
  }

  /*
  * lifre cycles
  */
  constructor(private master: MasterService, private ms: MessageService) { }

  ngOnInit() {
    
    this.contact['contactId'] || this.contact['id'] ? this.getNotes(this.contact['contactId']) : this.notes = []
    this.ms.channelComponents$.subscribe(res => {
      if (res.message == "changeLanguage") {
        this.language = res.value
      }
    })
  }



}
