import { Component, Input, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MasterService } from "../../../../services/master.service";
import { MessageService } from "../../../../services/message.service";
import { StoreService } from "../../../../services/store.service";
import * as languageLibrary from '../../../../services/language'
import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as moment from 'moment'

@Component({
  selector: 'active-after-sales',
  templateUrl: './active-after-sales.component.html',
  styleUrls: ['./active-after-sales.component.scss']
})
export class ActiveAfterSalesComponent implements OnInit {
  // *********************
  //  variables
  // *********************
  //
  @Input() index: number = -1
  //
  public isOpenDropDown: boolean = false
  //
  public lineSelected: number = -1
  //
  @Output() activeEmitter$: EventEmitter<Object> = new EventEmitter()
  //
  @Input() status = {}
  //
  @Input() cycles = []
  //
  @Input() types: any[] = []
  //
  @Input() vendors: any[] = []
  //
  @Input() data: any = {}
  //
  public loading: boolean = false
  // save the language
  @Input() language: string = 'EN'
  // set all words
  public words = languageLibrary.language
  //
  public typeSelected = -1
  // form contract line
  public contactLine = new FormGroup({
    typeId: new FormControl('', [Validators.required]),
    amount: new FormControl(0, [Validators.required]),
    cost: new FormControl(0, []),
    contactId: new FormControl('', []),
    description: new FormControl('', []),
  })
  // header
  public header = new FormGroup({
    id: new FormControl(null, [Validators.required]),
    corpId: new FormControl(null, [Validators.required]),
    date: new FormControl('', [Validators.required]),
    title: new FormControl('', [Validators.required]),
    status: new FormControl(true, []),
    oneTimePmt: new FormControl(true, [Validators.required])
  })
  // payment Schedule
  public paymentSchedule = new FormGroup({
    paymentCycleId: new FormControl('', [Validators.required]),
    firstScheduledPmtDue: new FormControl('', [Validators.required]),
    numberOfPmt: new FormControl(0, [Validators.required]),
    schedulePmtAmt: new FormControl(0, [Validators.required]),
  })
  //
  @Input() saleId: number = 0
  // *********************
  //  functions
  // *********************

  // ======================
  // ? header
  // ======================
  /*
  todo: update header
  */
  public updateContractLinesHeader = (oneTimeChange?: boolean): void => {
    this.loading = true
    this.header.get('oneTimePmt').setValue(this.header.value['oneTimePmt'] == 'false' || this.header.value['oneTimePmt'] == false ? false : true)
    if (oneTimeChange) {
      this.resetPayments(el => {
        this.paymentSchedule.setValue({
          paymentCycleId: '1',
          firstScheduledPmtDue: this.header.value['date'],
          numberOfPmt: 1,
          schedulePmtAmt: this.data.totalAmount
        })

      })

    }

    this.master.sendPost("afterSalesDispatcher", {
      params: {
        saleId: this.saleId,
        ...this.header.value
      }, activity: 'updateAfterSaleHeader'
    }, (res) => {
      this.loading = false

      if (res) {
        if (res.status == 200) {


          // this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        this.ms.sendMessage("alert", {
          type: "danger",
          text: this.words[this.language]['apiNoResponse'],
        });
      }
    });
  };
  // ======================
  // ? lines
  // ======================
  /*
  todo: update line
  */
  public updateContractLines = (): void => {
    this.loading = true
    this.master.sendPost("afterSalesContractLinesDispatcher", {
      params: {
        saleId: this.saleId,
        corpId: this.header.value['corpId'],
        afterSaleHeaderId: this.header.value['id'],
        id: this.data.afterSaleLines[this.lineSelected].id,
        ...this.contactLine.value
      }, activity: 'updateContractLine'
    }, (res) => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          this.data.afterSaleLines[this.lineSelected] = { ...res.data.updatedLine }
          this.data['totalAmount'] = this.data.afterSaleLines.reduce((total, value) => total + parseFloat(value.amount), 0)
          this.paymentSchedule.get('schedulePmtAmt').setValue((this.data['totalAmount'] / (this.paymentSchedule.value['numberOfPmt'] == 0 ? 1 : this.paymentSchedule.value['numberOfPmt'])))
          this.contactLine.reset()
          // this.activeEmitter$.emit({message:'recalculate kpi'})
          this.lineSelected = -1
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        this.ms.sendMessage("alert", {
          type: "danger",
          text: this.words[this.language]['apiNoResponse'],
        });
      }
    });
  }
  /*
  todo: create new line
  */
  public createContractLines = (): void => {
    this.loading = true

    this.master.sendPost("afterSalesContractLinesDispatcher", {
      params: {
        saleId: this.saleId,
        corpId: this.header.value['corpId'],
        afterSaleHeaderId: this.header.value['id'],
        ...this.contactLine.value
      }, activity: 'createContractLine'
    }, (res) => {
      this.loading = false
      if (res) {
        if (res.status == 200) {

          this.data.afterSaleLines.push({ ...res.data.createdLine })
          this.data['totalAmount'] = this.data.afterSaleLines.reduce((total, value) => total + parseFloat(value.amount), 0)
          this.paymentSchedule.get('schedulePmtAmt').setValue((this.data['totalAmount'] / (this.paymentSchedule.value['numberOfPmt'] == 0 ? 1 : this.paymentSchedule.value['numberOfPmt'])))
          this.contactLine.reset()
          // this.activeEmitter$.emit({message:'recalculate kpi'})
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        this.ms.sendMessage("alert", {
          type: "danger",
          text: this.words[this.language]['apiNoResponse'],
        });
      }
    });
  };
  /*
  todo: clear line
  */
  public clearLine = (): void => {
    this.contactLine.reset()
    this.lineSelected = -1
  }
  /*
  todo:delete payments schedule
  */
  public deletePaymentSchedules = () => {
    this.resetPayments(el => {
      this.data['paymentExpecteds'] = []
      this.paymentSchedule.setValue({
        paymentCycleId: '1',
        firstScheduledPmtDue: this.header.value['date'],
        numberOfPmt: 1,
        schedulePmtAmt: this.data.totalAmount
      })
    })

  }
  /*
  todo: select line
  */
  public selectLine = (i, line:Object) => {
    this.lineSelected = i
    this.contactLine.setValue({
      typeId: line['typeId'],
      amount: line['amount'],
      cost: line['cost'],
      contactId: line['contactId'] || null,
      description: line['description'],
    })
  }
  /*
  todo: delete line
  */
  public deleteLine = (i:number, line:Object) => {
    this.loading = true
    this.master.sendPost("afterSalesContractLinesDispatcher", {
      params: {
        id: line['id'],
        afterSaleHeaderId: this.header.value['id'],
      }, activity: 'deleteContractLine'
    }, (res) => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          this.data.afterSaleLines.splice(i, 1)
          this.data['totalAmount'] = this.data.afterSaleLines.reduce((total, value) => total + parseFloat(value.amount), 0)
          this.paymentSchedule.get('schedulePmtAmt').setValue((this.data['totalAmount'] / (this.paymentSchedule.value['numberOfPmt'] == 0 ? 1 : this.paymentSchedule.value['numberOfPmt'])))
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });

          // this.activeEmitter$.emit({message:'recalculate kpi'})
        } else {
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        this.ms.sendMessage("alert", {
          type: "danger",
          text: this.words[this.language]['apiNoResponse'],
        });
      }
    });
  }
  // ======================
  // ? types
  // ======================
  /*
    todo: add new type
    */
  public addNewType = () => {
    let newTypeName = (document.getElementById('newTypeName') as HTMLInputElement).value;
    if (newTypeName) {
      // check not repeat
      if ((this.types.filter(el => el['name'].toLowerCase() == newTypeName.toLowerCase())).length == 0) {
        this.loading = true
        this.master.sendPost('crudReconditioningType', { name: newTypeName, type: "create" }, res => {
          this.loading = false
          if (res) {
            if (res.status == 200) {
              // * success
              this.types.push({ id: res.data.id, name: newTypeName });
              (document.getElementById('newTypeName') as HTMLInputElement).value = '';
              this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
            } else {
              // ! in case error
              this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
            }
          } else {
            // in case API no response
            this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
          }
        })
      } else {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['Type already exist'] });
      }
    }

  }
  /*
  todo: edit  type
  */
  public editType = (type: Object, index: number) => {

    let name = (document.getElementById('newTypeName_' + index) as HTMLInputElement).value;
    if (name) {
      // check the name is the same
      if (name == type['name']) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['The name is the same'] });
        return
      }
      // check not repeat
      let repeat = this.types.filter(el => el['name'].toLowerCase() == name.toLowerCase())
      if (repeat.length > 0) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['The name already exist'] });
        return
      }
      this.loading = true
      this.master.sendPost('crudReconditioningType', { id: type['id'], name: name, type: "update" }, res => {
        this.loading = false
        if (res) {
          if (res.status == 200) {
            // * success
            this.types[index] = { ...type, name: name }
            this.typeSelected = -1
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          } else {
            // ! in case error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })
    }

  }
  /*
  todo: delete  type
  */
  public deleteType = (type: Object, index: number) => {
    this.loading = true
    this.master.sendPost('crudReconditioningType', { id: type['id'], type: "delete" }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.types.splice(index, 1)
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })

  }
  /*
  todo: select  type
  */
  public selectType = (i: number) => {
    this.typeSelected = i;
    setTimeout(() => {
      (document.getElementById('newTypeName_' + i) as HTMLInputElement).value = this.types[i].name;
    }, 0);
  }


  // ======================
  // ? payments schedule
  // ======================
  /*
  todo: get date of new schedule payment
  */
  public getDateCategory = (category, date) => {
    category = category + ''
    switch (category) {
      case '1':
        return moment(date).add(1, 'month').format('YYYY-MM-DD')
      case '2':
        let daysInMonth = Math.floor(moment(date).daysInMonth() / 2)
        return moment(date).add(daysInMonth, 'days').format('YYYY-MM-DD')
      case '3':
        return moment(date).add(1, 'week').format('YYYY-MM-DD')
      case '4':
        return moment(date).add(2, 'week').format('YYYY-MM-DD')
      default:
        break;
    }
  }
  /*
  todo: generate and save payments schedule
  */
  public savePaymentSchedule = (): void => {

    if (this.paymentSchedule.valid) {

      this.loading = true
      this.resetPayments(el => {

        if (el) {
          this.calcPayment('calcSchedulePayments',false)
          this.master.sendPost('afterSalesContractLinesDispatcher', {
            params:
            {
              saleTerms: {
                ...this.paymentSchedule.value, paymentCycleId: parseInt(this.paymentSchedule.value['paymentCycleId']),
                saleId: this.saleId, afterSaleHeaderId: this.header.value['id'], oneTime: this.header.value['oneTimePmt']
              },
              calendar: this.makeCalendar(this.paymentSchedule.value)
            },
            activity: "createPaymentSchedule"
          }, res => {
            setTimeout(() => {
              this.loading = false
            }, 500);
            if (res) {
              if (res.status == 200) {
                // * success
                // todo: update the header
                this.activeEmitter$.emit({ message: 'reloadTable' })

                this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
              } else {
                // ! in case error
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
              }
            } else {
              // in case API no response
              this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
            }
          })

        }
      })
    }
    // })

  }
  /*
  todo: delete all rows
  */
  public resetPayments = (callback?): void => {

    if (this.data.paymentExpecteds.length > 0) {

      this.master.sendPost("afterSalesContractLinesDispatcher", {
        params: {
          afterSaleHeaderId: this.header.value['id'],
        }, activity: 'deletePaymentSchedule'
      }, (res) => {

        if (res) {
          if (res.status == 200) {

            if (callback) {
              this.data['paymentExpecteds']=[]
              this.ms.sendMessage("alert", { type: "success", text: 'Delete success' });
              callback(true)
            }
          } else {
            if (!callback) {
              callback(false)
            }
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          this.ms.sendMessage("alert", {
            type: "danger",
            text: this.words[this.language]['apiNoResponse'],
          });
        }
      });
    } else {

      if (callback) {
        callback(true)
      }
    }

  };
  /*
  todo: calc number or schedule payments
  */
  public calcPayment = (calcType: string,fixed:boolean) => {
    if (this.paymentSchedule.valid) {
      if (calcType == 'calcNumberPayments') {
        // calc number of payments
        if(this.paymentSchedule.value['schedulePmtAmt']>this.data['totalAmount']){
          return
        }
        this.paymentSchedule.get('numberOfPmt').setValue((this.data['totalAmount'] / (this.paymentSchedule.value['schedulePmtAmt'] == 0 ? 1 : this.paymentSchedule.value['schedulePmtAmt'])))
      } else if (calcType == 'calcSchedulePayments') {
        // calc schedule payments
        this.paymentSchedule.get('schedulePmtAmt').setValue((this.data['totalAmount'] / (this.paymentSchedule.value['numberOfPmt'] == 0 ? 1 : this.paymentSchedule.value['numberOfPmt'])))
      }
      if(fixed){
        this.paymentSchedule.get('numberOfPmt').setValue((this.paymentSchedule.value['numberOfPmt'] ).toFixed(2))
        this.paymentSchedule.get('schedulePmtAmt').setValue((this.paymentSchedule.value['schedulePmtAmt'] ).toFixed(2))
      }

    }
  }
  /*
  todo: calc and generate payments schedule
  */
  public makeCalendar = (paymentForm): any => {
    let calendars = []
    const numPayments = Math.ceil(paymentForm['numberOfPmt'])
    // const decimal = paymentForm['numberOfPmt'] - Math.ceil(paymentForm['numberOfPmt'])
    // const lastPayment = decimal * paymentForm['schedulePmtAmt']

    for (let index = 0; index < numPayments; index++) {
      calendars.push({
        dueDate: index == 0 ? paymentForm['firstScheduledPmtDue'] : this.getDateCategory(paymentForm['paymentCycleId'], calendars[calendars.length - 1].dueDate),
        amountDue: parseFloat(paymentForm['schedulePmtAmt']).toFixed(2),
        paymentCategoryId: 5,
        paymentStatusId: 1,
        afterSaleHeaderId: this.header.value['id'],
        saleId: this.saleId
      })

    }

    const total=this.data.totalAmount
    const calendarSum=calendars.reduce((total,value)=>total + parseFloat(value['amountDue']),0)

    // calendars[calendars.length-1]['amount']=(+calendars[calendars.length-1]['amount'])+(total-calendarSum)

    calendars[calendars.length - 1].amountDue = ((+calendars[calendars.length - 1].amountDue )+(total-calendarSum)).toFixed(2)

    return calendars
  }


  // ======================
  // ?  agreement
  // ======================
  /*
  todo: cancel agreement
  */
  public cancelAgreement = (): void => {
    this.loading = true
    this.master.sendPost("afterSalesContractLinesDispatcher", {
      params: {
        afterSaleHeaderId: this.header.value['id'],
      }, activity: 'cancelContract'
    }, (res) => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          this.activeEmitter$.emit({ message: 'reloadTable' })

        } else {
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    });
  }

  // *********************
  //  life cycles
  // *********************
  constructor(private master: MasterService,
    private ms: MessageService,
    private store: StoreService) { }

  ngOnInit() {
    // listener

  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.

    if (changes.data) {
      // define the total of payments
      this.data['totalAmount'] = this.data.afterSaleLines.reduce((total, value) => total + parseFloat(value.amount), 0)
      // inicitial value of date

      this.paymentSchedule.get('firstScheduledPmtDue').setValue(moment().format('yyyy-MM-DD'))
      // set values of header
      this.header.setValue({
        id: changes.data.currentValue.id,
        corpId: changes.data.currentValue.corpId,
        date: changes.data.currentValue.date ? moment(changes.data.currentValue.date).format('yyyy-MM-DD') : moment().format('yyyy-MM-DD'),
        title: changes.data.currentValue.title,
        status: changes.data.currentValue.status,
        oneTimePmt: changes.data.currentValue.oneTimePmt
      })
      if (changes.data.currentValue['paymentExpecteds'].length > 0) {
        this.data.paymentExpecteds = changes.data.currentValue['paymentExpecteds']

        this.data['totalAmountPaid'] = this.data.paymentExpecteds.reduce((total,value)=>total+value['amountPayment'],0)
      }
      if (changes.data.currentValue['saleTerms'].length > 0) {
        this.paymentSchedule.setValue({
          paymentCycleId: changes.data.currentValue['saleTerms'][0].paymentCycleId,
          firstScheduledPmtDue: changes.data.currentValue['saleTerms'][0].firstScheduledPmtDue,
          numberOfPmt: changes.data.currentValue['saleTerms'][0].numberOfPmt,
          schedulePmtAmt: changes.data.currentValue['saleTerms'][0].schedulePmtAmt ? changes.data.currentValue['saleTerms'][0].schedulePmtAmt:0,
        })
        //
        this.paymentSchedule.get('schedulePmtAmt').setValue(parseFloat(this.paymentSchedule.get('schedulePmtAmt').value).toFixed(2))
      } else {
        this.updateContractLinesHeader()
        this.paymentSchedule.setValue({
          paymentCycleId: '1',
          firstScheduledPmtDue: this.header.value['date'],
          numberOfPmt: 1,
          schedulePmtAmt: this.data.totalAmount,
        })
      }
      this.paymentSchedule.get('schedulePmtAmt').setValue((this.data['totalAmount'] / (this.paymentSchedule.value['numberOfPmt'] == null || this.paymentSchedule.value['numberOfPmt'] == 0 ? 1 : this.paymentSchedule.value['numberOfPmt'])))

    }
  }

}
