import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
// services
import { MessageService } from '../../../../services/message.service'
import { MasterService } from '../../../../services/master.service'
import { StoreService } from '../../../../services/store.service'
import * as languageLibrary from '../../../../services/language'
//
// import { NgxXml2jsonService } from 'ngx-xml2json';

@Component({
  selector: 'inventory-general-information',
  templateUrl: './inventory-general-information.component.html',
  styleUrls: ['./inventory-general-information.component.scss']
})
export class InventoryGeneralInformationComponent implements OnInit {
  /*
  * Variables
  */
  //
  @Output() emitterInformation$:EventEmitter<Object> =new EventEmitter()
  // first value of stock number
  public firstStockNumber: string = ""
  //  define if some changes not saved
  public savePending: boolean = false
  //  carry count of VIN
  public vinCount: number = 0
  // save the language
  @Input() language: string =  'EN'
  // set all words
  public words = languageLibrary.language
  @Input() cleanStockNumber
  // contact object
  @Input() data: Object = {}
  // contact object
  @Input() setup: Object = {}
  // permissions
  @Input() permissions: string[] = []
  // permissions
  @Input() vehicleId: string = ''
  // define the inventory id
  @Input() inventoryId: string = ''
  @Input() isFlat = false
  @Output() allData$: EventEmitter<Object> = new EventEmitter();
  // define expanded as true
  public expanded: boolean = true
  // car colors catalog
  @Input() colors: string[] = []
  //
  @Input() isTradeIn=false
  // car types catalog
  @Input() types: string[] = []
  //
  public image = ''
  // list of odometers
  public odometers: string[] = ['Actual Mileage',
    'Not Actual Mileage (WARNING - ODOMETER DISCREPANCY)',
    'Exceeds Mechanical Limits',
    'Exempt'
  ]
  // vin query
  // public vinNumber: string = ''
  // loading
  public loading: boolean = false
  //
  public isGhost:boolean
  // Group of inputs
  public information = new FormGroup({
    vinNumber: new FormControl('', [Validators.required]),
    stockNumber: new FormControl('', [Validators.required]),
    model: new FormControl('', []),
    modelYear: new FormControl('', [Validators.pattern('[0-9]{4,4}')]),
    make: new FormControl('', []),
    trimLevel: new FormControl('', []),
    cylinders: new FormControl('', []),
    miles: new FormControl('', [Validators.pattern('[0-9]{1,20}')]),
    interiorColor: new FormControl('', []),
    exteriorColorMinor: new FormControl('', []),
    type: new FormControl('', []),
    odometer: new FormControl('', []),
    curbWeight: new FormControl('', []),
    carryingWeight: new FormControl('', []),
    gvwr: new FormControl('', []),
    tonnage: new FormControl('', []),
    lotId: new FormControl('', []),
    suffix: new FormControl('', []),
    prefix: new FormControl('', []),
    manufacturedIn: new FormControl('', []),
    productionNumber: new FormControl('', []),
    // specifications general
    bodyStyle: new FormControl('', []),
    transmission: new FormControl('', []),
    driveline: new FormControl('', []),
    fuelEconomyCity: new FormControl('', []),
    engine: new FormControl('', []),
    transmissionLong: new FormControl('', []),
    tank: new FormControl('', []),
    fuelEconomyHighway: new FormControl('', []),
    // specifications chasis
    antiBrakeSystem: new FormControl('', []),
    rearBrakeType: new FormControl('', []),
    rearSuspension: new FormControl('', []),
    rearSpringType: new FormControl('', []),
    steeringType: new FormControl('', []),
    turningDiameter: new FormControl('', []),
    frontSpringType: new FormControl('', []),
    tires: new FormControl('', []),
    frontBrakeType: new FormControl('', []),
    frontSuspension: new FormControl('', []),
    // interior dimensions
    frontHeadRoom: new FormControl('', []),
    frontLegRoom: new FormControl('', []),
    frontShoulderRoom: new FormControl('', []),
    frontHipRoom: new FormControl('', []),
    rearHeadRoom: new FormControl('', []),
    rearLegRoom: new FormControl('', []),
    rearShoulderRoom: new FormControl('', []),
    rearHipRoom: new FormControl('', []),
    // colors
    interiorTrim: new FormControl('', []),
    exteriorColor: new FormControl('', []),
    // exterior dimensions
    curbWeightAutomatic: new FormControl('', []),
    overallWidth: new FormControl('', []),
    wheelbase: new FormControl('', []),
    trackFront: new FormControl('', []),
    curbWeightManual: new FormControl('', []),
    overallHeight: new FormControl('', []),
    groundClearence: new FormControl('', []),
    trackRear: new FormControl('', []),
    overallLength: new FormControl('', []),
    // cargo bed dimensions
    length: new FormControl('', []),
    widthAtWheelwell: new FormControl('', []),
    widthAtWall: new FormControl('', []),
    depth: new FormControl('', []),
    // capacities
    standardSeating: new FormControl('', []),
    cargoVolume: new FormControl('', []),
    standardPayload: new FormControl('', []),
    standardGVWR: new FormControl('', []),
    optionalSeating: new FormControl('', []),
    standardTowing: new FormControl('', []),
    maximumPayload: new FormControl('', []),
    maximumGVWR: new FormControl('', []),
    passengerVolume: new FormControl('', []),
    maximumTowing: new FormControl('', []),
    // warranty
    basicDuration: new FormControl('', []),
    powertrainDuration: new FormControl('', []),
    rustDuration: new FormControl('', []),
    rustDistance: new FormControl('', []),
    basicDistance: new FormControl('', []),
    powertrainDistance: new FormControl('', []),
    // pricing
    msrp: new FormControl('', []),
    dealerInvoice: new FormControl('', []),
    destinationCharge: new FormControl('', []),
    // locks
    childSafetyDoorLocks: new FormControl('', []),
    lockingPickupTruckTailgate: new FormControl('', []),
    powerDoorLocks: new FormControl('', []),
    vehicleAntiTheft: new FormControl('', []),
    // traction
    awd: new FormControl('', []),
    automaticLoadLeveling: new FormControl('', []),
    limitedSlipDifferencial: new FormControl('', []),
    tractionControl: new FormControl('', []),
    absBrakes: new FormControl('', []),
    electronicBrakeAssistance: new FormControl('', []),
    lockingDifferential: new FormControl('', []),
    vehicleStabilityControlSystem: new FormControl('', []),
    // safety
    driverAirbag: new FormControl('', []),
    passengerAirbag: new FormControl('', []),
    secondRow: new FormControl('', []),
    firstAidKit: new FormControl('', []),
    frontSideAirbag: new FormControl('', []),
    sideHeadCurtainAirbag: new FormControl('', []),
    electronicParkingAid: new FormControl('', []),
    trunkAntiTrapDevice: new FormControl('', []),
    frontSideAirbagwithHeadProtection: new FormControl('', []),
    secondRowSideAirbag: new FormControl('', []),
    // remote controls
    keylessEntry: new FormControl('', []),
    remoteIgnition: new FormControl('', []),
    // climate control
    airConditioning: new FormControl('', []),
    separateDriver: new FormControl('', []),
    // interior features
    cruiseControl: new FormControl('', []),
    tiltSteeringColumn: new FormControl('', []),
    steeringWheelMountedControls: new FormControl('', []),
    genuineWoodTrim: new FormControl('', []),
    tachometer: new FormControl('', []),
    heatedSteeringWheel: new FormControl('', []),
    telescopicSteeringColumn: new FormControl('', []),
    tirePressureMonitor: new FormControl('', []),
    tiltSteeringWheel: new FormControl('', []),
    leatherSteeringWheel: new FormControl('', []),
    adjustableFootPedals: new FormControl('', []),
    tripComputer: new FormControl('', []),
    // navigation
    aMFmRadio: new FormControl('', []),
    cdChanger: new FormControl('', []),
    navigationAid: new FormControl('', []),
    subwoofer: new FormControl('', []),
    cassettePlayer: new FormControl('', []),
    dvdPlayer: new FormControl('', []),
    secondRowSoundControls: new FormControl('', []),
    telematicSystems: new FormControl('', []),
    cdPlayer: new FormControl('', []),
    voiceActivatedTelephone: new FormControl('', []),
    // seat
    driverMultiAdjustablePowerSeat: new FormControl('', []),
    frontPowerMemorySeat: new FormControl('', []),
    passengerMultiAdjustablePowerSeat: new FormControl('', []),
    secondRowMultiAdjustablePowerSeat: new FormControl('', []),
    frontCooledSeat: new FormControl('', []),
    frontSplitBenchSeat: new FormControl('', []),
    secondRowFoldingSeat: new FormControl('', []),
    secondRowRemovableSeat: new FormControl('', []),
    frontHeatedSeat: new FormControl('', []),
    leatherSeat: new FormControl('', []),
    secondRowHeatedSeat: new FormControl('', []),
    thirdRowRemovableSeat: new FormControl('', []),
    frontPowerLumbarSupport: new FormControl('', []),
    // storage
    cargoAreaCover: new FormControl('', []),
    cargoNet: new FormControl('', []),
    loadBearingExteriorRack: new FormControl('', []),
    pickupTruckBedLiner: new FormControl('', []),
    cargoAreaTiedowns: new FormControl('', []),
    // roof
    powerSunroof: new FormControl('', []),
    removableTop: new FormControl('', []),
    manualSunroof: new FormControl('', []),
    // exterior light
    automaticHeadlights: new FormControl('', []),
    fogLights: new FormControl('', []),
    highIntensityDischargeHeadlights: new FormControl('', []),
    pickupTruckCargoBoxLight: new FormControl('', []),
    daytimeRunningLights: new FormControl('', []),
    // exterior features
    runningBoard: new FormControl('', []),
    rearSpoiler: new FormControl('', []),
    splashGuards: new FormControl('', []),
    powerSlidingSideVanDoor: new FormControl('', []),
    frontAirDam: new FormControl('', []),
    skidPlate: new FormControl('', []),
    windDeflectorforConvertibles: new FormControl('', []),
    powerTrunkLid: new FormControl('', []),
    // tires
    alloyWheels: new FormControl('', []),
    fullSizeSpareTire: new FormControl('', []),
    runFlatTires: new FormControl('', []),
    steelWheels: new FormControl('', []),
    chromeWheels: new FormControl('', []),
    // mirrors
    powerWindows: new FormControl('', []),
    heatedExteriorMirror: new FormControl('', []),
    deepTintedGlass: new FormControl('', []),
    rearWindowDefogger: new FormControl('', []),
    electrochromicExteriorRearviewMirror: new FormControl('', []),
    electrochromicInteriorRearviewMirror: new FormControl('', []),
    intervalWipers: new FormControl('', []),
    rearWiper: new FormControl('', []),
    glassRearWindowOnConvertible: new FormControl('', []),
    powerAdjustableExteriorMirror: new FormControl('', []),
    rainSensingWipers: new FormControl('', []),
    slidingRearPickupTruckWindow: new FormControl('', []),
    // towins
    towHitchReceiver: new FormControl('', []),
    towingPreparationPackage: new FormControl('', []),
  })
  // Group of inputs

  // get information of inputs
  get vinNumber() { return this.information.get('vinNumber') }
  get stockNumber() { return this.information.get('stockNumber') }
  get modelYear() { return this.information.get('modelYear') }
  get make() { return this.information.get('make') }
  get model() { return this.information.get('model') }
  get trimLevel() { return this.information.get('trimLevel') }
  get bodyStyle() { return this.information.get('bodyStyle') }
  get engine() { return this.information.get('engine') }
  get transmission() { return this.information.get('transmission') }
  get driveline() { return this.information.get('driveline') }
  get type() { return this.information.get('type') }
  get cylinders() { return this.information.get('cylinders') }
  get miles() { return this.information.get('miles') }
  get exteriorColor() { return this.information.get('exteriorColor') }
  get interiorColor() { return this.information.get('interiorColor') }
  get exteriorColorMinor() { return this.information.get('exteriorColorMinor') }
  get odometer() { return this.information.get('odometer') }
  get curbWeight() { return this.information.get('curbWeight') }
  get carryingWeight() { return this.information.get('carryingWeight') }
  get gvwr() { return this.information.get('gvwr') }
  get tonnage() { return this.information.get('tonnage') }
  /*
 * Functions
 */
  public openFile = () => {
    (document.getElementById('inputScan') as HTMLInputElement).click();
  }

  public upload = (e): void => {
    let formData = new FormData();
    formData.append("inputimage", e.target.files[0]);
    var request = new XMLHttpRequest();
    request.open("POST", "https://www.recognition.ws/vinbarcode/v1?accesscode=f3297bcb-6a50-41d2-8b5e-f4859d5062f4&saveImage=TRUE&vindecode=TRUE");
    request.send(formData)
    let vm = this
    this.loading = true
    // request.onload = function (oEvent) {
    //   if (request.status == 200) {
    //     const parser = new DOMParser();
    //     const xml2 = parser.parseFromString(request.response, 'text/xml');
    //     vm.ngxXml2jsonService.xmlToJson(xml2);
    //     vm.vinNumber.setValue(obj['VINbarcode'].VIN_Captured)
    //     vm.decode(obj['VINbarcode'].VIN_Captured)
    //   } else {
    //     vm.ms.sendMessage("alert", { type: "danger", text: vm.words[vm.language]['imageError'] });
    //   }

    // };
  }

  //  save all data
  public save = (): void => {
    if (this.information.valid) {

      this.information.get('lotId').setValue(localStorage.getItem('lot') ? localStorage.getItem('lot') : this.store.lotSelected)

      let params = JSON.stringify(this.information.value)
      let lastStock = null
      if (this.setup) {
        lastStock = this.setup['setup'] == "increaseBy" ? this.setup['lastStock'] : null
      }
      //

      if (this.cleanStockNumber == null && !isNaN(this.information.value['stockNumber'])) {

        this.cleanStockNumber = this.information.value['stockNumber']
      }


      this.firstStockNumber == this.information.value['stockNumber'] ? false : true

      //
      this.master.sendPost('updateInventory', { ...this.information.value,
        features: params, inventoryId: this.inventoryId,
        forUpdate: 'generalInformation',
        lastStock: lastStock,
        cleanStockNumber: this.cleanStockNumber,
        isGhost:this.isGhost,
        isTradeIn: this.isTradeIn }, res => {
        if (res) {
          if (res.status == 200) {
            // * success
            this.isGhost=false
            if (!this.isFlat) {
              (document.getElementById('close') as HTMLButtonElement).click();
            }
            this.allData$.emit({ value: this.information.value })
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['successGeneral'] });
            this.emitterInformation$.emit({ message: 'reloadInventoryDetails', vinNumber: this.information.value['vinNumber'], stockNumber: this.information.value['stockNumber'] })
            this.savePending = false
            this.emitterInformation$.emit({ message: 'changesPendingInventory', section: 'generalInformationInventory', value: false })
          } else {
            // ! in case error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })
    }
  }
  // decode VIN Query
  public decode = (vinNumber: string) => {
    return false; // JMO: Temporal until we fix the decoding with new API Version
    this.loading = true

    this.stockNumber.setValue(this.getVinNumber(this.setup))
    this.master.getVinQuery('json','1', vinNumber, res => {
      this.loading = false

      if (res) {

        // const parser = new DOMParser();
        // const xml2 = parser.parseFromString(res.data, 'text/xml');
        const obj = res;
        // this.ngxXml2jsonService.xmlToJson(xml2);
        let path = {}
        obj['VINquery'].VIN.Vehicle.length ? path = obj['VINquery'].VIN.Vehicle[0] : path = obj['VINquery'].VIN.Vehicle
        path = obj.vehicles[0];
        this.model.setValue(path["Model"]["value"])
        this.modelYear.setValue(path["Model Year"]["value"])
        this.make.setValue(path["Make"]["value"])
        this.trimLevel.setValue(path["Trim Level"]["value"])
        this.curbWeight.setValue(path["Curb Weight-automatic"]["value"] == 'No data' ? path["Curb Weight-manual"]["value"] : path["Curb Weight-automatic"]["value"])
        let engine = path["Engine Type"]["value"].split(' ')
        this.cylinders.setValue(engine[1])
        // specifications general
        this.bodyStyle.setValue(path["Body Style"]["value"])
        this.engine.setValue(path["Engine Type"]["value"])
        this.transmission.setValue(path["Transmission-short"]["value"])
        this.driveline.setValue(path["Driveline"]["value"])

        this.information.get('manufacturedIn').setValue(path["Manufactured in"]["value"])
        this.information.get('productionNumber').setValue(path["Production Seq. Number"]["value"])
        this.information.get('transmissionLong').setValue(path["Transmission-long"]["value"])
        this.information.get('tank').setValue(path["Tank"]["value"])
        this.information.get('fuelEconomyCity').setValue(path["Fuel Economy-city"]["value"])
        this.information.get('fuelEconomyHighway').setValue(path["Fuel Economy-highway"]["value"])
        // specifications chasis
        this.information.get('antiBrakeSystem').setValue(path["Anti-Brake System"]["value"])
        this.information.get('steeringType').setValue(path["Steering Type"]["value"])
        // cargo bed dimensions
        this.information.get('length').setValue(path["Length"]["value"])
        // capacities
        this.information.get('standardSeating').setValue(path["Standard Seating"]["value"])
        this.information.get('optionalSeating').setValue(path["Optional Seating"]["value"])
        // locks
        this.information.get('childSafetyDoorLocks').setValue(path["Child Safety Door Locks"]["value"])
        this.information.get('lockingPickupTruckTailgate').setValue(path["Locking Pickup Truck Tailgate"]["value"])
        this.information.get('powerDoorLocks').setValue(path["Power Door Locks"]["value"])
        this.information.get('vehicleAntiTheft').setValue(path["Vehicle Anti-Theft"]["value"])
        // traction
        this.information.get('awd').setValue(path["4WD/AWD"]["value"])
        this.information.get('absBrakes').setValue(path["ABS Brakes"]["value"])
        this.information.get('automaticLoadLeveling').setValue(path["Automatic Load-Leveling"]["value"])
        this.information.get('electronicBrakeAssistance').setValue(path["Electronic Brake Assistance"]["value"])
        this.information.get('limitedSlipDifferencial').setValue(path["Limited Slip Differential"]["value"])
        this.information.get('lockingDifferential').setValue(path["Locking Differential"]["value"])
        this.information.get('tractionControl').setValue(path["Traction Control"]["value"])
        this.information.get('vehicleStabilityControlSystem').setValue(path["Vehicle Stability Control System"]["value"])
        // safety
        this.information.get('driverAirbag').setValue(path["Driver Airbag"]["value"])
        this.information.get('passengerAirbag').setValue(path["Passenger Airbag"]["value"])
        this.information.get('secondRow').setValue(path["Second Row Side Airbag"]["value"])
        this.information.get('firstAidKit').setValue(path["First Aid Kit"]["value"])
        this.information.get('frontSideAirbag').setValue(path["Front Side Airbag"]["value"])
        this.information.get('sideHeadCurtainAirbag').setValue(path["Side Head Curtain Airbag"]["value"])
        this.information.get('electronicParkingAid').setValue(path["Electronic Parking Aid"]["value"])
        this.information.get('trunkAntiTrapDevice').setValue(path["Trunk Anti-Trap Device"]["value"])
        this.information.get('frontSideAirbagwithHeadProtection').setValue(path["Front Side Airbag with Head Protection"]["value"])
        this.information.get('secondRowSideAirbag').setValue(path["Second Row Side Airbag with Head Protection"]["value"])
        // remote controls
        this.information.get('keylessEntry').setValue(path["Keyless Entry"]["value"])
        this.information.get('remoteIgnition').setValue(path["Remote Ignition"]["value"])
        // climate control
        this.information.get('airConditioning').setValue(path["Air Conditioning"]["value"])
        this.information.get('separateDriver').setValue(path["Separate Driver/Front Passenger Climate Controls"]["value"])
        // interior features
        this.information.get('cruiseControl').setValue(path["Cruise Control"]["value"])
        this.information.get('tiltSteeringColumn').setValue(path["Tilt Steering Column"]["value"])
        this.information.get('steeringWheelMountedControls').setValue(path["Steering Wheel Mounted Controls"]["value"])
        this.information.get('genuineWoodTrim').setValue(path["Genuine Wood Trim"]["value"])
        this.information.get('tachometer').setValue(path["Tachometer"]["value"])
        this.information.get('heatedSteeringWheel').setValue(path["Heated Steering Wheel"]["value"])
        this.information.get('telescopicSteeringColumn').setValue(path["Telescopic Steering Column"]["value"])
        this.information.get('tirePressureMonitor').setValue(path["Tire Pressure Monitor"]["value"])
        this.information.get('tiltSteeringWheel').setValue(path["Tilt Steering"]["value"])
        this.information.get('leatherSteeringWheel').setValue(path["Leather Steering Wheel"]["value"])
        this.information.get('adjustableFootPedals').setValue(path["Adjustable Foot Pedals"]["value"])
        this.information.get('tripComputer').setValue(path["Trip Computer"]["value"])
        // navigation
        this.information.get('aMFmRadio').setValue(path["AM/FM Radio"]["value"])
        this.information.get('cdChanger').setValue(path["CD Changer"]["value"])
        this.information.get('navigationAid').setValue(path["Navigation Aid"]["value"])
        this.information.get('subwoofer').setValue(path["Subwoofer"]["value"])
        this.information.get('cassettePlayer').setValue(path["Cassette Player"]["value"])
        this.information.get('dvdPlayer').setValue(path["DVD Player"]["value"])
        this.information.get('secondRowSoundControls').setValue(path["Second Row Sound Controls"]["value"])
        this.information.get('telematicSystems').setValue(path["Telematics System"]["value"])
        this.information.get('cdPlayer').setValue(path["CD Player"]["value"])
        this.information.get('voiceActivatedTelephone').setValue(path["Voice Activated Telephone"]["value"])
        // seat
        this.information.get('driverMultiAdjustablePowerSeat').setValue(path["Driver Multi-Adjustable Power Seat"]["value"])
        this.information.get('frontPowerMemorySeat').setValue(path["Front Power Memory Seat"]["value"])
        this.information.get('passengerMultiAdjustablePowerSeat').setValue(path["Passenger Multi-Adjustable Power Seat"]["value"])
        this.information.get('secondRowMultiAdjustablePowerSeat').setValue(path["Second Row Multi-Adjustable Power Seat"]["value"])
        this.information.get('frontCooledSeat').setValue(path["Front Cooled Seat"]["value"])
        this.information.get('frontSplitBenchSeat').setValue(path["Front Split Bench Seat"]["value"])
        this.information.get('secondRowFoldingSeat').setValue(path["Second Row Folding Seat"]["value"])
        this.information.get('secondRowRemovableSeat').setValue(path["Second Row Removable Seat"]["value"])
        this.information.get('frontHeatedSeat').setValue(path["Front Heated Seat"]["value"])
        this.information.get('leatherSeat').setValue(path["Leather Seat"]["value"])
        this.information.get('secondRowHeatedSeat').setValue(path["Second Row Heated Seat"]["value"])
        this.information.get('thirdRowRemovableSeat').setValue(path["Third Row Removable Seat"]["value"])
        this.information.get('frontPowerLumbarSupport').setValue(path["Front Power Lumbar Support"]["value"])
        // storage
        this.information.get('cargoAreaCover').setValue(path["Cargo Area Cover"]["value"])
        this.information.get('cargoNet').setValue(path["Cargo Net"]["value"])
        this.information.get('loadBearingExteriorRack').setValue(path["Load Bearing Exterior Rack"]["value"])
        this.information.get('pickupTruckBedLiner').setValue(path["Pickup Truck Bed Liner"]["value"])
        this.information.get('cargoAreaTiedowns').setValue(path["Cargo Area Tiedowns"]["value"])
        // roof
        this.information.get('powerSunroof').setValue(path["Power Sunroof"]["value"])
        this.information.get('removableTop').setValue(path["Removable Top"]["value"])
        this.information.get('manualSunroof').setValue(path["Manual Sunroof"]["value"])
        // exterior light
        this.information.get('automaticHeadlights').setValue(path["Automatic Headlights"]["value"])
        this.information.get('fogLights').setValue(path["Fog Lights"]["value"])
        this.information.get('highIntensityDischargeHeadlights').setValue(path["High Intensity Discharge Headlights"]["value"])
        this.information.get('pickupTruckCargoBoxLight').setValue(path["Pickup Truck Cargo Box Light"]["value"])
        this.information.get('daytimeRunningLights').setValue(path["Daytime Running Lights"]["value"])
        // exterior features
        this.information.get('runningBoard').setValue(path["Running Boards"]["value"])
        this.information.get('rearSpoiler').setValue(path["Rear Spoiler"]["value"])
        this.information.get('splashGuards').setValue(path["Splash Guards"]["value"])
        this.information.get('powerSlidingSideVanDoor').setValue(path["Power Sliding Side Van Door"]["value"])
        this.information.get('frontAirDam').setValue(path["Front Air Dam"]["value"])
        this.information.get('skidPlate').setValue(path["Skid Plate"]["value"])
        this.information.get('windDeflectorforConvertibles').setValue(path["Wind Deflector for Convertibles"]["value"])
        this.information.get('powerTrunkLid').setValue(path["Power Trunk Lid"]["value"])
        // tires
        this.information.get('alloyWheels').setValue(path["Alloy Wheels"]["value"])
        this.information.get('fullSizeSpareTire').setValue(path["Full Size Spare Tire"]["value"])
        this.information.get('runFlatTires').setValue(path["Run Flat Tires"]["value"])
        this.information.get('steelWheels').setValue(path["Steel Wheels"]["value"])
        this.information.get('chromeWheels').setValue(path["Chrome Wheels"]["value"])

        // mirrors
        this.information.get('powerWindows').setValue(path["Power Windows"]["value"])
        this.information.get('heatedExteriorMirror').setValue(path["Heated Exterior Mirror"]["value"])
        this.information.get('deepTintedGlass').setValue(path["Deep Tinted Glass"]["value"])
        this.information.get('rearWindowDefogger').setValue(path["Rear Window Defogger"]["value"])
        this.information.get('electrochromicExteriorRearviewMirror').setValue(path["Electrochromic Exterior Rearview Mirror"]["value"])
        this.information.get('electrochromicInteriorRearviewMirror').setValue(path["Electrochromic Interior Rearview Mirror"]["value"])
        this.information.get('intervalWipers').setValue(path["Interval Wipers"]["value"])
        this.information.get('rearWiper').setValue(path["Rear Wiper"]["value"])
        this.information.get('glassRearWindowOnConvertible').setValue(path["Glass Rear Window on Convertible"]["value"])
        this.information.get('powerAdjustableExteriorMirror').setValue(path["Power Adjustable Exterior Mirror"]["value"])
        this.information.get('rainSensingWipers').setValue(path["Rain Sensing Wipers"]["value"])
        this.information.get('slidingRearPickupTruckWindow').setValue(path["Sliding Rear Pickup Truck Window"]["value"])
        //
        this.information.get('towHitchReceiver').setValue(path["Tow Hitch Receiver"]["value"])
        // this.information.get('towingPreparationPackage').setValue(path['Item'][122]['@attributes']['Value'])
      }
    })
  }
  // get vin number
  public getVinNumber = (setup: Object): string => {

    switch (setup['setup']) {
      case 'manually':
        return this.information.value['stockNumber']
        break;
      case 'increaseBy':

        return this.information.value['stockNumber']
        break;
      case 'vinNumber':
        return this.data['prefix'] + this.information.value['vinNumber'].substring(this.information.value['vinNumber'].length - setup['value'], this.information.value['vinNumber'].length) + this.data['suffix']
        break;
      default:
        break;
    }
  }

  public checkForDupVIN = () => {
    this.changesPending()
    this.master.sendPost('checkForDupVIN', { vinNumber: this.vinNumber.value, lotId: localStorage.getItem('lot') }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          this.vinCount = res.data.vehicleVINs.length
          if (this.vinCount > 0) {

            this.ms.sendMessage("alert", { type: "warning", text: res.data.errorMessage });
          } else {
            this.ms.sendMessage("alert", { type: "success", text: 'Success' });
          }
        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }

  // todo: detect changes
  changesPending = () => {

    if (!this.savePending) {
      this.savePending = true

      this.emitterInformation$.emit({ message: 'changesPendingInventory', section: 'generalInformationInventory', value: this.savePending })
    }
  }
  // get image
  public getImage = (id): void => {
    this.loading = true

    this.master.sendPost('readImagesByInventory', { vehicleId: id }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          // this.data[0]['img']=res.data.uploads[2].fileURL+'480.jpg'
          if (res.data.uploads.length > 0) {
            this.image = res.data.uploads[0].fileURL
          }
        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  //
  /*
 * life cycles
 */
  constructor(
    // private ngxXml2jsonService: NgxXml2jsonService, 
    private route: ActivatedRoute, private ms: MessageService, private master: MasterService, private store: StoreService) {

  }

  ngOnInit() {
    this.getImage(this.inventoryId)
    this.isGhost = this.data['vinNumber'] == null ? true:false
    this.information.get('stockNumber').setValue(this.data['stockNumber'])
    this.firstStockNumber = this.information.get('stockNumber').value

    if (this.data['features']) {
      this.information.setValue({ ...JSON.parse(this.data['features']) })
    }

  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.ms.channelComponents$.subscribe(el=>{
      if(el.message=='reloadImages'){
        this.getImage(this.inventoryId)
      }
    })
  }




}
