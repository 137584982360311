import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
// services
import { MessageService } from "../../../../services/message.service";
import { MasterService } from "../../../../services/master.service";
import { StoreService } from "../../../../services/store.service";
//
// import { NgxXml2jsonService } from "ngx-xml2json";
import * as languageLibrary from '../../../../services/language'
import * as moment from "moment";

@Component({
  selector: "contract",
  templateUrl: "./contract.component.html",
  styleUrls: ["./contract.component.scss"],
})
export class DealContractComponent implements OnInit {
  //
  public kpi: Object = {
    subjectToFinance: 0,
    totalPaymentAmount: 0,
    financeCharges: 0
  }
  public inventoryTaxRate:number=0
  //
  @Input() lienholders: Object[] = []
  //
  public fees: Object[] = []
  //
  @Input() isUnLock = true
  // define expanded
  public expanded: boolean = true;
  //
  public loading: boolean = false
  // save the language
  public language: any = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  //
  public totals = { ttl: 0, accessories: 0 }
  // sale id
  @Input() saleId = ''
  //
  @Input() saleType = ''
  //
  @Input() buyer = null
  //
  @Output() salePrice$: EventEmitter<Object> = new EventEmitter();;
  //
  @Input() buyerType = null
  //
  public errorsCatalog = {
    'username': 'User Name',
    'password': 'Password',
    'corporateName': 'Corporate Name',
    'firstName': 'First Name',
    'middleName': 'Middle Name',
    'lastName': 'Last Name',
    'suffix': 'Suffix',
    'dealNumber': 'Deal Number',
    'entityType': 'Entity Type',
    'city': 'city',
    'state': 'State',
    'street1': 'Street',
    'zipcode': 'Zip code',
    'salesPrice': 'Sales Price',
    'bodyType': 'Body Type',
    'majorColorCode': 'Color',
    'make': 'Make',
    'model': 'model',
    'modelYear': 'Year',
    'vin': 'VIN Number'
  }

  //
  public data = {}
  //  sale type options
  public saleTypeList: string[] = [
    "Cash",
    "Wholesale",
    "Outside",
    "BHPH Sales Tax Deferred",
    "BHPH Sales Tax Paid",
  ];

  //  VI Tax type options
  public VITaxTypeList: string[] = [
    "MV - Motor Vehicle",
    "FL - Fleet Sale",
    "DL - Dealer Sale",
    "SS - Subsequent Sale",
  ];

  //  VI Tax type options
  public paymentCycleList: string[] = [
    "Monthly",
    "Semi-Monthly",
    "Weekly",
    "Bi-Weekly",
  ];

  public methodsList: string[] = [
    "ACH Checking",
    "ACH Savings",
    "Bank Draft",
    "Cash",
    "Cashier Check",
    "Check",
    "Credit Card",
    "Debit Card",
    "Direct Deposit",
    "House Credit",
    "Money Order",
    "Other",
    "Payment Deferred to End of Contract",
    "Prepaid Debit Card",
    "Referral Fee",
    "Traveler Check",
    "Treasurer Check"
  ];
  //
  @Input() inventory = null
  //
  public txdmv: any = {}
  //
  //todo: term listener
  public termListener = (e): void => {
    switch (e.message) {
      case 'set request of calc origination plus':

        this.salePrice$.emit({ message: 'set request of calc origination plus', value: e.value })
        break;

      default:
        this.kpi = { ...e }
        break;
    }
  }

  // =============================
  // ? read taxes
  // =============================
  public readForSalePrices = (): void => {

    this.master.sendPost('crudForSalePrices', {
      activity: 'read',
      saleId: this.saleId
    }, res => {
      if (res) {
        if (res.status == 200) {
          // * success

          this.data = res.data

          this.calculateTotal(false)
          this.setAtributes(this.store.userAccount['integrationsPermissions'])

        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // =============================
  // ? read taxes
  // =============================
  public calculateTotal = (save: boolean): void => {
    this.data['salePrice'] = this.transformNumber(this.data['salePrice'])

    this.data['totalSalePrice'] = this.data['salePrice'] + this.transformNumber(this.totals['accessories'])
    this.data['driveOutPrice'] = this.data['totalSalePrice'] + this.transformNumber(this.totals['ttl'])
    this.salePrice$.emit({ value: this.data['salePrice'] })

    if (save) {
      this.save()
    }

  }
  //
  public transformNumber = (value: any): number => {
    // value=parseFloat(value)
    //
    return parseFloat(value) || 0
  }
  //
  public save = (): void => {

    this.master.sendPost('crudForSalePrices', {
      activity: 'update',
      saleId: this.saleId,
      salePrice: this.data['salePrice'],
      totalSalePrice: this.data['totalSalePrice'],
      driveOutPrice: this.data['driveOutPrice']
    }, res => {
      if (res) {
        if (res.status == 200) {
          // * success
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // todo ttl listener
  public ttlListener = (e: any): void => {
    this.fees = e.fees
    this.totals['ttl'] = e.value
    this.inventoryTaxRate=e.inventoryTaxRate
    this.calculateTotal(e.save)


  }
  //
  public getAccesssories = (e): void => {
    this.totals['accessories'] = e.value

    this.calculateTotal(e.save)

  }
  // open payments in another window
  public openPayment = () => {
    let index = window.location.href.indexOf('#/')

    let route = window.location.href.substr(0, index + 2)

    window.open(route + 'payments/' + this.saleId, 'fullscreen',
      'top=0,left=0,width=' + (screen.availWidth) + ',height =' + (screen.availHeight) +
      ',fullscreen=yes,toolbar=0 ,location=0,directories=0,status=0,menubar=0,resiz able=0,scrolling=0,scrollbars=0')
  }
  //
  public setAtributes = (permissions: any[]): void => {
    for (let role = 0; role < permissions.length; role++) {
      if (!this.data['use']) {
        this.data['use'] = permissions[role]['integrationsPermissions'].some(el => el['use'] == true)
      }
    }
  }
  //
  public openTXDMV = (): void => {
    this.loading = true
    this.master.sendPost('webDealerDispatcher', {
      activity: 'read',
      saleId: this.saleId,
      lotId: this.store.lotSelected,
      primaryKeyId: this.inventory.inventoryId ? this.inventory.inventoryId : null
    }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          let id = null
          this.txdmv = res.data
          if (this.txdmv.body.length > 0) {
            for (let index = 0; index < this.txdmv.body.length; index++) {
              this.txdmv.body[index].message = JSON.parse(this.txdmv.body[index].message)
              if (this.txdmv.body[index].message['ax21:success'] == 'true') {
                id = this.txdmv.body[index].message['ax21:titleId']
              }
            }
            this.txdmv.body.forEach(el => {
              el.message['ax21:titleId'] = id
              el.message['ax21:fees'] = el.message['ax21:fees'] && typeof el.message['ax21:fees'] === 'object' ? [] : el.message['ax21:fees']
              // el.message['ax21:messages']=el.message['ax21:messages'] && typeof el.message['ax21:messages']==='object'?[]:el.message['ax21:messages']
            });
          }

          // this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  //
  public checkTitleTXDMV = (): void => {
    this.loading = true
    this.master.sendPost('webDealerDispatcher', {
      activity: 'checkTitleStatus',
      checkStatusData: {
        dealersConfigId: this.txdmv.integrationUseParameters.dealer.id,
        primaryKeyId: this.txdmv.header.inventory,
        userId: this.txdmv.integrationUseParameters.user.userId,
        username: this.txdmv.integrationUseParameters.user.username,
        dealerId: this.txdmv.integrationUseParameters.dealer.dealerId,
        password: this.txdmv.integrationUseParameters.user.password,
        titleId: this.txdmv.body[0].message['ax21:titleId'],
      }
    }, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          this.openTXDMV()
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // upload title aplication
  public uploadTitle = (): void => {
    if (this.buyerType && this.inventory) {

      let color = this.txdmv.colorsCatalog.find(el => el['name'] == this.inventory.data['exteriorColor'])
      const make = this.txdmv.makesCatalog.find(el => el['name'] == this.inventory.data['make'].toUpperCase())
      // error make
      if (make == undefined) {
        this.ms.sendMessage("alert", { type: "warning", text: 'The make or model of the vehicle is not compatible with webDealer' });
        return
      }
      let uploadTitleData = {
        dealersConfigId: this.txdmv.integrationUseParameters.dealer.id,
        primaryKeyId: this.txdmv.header.inventory,
        userId: this.txdmv.integrationUseParameters.user.userId,
        username: this.txdmv.integrationUseParameters.user.username,
        dealerId: this.txdmv.integrationUseParameters.dealer.dealerId,
        password: this.txdmv.integrationUseParameters.user.password,
        corporateName: this.store.userAccount['user'].corporateName,
        createTimestamp: moment().format('yyyy-MM-DD'),
        contractDate: moment(this.buyerType['createdAt']).format('yyyy-MM-DD'),
        dealNumber: this.inventory['stockNumber'],
        firstName: this.buyerType['firstName'],
        lastName: this.buyerType['lastName'],
        entityType: this.buyerType['code'] ? this.buyerType['code'] : '',
        city: this.buyer['city'],
        state: this.buyer['state'],
        street1: this.buyer['address'],
        zipcode: this.buyer['zipCode'],
        idJurisdiction: this.buyer['issuer'], //2
        idNumber: this.buyer['number'],
        idType: this.buyerType.contactIdentifications.length > 0 ? this.buyerType.contactIdentifications[0].identificationType['code'] : '',
        salesPrice: this.data['salePrice'],
        bodyType: this.inventory.data['type'], // 2
        majorColorCode: color.code,
        make: make.code,
        model: this.inventory.data['model'],
        modelYear: this.inventory.data['modelYear'],
        vin: this.inventory.data['vinNumber'],
      }

      for (const property in uploadTitleData) {
        if (uploadTitleData[property] == '') {
          this.ms.sendMessage("alert", { type: "info", text: `${this.errorsCatalog[property]} cannot be empty` });
          return
        }
      }
      uploadTitleData['middleName'] = this.buyerType['middleName']
      uploadTitleData['suffix'] = this.buyerType['suffix']

      this.loading = true
      this.master.sendPost('webDealerDispatcher', {
        activity: 'uploadTitle',
        uploadTitleData: uploadTitleData,

      }, res => {
        this.loading = false
        if (res) {
          if (res.status == 200) {
            // * success
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
            this.openTXDMV()
          } else {
            // ! send message in case error
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })
    }

  }
  // =============================
  // todo: listener of inventory
  // =======================
  public listenerInventory = (res) => {
    this.inventory = res.value
  }
  //
  public openWebSite = () => {
    window.open('https://webdealer.txdmv.gov/title/login.do#')
  }

  // =============================
  // ? create drive out Price
  // =============================
  public createDriveOutPrice = async () => {

    const param = {
      "activity": "createDriveOutPriceDispatcher",
      "saleId": this.saleId,
      "saleTypeId": this.saleType,
      "amountDue": (+this.data['driveOutPrice']),
      "dueDate": moment().format('yyyy-MM-DD')


    }
    const res = await this.master.sendPostAsync('driveOutPriceDispatcher', param)
    if (res && res.status == 200) {
      this.ms.sendMessage("alert", { type: "info", text: 'Drive-out price was created' });
    } else {
      this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
    }
  }
  constructor(
    // private ngxXml2jsonService: NgxXml2jsonService,
    private route: ActivatedRoute,
    private ms: MessageService,
    private master: MasterService,
    private store: StoreService
  ) {
    this.ms.channelComponents$.subscribe(res => {
      if (res.message == 'setPermissions') {
        // permissions on user list

        this.setAtributes(res['integrations'])



      }
    })
  }

  ngOnInit() {
    this.readForSalePrices()
    this.data['salePrice'] = 0
    this.data['totalSalePrice'] = 0
    this.data['driveOutPrice'] = 0
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inventory && changes.inventory.currentValue.sellingPrice) {

      if (!changes.inventory.firstChange) {
        this.data['salePrice'] = this.data['salePrice'] ? this.data['salePrice'] : changes.inventory.currentValue.sellingPrice
        this.calculateTotal(false)
      }
    }
    if (changes.isUnLock && changes.isUnLock.currentValue == true) {


      if (!changes.isUnLock.firstChange) {

        if (this.saleType == '2' || this.saleType == '1') {
          this.createDriveOutPrice()
        }
      }

    }


  }



}
