import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// services
import { MessageService } from "../../../../../../services/message.service";
import { MasterService } from "../../../../../../services/master.service";
import { StoreService } from "../../../../../../services/store.service";

@Component({
  selector: 'promises-form',
  templateUrl: './promises-form.component.html',
  styleUrls: ['./promises-form.component.scss']
})
export class PromisesFormComponent implements OnInit {
  // ************************
  // variables
  // ***********************
  // send listener to father
  @Output() promisesForm$: EventEmitter<Object> = new EventEmitter();
  //  loading state
  public loading: boolean = false
  // define the payment selected
  @Input() paymentSelected: Object = {}
  // define the date selected
  @Input() dateSelected: string = ''
  // all promises
  @Input() promises: any[] = []
  // all  dates selecteds
  @Input() dates: Object[] = []
  // general data from father
  @Input() data: Object = {}
  // save the language
  @Input() language: string = 'EN'
  // set all words
  @Input() words: Object = {}
  // form new promise
  public promiseInformation = new FormGroup({
    amount: new FormControl('', [Validators.required, Validators.pattern('^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$')]),
    date: new FormControl('', [Validators.required]),
    note: new FormControl('', []),
  })
  // ************************
  //  functions
  // ***********************
  // get calendar dates
  public getDataCalendar = (id?: number): void => {

    // let backup=this.promises.slice()
    this.promises = []
    id != undefined ? this.dates.splice(id, 1) : this.dates.push(this.dateSelected)
    for (let index = 0; index < this.dates.length; index++) {
      this.promises.push({
        amount: ((this.paymentSelected['amountDue'] - this.paymentSelected['amountPaid']) / this.dates.length).toFixed(2),
        date: this.dates[index],
        note: '',
        amountDue: this.paymentSelected['amountDue'],
        paymentCategoryId: this.paymentSelected['paymentCategoryId'],
        expectedPaymentId: this.paymentSelected['id'],
        createdBy: this.store.userAccount['user'].id,
      })
    }
    const total=(this.paymentSelected['amountDue'] - this.paymentSelected['amountPaid'])
    const promesasSum=this.promises.reduce((total,value)=>total + parseFloat(value['amount']),0)

    this.promises[this.promises.length-1]['amount']=((+this.promises[this.promises.length-1]['amount'])+(total-promesasSum)).toFixed(2)

    this.dateSelected = moment().format('YYYY-MM-DD')
    // set notes
    // for (let index = 0; index < this.promises.length; index++) {
    //   this.promises[index].note=backup[index]?backup[index].note:''
    //
    // }
  }
  // todo: reload field
  public checkField = (index: number): void => {
    let regExp = new RegExp('^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$')
    if (!regExp.test(this.data['paymentsExpected'][index]['amountAllocated'])) {
      this.data['paymentsExpected'][index]['amountAllocated'] = ''
    }
  }
  // todo clear
  public clear = (): void => {
    this.promises = []
    this.dates = []
    this.promisesForm$.emit({ message: 'clear' })
  }
  // todo: save promise
  public savePromise = (): void => {
    for (let index = 0; index < this.promises.length; index++) {
      this.promiseInformation.setValue({
        amount: this.promises[index].amount,
        date: this.promises[index].date,
        note: this.promises[index].note
      })

      if (this.promiseInformation.invalid) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['invalidValue'] });
        return
      } else {
        this.promises[index].amount = parseFloat(this.promises[index].amount)
      }
    }
    if ((this.promises.reduce((total, value) => value['amount'] + total, 0)).toFixed(2) > this.promises[0].amountDue) {
      this.ms.sendMessage("alert", { type: "danger", text: 'Amount Promised cannot be greater than Amount Due' });
      return
    }

    this.master.sendPost("promiseToPayDispatcher", { parameters: this.promises, activity: 'createPromised' }, (res) => {
      if (res) {
        if (res.status == 200) {
          // * success

          this.promisesForm$.emit({ message: 'reload Payments' })
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          // ! error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    }
    );
  }
  // ************************
  //  life cycles
  // ***********************
  constructor(private ms: MessageService,
    private master: MasterService,
    private store: StoreService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if(changes.data){
      this.promises = []
      this.paymentSelected = {}
    }

  }

}
