import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as languageLibrary from '../../../../services/language'
// services
import { MessageService } from "../../../../services/message.service";
import { MasterService } from "../../../../services/master.service";
//


@Component({
  selector: "buyer",
  templateUrl: "./buyer.component.html",
  styleUrls: ["./buyer.component.scss"],
})
export class DealBuyerComponent implements OnInit {
  /*
  * Variables
  */
  //
  public isEditing: boolean = false
  //  auxiliar
  public auxContact = {}
  //
  public typeBuyerSelected = null
  // save the language
  @Input() language
  // set all words
  public words = languageLibrary.language
  // define expanded
  public expanded: boolean = false;
  //  buyer type options
  public buyerTypeList: string[] = [];
  // contains all contacts (filtered)
  @Input() contacts: Object[] = []
  //
  @Input() isUnLock = true
  //
  @Output() buyer$: EventEmitter<Object> = new EventEmitter();
  //
  @Output() typeBuyerSelected$: EventEmitter<Object> = new EventEmitter();
  // clone of contacts (just the enable)
  public contactComplete: Object[] = []
  // clone of contactsCoplete (contains all contacts enables and disabled)
  public fullContacts = []
  // new contact created
  public viewDetail: boolean = false
  // define is anything is loading
  public loading: boolean = false
  // saleId
  public saleId: number
  // lotId
  public lotId: number
  // displayList
  public displayList: boolean = false
  // define the total buyer, co-buyers or cosigners
  public buyers = {
    buyer: {},
    coBuyers: [],
    cosigners: []
  }
  /*
  * Functions
  */
  //  todo: change edit view
  public toggleEdit = (isEditing: boolean): void => {
    this.isEditing = !isEditing

    if (isEditing) {
      // change false -> save
      const address = this.buyers.buyer['contact']['addresses'].find(el => el['address'] == this.buyers.buyer['address'])
      const identification = this.buyers.buyer['contact']['contactIdentifications'].find(el => el['number'] == this.buyers.buyer['number'])
      this.buyers.buyer = { ...this.buyers.buyer, ...address, ...identification }

      this.changeContactFromEdit(this.buyers.buyer)
    }
  }
  //

  //
  public showLookupList = (): void => {
    this.displayList = !this.displayList
    if (this.displayList) {
      document.getElementById('lookup-list').style.display = "block";
    } else
      document.getElementById('lookup-list').style.display = "none";

  }

  //  todo:get contacts
  public getContacts = (): void => {
    this.loading = true

    this.master.sendPost('contactLookupForSales', {}, res => {

      this.loading = false
      if (res) {
        if (res.status == 200) {
          // * success
          res.data.contactData.forEach(el => {
            el['companyName'] = el['companyName'] ? el['companyName'] : ''
          });

          this.contacts = res.data.contactData
          this.fullContacts = res.data.contactData

        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })

  }

  // todo: select an user and get all data
  public changeContact = (contact: any) => {

    let cellphones = contact.contactCommunications.filter(el => el['type'] == "cellphone" || el['type'] == "home" || el['type'] == "work")
    this.buyers.buyer = {
      contactId: contact.id,
      address: contact.addresses.length > 0 ? contact.addresses[0]['address'] : '',
      address2: contact.addresses.length > 0 ? contact.addresses[0]['address2'] : '',
      city: contact.addresses.length > 0 ? contact.addresses[0]['city'] : '',
      state: contact.addresses.length > 0 ? contact.addresses[0]['state'] : '',
      zipCode: contact.addresses.length > 0 ? contact.addresses[0]['zipCode'] : '',
      county: contact.addresses.length > 0 ? contact.addresses[0]['county'] : '',
      country: contact.addresses.length > 0 ? contact.addresses[0]['country'] : '',
      phoneType: cellphones.length > 0 ? cellphones[0]['type'] : '',
      phoneNumber: cellphones.length > 0 ? cellphones[0]['value'] : '',
      number: contact.contactIdentifications.length > 0 ? parseInt(contact.contactIdentifications[0]['number']) : 0,
      issuer: contact.contactIdentifications.length > 0 ? contact.contactIdentifications[0]['issuer'] : '',
      identificationTypeId: contact.contactIdentifications.length > 0 ? parseInt(contact.contactIdentifications[0]['identificationTypeId']) : null,
      saleBuyerRoleId: 1,
      saleId: this.saleId,
      lotId: this.lotId
    }

    this.typeBuyerSelected = this.typeBuyerSelected != 0 ? this.typeBuyerSelected : '1'

    this.save()

  }
  // todo: set format to edit values
  public changeContactFromEdit = (contact: any) => {

    this.buyers.buyer = {
      contactId: contact['contactId'],
      address: contact['address'],
      address2: contact['address2'],
      city: contact['city'],
      state: contact['state'],
      zipCode: contact['zipCode'],
      county: contact['county'],
      country: contact['country'],
      phoneType: contact['phoneType'],
      phoneNumber: contact['phoneNumber'],
      number: contact['number'],
      issuer: contact['issuer'],
      identificationTypeId: contact['identificationTypeId'],
      saleBuyerRoleId: contact['saleBuyerRoleId'],
      saleId: this.saleId,
      lotId: this.lotId
    }

    this.save()

  }
  // todo: select an user and get all data
  public changeContactFromModal = (contact: any) => {

    let cellphones = contact.communications.filter(el => el['type'] == "cellphone" || el['type'] == "home" || el['type'] == "work")
    this.buyers.buyer = {
      contactId: contact.id,
      address: contact.addresses.length > 0 ? contact.addresses[0]['address'] : '',
      address2: contact.addresses.length > 0 ? contact.addresses[0]['address2'] : '',
      city: contact.addresses.length > 0 ? contact.addresses[0]['city'] : '',
      state: contact.addresses.length > 0 ? contact.addresses[0]['state'] : '',
      zipCode: contact.addresses.length > 0 ? contact.addresses[0]['zipCode'] : '',
      county: contact.addresses.length > 0 ? contact.addresses[0]['county'] : '',
      country: contact.addresses.length > 0 ? contact.addresses[0]['country'] : '',
      phoneType: cellphones.length > 0 ? cellphones[0]['type'] : '',
      phoneNumber: cellphones.length > 0 ? cellphones[0]['value'] : '',
      number: contact.identifications.length > 0 ? parseInt(contact.identifications[0]['number']) : 0,
      issuer: contact.identifications.length > 0 ? contact.identifications[0]['issuer'] : '',
      identificationTypeId: contact.identifications.length > 0 ? parseInt(contact.identifications[0]['identificationTypeId']) : null,
      saleBuyerRoleId: 1,
      saleId: this.saleId,
      lotId: this.lotId
    }

    this.save()

  }
  // simple refresh data to contact detail
  public simpleRefresh = (id: string): void => {

    this.master.sendPost('getContactDetails', { contactId: id }, res => {

      if (res) {
        if (res.status == 200) {
          // * success

          this.auxContact = res.data.contact[0]

        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // todo:get data for contact selected
  public getContactDetail = (id: string, openDetail: boolean, callback?): void => {
    this.loading = true

    this.master.sendPost('getContactDetails', { contactId: id }, res => {
      this.loading = false
      // define if the modal detal is open
      this.viewDetail = openDetail
      if (res) {
        if (res.status == 200) {
          // * success

          this.auxContact = res.data.contact[0]
          if (callback) {
            callback(true)
          }
          // this.buyers.buyer = res.data.contact[0]
          setTimeout(() => {
            (document.getElementById('contactDetailBuyerModal') as HTMLButtonElement).click()
          }, 100);
        } else {
          // ! in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    })
  }
  // todo:get data all contacts (only name)
  public lookupContact = (): void => {
    let word = (document.getElementById('lookupContact') as HTMLInputElement).value.toLowerCase()
    let convert2String = (word: string): string => {
      return word + ''
    }
    this.contacts = this.contactComplete.filter(el =>
      convert2String(el['firstName']).toLowerCase().indexOf(word) >= 0 ||
      convert2String(el['lastName']).toLowerCase().indexOf(word) >= 0 ||
      convert2String(el['companyName']).toLowerCase().indexOf(word) >= 0 ||
      convert2String(el['address']).toLowerCase().indexOf(word) >= 0 ||
      convert2String(el['contactType']['type']).toLowerCase().indexOf(word) >= 0
    )
  }

  /*
  ? create contact
  */
  // todo: create new contact and open modal (just buyer)
  public createContact = (): void => {
    this.loading = true;
    this.master.sendPost("createContactType", { type: 'buyers' }, (res) => {
      this.loading = false;
      if (res) {
        if (res.status == 200) {
          // * success
          this.getContactDetail(res.data.contact.id, true);

        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse '] });

      }
    });
  }

  // ? delete
  // todo: check if the new contact is valid or have to delete it
  public questDelete = (): void => {
    if (this.auxContact['firstName'] == "" || this.auxContact['firstName'] == null) {

      if (confirm('The information is not valid, if it leaves the view the contact will be eliminated, do you want to leave?')) {

        this.deleteContact(this.auxContact['id'])
      }
    } else {
      this.changeContactFromModal(this.auxContact)
      this.viewDetail = false
      this.auxContact = {};


      (document.getElementById('btn-close-modal-delete') as HTMLButtonElement).click()
    }
  }
  public saveAll = () => {
    let buttons = [
      'btn-save-generalInformation',
      'btn-save-addresses', 'btn-save-communications', 'btn-save-identifications',
    ];
    buttons.forEach(el => {
      (document.getElementById(el) as HTMLButtonElement).click()
    });
  }
  // todo: listener to childrens
  public listenEndpoint = (e) => {

    switch (e.message) {
      case 'reloadContactDetail':
        if (e.firstName) {
          this.auxContact['firstName'] = e.firstName
        }
        this.getContactDetail(this.auxContact['id'], false, res => {
          this.questDelete()
        })
        break;
      default:
        break;
    }

  }

  // todo: delete a contact
  public deleteContact = (id: string): void => {
    this.loading = true;

    this.master.sendPost("contactDelete", { contactId: id, query: false }, (res) => {
      this.loading = false;
      if (res) {
        if (res.status == 200) {
          // * success
          this.auxContact = {}
          // this.cleanFields()
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
          this.viewDetail = false;
          (document.getElementById("btn-close-modal-delete") as HTMLButtonElement).click();
          // this.getContacts()
        } else {
          // ! error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    }
    );
  }
  // clean fields
  public cleanFields = (): void => {
    this.contacts = this.fullContacts.slice()

    this.deleteBuyer(this.buyers.buyer['id'])
  }
  // todo: delete buyer
  public deleteBuyer = (id) => {

    this.loading = true;
    this.master.sendPost("deleteSaleBuyer", { saleBuyerId: id }, (res) => {
      this.loading = false;
      if (res) {
        if (res.status == 200) {
          // * success
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });

          this.buyers.buyer = { contactName: '' }
          setTimeout(() => {
            (document.getElementById('btn-close-modal-delete-buyer') as HTMLButtonElement).click();
          }, 50);
        } else {
          // ! error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    }
    );


  }

  // ? save
  public save = (): void => {
    const type = this.buyerTypeList.find(el => el['id'] == this.typeBuyerSelected)
    const buyer = this.fullContacts.find(el => el['id'] == this.buyers.buyer['contactId'] || el['id'] == this.buyers.buyer['id'])

    this.typeBuyerSelected$.emit({ data: type, buyer: buyer })
    this.loading = true

    this.master.sendPost("createSaleBuyer", {
      saleBuyersData: {
        saleBuyerTypeId: parseInt(this.typeBuyerSelected),
        saleId: this.saleId,
        contactId: this.buyers.buyer['id'],
        buyer: this.buyers.buyer,
        cobuyers: this.buyers.coBuyers,
        cosigners: this.buyers.cosigners
      }
    }, (res) => {

      this.loading = false;
      if (res) {
        if (res.status == 200) {
          // * success
          // get sale detail
          // this.cleanFields()
          this.getSaleDetail(true)
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
        } else {
          // ! error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    }
    );

  }
  // check if some contacts are repeat
  public checkNotrepeat = (): boolean => {
    let listBuyers = []
    let listBuyersComplete = []
    listBuyers.push(this.buyers.buyer)
    listBuyers = listBuyers.concat(this.buyers.coBuyers)
    listBuyers = listBuyers.concat(this.buyers.cosigners)
    listBuyersComplete = listBuyers.slice()
    let repeat = []
    for (let index = 0; index < listBuyers.length; index++) {

      repeat = listBuyersComplete.filter(el => el['params']['contactId'] == listBuyers[index]['params']['contactId'])
      if (repeat.length > 1) {
        // ! repeat

        return true
      } else if (index == listBuyers.length - 1) {

        return false
      }
    }

  }
  // get all co-buyers
  public listenerCobuyers = (res) => {
    this.buyers.coBuyers = res.value

    if (res.doSave) {
      this.save()
    }
  }
  // get all cosigners
  public listenerCosigners = (res) => {

    this.buyers.cosigners = res.value
    if (res.doSave) {
      this.save()
    }
  }

  // get detail sale
  public getSaleDetail = (reload:boolean) => {
    this.loading = true;
    this.master.sendPost("getSalesBuyersDetail", { saleId: this.saleId }, (res) => {
      this.loading = false;
      if (res) {
        if (res.status == 200) {
          // * success
          this.buyers = {
            buyer: res.data.data.buyers.length > 0 ? res.data.data.buyers[0] : {},
            coBuyers: res.data.data.cobuyers,
            cosigners: res.data.data.cosigners
          }
          // config buyer
          if (this.buyers.buyer['contact']) {
            this.buyers.buyer['contact']['phones'] = this.buyers.buyer['contact']['contactCommunications'].filter(el => el['type'] == 'cellphone' || el['type'] == "home" || el['type'] == "work")
            this.buyers.buyer['contact']['contactCommunications'] = this.buyers.buyer['contact']['contactCommunications'].filter(el => el['type'] == 'email')
            this.buyers.buyer['identificationSelected'] = this.buyers.buyer['contact']['contactIdentifications'].find(el => el['number'] == this.buyers.buyer['number'])
          }
          // config cobuyers

          if (this.buyers.coBuyers.length > 0) {
            for (let index = 0; index < this.buyers.coBuyers.length; index++) {
              this.buyers.coBuyers[index]['contact']['phones'] = this.buyers.coBuyers[index]['contact']['contactCommunications'].filter(el => el['type'] == 'cellphone' || el['type'] == "home" || el['type'] == "work")
              this.buyers.coBuyers[index]['contact']['contactCommunications'] = this.buyers.coBuyers[index]['contact']['contactCommunications'].filter(el => el['type'] == 'email')
              this.buyers.coBuyers[index]['identificationSelected'] = this.buyers.coBuyers[index]['contact']['contactIdentifications'].find(el => el['number'] == this.buyers.coBuyers[index]['number'])

            }

          }
          // config cosigners
          if (this.buyers.cosigners.length > 0) {
            for (let index = 0; index < this.buyers.cosigners.length; index++) {
              this.buyers.cosigners[index]['contact']['phones'] = this.buyers.cosigners[index]['contact']['contactCommunications'].filter(el => el['type'] == 'cellphone' || el['type'] == "home" || el['type'] == "work")
              this.buyers.cosigners[index]['contact']['contactCommunications'] = this.buyers.cosigners[index]['contact']['contactCommunications'].filter(el => el['type'] == 'email')
              this.buyers.cosigners[index]['identificationSelected'] = this.buyers.cosigners[index]['contact']['contactIdentifications'].find(el => el['number'] == this.buyers.cosigners[index]['number'])

            }
          }

          this.buyer$.emit({ value: this.buyers.buyer,reload: reload})
          this.contactComplete = this.fullContacts.filter(el => el['id'] != this.buyers.buyer['contactId'])

          // cobuyers
          for (let index = 0; index < this.buyers.coBuyers.length; index++) {
            this.contactComplete = this.contactComplete.filter(el => el['id'] != this.buyers.coBuyers[index]['contactId'])
          }
          // cosigners
          for (let index = 0; index < this.buyers.cosigners.length; index++) {
            this.contactComplete = this.contactComplete.filter(el => el['id'] != this.buyers.cosigners[index]['contactId'])
          }

          this.contacts=this.contactComplete
          this.buyerTypeList = res.data.buyersTypes
          this.typeBuyerSelected = res.data.selectedBuyerType.saleBuyerTypeId
          const type = this.buyerTypeList.find(el => el['id'] == this.typeBuyerSelected)
          const buyer = this.fullContacts.find(el => el['id'] == this.buyers.buyer['contactId'] || el['id'] == this.buyers.buyer['id'])

          this.typeBuyerSelected$.emit({ data: type, buyer: buyer })

          // this.buyers.buyer['contactId'] ? this.changeContact(this.buyers.buyer['contactId']) : ''
        } else {
          // ! error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    }
    );
  }
  //
  // todo: listener to childrens
  public listenerChildrens = (e): void => {

    if (e.message == 'reloadContactDetail') {

      this.simpleRefresh(this.auxContact['id'])

    }
  }


  /*
    * Life Cycles
    */
  constructor(
    private route: ActivatedRoute,
    private ms: MessageService,
    private master: MasterService
  ) {
    this.saleId = parseInt(this.route.snapshot.params['id'])
    this.lotId = parseInt(this.route.snapshot.params['lotId'])


  }

  ngOnInit() {
    this.contactComplete = this.contacts.slice()
    this.fullContacts = this.contactComplete.slice()
    // get sale detail
    this.getSaleDetail(false)
  }


}
