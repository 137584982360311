import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MasterService } from '../../../../../services/master.service'
import { MessageService } from '../../../../../services/message.service'
import * as languageLibrary from '../../../../../services/language'
@Component({
  selector: 'fp-default-setup',
  templateUrl: './fp-default-setup.component.html',
  styleUrls: ['./fp-default-setup.component.scss']
})
export class InventoryContactsComponent implements OnInit {
  /*
  * Variables
  */
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  //  define if is open
  public isOpen: boolean = true
  // criterias array
  public criterias: string[] = ['Flat Rate', 'Percent']
  // frecuencys
  public frecuencys: string[] = ['monthly', 'weekly', 'biWeekly', 'semiMonthly']
  // permissions
  @Input() permissions: string[] = [] 
  // contact
  @Input() contact: Object = {}

  /*
  * Functions
  */
  public information = new FormGroup({
    id: new FormControl('', []),
    contactId: new FormControl('', []),
    statusDate: new FormControl('', []),
    accountNumber: new FormControl('', []),
    apr: new FormControl('', [Validators.pattern('[0-9]{1,2}')]),
    daysPerYear: new FormControl('', [Validators.required, Validators.pattern('[0-9]{1,3}')]),
    daysAllowed: new FormControl('', [Validators.required, Validators.pattern('[0-9]{1,3}')]),
    reductionCriteria: new FormControl('', []),
    reductionAmount: new FormControl('', []),
    reductionFrecuency: new FormControl('Monthly', []),
    oneTimeFeeCriteria: new FormControl('', []),
    // recurrentFeeCriteria: new FormControl('', []),
    recurrentFeeAmount: new FormControl('', []),
    recurrentFeeFrecuency: new FormControl('Monthly', []),
    oneTimeFeeAmount: new FormControl('', []),
  })
  get accountNumber() { return this.information.get('accountNumber') }
  get apr() { return this.information.get('apr') }
  get daysPerYear() { return this.information.get('daysPerYear') }
  get daysAllowed() { return this.information.get('daysAllowed') }
  get reductionCriteria() { return this.information.get('reductionCriteria') }
  get reductionAmount() { return this.information.get('reductionAmount') }
  get reductionFrecuency() { return this.information.get('reductionFrecuency') }
  get oneTimeFeeCriteria() { return this.information.get('oneTimeFeeCriteria') }
  // get recurrentFeeCriteria() { return this.information.get('recurrentFeeCriteria') }
  get recurrentFeeAmount() { return this.information.get('recurrentFeeAmount') }
  get recurrentFeeFrecuency() { return this.information.get('recurrentFeeFrecuency') }
  get oneTimeFeeAmount() { return this.information.get('oneTimeFeeAmount') }
  /*
  * functions
  */
  public save = (): void => {
    if (this.information.valid) {
      if (this.information.value['daysPerYear'] > 366) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['invalidDaysPerYear'] });
        return
      }

      this.master.sendPost('createContactInventory', { ...this.information.value, reductionFrecuency: 'Monthly', contactId: this.contact['id'], recurrentFeeFrecuency: 'Monthly' }, res => {
        if (res) {
          if (res.status == 200) {
            // * success
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });

          } else {
            // ! in case error 
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
          }
        } else {
          // in case API no response
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        }
      })
    }
  }
  /*
  * Life Cycles
  */
  constructor(private master: MasterService, private ms: MessageService) {
    this.ms.channelComponents$.subscribe(res => {
      if (res.message == "changeLanguage") {
        this.language = res.value
      }
    })
    // 
    
  }


  ngOnInit() {

    if (this.contact['inventory']) {
      
      delete this.contact['inventory']['recurrentFeeCriteria']
      this.information.setValue({ ...this.contact['inventory'] })
    }
  }

}
