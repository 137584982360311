import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { MasterService } from "../../../../services/master.service";
import { MessageService } from "../../../../services/message.service";
import { StoreService } from "../../../../services/store.service";
import * as languageLibrary from '../../../../services/language'

@Component({
  selector: 'after-sale-modal',
  templateUrl: './after-sale-modal.component.html',
  styleUrls: ['./after-sale-modal.component.scss']
})
export class AfterSaleModalComponent implements OnInit {
  // *********************
  //  variables
  // *********************
  @Input() kpi = {}
  //
  public data = {}
  //
  public isOpenTable: boolean = false
  //
  @Input() vendors: any[] = []
  //
  public loading: boolean = false
  // save the language
  @Input() language: string = 'EN'
  // set all words
  public words = languageLibrary.language
  //
  public users: any[] = []
  //
  public usersComplete: any[] = []
  //
  @Input() saleId: number = 0
  //
  @Output() emitterModal$: EventEmitter<Object> = new EventEmitter()
//
public dataPaymentSchedule:Object={}
//
public incrementalMode:boolean=false
//
public status = {}
  // *********************
  //  function
  // *********************
// ======================
  // ?  users
  // ======================
  // todo: search user
  public filter = (e) => {
    let word = (document.getElementById('userFilter') as HTMLInputElement).value.toLowerCase();

    let dataFound=[]
    if (e.key == 'Enter') {

      dataFound = dataFound.concat(this.usersComplete.filter(el => (el.saleBuyers[0].contact.firstName || '').toLowerCase().indexOf(word) > -1))
      dataFound = dataFound.concat( this.usersComplete.filter(el => (el.saleBuyers[0].contact.lastName || '').toLowerCase().indexOf(word) > -1))
      dataFound = dataFound.concat(this.usersComplete.filter(el => el.saleVehicles[0].inventory.stockNumber.toLowerCase().indexOf(word) > -1))
      dataFound = dataFound.concat(this.usersComplete.filter(el => (el.saleVehicles[0].inventory.vehicle.exteriorColor || '').toLowerCase().indexOf(word) > -1))
      dataFound = dataFound.concat( this.usersComplete.filter(el => (el.saleVehicles[0].inventory.vehicle.make || '').toLowerCase().indexOf(word) > -1))
      dataFound = dataFound.concat( this.usersComplete.filter(el => (el.saleVehicles[0].inventory.vehicle.model || '').toLowerCase().indexOf(word) > -1))
      dataFound = dataFound.concat( this.usersComplete.filter(el => (el.saleVehicles[0].inventory.vehicle.modelYear || '').toLowerCase().indexOf(word) > -1))

      this.users = dataFound.filter((v,i,a)=>a.indexOf(v)==i)

    }
  }
  //
  //  todo: seach users
  //
  public toggleTable = () => {
    this.isOpenTable = !this.isOpenTable
    if (this.isOpenTable) {
      this.readSales(0)
    }
  }
  //
  //  todo: seach users
  //
  public readSales = (saleId: number) => {
    saleId == 0 ? false : saleId
    this.loading = true;
    this.master.sendPost("CRUDForPayments", { saleId: saleId, activity: 'read' }, (res) => {
      this.loading = false;
      if (res) {
        if (res.status == 200) {
          // * success
          if (saleId) {
            this.isOpenTable = false
            this.saleId = saleId

            this.status=res.data.body.paymentStatusList
            this.getAfterSales(this.saleId)
          } else {
            this.usersComplete = res.data.allSales.filter(el => el['saleBuyers'].length > 0)

            this.users = this.usersComplete.slice()
          }
        } else {
          // ! error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }
    }
    );
  }
  // todo reset data
public resetData=()=>{
  this.emitterModal$.emit({message:'reset'})
}
  // todo: get after sales
  public getAfterSales = (id): void => {
    this.master.sendPost("afterSalesDispatcher", {
      params: {
        saleId: id
      }, activity: 'getAfterSaleDataBySaleId'
    }, (res) => {
      if (res) {
        if (res.status == 200) {

          this.data = res.data

          this.kpi['chargedCustomerTotal']=res.data.scheduledPayments.reduce((total,value)=>total + parseFloat(value.amountDue),0).toFixed(2)
          this.kpi['amountPaidTotal']=res.data.scheduledPayments.reduce((total,value)=>total + parseFloat(value.amountPayment),0).toFixed(2)

        } else {
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        this.ms.sendMessage("alert", {
          type: "danger", text: this.words[this.language]['apiNoResponse']
        });
      }
    });
  };

  // ======================
  // ?  header
  // ======================
  // todo: create header
  public createContractLinesHeader = (): void => {
    this.loading = true
    this.master.sendPost("afterSalesDispatcher", {
      params: {
        saleId: this.saleId
      }, activity: 'createAfterSaleHeader'
    }, (res) => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          // this.data = res.data.createdHeader
          this.getAfterSales(this.saleId)
        } else {
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        this.ms.sendMessage("alert", {
          type: "danger",
          text: this.words[this.language]['apiNoResponse'],
        });
      }
    });
  }
  // reload payments
  public listenerAfterSales = (e) => {

    switch (e.message) {
      case 'reloadTable':
        // this.emitterModal$.emit({ message: 'reloadTable' })
        this.getAfterSales(this.saleId)
        break;
        case 'recalculate kpi':
          // this.kpi['chargedCustomerTotal']=0
          // this.kpi['amountpaidTotal']=0
          // for (let index = 0; index < this.data['activeContracts'].length; index++) {
          //   debugger
          //   this.kpi['chargedCustomerTotal']=this.kpi['chargedCustomerTotal']+this.data['activeContracts'][index].afterSaleLines.reduce((total,value)=>total + parseFloat(value.amount),0)
          // }

          break
      default:
        break;
    }



  }


  /*
    ------------------------
     todo: open payment view in another window
    / ------------------------
    */
  public openPayment = () => {
    let index = window.location.href.indexOf('#/')
    let route = window.location.href.substr(0, index + 2)
    window.open(route + 'payments/' + this.saleId, 'fullscreen',
      'top=0,left=0,width=' + (screen.availWidth) + ',height =' + (screen.availHeight) +
      ',fullscreen=yes,toolbar=0 ,location=0,directories=0,status=0,menubar=0,resizable=0,scrolling=0,scrollbars=0')
  }
  // *********************
  //  life cycle
  // *********************
  constructor(private master: MasterService,
    private ms: MessageService,
    private store: StoreService) {

  }

  ngOnInit() {
    // listener
    this.ms.channelComponents$.subscribe((res) => {
      if (res.message == "changeLanguage") {
        this.language = res.value
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class

    if (changes.saleId) {
      if(changes.saleId.currentValue==0){
        this.data = {}
      }else{
        this.readSales(changes.saleId.currentValue)

      }

    }

  }

}
