import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// services
import { MasterService } from '../../services/master.service'
import { MessageService } from '../../services/message.service';
import { StoreService } from '../../services/store.service';
import * as languageLibrary from '../../services/language'

@Component({
  selector: 'customer-information-form',
  templateUrl: './customer-information-form.component.html',
  styleUrls: ['./customer-information-form.component.scss']
})
export class CustomerInformationFormComponent implements OnInit {
  /*
* Variables
*/
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language'):'EN'
  // set all words
  public words = languageLibrary.language
  // user informaion
  @Input() user = { email: '', firstName: '', lastName: '', phone: '', corporateURL: '' }
  // define the status of end-point
  public loading: boolean = false
  // list of languages
  public languagesList: object[] = [{ name: 'english', value: 'EN' }, { name: 'spanish', value: 'ES' }]
  // this variable is independence of form group
  public corporateURL: string = ''
  // Group of inputs
  public customerInformation = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(150), Validators.minLength(5)]),
    firstName: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(3), Validators.pattern('[A-Za-zÑñÁáÉéÍíÓóÚú ]{3,50}')]),
    lastName: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(3), Validators.pattern('[A-Za-zÑñÁáÉéÍíÓóÚú _-]{3,50}')]),
    phone: new FormControl('', [Validators.required, Validators.pattern('[0-9]{10,11}')]),
    languagePreference: new FormControl('ES', [Validators.required]),
  })
  // get information of inputs
  get email() { return this.customerInformation.get('email') }
  get firstName() { return this.customerInformation.get('firstName') }
  get lastName() { return this.customerInformation.get('lastName') }
  get phone() { return this.customerInformation.get('phone') }
  get languagePreference() { return this.customerInformation.get('languagePreference') }
  /*
* Functions
*/
  //  send the new user information 
  public save = (): void => {
    this.loading = true
    // set id
    this.customerInformation.value['id'] = this.store.userAccount['user'].id
    this.master.sendPost('updateUser', this.customerInformation.value, res => {
      this.loading = false
      if (res) {
        if (res.status == 200) {
          this.ms.sendMessage("alert", { type: "success", text: this.words[this.language].profileUpdated });
          // realod the information
          this.ms.channelComponents$.emit({ message: 'updateInformation', data: this.customerInformation.value })
        } else {
          // send message in case error 
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language].apiNoResponse });

      }
    })
  }

  public cancel = (): void => {
    this.customerInformation.setValue({
      email: this.store.userAccount['user'].email,
      firstName: this.store.userAccount['user'].firstName,
      lastName: this.store.userAccount['user'].lastName,
      phone: this.store.userAccount['user'].phone,
      languagePreference: this.store.userAccount['user'].languagePreference,
    })
    this.corporateURL = this.store.userAccount['user'].corpShortURL
  }

   /*
todo: change the language preferences
*/
public changeLanguage = (e): void => {
  this.customerInformation.get('name').setValue(e.target.value, {
    onlySelf: true
  })
}
  /*
* Life cycles
*/

  constructor(private master: MasterService, private ms: MessageService, private store: StoreService) {
    // set data from store service
    if (this.store.userAccount['user']) {
      this.customerInformation.setValue({
        email: this.store.userAccount['user'].email,
        firstName: this.store.userAccount['user'].firstName,
        lastName: this.store.userAccount['user'].lastName,
        phone: this.store.userAccount['user'].phone,
        languagePreference: this.store.userAccount['user'].languagePreference
      })
      this.corporateURL = this.store.userAccount['user'].corpShortURL
    }
        // detect change language
        this.ms.channelComponents$.subscribe(res => {
          if (res.message == "changeLanguage") {
            this.language = res.value
          }
        })

  }

  ngOnInit() {
    // detect change language
    this.ms.channelComponents$.subscribe(res => {
      if (res.message == "changeLanguage") {
        this.language = res.value
      }
    })
  }


}
